import React from 'react';
import { Redirect } from "react-router-dom";

import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/ApiService';

class insightPost extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.columnDefs = [
      { headerName: "구분", field: "index", sortable: true, width: 60, resizable: true },
      { headerName: "제목", field: "title", filter: true, width: 757, resizable: true },
      { headerName: "카테고리", field: "category", filter: true, width: 100, resizable: true },
      { headerName: "작성자", field: "writer", filter: true, width: 250, resizable: true },
      { headerName: "추천 여부", field: "recommend", filter: true, width: 160, resizable: true },
      { headerName: "좋아요 수", field: "likeCount", sortable: true, width: 100, resizable: true },
      { headerName: "스크랩 수", field: "scrapCount", sortable: true, width: 100, resizable: true },
      { headerName: "작성일", field: "publishDate", sortable: true, width: 100, resizable: true }
    ];
    this.state = {
      data: [],
      redirect: null,
      sendState: null
    };
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;

    let parameter = {
      from: 0,
      size: 500
    };

    let resData = await this.api.getPostList(parameter);
    resData = resData ? (resData.getPostList ? resData.getPostList : []) : [];

    if (resData && Array.isArray(resData) && resData.length > 0) {
      resData.map((item, index) => {
        item.index = index + 1;
        item.title = item.title.split('<br />').join("\n");
        item.desc = item.desc.split('<br />').join("\n");
        item.recommend = item.recommend ? "O" : "X";
        item.publishDate = moment(new Date(item.publishDate * 1000)).format('YYYY-MM-DD');
        return item;
      });

      this.setState({
        data: resData
      });
    }
  };

  onSelectionChanged = () => {
    var selectedRows = this.gridApi.getSelectedRows();
    this.setState({
      redirect: "/insight/post/" + selectedRows[0]._id,
      sendState: selectedRows[0]
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={{ pathname: this.state.redirect, state: { defaultState: this.state.sendState } }} />;
    }

    return <>
      <div style={{ display: "flex", width: "100%", padding: "0px 55px", justifyContent: "flex-end", marginBottom: 10 }}>
        <button onClick={() => this.setState({ redirect: "/insight/post/new" })}>추가</button>
      </div>
      <div className="ag-theme-balham" style={{ width: "100%", height: "595px" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          columnDefs={this.columnDefs}
          rowData={this.state.data}
          rowSelection="multiple"
          onSelectionChanged={this.onSelectionChanged}
        />
      </div>
    </>;
  }
}

export default (insightPost);