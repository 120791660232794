import React, { useEffect, useState } from 'react';

import GradeBrand from './grade/GradeBrand';

import styles from './MembershipPage.module.scss';

export default function GradePage() {

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>멤버쉽_등급 관리</h1>
      <div className={styles.content}>
        <div className={styles.brand}>
          <GradeBrand />
        </div>
        {/* <div className={styles.youtuber}>
          <h6 className={styles.sub_title}>유튜버</h6>
          <GradeBrand />
        </div> */}
      </div>
    </div>
  );
}