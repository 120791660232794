import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { Redirect } from "react-router-dom";
import moment from "moment";

import styles from './MonthlyGrade.module.css'

const getMonthlyGradeGql = gql`
  query getMonthlyGrade {
    getMonthlyGrade
  }
`

const now = parseInt(moment() / 1000);
const thisMonthStart = parseInt(+new Date(moment().format("YYYY-MM")) / 1000);
const prev1MonthStart = parseInt(+new Date(moment().subtract(1, "months").format("YYYY-MM")) / 1000);
const prev2MonthsStart = parseInt(+new Date(moment().subtract(2, "months").format("YYYY-MM")) / 1000);
const prev3MonthsStart = parseInt(+new Date(moment().subtract(3, "months").format("YYYY-MM")) / 1000);
const startDateArr = [thisMonthStart, prev1MonthStart, prev2MonthsStart, prev3MonthsStart];
const grades = ['standard', 'premium', 'professional', 'enterprise'];

export default function MonthlyGrade() {
  const [redirect, setRedirect] = useState(null);
  const [detailData, setDetailData] = useState(null);

  // 3달 전~ 체험판을 제외한 유저의 데이터
  const { data } = useQuery(getMonthlyGradeGql);
  const copyData = [...data?.getMonthlyGrade || []];

  // 이벤트, 쿠폰 제외
  const paidData = copyData.map(el => {
    return { ...el, membership: el.membership.filter(e => !(e.memo === '' || e.coupon || e.memo)) }
  }).filter(e => e.membership.length > 0)

  // 멤버십에 기존/신규/해지 추가
  paidData.map(el => {
    for (let i = 0; i < el.membership.length; i++) {
      let lastElem = el.membership.length - 1
      // 결제, 해지 동시에 있는 경우 열외
      if (el.membership[i].start_at === el.membership[i].expire_at) {
        el.membership.splice(i, 1);
      }
      // 첫 멤버십은 무조건 신규
      if (i === 0) {
        el.membership[i] = { ...el.membership[i], ...{ new: true } }
      }
      // 이전 expire ~ 현재 start 간에 하루 이상 차이나면 현재 신규 / 전월 해지 처리
      if (i > 0 && i <= lastElem) {
        if (el.membership[i].start_at - el.membership[i - 1].expire_at > 60 * 60 * 24) {
          el.membership[i] = { ...el.membership[i], ...{ new: true } }
          el.membership[i - 1] = { ...el.membership[i - 1], ...{ expire: true } }
        }
        if (grades.indexOf(el.membership[i].grade) > grades.indexOf(el.membership[i - 1].grade)) {
          el.membership[i] = { ...el.membership[i], ...{ upgrade: true } }
        }
        if (grades.indexOf(el.membership[i].grade) < grades.indexOf(el.membership[i - 1].grade)) {
          el.membership[i] = { ...el.membership[i], ...{ downgrade: true } }
        }
      }
      // 마지막 결제의 expire가 현재보다 낮으면 해지
      if (i === lastElem) {
        if (el.membership[lastElem].expire_at < now) {
          el.membership[lastElem] = { ...el.membership[lastElem], ...{ expire: true } }
        }
      }
    }
    return el;
  })

  // 월 시작점 unix time을 parameter로 받아서 등급별, 유형별로 return
  const getMonthlyData = (params) => {
    let monthEnd = moment(params * 1000).endOf("month").format("X");
    let result = {
      standard: { maintain: [], new: [], expire: [] },
      premium: { maintain: [], new: [], expire: [] },
      professional: { maintain: [], new: [], expire: [] },
      enterprise: { maintain: [], new: [], expire: [] },
      'youtuber-channelReport': { maintain: [], new: [], expire: [] },
      change: { upgrade: [], downgrade: [] }
    }

    paidData.map(el => {
      for (let i = 0; i < el.membership.length; i++) {
        let curMemb = el.membership[i]
        // 신규
        if (curMemb.new === true) {
          if (curMemb.start_at > params && curMemb.start_at < monthEnd) {
            result[curMemb.grade].new.push({ ...el, membership: curMemb })
          }
        }
        // 해지
        if (curMemb.expire === true) {
          if (curMemb.expire_at > params && curMemb.expire_at < monthEnd) {
            result[curMemb.grade].expire.push({ ...el, membership: curMemb })
          }
        }
        // 기존
        if (curMemb.start_at < params && curMemb.expire_at >= params) {
          result[curMemb.grade].maintain.push({ ...el, membership: curMemb })
          // 등급 변경
          if (curMemb.upgrade === true) {
            result.change.upgrade.push({ ...el, membership: curMemb })
          }
          if (curMemb.downgrade === true) {
            result.change.downgrade.push({ ...el, membership: curMemb })
          }
        }
      }
      return el;
    });

    return result;
  }

  let tableData = startDateArr.map(el => {
    let result = { date: moment(el * 1000).format("YYYY-MM"), ...getMonthlyData(el) }
    return result
  })

  const tableBody = tableData?.map((el, index) => {
    return (
      <tr key={index} onClick={() => { toDetailPage(el.date); setDetailData(el); }}>
        <td>{el.date}</td>
        <td>
          {el.standard.maintain.length + el.premium.maintain.length + el.professional.maintain.length + el.enterprise.maintain.length}/
          {el.standard.new.length + el.premium.new.length + el.professional.new.length + el.enterprise.new.length}/
          {el.standard.expire.length + el.premium.expire.length + el.professional.expire.length + el.enterprise.expire.length}
        </td>
        <td>{el.standard.maintain.length}/{el.standard.new.length}/{el.standard.expire.length}</td>
        <td>{el.premium.maintain.length}/{el.premium.new.length}/{el.premium.expire.length}</td>
        <td>{el.professional.maintain.length}/{el.professional.new.length}/{el.professional.expire.length}</td>
        <td>{el.enterprise.maintain.length}/{el.enterprise.new.length}/{el.enterprise.expire.length}</td>
        <td>
          <span style={{ color: "#ff004f" }}>▲{el.change.upgrade.length} </span>
          <span style={{ color: "#1260cc" }}>▼{el.change.downgrade.length}</span>
        </td>
      </tr>
    )
  })

  const toDetailPage = (date) => {
    setRedirect(`member/month/${date}`)
  }

  if (redirect) {
    return <Redirect push={true} to={{ pathname: redirect, state: { data: detailData } }} />
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>멤버십인원수
          <span className={styles.description}> (기존/신규/해지)</span>
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>날짜</th>
            <th>전체</th>
            <th>스탠다드</th>
            <th>스타트업</th>
            <th>프로페셔널</th>
            <th>엔터프라이즈</th>
            <th>등급변경</th>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </table>
    </div>)
}