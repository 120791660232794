import gql from 'graphql-tag';
import { BannerShortFragment, BannerDefaultFragment } from '../Banner/gql'
export const getInventoryList = gql`
  ${BannerShortFragment}
  query getInventoryList(
    $startDate: String
    $endDate: String
    $ignoreExpire: Boolean
  ) {
    getInventoryList(
      startDate: $startDate
      endDate: $endDate
      ignoreExpire: $ignoreExpire
    ) {
      _id
      createAt
      updateAt
      userId
      userInfo {
        name
        email
      }
      banners {
        ...BannerShortFragment
      }
    }
  }
`

export const getInventory_withBanners = gql`
  ${BannerDefaultFragment}
  query getInventory(
    $_id: String!
  ) {
    getInventory(
      _id:$_id
    ) {
      _id
      createAt
      userId
      userInfo {
        name
        email
      }
      banners {
        ...BannerDefaultFragment
      }
    }
  }
`


export const upsertInventory = gql`
  mutation upsertInventory(
    $_id: String
    $banner: [String]
    $userId: String
  ) {
    upsertInventory(
      _id:$_id
      banner:$banner
      userId:$userId
    ) {
      result
    }
  }
`


export const deleteInventory = gql`
  mutation deleteInventory($_id:String) {
    deleteInventory(_id:$_id){
      result
    }
  }
`