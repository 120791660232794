import React from "react";

import { Route } from "react-router-dom";
import SendMailFunc from "../components/sendMail/SendMail"

export default function SendMail(props) {
  return (
    <>
        <Route exact path={props.match.path} component={SendMailFunc} />
    </>
  );
}
