import React from 'react';
import { Redirect } from "react-router-dom";

import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import ApiService from '../../services/ApiService';
import Styles from './enterpriseList.module.css';
import { Checkbox } from 'material-ui';

class enterpriseList extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.columnDefs = [
      { headerName: "No.", field: "index", sortable: true, resizable: true,  width: 50 },
      { headerName: "이름", field: "title", sortable: true, filter: true,  resizable: true },
      { headerName: "마스터", field: "master", sortable: true, filter: true,  resizable: true },
      { headerName: "인원", field: "count", resizable: true, width: 80 },
      // { headerName: "타입", field: "type", filter: true,  resizable: true },
      { headerName: "CR1", field: "cloudRanking", resizable: true,  width: 50,
        cellStyle: params => {
          if (params.value !== 'X') return { background: '#66DEDA' };
          else return null;
        }
      },
      { headerName: "CR2", field: "cloudRanking2", resizable: true,  width: 50,
        cellStyle: params => {
          if (params.value !== 'X') return { background: '#33CECA' };
          else return null;
        }
      },
      { headerName: "ET", field: "easyTracker", resizable: true,  width: 50,
        cellStyle: params => {
          if (params.value !== 'X') return { backgroundColor: '#FFB400' };
          else return null;
        }
      },
      { headerName: "VC", field: "videoCuration", resizable: true,  width: 50, 
        cellStyle: params => {
          if (params.value !== 'X') return { backgroundColor: '#C290FF' };
          else return null;
        }
      },
      { headerName: "시작일(UTC)", field: "start_at", sortable: true,  resizable: true, width: 100 },
      { headerName: "종료일(UTC)", field: "expire_at", sortable: true,  resizable: true, width: 100 },
      { headerName: "메모", field: "memo", filter: true,  resizable: true }
    ];
    this.defaultType = null;
    this.state = {
      isExceptExpired: false,
      typeData: [],
      data: [],
      redirect: null,
      sendState: null
    }
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;

    let parameters = {
      from: 0,
      size: 100
    };

    // let resTypeData = await this.api.getEnterpriseTypeList(parameters);
    // resTypeData = resTypeData ? (resTypeData.getEnterpriseType || null) : null;

    // if (resTypeData) {
    //   let defaultTypeArray = resTypeData.filter(item => item.isDefault);
    //   if (Array.isArray(defaultTypeArray) && defaultTypeArray.length > 0) {
    //     this.defaultType = defaultTypeArray[0];
    //   }
    // }

    let resData = await this.api.getEnterpriseList(parameters);
    resData = resData ? (resData.getEnterprise || []) : [];

    resData.map((item, index) => {
      let masterResult = null;
      let masterArray = item.user.filter(i => i.isMaster);
      if (masterArray.length > 0) masterResult = masterArray[0].email;
      // let typeLabel = "";
      // if (item.type && Array.isArray(item.type)) {
      //   item.type.map((i, _index) => {
      //     typeLabel += i.label;
      //     if (_index !== item.type.length - 1) {
      //       typeLabel += ", "
      //     }
      //   })
      // }
      item.cloudRanking = "X";
      item.cloudRanking2 = "X";
      item.videoCuration = "X";
      item.easyTracker = "X";      
      if (item.type && Array.isArray(item.type)) {
        item.type.forEach((type, _index) => {
          if(type.value === "cloudRanking") item.cloudRanking = "O";
          else if(type.value === "cloudRanking2") item.cloudRanking2 = "O";
          else if(type.value === "videoCuration") item.videoCuration = "O";
          else if(type.value === "easyTracker") item.easyTracker = "O";
        })
      }

      if (typeof item.memo === "string") {
        item.memo = item.memo.replace(/<br>/g, "\n");
      }

      item.index = index + 1;
      item.maxCount = item.maxCount || "미설정";
      item.nowCount = item.user? item.user.length : 0;
      item.count = item.nowCount + " / " + item.maxCount;

      // item.type = typeLabel !== "" ? typeLabel : "미설정";



      item.startEpoch = item.start_at;
      item.expireEpoch = item.expire_at;
      item.start_at = moment(new Date(item.start_at * 1000)).format('YYYY-MM-DD HH:mm');
      item.expire_at = moment(new Date(item.expire_at * 1000)).format('YYYY-MM-DD HH:mm');
      item.master = masterResult || "-";

      let nowEpoch = Math.floor(new Date().valueOf() / 1000);
      if (nowEpoch >= item.expireEpoch) {
        item.isExpireEpoch = true;
      }

      return item;
    });

    this.setState({
      data: resData
    });
  }

  getRowStyle = (params) => {
    let data = params.data;
    // let nowEpoch = Math.floor(new Date().valueOf() / 1000);
    // if (nowEpoch >= data.expireEpoch) {
    //   return { backgroundColor: "#d9d9d9" }
    // }
    // return null;
    if(data.isExpireEpoch) return { backgroundColor: "#d9d9d9" };
    return null;
  }

  onSelectionChanged = () => {
    var selectedRows = this.gridApi.getSelectedRows();
    this.setState({
      redirect: "/enterprise/detail/" + selectedRows[0]._id,
    });
  }

  onCheckedExpireEpoch = (e) => {
    let isExceptExpired = false;
    if(e.target.checked) isExceptExpired = true

    this.setState({
      isExceptExpired
    });
  }

  render() {
    // console.log("=======");
    // console.log(this.state.data);
    if (this.state.redirect) {
      return <Redirect push={true} to={{ pathname: this.state.redirect }} />
    }
    let rowData = this.state.data;
    if(this.state.isExceptExpired) rowData = rowData.filter(data => !data.isExpireEpoch);

    return <>
      <div className={Styles.add}>
        <button onClick={() => this.setState({ redirect: "/enterprise/detail/new" })}>추가</button>
      </div>
      <div className={Styles.expireEpoch}>
        종료된 엔터프라이즈 제거 <input type="Checkbox" id="expireEpochCheckbox" onChange={(e) => this.onCheckedExpireEpoch(e)} />
      </div>
      <div className={Styles.label}>
        <p className={Styles.labelCR1}>클라우드랭킹1(CR1)</p>
        <p className={Styles.labelCR2}>클라우드랭킹2(CR2)</p>
        <p className={Styles.labelET}>이지트래커(ET)</p>
        <p className={Styles.labelVC}>영상큐레이션(VC)</p>
      </div>
      <div className="ag-theme-balham" style={{ width: "100%", height: "595px" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          getRowStyle={this.getRowStyle}
          columnDefs={this.columnDefs}
          rowData={rowData}
          rowSelection="multiple"
          onSelectionChanged={this.onSelectionChanged}
          enableCellTextSelection={true}
        />
      </div>
    </>
  }
}

export default enterpriseList;