/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import moment from "moment";

import styles from "./MonthlyGradeDetail.module.css";

const grades = ['standard', 'premium', 'professional', 'enterprise'];

export default function MonthlyGradeDetail(props) {
  const propsData = props.history.location.state.data;
  const [rowData, setRowData] = useState([]);

  const getGroupedData = (params) => {
    let result = []
    
    grades.map(el =>
      result.push(...propsData[el][params])
    )
    result.sort((a, b) => b.membership.start_at - a.membership.start_at)

    return result;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    setRowData([getGroupedData('maintain'), getGroupedData('new'), getGroupedData('expire')])
  }, [propsData])

  const tableBody = (params) => {
    if (params) {
      let result = params.map((el, index) => {
        return (
          <tr key={index}>
            <td>{index + 1}</td>
            <td>
              {moment(el.membership.start_at * 1000).format(
                "MM-DD HH:mm"
              )}
            </td>
            <td>{el.membership.grade}</td>
            <td>
              {moment(el.membership.expire_at * 1000).format(
                "MM-DD HH:mm"
              )}
            </td>
            <td>{el.usertype}</td>
            <td>{el.email}</td>
            <td>{el.name}</td>
            <td>{el._id}</td>
          </tr>
        );
      });
      return result;
    }
  };

  const table = ["기존 회원", "신규 회원", "해지 회원"].map((el, index) => {
    return (
        <div className={styles.container} key={index}>
          <div className={styles.header}>
            <div className={styles.title}>{el}: {rowData[index]?.length}명</div>
          </div>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>번호</th>
                <th>시작일</th>
                <th>등급</th>
                <th>만료일</th>
                <th>회원구분</th>
                <th>이메일</th>
                <th>닉네임</th>
                <th>아이디</th>
              </tr>
            </thead>
            <tbody>{tableBody(rowData[index])}</tbody>
          </table>
        </div>
    );
  });

  return <>{table}</>;
}
