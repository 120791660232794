import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { Link } from "react-router-dom";
import ApiService from '../../services/ApiService';


const API = new ApiService();

export default function CouponEdit(props) {

  
  const [couponInfo, setCouponInfo] = useState(props.location.state.selectedData);
  const [selectDate, setSelectDate] = useState(moment(couponInfo.expiryDate).toDate());

  const changeExpiryDate = (event) => {
    setSelectDate(event);
    couponInfo.expiryDate = new Date(event).toISOString().substr(0, 10);
  }
  
  //parseInt(new Date("2021-06-03").valueOf()/1000); 쿠폰 등록 시 시간
  const setCouponData = (e) => {
    let couponObject = { [e.target.name]: e.target.value };
    if (couponInfo[e.target.name] && e.target.value === "") {
      couponObject = { ...couponInfo, ...couponObject };
      delete couponObject[e.target.name];
      setCouponInfo(couponObject);
    } else if(e.target.value !== ""){
      couponObject = { ...couponInfo, ...couponObject };
      setCouponInfo(couponObject);
    }
  }

  const validationCheck = () => {
    let checkKeyName = { expiryDate: "만료일", grade: "등급", code: "쿠폰 코드", term: "사용 기간", description: "정보" };
    let checkKeys = Object.keys(checkKeyName);
    let couponInfoKeys = Object.keys(couponInfo);
    let invalidInfo = { filedName: "", valid: true };
    if (couponInfoKeys.length !== checkKeyName.length) {
      for (let i = 0; i < checkKeys.length; i++) {
        if (!couponInfoKeys.includes(checkKeys[i])) {
          invalidInfo.filedName = checkKeyName[checkKeys[i]];
          invalidInfo.valid = false;
          break;
        }
      }
      return invalidInfo;
    }
    return invalidInfo;
  }

  const editCouponAction = async (e) => {
    let validCheck = validationCheck();

    if (!validCheck.valid) {
      alert(`${validCheck.filedName} 입력값을 확인해 주세요.`);
      e.preventDefault();
      return;
    }

    await API.editCoupon(couponInfo);
  } 

  return (
    <>
      <form id="metaForm">
        <div className="form-group">
          <label for="grade">등급</label>
          <div id="grade">
            <select defaultValue={couponInfo.grade} name="grade" onChange={setCouponData}>
              <option value="standard">스탠다드</option>
              <option value="premium">스타트업</option>
              <option value="professional">프로페셔널</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label for="expiryDate">만기일</label>
          <div id="expiryDate" style={{ width: 150 }}>
            <DatePicker
              selected={selectDate}
              onChange={changeExpiryDate}
              className="react-datepicker-input"
              dateFormat="yyyy-MM-dd"
              minDate={moment(new Date(Date.now()-1000*60*60*24).toISOString().substr(0, 10)).toDate()}
              onFocus={(e) => e.target.readOnly = true}
              shouldCloseOnSelect={true}
              disabledKeyboardNavigation
            />
          </div>
        </div>
        <div className="form-group">
          <label for="code">쿠폰 코드</label>
          <div style={{fontWeight : "bold"}}>{couponInfo.code ? couponInfo.code : ""}</div>
        </div>
        <div className="form-group">
          <label for="term">사용 기간</label>
          <input type="text" id="term" name="term" value={couponInfo.term ? couponInfo.term : ""} className="form-control" placeholder="사용기간 입력 ex) 일수는 숫자입력 1(1일) 3(3일), 월 1m(1개월), 3m(3개월)" onChange={setCouponData} />
        </div>
        <div className="form-group">
          <label for="description">정보</label>
          <input type="text" id="description" name="description" value={couponInfo.description ? couponInfo.description : ""} className="form-control" placeholder="쿠폰에 대한 정보" onChange={setCouponData} />
        </div>
      </form>
      <div className="row">
        <div className="col-lg-12">
          <center>
            <Link onClick={editCouponAction} to={"/couponList"}>
              <Button variant="contained" color="primary" style={{ margin: "auto 30px", padding: "10px 35px" }}>
                수정
            </Button>
            </Link>
            <Link to={"/couponList"}>
              <Button variant="contained" style={{ margin: "auto 30px", padding: "10px 35px" }}>
                취소
              </Button>
            </Link>
          </center>
        </div>
      </div>
    </>
  )
}