import React from 'react';
import { SignIn } from 'aws-amplify-react';
import { Auth } from 'aws-amplify';
import awsconfig from '../../aws-exports';

export default class CustomSignIn extends SignIn{
  signInFunc = () => {
    let rtn = window.origin.match(/canary/i);
    if(rtn && rtn.length > 0){
      let addr = window.origin.replace('://', '://canary.');
      addr = "https://canary.admin.vling.net:8848";
      awsconfig.aws_user_pools_id = process.env.REACT_APP_COGNITO_POOL_ID;
      awsconfig.aws_user_pools_web_client_id = process.env.REACT_APP_COGNITO_POOL_CLIENT_ID;
      awsconfig.oauth.redirectSignIn = addr;
      awsconfig.oauth.redirectSignOut = addr;
      awsconfig.oauth.domain = process.env.REACT_APP_COGNITO_POOL_OAUTH_DOMAIN;

      awsconfig.cookieDomain = '.' + window.location.hostname;
      awsconfig.cookieSettings = {
        sameSite: 'strict'
      };

      Auth.configure(awsconfig);      
    }
    Auth.federatedSignIn();
  };
  showComponent(){
    //TODO: Implement design component
    return (
      <>
      <div>
        <h3>관리자 계정은 가입신청 후, 승인을 받아야만 사용할 수 있습니다.</h3>
      </div>
      <div>
        <button onClick={this.signInFunc}>로그인 또는 회원가입</button>
      </div>
      </>
    );
  }
}