import React from "react";
import { Link , Redirect } from "react-router-dom";

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import { AgGridReact } from 'ag-grid-react';
import { AllcommunityModules } from "ag-grid-community";

import ApiService from "../../services/ApiService";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import moment from "moment";

class MetaTagList extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      modules: AllcommunityModules,
      columnDefs: [
        {headerName: "페이지명", field: "page_name", width: 20 },
        {headerName: "URL", field: "url", width: 20},
        {headerName: "타이틀", field: "title", width: 30 },
        {headerName: "Description", field: "description", width: 50 },
        {headerName: "Site_name", field: "site_name", width: 50 },
        {headerName: "배너이미지", field: "banner", width: 20 },
      ],
      defaultColDef: {
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "multiple",
      paginationPageSize: 100,
      paginationNumberFormatter: function (params) {
        return params.value.toLocaleString();
      },
      data: [],
      rowData: [],
      selectedData: [],
      redirect: null,
      searchStartDate: moment().format("YYYY-MM-DD"),
      searchEndDate: moment().format("YYYY-MM-DD"),
      HighlightDate: []
    }
  }

  // ============================================================= Life cycle 

  componentDidMount() {
    //데이터 주입
    this.initialize();
  }

  // ============================================================= Component function

  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
    // let metaData = await this.api.getMetaTagList();
    // console.log("metaData",metaData);
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    // let resData = await this.api.getHottrendList();
    let metaData = await this.api.getMetaTagList();
    console.log("metaData",metaData);
    // resData = resData.trendData;
    
    let tempData = [];
    if(metaData && Array.isArray(metaData.getMetaTagList)){
      metaData.getMetaTagList.map((metaData,index) => {
        tempData.push({
          page_name : metaData.page_name,
          url : metaData.url,
          title : metaData.title,
          description : metaData.description,
          site_name : metaData.site_name,
          banner : metaData.banner,
          id : metaData._id
        })
      });
    }

    this.setState({
      rowData: tempData,
    });

    this.gridApi.sizeColumnsToFit();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById("page-size").value;
    this.setState({
      paginationPageSize: value
    })
    this.gridApi.paginationSetPageSize(Number(value));
  }

  onSelectionChanged = () => {
    var selectedRow = this.gridApi.getSelectedRows();
    this.setState({
      redirect: "/metaTagList/" + selectedRow[0].id,
      selectedData: selectedRow[0],
    });
  }

  onCreateMetaTag = () => {
    this.setState({
      redirect: "/metaTagList/create",
      selectedData: null
    })
  }

  onClickSearch = () => {
    let searchValue = document.getElementById("datasearchInput").value;
    console.log(searchValue);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={{ pathname: this.state.redirect, state: { selectedData: this.state.selectedData, HighlightDate: this.state.HighlightDate}}} />
    }

    return (
      <>
        <div className="row" style={{ height: "600px" }}>
          <div className="col-lg-12">
            <div className="list-pagination-wrap">
              <div className="list-pagination-content">
                Page Size:
                <select onChange={this.onPageSizeChanged.bind(this)} id="page-size" style={{marginLeft : "10px"}}>
                  <option value="100" selected="">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="5000">5000</option>
                </select>
                <div style={{marginBottom : "3%"}}>
                  <Button id="BtnNewThema" variant="contained" onClick={this.onCreateMetaTag} color="primary" style={{ margin: "auto", padding: "5px 10px", float : "right" }}>
                      메타태그 등록
                  </Button>
                </div>
              </div>
            </div>
            <div className="ag-theme-balham" style={{ height: "100%", width: "100%",  marginTop : "10px" }}>
              <AgGridReact
                modules={this.state.modules}
                floatingFilter={true}
                columnDefs={this.state.columnDefs}
                autoGroupColumnDef={this.state.autoGroupColumnDef}
                defaultColDef={this.state.defaultColDef}
                debug={true}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
                paginationNumberFormatter={this.state.paginationNumberFormatter}
                rowSelection={this.state.rowSelection}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}>
              </AgGridReact>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default (MetaTagList);