import React from 'react';
import { Redirect } from "react-router-dom";

import Datasheet from "react-datasheet";

import 'react-datasheet/lib/react-datasheet.css';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import ApiService from '../../services/ApiService';

// import * as moment from 'moment';
// import update from 'react-addons-update'

class InfluencerChannelsAdd extends React.Component {
  
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.loading = false;
    this.state = {
        modules: AllCommunityModules,
        columnDefs: [
          {
            headerName: "",
            field: "",
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            width:20
          },
            { headerName: "분류", field: "type" , filter: true },
            { headerName: "값", field: "_id" , filter: true},
        ],        
        defaultColDef: {
          editable: false,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          resizable: true,                 
        },
        rowSelection: "multiple",
        rowData: [],
        redirect: null,
        grid: [],
        selectedRows: [], 
        isFirstPage : true
      };
  }
 

  // ============================================================= Life cycle 
  
  componentDidMount() {
    //데이터 주입
    this.initialize();

  }


   
  // ============================================================= Component function
   

  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {    
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
    
    let dateSheeteArr = [];
    for(let i=0; i< 101; i++) {
      if (i === 0) {
        dateSheeteArr.push([{readOnly: true, value: '' , width : "10%"},{value: '채널 주소', readOnly: true}]);
      } else {
        dateSheeteArr.push([{readOnly: true, value: i , width : "10%"}, {value: ""}]);
      }
    }

    let columnDefs= [
      { headerName: "분류", field: "type" , filter: true, width : 350},
      { headerName: "값", field: "_id" , filter: true, width : 350},
    ];

    this.setState({
      grid : dateSheeteArr,
      isFirstPage : true,
      columnDefs : columnDefs,
      rowData : [] 
    })
  }


  onGridReady = async (params) =>{
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.sizeColumnsToFit();

    let resData =  await this.api.getAddNewChannels();

    this.setState({ 
      rowData: resData.newChannels
    });
  };
 
  onPageSizeChanged(newPageSize) {
    var value = document.getElementById("page-size").value;
    this.setState({
        paginationPageSize : value
    })
    // this.gridApi.paginationSetPageSize(Number(value));
  }

  onSelectionChanged = async () =>{
    var selectedRows = this.gridApi.getSelectedRows();
    
    let channelIdArr = selectedRows.map((item , index) =>{
      return item._id;
    })

    channelIdArr = channelIdArr.filter(function(item) {
      return item;
    });
    
    this.setState({ 
      selectedRows: channelIdArr
    });
  }

  
  addNewChannels = async () => {
    this.setState({loading: true});
    
    let channelIdArr = this.state.grid.map((item, index)=>{
      if (index > 0 && item[1].value !== "") {
        return item[1].value;
      }else{ return "" }
    });
    
    //undefined , null 데이터 필터링
    channelIdArr = channelIdArr.filter(function(item) {
      return item;
    });
    
    if (channelIdArr.length > 0) {
      let resData = ""; 
      resData = await this.api.addNewChannels(channelIdArr);
    
      if (resData) {
        let data = [];
        data = await this.api.getAddNewChannels();
        data = data ? data.newChannels : [];
        this.dataInit();
        
        this.setState({ 
          rowData: data,
          loading: false
        });
      }
    } else {
      alert("데이터가 없습니다.");
      this.setState({loading: false});
    }
  }
  
  deleteNewChannels = async () => {
    this.setState({loading: true});

    if (this.state.selectedRows.length > 0) {
      let deleteResult = await this.api.deleteNewChannels(this.state.selectedRows);

      if (deleteResult) {
        let getResult =  await this.api.getAddNewChannels();

        this.setState({ 
          rowData: getResult.newChannels,
          loading: false
        });
      }
    } else {
      alert("삭제할 대상을 선택해주세요.");
      this.setState({loading: false});
    }
  }


  // ============================================================= Component Render

  render() {    
    if (this.state.redirect) {
        return <Redirect push={true} to={this.state.redirect} />
    }

    return (
    <>
        <div className="row">
          <div className="col-lg-2">
            <label>신규 채널 URL</label>
          </div>
          <div className="col-lg-10">
          </div>
        </div>

        <div className="section-line"></div>

        <div className="row" style={{height: "400px"}}>
            <div className="col-lg-6">
              <div className="scrollbar backoffic-scrollbar">
                <Datasheet
                    className="influencer-channels-datasheet"
                    data={this.state.grid}
                    valueRenderer={(cell) => cell.value}
                    onContextMenu={(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
                    onCellsChanged={changes => {
                      const grid = this.state.grid.map(row => [...row])
                      changes.forEach(({cell, row, col, value}) => {
                        grid[row][col] = {...grid[row][col], value}
                      })
                      this.setState({ 
                        grid, 
                        excelData : grid
                      })
                    }}
                  />
              </div>
              <button onClick={this.addNewChannels} disabled={this.state.loading}>저장</button>
            </div>
            <div className="col-lg-6">
                <div>
                  <button onClick={this.deleteNewChannels} disabled={this.state.loading}>삭제</button>
                </div>  
                <div className="ag-theme-balham" style={{height: "100%", width: "100%" }}>
                    <AgGridReact
                        modules={this.state.modules}
                        floatingFilter={true}
                        columnDefs={this.state.columnDefs}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        defaultColDef={this.state.defaultColDef}
                        debug={true}
                        pagination={true}
                        onSelectionChanged={this.onSelectionChanged.bind(this)}
                        rowSelection={this.state.rowSelection}                      
                        onGridReady={this.onGridReady}
                        rowData={this.state.rowData}
                    >                            
                    </AgGridReact>
                </div>
                <div style={{ marginBottom : 10 }}></div>
                {
                  !this.state.isFirstPage ? 
                    <button onClick={this.addNewChannels} disabled={this.state.loading}>데이터 반영</button>
                  : 
                    <div></div> 
                }
            </div>
        </div>
    </>
    );
  }
}

export default (InfluencerChannelsAdd);