import React from 'react';

import Modal from "react-responsive-modal";

const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

class UnApprovalModal extends React.Component {
  
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = { 
      data: [] , 
      open: false
    };
  }
 

  // ============================================================= Life cycle 
  


   
  // ============================================================= Component function
   
  requestUnapproved= () =>{
    if (window.confirm('거부하시겠습니까?')){
        let reason = document.getElementById("reason").value();
        if ( reason == null || reason == "" || reason == undefined) {
            alert("사유가 없습니다. 사유를 등록해주세요.");
        } else {
            this.props.requestUnapproved(reason);
            this.props.onCloseModal();
        }
    }
  }
  

  // ============================================================= Component Render

  render() {    
    return (
        <>
         {/* 광고제안 모달 팝업 템플릿 */}
         <Modal 
        styles={{modal: { margin: "0px" , width: "480px" , height:"550px", padding: "10px" , background : "#15AAFA" , borderRadius: 5, alignSelf:"center" , marginLeft:"35%"}}}
        open={this.props.open} 
        onClose={this.props.onCloseModal}
        center={true}
        >
            <div>
                <textarea id="reason"></textarea>
            </div>
            <div className="advertising-wrap-bottom-action">
              <button type="button" onClick={this.requestUnapproved} >거부</button>
            </div>
        </Modal>
      </>
    );
  }

  

}

export default (UnApprovalModal);