import React from 'react';
import { BrowserRouter } from "react-router-dom";

import Auth from '@aws-amplify/auth';
import { Authenticator, SignIn, Greetings } from 'aws-amplify-react';
import awsconfig from './aws-exports';

import CustomSignIn from './components/auth/CustomSignIn';

import ApolloProviderWrapper from './services/ApolloProviderWrapper';
import Layout from './pages/layout/Layout'
// import logo from './logo.svg';
import './App.css';

awsconfig.aws_user_pools_id = process.env.REACT_APP_COGNITO_POOL_ID;
awsconfig.aws_user_pools_web_client_id = process.env.REACT_APP_COGNITO_POOL_CLIENT_ID;
awsconfig.oauth.redirectSignIn = process.env.REACT_APP_BACKEND_HOST;
awsconfig.oauth.redirectSignOut = process.env.REACT_APP_BACKEND_HOST;
awsconfig.oauth.domain = process.env.REACT_APP_COGNITO_POOL_OAUTH_DOMAIN;

Auth.configure(awsconfig);

class App extends React.Component {
  render() {
    if (this.props.authState === "signedIn") {
      return (
        <>
          <React.Suspense>
            <BrowserRouter>
              <ApolloProviderWrapper>
                {/* 차후 인증 페이지 이부분에 적용하고 인증 값이 있을때만 Layout을 보여주시면 됩니다. */}
                <Layout />
              </ApolloProviderWrapper>
            </BrowserRouter>
          </React.Suspense>
        </>
      );
    } else {
      return null;
    }
  }
}

class AppWithAuth extends React.Component {
  render() {
    return (
      <div>
        <Authenticator hide={[SignIn, Greetings]}>
          <CustomSignIn override={'SignIn'} />
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;

