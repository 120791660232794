import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import { unsendableAdsProposal, getQuotas } from './gql';
import CampaignModalPreview from './CampaignModalPreview';
import CampaignModalList from './CampaignModalList';

export default function CampaignModal(props) {
  const { modalData, setShowModal, setCampaignDataUpdated, openScroll } = props;
  const [_showModal, _setShowModal] = useState(false);
  const [channelData, setChannelData] = useState([]);
  const [selectedCampaignLists, setSelectedCampaignLists] = useState([]);

  const [getQuotasFunc] = useLazyQuery(getQuotas);
  const [unsendableAdsProposalFunc] = useMutation(unsendableAdsProposal);

  const previewModalOpen = () => {
    _setShowModal(true);
    setChannelData(modalData)
  };

  const handleCheckboxChange = (event, channelId, thumbnails, title, description) => {
    const isChecked = event.target.checked;
    let channels = selectedCampaignLists.slice();
    const channelIndex = channels.findIndex((channel) => channel.channelId === channelId);
    if (isChecked && channelIndex === -1) {
      channels.push({ channelId, title, thumbnails, description});
    } else if (!isChecked && channelIndex !== -1) {
      channels.splice(channelIndex, 1);
    }
    setSelectedCampaignLists(channels);
  };

  const handleApiRequestSend = async (selectedChannels) => {
    const involvedIds = selectedChannels

    if (involvedIds.length === 0) {
      alert('선택된 항목이 없습니다.')
      return;
    }

    const variables = {
      objectId: modalData.objectId,
      makerId: modalData.userId,
      makerName: modalData.makerId,
      campaignType: "Target",
      title: modalData.title,
      description: modalData.desc,
      productName: modalData.productName,
      involvedIds: involvedIds,
      step: 3,
      useQuota: involvedIds.length,
      email: modalData.email,
      language: modalData.language,
    };


    if (variables && involvedIds && window.confirm(`발송 불가 처리하시겠습니까 ?`)) {
      try {
        const { data: res } = await getQuotasFunc({fetchPolicy: 'network-only', variables: {userId: modalData.userId}})

        variables["remainQuotas"] = res.getQuotas.remainQuotas

        await unsendableAdsProposalFunc({
          variables
        })
        alert('발송 불가 처리되었습니다.')
        setCampaignDataUpdated(true);
      } catch (e) {
        console.error('Error', e.message);
        alert('오류가 발생했습니다. 다시 시도해주세요.')
      }
    }
  };

  return (
    <div className="modal">
      <div className="modal_content">
        <span className="close" onClick={() => {setShowModal(false); openScroll();}}>
          &times;
        </span>
        <div className='campaign_info'>
          <span>캠페인명: {modalData.campaignName}</span>
          <span>만든사람: {modalData.makerId}</span>
          <span>등급: {modalData.grade}</span>
        </div>
        <hr />
        <div className='campaignList_previewBtn'>
          <div className='campaignList'>Campaign Channel List</div>
          <button className='previewBtn' onClick={() => {handleApiRequestSend(selectedCampaignLists)}}>발송불가</button>
          <button className='previewBtn' onClick={previewModalOpen}>보낸메일 확인하기</button>
        </div>
        {
          modalData?.involvedChannels?.map((x, index) => (
            <>
              <CampaignModalList key={x.channelId}
                index={index}
                modalData={modalData}
                channelId={x.channelId}
                thumbnails={x.thumbnails}
                title={x.title}
                description={x.description}
                onCheckboxChange={handleCheckboxChange}
                setCampaignDataUpdated={setCampaignDataUpdated}
              />
            </>
          ))
        }
        {
          _showModal && (
            <CampaignModalPreview 
              channelData={channelData}
              _setShowModal={_setShowModal}
            />
          )
        }
      </div>
    </div>
  )
}