import React, { useState } from 'react';
import ApiService from '../../services/ApiService';

import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { uploadImage } from './gql';
import getSession from '../commons/ClientFileUpload';

export default function SendMail() {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [receiver, setReceiver] = useState("");
  const [title, setTitle] = useState("");

  const api = new ApiService();

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const onClickSend = async () => {
    let content = draftToHtml(convertToRaw(editorState.getCurrentContent())).replace(/\n/g, "");
    let sender = document.getElementById("sender").value;
    let title = document.getElementById("title").value;

    if (!sender.includes("@vling.net") && !sender.includes("@bzznbyd.com")) {
      sender = "no-reply@vling.net";
    }

    if (receiver.length === 0) {
      alert("Receiver is null or undefined");
      return;
    }

    if (!title) {
      alert("Title is null or undefined");
      return;
    }

    if (content === '<p></p>') {
      alert("Content is null or undefined");
      return;
    }

    if (window.confirm(`${receiver} 님에게 이메일을 발송하시겠습니까 ?`)) {
      let resData = await api.backofficeSendMail(sender, receiver, title, content);
      console.log("resData", resData);

      console.log(content)
      if (resData && resData?.sendMail[0]?.result === "OK") {
        setEditorState(EditorState.createEmpty());
        setReceiver("");
        setTitle("");
        setToast("발송완료");
      }
      else setToast("발송실패");
    }

  };

  const handleImageUpload = async (file) => {
    try {
      const client = await getSession();

      const { data } = await client.mutate({
        mutation: uploadImage,
        variables: {
          imageFile: file,
        },
      });

      // console.log('data', data.uploadImage.url);
      return { data: { link: data.uploadImage.url } };

    } catch (error) {
      console.log(error);
      toast.error('이미지 업로드 중 오류가 발생했습니다.');
    }
  };

  const setToast = (str) => {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  };

  return (
    <>
      <div className="container">
        <div className="row ">
          <div className="card mt-2 mx-auto p-4 bg-light">
            <div className="card-body bg-light">
              <div className="container">
                <form id="contact-form">
                  <div className="controls">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="form_name">보내는 사람 Email</label>
                          <select id="sender" className="form-control" required="required" data-error="Please specify your need.">
                            <option value="no-reply@vling.net" selected="">no-reply@vling.net</option>
                            <option value="contact@bzznbyd.com">contact@bzznbyd.com</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label htmlFor="form_lastname">받는 사람 Email</label>
                          <input id="receiver" key="receiver" type="text" name="surname" className="form-control" required="required" data-error="Lastname is required." value={receiver} onChange={(e) => setReceiver(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="form_email">제목</label>
                          <input id="title" key="title" type="text" name="title" className="form-control" required="required" data-error="title is required." value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="form_message">Message *</label>
                          <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            contentStyle={{ height: 320 }}
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                              image: {
                                uploadCallback: handleImageUpload,
                                previewImage: true,
                                alt: { present: true, mandatory: false },
                                inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <input type="button" className="btn btn-success btn-send pt-2 btn-block" onClick={onClickSend} value="Send Message" /> </div>
                    </div>
                    <ToastContainer
                      position="top-right"
                      autoClose={30000}
                      hideProgressBar
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnVisibilityChange={false}
                      draggable={false}
                      pauseOnHover={false}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}
