import gql from 'graphql-tag';

export const GET_FAVORITE_FOLDERS = gql`
  query getFavoriteFolders($userId: String!) {
    getFavoriteFolders(userId: $userId)
  }
`;

export const CREATE_FOLDER_MUTATION = gql`
  mutation createFolder($userId: String!, $folderType: String!, $folderName: String!) {
    createFolder(userId: $userId, folderType: $folderType, folderName: $folderName)
  }
`;

export const UPDATE_FOLDER_MUTATION = gql`
  mutation updateFolder($userId: String!, $folderType: String!, $folderId: String!, $newFolderName: String!) {
    updateFolder(userId: $userId, folderType: $folderType, folderId: $folderId, newFolderName: $newFolderName)
  }
`;

export const DELETE_FOLDER_MUTATION = gql`
  mutation deleteFolder($userId: String!, $folderType: String!, $folderId: String!) {
    deleteFolder(userId: $userId, folderType: $folderType, folderId: $folderId)
  }
`;

export const ADD_ITEM_TO_FOLDER = gql`
  mutation addItemToFolder($userId: String!, $folderType: String!, $folderId: String!, $itemId: String!) {
    addItemToFolder(userId: $userId, folderType: $folderType, folderId: $folderId, itemId: $itemId)
  }
`;

export const REMOVE_ITEM_FROM_FOLDER = gql`
  mutation removeItemFromFolder($userId: String!, $folderType: String!, $folderId: String!, $itemId: String!) {
    removeItemFromFolder(userId: $userId, folderType: $folderType, folderId: $folderId, itemId: $itemId)
  }
`;

export const ADD_PERMISSION_TO_FOLDER = gql`
  mutation addPermissionToFolder($userId: String!, $folderType: String!, $folderId: String!, $permissionUserId: String!) {
    addPermissionToFolder(userId: $userId, folderType: $folderType, folderId: $folderId, permissionUserId: $permissionUserId)
  }
`;

export const REMOVE_PERMISSION_FROM_FOLDER = gql`
  mutation removePermissionFromFolder($userId: String!, $folderType: String!, $folderId: String!, $permissionUserId: String!) {
    removePermissionFromFolder(userId: $userId, folderType: $folderType, folderId: $folderId, permissionUserId: $permissionUserId)
  }
`;