import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Link, Redirect } from "react-router-dom";
import ApiService from '../../services/ApiService';


const API = new ApiService();

export default function CouponInfo(props) {

  const curCouponInfo = props.location.state.selectedData;
  
  const [redirect,setRedirect] = useState("");

  const renderGrade = () => {
    let grade = curCouponInfo.grade;
    let gradeName = "";
    if (grade === "standard") {
      gradeName = "스탠다드";
    } else if (grade === "premium") {
      gradeName = "스타트업";
    } else if (grade === "professional") {
      gradeName = "프로페셔널";
    }

    return (
      <>
        <span>{gradeName}</span>
      </>
    )
  }

  const deleteCoupon = async () => {
    let delConfirm = window.confirm("정말로 쿠폰을 삭제하시겠습니까?");

    if(!delConfirm){
      return;
    }
    let params = {id : curCouponInfo._id};
    await API.deleteCoupon(params);
    setRedirect("/couponList");
  }

  return (
    <>
      {redirect ? <Redirect push={true} to={{ pathname: redirect}} /> : <></>}
      <div className="form-group">
        <h5>등급</h5>
        <br/>
        <div id="grade">
          {renderGrade()}
        </div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="expiryDate">만기일</h5>
        <br/>
        <div id="expiryDate" style={{ width: 150 }}>
          {curCouponInfo.expiryDate}
        </div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="code">쿠폰 코드</h5>
        <br/>
        <div>{curCouponInfo.code}</div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="term">사용 기간</h5>
        <br/>
        <div>{curCouponInfo.term}</div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="description">정보</h5>
        <br/>
        <div>{curCouponInfo.description}</div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-12">
          <center>
            <Link to={{pathname : "/couponInfoEdit" , state : { selectedData : curCouponInfo}}}>
              <Button variant="contained" color="primary" style={{ margin: "auto 30px", padding: "10px 35px" }}>
                수정
            </Button>
            </Link>
            <Link to={"/couponList"}>
              <Button variant="contained" style={{ margin: "auto 30px", padding: "10px 35px" }}>
                취소
              </Button>
            </Link>
            {/* <Button onClick={deleteCoupon} variant="contained" color="secondary" style={{ margin: "auto 30px", padding: "10px 35px" }}>
              삭제
            </Button> */}
          </center>
        </div>
      </div>
    </>
  )
}

