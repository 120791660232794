import React from "react";

import { Route } from "react-router-dom";
import WhiteList from "../../components/gateway/WhiteList";

export default function whitelist(props) {
  return (
    <>
      <Route exact path={props.match.path} component={WhiteList} />
    </>
  );
}
