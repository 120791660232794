import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import styles from './price.module.scss';

import { Button, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getProducts, updateProductData, deleteProductData } from '../gql';

import InputModal from './InputModal';

const SUB_FIELDS = {
  _id: '',
  grade: '',
  isSubscribe: true,
  name: '',
  price: []
};

export default function ProductPage() {
  const classes = useStyles();
  const [state, setState] = useState({
    standard: { ...SUB_FIELDS },
    premium: { ...SUB_FIELDS },
    professional: { ...SUB_FIELDS },
    enterprise: { ...SUB_FIELDS }
  })
  const [fields, setFields] = useState([]);
  const [tempData, setTempData] = useState(null)
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const { data, refetch } = useQuery(getProducts, {
    fetchPolicy: 'network-only',
    variables: {}
  });

  const [updateProductDataFunc] = useMutation(updateProductData);
  const [deleteProductDataFunc] = useMutation(deleteProductData);

  useEffect(() => {
    if (data) {
      const productList = data.getProducts;

      const updatedState = { ...state };
      const updatedFields = [];

      for (const { _id, grade, name, isSubscribe, price } of productList) {
        updatedState[grade] = { _id, grade, isSubscribe, name, price };
        updatedFields.push({ _id, grade, isSubscribe, name, price });
      }

      setState(updatedState);
      setFields(updatedFields);
    }
  }, [data]);

  const handleInputChange = (event, fieldGrade, subfieldName) => {
    const inputValue = subfieldName.includes('price') ? Number(event.target.value) : event.target.value;

    // const setData = (prevState, newData) => ({
    //   ...prevState,
    //   [fieldGrade]: {
    //     ...prevState[fieldGrade],
    //     ...newData
    //   }
    // });

    switch (subfieldName) {
      case 'price_0':
        setState((prevState) => ({
          ...prevState,
          [fieldGrade]: {
            ...prevState[fieldGrade],
            price: [
              { ...prevState[fieldGrade].price[0], value: inputValue },
              { ...prevState[fieldGrade].price[1] }
            ],
          }
        }));
        break;
      case 'price_1':
        setState((prevState) => ({
          ...prevState,
          [fieldGrade]: {
            ...prevState[fieldGrade],
            price: [
              { ...prevState[fieldGrade].price[0] },
              { ...prevState[fieldGrade].price[1], value: inputValue }
            ]
          }
        }));
        break;
      case 'isSubscribe':
        setState((prevState) => ({
          ...prevState,
          [fieldGrade]: {
            ...prevState[fieldGrade],
            isSubscribe: inputValue === "true" ? true : false
          }
        }));
        break;
      default:
        setState((prevState) => ({
          ...prevState,
          [fieldGrade]: {
            ...prevState[fieldGrade],
            [subfieldName]: inputValue
          }
        }));
        break;
    }
  };

  const handleStartAdd = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    if (window.confirm(`Delete ${selectedRows.length} items?`)) {
      try {
        await deleteProductDataFunc({
          fetchPolicy: "network-only",
          variables: { input: {_ids: selectedRows} },
        });
        
        setSelectedRows([]);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleSubmit = async (fieldGrade) => {
    setIsEditing(false);
    setSelectedField(null);

    const input = {
      _id: state[fieldGrade]._id,
      name: state[fieldGrade].name,
      isSubscribe: state[fieldGrade].isSubscribe,
      grade: state[fieldGrade].grade,
      price: state[fieldGrade].price
    };

    try {
      if (window.confirm(`Update ${fieldGrade}?`)) {
        await updateProductDataFunc({
          fetchPolicy: "network-only",
          variables: { input },
        });
      } else {
        setIsEditing(true);
        setSelectedField(fieldGrade);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStartEdit = (fieldGrade) => {
    const temp = { ...state[fieldGrade] };
    setState((prevState) => ({ ...prevState, [fieldGrade]: temp }));

    setTempData(temp)
    setIsEditing(true);
    setSelectedField(fieldGrade);
  };

  const handleCancelEdit = (fieldGrade) => {
    setState((prevState) => ({ ...prevState, [fieldGrade]: tempData }));

    setIsEditing(false);
    setSelectedField(null);
  };

  const handleRowSelect = (event, productId) => {
    const { checked } = event.target;

    console.log(checked, productId);
    setSelectedRows((prevSelectedRows) =>
      checked ? [...prevSelectedRows, productId] : prevSelectedRows.filter((id) => id !== productId)
    );

  };

  const renderValue = (fieldGrade, subfield) => {
    const value = subfield.includes('price') ? (subfield.split('_')[1] === '0' ? state[fieldGrade]['price'][0].value : state[fieldGrade]['price'][1].value) : state[fieldGrade][subfield]

    if (!isEditing || selectedField !== fieldGrade) {
      if (typeof value === "boolean") return value.toString();
      return value !== null && value !== 0 ? value?.toString() : "-";
    }

    switch (subfield) {
      case "grade":
      case "name":
        return (
          <input
            className={styles.input_name}
            type="text"
            value={value}
            onChange={(event) => handleInputChange(event, fieldGrade, subfield)}
          />
        );
      case "isSubscribe":
        return (
          <div className={styles.isSubscribeInputBox}>
            <label htmlFor="isSubscribe">
              <input
                className={styles.input}
                type="radio"
                name="isSubscribe"
                value={true}
                checked={value ? true : null}
                onChange={(event) => handleInputChange(event, fieldGrade, subfield)}
              />true
            </label>
            <label htmlFor="isSubscribe">
              <input
                className={styles.input}
                type="radio"
                name="isSubscribe"
                value={false}
                checked={value ? null : true}
                onChange={(event) => handleInputChange(event, fieldGrade, subfield)}
              />false
            </label>
          </div>
        );
      default:
        return (
          <input
            className={styles.input}
            type="number"
            step={0.01}
            min={0}
            value={value}
            onChange={(event) => handleInputChange(event, fieldGrade, subfield)}
          />
        );
    }
  };

  const renderButtons = (fieldGrade) => {
    const isActive = selectedField === fieldGrade && isEditing;
    const isDisabled = selectedField === fieldGrade ? null : true
    if (isEditing && isActive) {
      return (
        <>
          <button className={styles.isEditingBtn} style={{ cursor: "pointer" }} onClick={() => handleSubmit(fieldGrade)}>Save</button>
          <button className={styles.isEditingBtn} style={{ cursor: "pointer" }} onClick={() => handleCancelEdit(fieldGrade)}>Cancel</button>
        </>
      );
    } else {
      if (!isEditing) {
        return (
          <button className={styles.button} style={{ cursor: "pointer" }} onClick={() => handleStartEdit(fieldGrade)}>Edit</button>
        );
      } else {
        return (
          <button className={styles.button} disabled={isDisabled} onClick={() => handleStartEdit(fieldGrade)}>Edit</button>
        );
      }
    }
  };

  const renderTable = () => (
    <table className={styles.table}>
      <thead>
        <tr>
          <th></th>
          <th>Grade</th>
          <th>Name</th>
          <th>Is Subscribe</th>
          <th colSpan={2}>
            <div>price</div>
            <div className={styles.price_value}>
              <div>KRW</div>
              <div>USD</div>
            </div>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => (
          <tr key={index}>
            <td>
              <Checkbox checked={selectedRows.includes(field._id)} onChange={(event) => handleRowSelect(event, field._id)} />
            </td>
            <td>{renderValue(field.grade, 'grade')}</td>
            <td>{renderValue(field.grade, 'name')}</td>
            <td>{renderValue(field.grade, 'isSubscribe')}</td>
            <td>{renderValue(field.grade, 'price_0')}</td>
            <td>{renderValue(field.grade, 'price_1')}</td>
            <td>
              <div className={styles.btnBox}>
                {renderButtons(field.grade)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      {isModalOpen && <InputModal handleCloseModal={handleCloseModal} setUpdateData={setUpdateData} SUB_FIELDS={SUB_FIELDS} />}
      <div className={styles.brand_container}>
        <h6 className={styles.sub_title}>광고주</h6>
        <div className={classes.btnBox}>
          <Button className={classes.button} onClick={handleStartAdd}>
            Add
          </Button>
          <Button className={classes.button} onClick={handleDelete} disabled={selectedRows.length === 0}>
            Delete
          </Button>
        </div>
      </div>
      {renderTable()}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  btnBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    marginBottom: theme.spacing(1),
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));