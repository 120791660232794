import gql from 'graphql-tag';

export const getGradeList = gql`
  query getGradeList(
    $grade: String
    $name: String
  ) {
    getGradeList(
      grade: $grade
      name: $name
    ) {
      _id
      grade
      name
      level
      quotas {
        searchVideoTrend
        detailSearch
        favoriteFolders
        favoriteItems
        adInfo
        viewersInfo
        campaign
        openapi
      }
    }
  }
`;

export const updateGradeList = gql`
  mutation updateGradeList($input: updateGradeListInput) {
    updateGradeList(input: $input) {
      _id
      name
      grade
      level
      modified
    }
  }
`;

export const createGradeList = gql`
  mutation createGradeList($input: createGradeListInput) {
    createGradeList(input: $input) {
      name
      grade
      level
      quotas
    }
  }
`;

export const deleteGradeList = gql`
  mutation deleteGradeList($input: deleteGradeListInput) {
    deleteGradeList(input: $input) {
      _ids
      resultMsg
    }
  }
`;

export const getProducts = gql`
  query getProducts(
    $productIds: [String]
    $userId: String
    $currency: String
  ) {
    getProducts(
      productIds: $productIds
      userId: $userId
      currency: $currency
    )
  }
`;

export const updateProductData = gql`
  mutation updateProductData($input: updateProductInput) {
    updateProductData(input: $input) {
      _id
      name
      grade
      isSubscribe
      modified
    }
  }
`;

export const createProductData = gql`
  mutation createProductData($input: createProductInput) {
    createProductData(input: $input) {
      name
      grade
      isSubscribe
      price
    }
  }
`;

export const deleteProductData = gql`
  mutation deleteProductData($input: deleteProductInput) {
    deleteProductData(input: $input) {
      _ids
      resultMsg
    }
  }
`;