import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks';

// library
import moment from 'moment'

// styles
import styles from './MrrAnalysis.module.css'

const getMrrAnalysis = gql`
    query getMrrAnalysis {
      getMrrAnalysis
    }
  `

function MrrAnalysis() {
  const { data, loading } = useQuery(getMrrAnalysis)
  const [copiedData, setCopiedData] = useState([])
  const [newSubscribers, setNewSubscribers] = useState([])
  const [existSubscribers, setExistSubscribers] = useState([])
  const [churnSubscribers, setChurnSubscribers] = useState([])
  const [changeSubscribers, setChangeSubscribers] = useState([])
  const changedIds = []

  const months = length => Array.from({ length }, (_, i) => i)
  const lastTwelveMonthsTime = months(12).map(month => parseInt(+new Date(moment().subtract(month, "months").format("YYYY-MM")) / 1000))

  const subscribers = [
    { id: 1, thisMmTime: lastTwelveMonthsTime[0] },
    { id: 2, thisMmTime: lastTwelveMonthsTime[1] },
    { id: 3, thisMmTime: lastTwelveMonthsTime[2] },
    { id: 4, thisMmTime: lastTwelveMonthsTime[3] },
    { id: 5, thisMmTime: lastTwelveMonthsTime[4] },
    { id: 6, thisMmTime: lastTwelveMonthsTime[5] },
    { id: 7, thisMmTime: lastTwelveMonthsTime[6] },
    { id: 8, thisMmTime: lastTwelveMonthsTime[7] },
    { id: 9, thisMmTime: lastTwelveMonthsTime[8] },
    { id: 10, thisMmTime: lastTwelveMonthsTime[9] },
    { id: 11, thisMmTime: lastTwelveMonthsTime[10] },
    { id: 12, thisMmTime: lastTwelveMonthsTime[11] },
  ];

  useEffect(() => {
    setNewSubscribers([
      { id: 1, month: 'thisMonth', thisMmTime: lastTwelveMonthsTime[0], subsInfo: [] },
      { id: 2, month: 'prev1Month', thisMmTime: lastTwelveMonthsTime[1], subsInfo: [] },
      { id: 3, month: 'prev2Month', thisMmTime: lastTwelveMonthsTime[2], subsInfo: [] },
      { id: 4, month: 'prev3Month', thisMmTime: lastTwelveMonthsTime[3], subsInfo: [] },
      { id: 5, month: 'prev4Month', thisMmTime: lastTwelveMonthsTime[4], subsInfo: [] },
      { id: 6, month: 'prev5Month', thisMmTime: lastTwelveMonthsTime[5], subsInfo: [] },
      { id: 7, month: 'prev6Month', thisMmTime: lastTwelveMonthsTime[6], subsInfo: [] },
      { id: 8, month: 'prev7Month', thisMmTime: lastTwelveMonthsTime[7], subsInfo: [] },
      { id: 9, month: 'prev8Month', thisMmTime: lastTwelveMonthsTime[8], subsInfo: [] },
      { id: 10, month: 'prev9Month', thisMmTime: lastTwelveMonthsTime[9], subsInfo: [] },
      { id: 11, month: 'prev10Month', thisMmTime: lastTwelveMonthsTime[10], subsInfo: [] },
      { id: 12, month: 'prev11Month', thisMmTime: lastTwelveMonthsTime[11], subsInfo: [] },
    ])
    setExistSubscribers([
      { id: 1, month: 'thisMonth', thisMmTime: lastTwelveMonthsTime[0], subsInfo: [] },
      { id: 2, month: 'prev1Month', thisMmTime: lastTwelveMonthsTime[1], subsInfo: [] },
      { id: 3, month: 'prev2Month', thisMmTime: lastTwelveMonthsTime[2], subsInfo: [] },
      { id: 4, month: 'prev3Month', thisMmTime: lastTwelveMonthsTime[3], subsInfo: [] },
      { id: 5, month: 'prev4Month', thisMmTime: lastTwelveMonthsTime[4], subsInfo: [] },
      { id: 6, month: 'prev5Month', thisMmTime: lastTwelveMonthsTime[5], subsInfo: [] },
      { id: 7, month: 'prev6Month', thisMmTime: lastTwelveMonthsTime[6], subsInfo: [] },
      { id: 8, month: 'prev7Month', thisMmTime: lastTwelveMonthsTime[7], subsInfo: [] },
      { id: 9, month: 'prev8Month', thisMmTime: lastTwelveMonthsTime[8], subsInfo: [] },
      { id: 10, month: 'prev9Month', thisMmTime: lastTwelveMonthsTime[9], subsInfo: [] },
      { id: 11, month: 'prev10Month', thisMmTime: lastTwelveMonthsTime[10], subsInfo: [] },
      { id: 12, month: 'prev11Month', thisMmTime: lastTwelveMonthsTime[11], subsInfo: [] },
    ])
    setChurnSubscribers([
      { id: 1, month: 'thisMonth', thisMmTime: lastTwelveMonthsTime[0], subsInfo: [] },
      { id: 2, month: 'prev1Month', thisMmTime: lastTwelveMonthsTime[1], subsInfo: [] },
      { id: 3, month: 'prev2Month', thisMmTime: lastTwelveMonthsTime[2], subsInfo: [] },
      { id: 4, month: 'prev3Month', thisMmTime: lastTwelveMonthsTime[3], subsInfo: [] },
      { id: 5, month: 'prev4Month', thisMmTime: lastTwelveMonthsTime[4], subsInfo: [] },
      { id: 6, month: 'prev5Month', thisMmTime: lastTwelveMonthsTime[5], subsInfo: [] },
      { id: 7, month: 'prev6Month', thisMmTime: lastTwelveMonthsTime[6], subsInfo: [] },
      { id: 8, month: 'prev7Month', thisMmTime: lastTwelveMonthsTime[7], subsInfo: [] },
      { id: 9, month: 'prev8Month', thisMmTime: lastTwelveMonthsTime[8], subsInfo: [] },
      { id: 10, month: 'prev9Month', thisMmTime: lastTwelveMonthsTime[9], subsInfo: [] },
      { id: 11, month: 'prev10Month', thisMmTime: lastTwelveMonthsTime[10], subsInfo: [] },
      { id: 12, month: 'prev11Month', thisMmTime: lastTwelveMonthsTime[11], subsInfo: [] },
    ])
    setChangeSubscribers([
      { id: 1, month: 'thisMonth', thisMmTime: lastTwelveMonthsTime[0], repayPrice: [], prevPayPrice: [] },
      { id: 2, month: 'prev1Month', thisMmTime: lastTwelveMonthsTime[1], repayPrice: [], prevPayPrice: [] },
      { id: 3, month: 'prev2Month', thisMmTime: lastTwelveMonthsTime[2], repayPrice: [], prevPayPrice: [] },
      { id: 4, month: 'prev3Month', thisMmTime: lastTwelveMonthsTime[3], repayPrice: [], prevPayPrice: [] },
      { id: 5, month: 'prev4Month', thisMmTime: lastTwelveMonthsTime[4], repayPrice: [], prevPayPrice: [] },
      { id: 6, month: 'prev5Month', thisMmTime: lastTwelveMonthsTime[5], repayPrice: [], prevPayPrice: [] },
      { id: 7, month: 'prev6Month', thisMmTime: lastTwelveMonthsTime[6], repayPrice: [], prevPayPrice: [] },
      { id: 8, month: 'prev7Month', thisMmTime: lastTwelveMonthsTime[7], repayPrice: [], prevPayPrice: [] },
      { id: 9, month: 'prev8Month', thisMmTime: lastTwelveMonthsTime[8], repayPrice: [], prevPayPrice: [] },
      { id: 10, month: 'prev9Month', thisMmTime: lastTwelveMonthsTime[9], repayPrice: [], prevPayPrice: [] },
      { id: 11, month: 'prev10Month', thisMmTime: lastTwelveMonthsTime[10], repayPrice: [], prevPayPrice: [] },
      { id: 12, month: 'prev11Month', thisMmTime: lastTwelveMonthsTime[11], repayPrice: [], prevPayPrice: [] },
    ])
    
    if (!loading && data) {
      setCopiedData(data.getMrrAnalysis);
    }
  }, [data])

  // 전체 DB에서 new / exist / churn, 12개월 치 조건별 분류
  useEffect(() => {
    if (copiedData) {
      copiedData.map(subs => {

        // new mrr
        if (subs.status === 'active' || subs.status === 'cancelled' || subs.status === 'unsubscribe') {
          if (newSubscribers) {
            newSubscribers.map(el => {
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000
              return subs.regist_at >= el.thisMmTime && subs.regist_at <= monthEnd && el['subsInfo'].push(subs)
            })
          }
        }
        if (subs.status === 'changed') {
          // 한달 내 신규가입+등급변경의 경우 집계 X => Exist: Upgrade로 집계
          if (newSubscribers) {
            newSubscribers.map(el => {
              const next1MonthStart = moment(el.thisMmTime * 1000).add(1, "months") / 1000;
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000
              return subs.regist_at >= el.thisMmTime && subs.regist_at <= monthEnd &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.revoked_at >= next1MonthStart &&
                el['subsInfo'].push(subs)
            })
          }
        }
  
        // exist mrr
        if (subs.status === 'active') {
          if (existSubscribers) {
            existSubscribers.map(el => {
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000
              if (subs.regist_at < el.thisMmTime) {
                if (el.id === 1) {
                  subs.payments.length > 0 &&
                  subs.payments[subs.payments.length - 1].data.schedule_at >= el.thisMmTime &&
                    el['subsInfo'].push(subs)
                } else {
                  // 결제 실패 됐음에도 불구하고, DB에는 active 상태로 남아있는 경우
                  subs.payments.length > 0 &&
                  subs.payments[subs.payments.length - 1].data.schedule_at >= monthEnd &&
                    el['subsInfo'].push(subs)
                }
              }
              return el
            })
          }
        }
        if (subs.status === 'unsubscribe') {
          if (existSubscribers) {
            existSubscribers.map(el => {
              return subs.regist_at < el.thisMmTime &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.schedule_at >= el.thisMmTime &&
                el['subsInfo'].push(subs)
            })
          }
        }
        if (subs.status === 'cancelled') {
          if (existSubscribers) {
            existSubscribers.map(el => {
              return subs.regist_at < el.thisMmTime &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.revoked_at >= el.thisMmTime &&
                el['subsInfo'].push(subs)
            })
          }
        }
        if (subs.status === 'changed') {
          if (existSubscribers) {
            // 등급변경을 하는 월에는 집계 X, 바로 Upgrade로 집계
            existSubscribers.map(el => {
              const next1MonthStart = moment(el.thisMmTime * 1000).add(1, "months") / 1000;
              return subs.regist_at < el.thisMmTime &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.revoked_at >= next1MonthStart &&
                el['subsInfo'].push(subs)
            })
          }
        }
  
        // churn mrr
        if (subs.status === 'cancelled') {
          if (churnSubscribers) {
            // 신규 결제 후 환불
            churnSubscribers.map(el => {
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000
              return subs.regist_at >= el.thisMmTime && subs.regist_at <= monthEnd &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.revoked_at >= el.thisMmTime &&
                subs.payments[subs.payments.length - 1].data.revoked_at <= monthEnd &&
                el['subsInfo'].push(subs)
            })
          }
          if (churnSubscribers) {
            // 구독 중 환불
            churnSubscribers.map(el => {
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000;
              subs.regist_at < el.thisMmTime &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.revoked_at >= el.thisMmTime &&
                subs.payments[subs.payments.length - 1].data.revoked_at <= monthEnd &&
                el['subsInfo'].push(subs)
              return el;
            })
          }
        }
        if (subs.status === 'unsubscribe') {
          if (churnSubscribers) {
            // 신규 결제 후 구독취소
            churnSubscribers.map(el => {
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000
              return subs.regist_at >= el.thisMmTime && subs.regist_at <= monthEnd &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.schedule_at >= el.thisMmTime &&
                subs.payments[subs.payments.length - 1].data.schedule_at <= monthEnd &&
                el['subsInfo'].push(subs)
            })
          }
          if (churnSubscribers) {
            // 구독 중 구독취소
            churnSubscribers.map(el => {
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000;
              return subs.regist_at < el.thisMmTime &&
                subs.payments.length > 0 &&
                subs.payments[subs.payments.length - 1].data.schedule_at >= el.thisMmTime &&
                subs.payments[subs.payments.length - 1].data.schedule_at <= monthEnd &&
                el['subsInfo'].push(subs)
            })
          }
        }
  
        // change mrr
        if (subs.status === 'changed') {
          if (changeSubscribers) {
            changeSubscribers.map(el => {
              const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000;
              if (subs.payments && subs.payments.length > 1) {
                return (
                  subs.payments.length > 0 &&
                  subs.payments[subs.payments.length - 1].data.revoked_at >= el.thisMmTime &&
                  subs.payments[subs.payments.length - 1].data.revoked_at <= monthEnd &&
                  changedIds.push(subs.userId) &&
                  el.prevPayPrice.push(subs.payments[subs.payments.length - 1].data.amount)
                );
              }
              return null;
            });
          }
        }
  
        return { newSubscribers, existSubscribers, churnSubscribers, changeSubscribers }
      })
    }

    if (changeSubscribers) {
      changeSubscribers.map(el => {
        const monthEnd = moment(el.thisMmTime * 1000).endOf("month") / 1000
        changedIds.length > 0 &&
          changedIds.map(ids => {
            // 등급 업그레이드 / 다운그레이드 이후 재결제 가격 집계. 환불 신청 후 구독하다 취소 후 다시 재구독할수도 있으므로, 업/다운 그레이드 변경 후 가격 집계
            let filterArr = copiedData.filter(user => user.userId === ids)
            filterArr.pop(f => f.status !== 'changed')
            let comparedNum = 3000000001;
            if (filterArr.length > 1) {
              filterArr.forEach(f => {
                if (f.regist_at < comparedNum) {
                  comparedNum = f.regist_at
                }
              })
              filterArr = filterArr.filter(f => f.regist_at === comparedNum)
            }
            // 등급 변경 후 재결제 가격 입력
            filterArr[0].regist_at >= el.thisMmTime && filterArr[0].regist_at <= monthEnd &&
              el.repayPrice.push(filterArr[0].payments[filterArr[0].payments.length - 2].data.amount)
            return ids;
          })
  
        return el;
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [copiedData])

  // mrr 가격 덧셈
  if (newSubscribers) {
    newSubscribers.map(subs => {
      let newAmounts = 0;
      subs.subsInfo.forEach(subsInfo => {
        if (subsInfo.payments && subsInfo.payments[0] && subsInfo.payments[0].data) {
          newAmounts += subsInfo.payments[0].data.amount;
        }
      });
      subs['revenue'] = newAmounts;
      return subs;
    });
  }
  
  if (existSubscribers) {
    existSubscribers.map(subs => {
      let newAmounts = 0;
      subs.subsInfo.forEach(subsInfo => {
        let lastIndex = subsInfo.payments.length - 1;
        if (subsInfo.payments && subsInfo.payments[lastIndex] && subsInfo.payments[lastIndex].data) {
          newAmounts += subsInfo.payments[lastIndex].data.amount;
        }
      });
      subs['revenue'] = newAmounts;
      return subs;
    });
  }
  
  if (churnSubscribers) {
    churnSubscribers.map(subs => {
      let newAmounts = 0;
      subs.subsInfo.forEach(subsInfo => {
        let lastIndex = subsInfo.payments.length - 1;
        if (subsInfo.payments && subsInfo.payments[lastIndex] && subsInfo.payments[lastIndex].data) {
          newAmounts += subsInfo.payments[lastIndex].data.amount
        }
      });
      subs['revenue'] = newAmounts;
      return subs;
    });
  }
  
  if (subscribers) {
    subscribers.map(subs => {
      // 등급 변경 후 기존 가격 > 새 결제 가격 = 다운 그레이드 / 기존 가격 < 새 결제 가격 = 업그레이드
      changeSubscribers.map(changeSubs => {
        if (changeSubs.id === subs.id) {
          if (changeSubs.repayPrice.length > 0) {
            changeSubs.repayPrice.map(amount => {
              return subs['repayAmount'] = amount
            })
            changeSubs.prevPayPrice.map(amount => {
              return subs['prevPayAmount'] = amount
            })
          }
        }
        return changeSubs
      })
      newSubscribers.map(newSubs => {
        if (newSubs.id === subs.id) {
          subs['news'] = newSubs.revenue
          // 등급 변경 후 재결제 시에 무조건 new로 들어가기 때문에 제거
          if (subs['repayAmount']) {
            return subs['news'] = newSubs.revenue - subs['repayAmount']
          }
        }
        return newSubs
      })
      // 새 결제 가격 >= 결제 가격 = 업그레이드
      existSubscribers.map(existSubs => {
        if (existSubs.id === subs.id) {
          subs['exists'] = existSubs.revenue
          if (subs['repayAmount'] >= subs['prevPayAmount']) {
            return subs['exists'] = existSubs.revenue + subs['repayAmount']
          }
        }
        return existSubs
      })
      // 새 결제 가격 < 결제 가격 = 다운그레이드
      churnSubscribers.map(churnSubs => {
        if (churnSubs.id === subs.id) {
          subs['churns'] = churnSubs.revenue
          if (subs['repayAmount'] < subs['prevPayAmount']) {
            return subs['churns'] = churnSubs.revenue + subs['repayAmount']
          }
        }
        return churnSubs
      })
      return subs
    })
  }

  const tableBody = subscribers.map(subs => {
    return (
      <tr key={`|${subs.id}|`}>
        <td>{moment(subs.thisMmTime * 1000).format("YYYY-MM")}</td>
        <td>{(subs.news + subs.exists - subs.churns).toLocaleString()}</td>
        <td>{(+subs.news).toLocaleString()}</td>
        <td>{(+subs.exists).toLocaleString()}</td>
        <td>{(+subs.churns).toLocaleString()}</td>
        <td>{`${((+subs.churns) / (+subs.exists) * 100).toFixed(2)}%`}</td>
      </tr>
    )
  })

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}> 웹 결제 MRR 분석
        </div>
      </div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>일자</th>
            <th>NET MRR</th>
            <th>NEW</th>
            <th>EXIST</th>
            <th>CHURN</th>
            <th>CHURN RATE</th>
          </tr>
        </thead>
        <tbody>
          {tableBody}
        </tbody>
      </table>
    </div>
  );
}

export default MrrAnalysis;
