import gql from 'graphql-tag';

export const getMailData = gql`
  query channel(
    $id: String!
    $userId: String
  ) { 
    channel(
      id: $id
      userId: $userId
    ) { 
      channelId
      mails
      emails {
        backoffice
        certification
        defaultMail
        description
      }
      title
      description
    }
  }
`;

export const postMailData = gql`
  mutation addMail(
    $channelId: String!
    $mails: [JSON]!
    $type: String
    $deleteMail: String
  ){
    addMail(
      channelId: $channelId
      mails: $mails
      type: $type
      deleteMail: $deleteMail
    )
  }
`;
