import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styles from './AddMails.module.css';
import { getMailData, postMailData } from './gql';

export default function AddMails() {
  const [userId, setUserId] = useState('');
  const [channelId, setChannelId] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [addMailInput, setAddMailInput] = useState('');
  const [postMailDataFunc] = useMutation(postMailData);

  const getUser = async () => {
    const res = await Auth.currentAuthenticatedUser();
    setUserId(res.attributes.sub);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleSearchInput = e => {
    setSearchInput(e.target.value);
  };

  const handleSearchClick = () => {
    setChannelId(searchInput);
  };

  const handleSearchClickEnter = e => {
    if (e.key === 'Enter') {
      setChannelId(searchInput);
    }
  };

  const onClickAddMail = async (emails = []) => {
    if (addMailInput) {
      if (emails.includes(addMailInput)) {
        alert(`이메일 ${addMailInput} 이 이미 존재합니다.`);
        return;
      }
      const newEmails = [addMailInput, ...emails];

      try {
        const result = await postMailDataFunc({
          variables: {
            channelId,
            mails: newEmails,
            type: 'ADD'
          }
        });
        refetch();
        if (result?.data?.addMail?.msg === 'SUCCESS') {
          alert(`이메일 ${addMailInput} 이 추가되었습니다.`);
        } else {
          alert(`${result.data.addMail.msg}`);
        }
      } catch (error) {
        console.log(error);
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
  };

  const handleAddMailInput = e => {
    setAddMailInput(e.target.value);
  };

  const handleAddMailClickEnter = (e, emails) => {
    if (e.key === 'Enter') {
      onClickAddMail(emails);
    }
  };

  const onClickDeleteMail = async (mail, emails) => {
    if (window.confirm(`정말 ${mail} 를 삭제하시겠습니까?`)) {
      const newEmails = emails.filter(el => el !== mail);

      try {
        await postMailDataFunc({
          variables: {
            channelId,
            mails: newEmails,
            deleteMail: mail,
            type: 'DELETE'
          }
        });
        refetch();
      } catch (error) {
        console.log(error);
        alert('오류가 발생했습니다. 다시 시도해주세요.');
      }
    }
  };

  const { loading, error, data, refetch } = useQuery(getMailData, {
    skip: channelId === '',
    fetchPolicy: 'no-cache',
    variables: { id: channelId, userId }
  });

  if (loading) return <AddMails.Skeleton />;
  if (error) return <p>오류가 발생했습니다. 다시 시도해주세요.</p>;

  const emails = [
    ...data?.channel?.emails?.backoffice || [],
    ...data?.channel?.emails?.certification || [],
    ...data?.channel?.emails?.description || [],
    ...data?.channel?.emails?.defaultMail || []
  ];
  const emailSet = new Set(emails);
  const emailArray = Array.from(emailSet);
  const title = data?.channel?.title;

  return (
    <div>
      <h2 className={styles.title}>채널 연락처 검색</h2>
      <input
        className={styles.input}
        type='text'
        placeholder='Channel ID를 입력해주세요.'
        onChange={handleSearchInput}
        onKeyPress={handleSearchClickEnter}
      />
      <button onClick={handleSearchClick}>검색</button>
      <div className={styles.mailListWrapper}>
        {channelId &&
          <table className={styles.table}>
            <tbody>
              <tr>
                <td className={styles.orderNumTd}>채널명</td>
                <td className={styles.resultTd}>{title}</td>
              </tr>
              <tr>
                <td className={styles.orderNumTd}>입력한 채널아이디</td>
                <td className={styles.resultTd}>{channelId}</td>
              </tr>
            </tbody>
          </table>
        }
        {emailArray.length > 0 &&
          <table className={styles.table}>
            <tbody>
              <tr>
                <td className={styles.orderNumTd}>번호</td>
                <td>이메일</td>
                <td className={styles.deleteTd}>삭제</td>
              </tr>
              {emailArray.map((mail, idx) => (
                <tr key={idx}>
                  <td className={styles.orderNumTd}>{idx + 1}</td>
                  <td>{mail}</td>
                  <td className={styles.deleteTd}>
                    <button className={styles.deleteBtn} onClick={() => { onClickDeleteMail(mail, emailArray); }}>X</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
        {emailArray.length === 0 &&
          <p className={styles.noDataText}>연락처가 없습니다.</p>
        }
        {data?.channel === null &&
          <p className={styles.noDataText}>채널 아이디가 잘못되었습니다.</p>
        }
      </div>
      {emails !== undefined &&
        <div className={styles.addContactWrapper}>
          <h2 className={styles.title}>채널 연락처 추가</h2>
          <input
            className={styles.input}
            type='text'
            placeholder='추가할 이메일을 입력해주세요.'
            onChange={handleAddMailInput}
            onKeyPress={(e) => handleAddMailClickEnter(e, emailArray)}
          />
          <button onClick={() => onClickAddMail(emailArray)}>추가</button>
        </div>
      }
    </div>
  );
}

AddMails.Skeleton = () => {
  return (
    <div>
      <h2 className={styles.title}>채널 연락처 검색</h2>
      <input className={styles.input} type='text' placeholder='Channel ID를 입력해주세요.' />
      <button>검색</button>
      <div className={styles.loading}>LOADING...</div>
    </div>
  );
};
