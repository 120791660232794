import React from 'react';
import { Redirect } from "react-router-dom";
import Chips from 'react-chips';
import moment from 'moment';
import { ApolloProvider } from '@apollo/react-hooks';
import DatePicker from 'react-datepicker';
import ApiService from '../../services/ApiService';
import UtilService from '../../services/UtilService';

import UploadFile from './uploadThumbnail';
import ClinetFileUpload from '../commons/ClientFileUpload';

const escape_double_quotes = (str="") => str.replaceAll("\"","\\\"")
class insightPostDetail extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.util = new UtilService();
    this.defaultState = this.props.location.state ? this.props.location.state.defaultState : null;
    this.state = {
      isLoading: true,
      publishDate: this.defaultState ? moment(this.defaultState.publishDate, 'YYYY-MM-DD') : moment(),
      thumbnailImage: this.defaultState ? this.defaultState.thumbnail : null,
      tagList: this.defaultState ? this.defaultState.tagList : [],
      clientUpload: ClinetFileUpload(),
      redirect: null
    }
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    let params = {
      from: 0,
      size: 100
    };

    let _writerList = await this.api.getWriterList(params);
    _writerList = _writerList ? (_writerList.getWriterList ? _writerList.getWriterList : []) : [];

    params.onlyShowData = true;
    let _categoryList = await this.api.getCategoryList(params);
    _categoryList = _categoryList ? (_categoryList.getCategoryList ? _categoryList.getCategoryList : []) : [];

    let _clientUpload = await ClinetFileUpload();

    this.setState({
      writerList: _writerList,
      categoryList: _categoryList,
      clientUpload: _clientUpload,
      isLoading: false
    });
  }

  onChangePublishDate = (event) => {
    this.setState({ publishDate: moment(event) });
  }

  onChangeTagList = datas => {
    this.setState({ tagList: datas });
  }

  onClickBack = async () => {
    this.setState({ redirect: "/insight/post" });
  }

  onClickDelete = async () => {
    let resultURL = document.getElementById("input-url").value;
    if (!resultURL) {
      alert("포스트글 ID를 입력해주세요.");
      return;
    }
    // 지우고 
    // 목록으로 이동
    let resData = await this.api.deletePost({url:resultURL});
    this.setState({ redirect: "/insight/post" });
  }
  onClickSave = async () => {
    let writerValues = document.getElementById("select-writer").value;
    if (!writerValues) {
      alert("작성자를 선택해주세요.");
      return;
    }

    let writerInfo = JSON.parse(writerValues);
    let resultWriterInfo = {
      id: writerInfo._id,
      name: escape_double_quotes(writerInfo.name),
      thumbnail: writerInfo.thumbnail
    }

    let resultCategory = document.getElementById("select-category").value;
    if (!resultCategory) {
      alert("카테고리를 선택해주세요.");
      return;
    }

    let categoryInfo = JSON.parse(resultCategory);
    let resultCategoryInfo = {
      id: categoryInfo._id,
      name: escape_double_quotes(categoryInfo.name),
      language: categoryInfo.language
    };

    let recommendValues = document.getElementsByName(`input-isRecommend`);
    let resultRecommend = null;
    for (let i = 0; i < recommendValues.length; ++i) {
      if (recommendValues[i].checked) {
        resultRecommend = recommendValues[i].value;
        break;
      }
    }
    if (!resultRecommend) {
      alert("추천 여부를 선택해주세요.");
      return;
    }

    let resultURL = document.getElementById("input-url").value;
    if (!resultURL) {
      alert("포스트글 ID를 입력해주세요.");
      return;
    }

    let resultTitle = document.getElementById("input-title").value;
    if (!resultTitle) {
      alert("제목을 입력해주세요.");
      return;
    }

    let resultSubTitle = document.getElementById("input-subTitle").value;
    if (!resultSubTitle) {
      alert("부제목을 입력해주세요.");
      return;
    }

    let resultDesc = document.getElementById("input-desc").value;
    if (!resultDesc) {
      alert("설명을 입력해주세요.");
      return;
    }

    if (!this.state.publishDate) {
      alert("작성일을 선택해주세요.");
      return;
    }
    let resultPublishDate = Math.floor(this.state.publishDate.toDate().valueOf() / 1000);

    this.setState({ isLoading: true });

    let params = {
      writer: resultWriterInfo.name,
      writerThumbnail: resultWriterInfo.thumbnail,
      category: resultCategoryInfo.name,
      recommend: resultRecommend,
      url: resultURL,
      title: escape_double_quotes(resultTitle.replace(/\n/g, "<br />")),
      subTitle: escape_double_quotes(resultSubTitle),
      desc: escape_double_quotes(resultDesc.replace(/\n/g, "<br />")),
      publishDate: resultPublishDate,
      tagList: JSON.stringify(this.state.tagList),
      type: this.defaultState ? "edit" : "add",
      writerInfo: JSON.stringify(JSON.stringify(resultWriterInfo)),
      categoryInfo: JSON.stringify(JSON.stringify(resultCategoryInfo))
    };

    let resData = await this.api.updatePost(params);
    resData = resData ? (resData.updatePost ? resData.updatePost : null) : null;
    this.defaultState = resData;
    this.defaultState.title = this.defaultState.title.split('<br />').join("\n");
    this.defaultState.recommend = this.defaultState.recommend ? "O" : "X";

    this.setState({
      isLoading: false
    });
  }

  handleUploadFile = (result) => {
    if (result && result.data && result.data.updatePost) {
      this.setState({
        thumbnailImage: result.data.updatePost.thumbnail + `?v=${this.util.getEPOCHSeconds()}`
      });
    }
  }

  _renderWriterList() {
    return <div style={{ display: "flex" }}>
      <div style={{ marginRight: 10, width: "10%" }}>작성자　</div>
      <select id="select-writer" style={{ width: "150px" }}>
        {this.state.writerList.map(item => {
          return <option
            value={JSON.stringify(item)}
            // thumbnail={item.thumbnail}
            selected={this.defaultState ? (this.defaultState.writer === item.name ? true : false) : false}
          >
            {item.name}
          </option>
        })}
      </select>
    </div>
  }

  _renderCategoryList() {
    return <div style={{ display: "flex", marginTop: 20 }}>
      <div style={{ marginRight: 10, width: "10%" }}>카테고리</div>
      <select id="select-category" style={{ width: "150px" }}>
        {this.state.categoryList.map(item => {
          return <option value={JSON.stringify(item)} selected={this.defaultState ? (this.defaultState.category === item.name ? true : false) : false}>{item.name}</option>
        })}
      </select>
    </div>
  }

  _renderRecommend() {
    let result = this.defaultState ? this.defaultState.recommend : null;

    if (result) result = result === "O" ? true : false;
    else result = false;

    return <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
      <div style={{ marginRight: 10, width: "10%" }}>추천 여부</div>
      <input type="radio" name={`input-isRecommend`} defaultChecked={result} value={true} /><span style={{ marginLeft: 5, marginRight: 10 }}>추천</span>
      <input type="radio" name={`input-isRecommend`} defaultChecked={!result} value={false} /><span style={{ marginLeft: 5 }}>비추천</span>
    </div>
  }

  _renderInputURL() {
    return <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
      <div style={{ marginRight: 10, width: "10%" }}>포스트글 ID</div>
      <input type="text" id={`input-url`} defaultValue={this.defaultState ? `https://blog.naver.com/bzznbyd/${this.defaultState._id}` : null} disabled={this.defaultState} style={{ width: "60%" }} />
    </div>
  }

  _renderInputTitle() {
    return <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
      <div style={{ marginRight: 10, width: "10%" }}>제목</div>
      <textarea type="text" id={`input-title`} defaultValue={this.defaultState ? this.defaultState.title : null} style={{ width: "60%" }} />
    </div>
  }

  _renderInputSubTitle() {
    return <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
      <div style={{ marginRight: 10, width: "10%" }}>부제목</div>
      <input type="text" id={`input-subTitle`} defaultValue={this.defaultState ? this.defaultState.subTitle : null} style={{ width: "60%" }} />
    </div>
  }

  _renderInputDesc() {
    return <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
      <div style={{ marginRight: 10, width: "10%" }}>설명</div>
      <textarea type="text" id={`input-desc`} defaultValue={this.defaultState ? this.defaultState.desc : null} style={{ width: "60%" }} />
    </div>
  }

  _renderPublishDate() {
    return <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
      <div style={{ marginRight: 10, width: "10%" }}>작성일</div>
      <div style={{ width: 150 }}>
        <DatePicker
          selected={this.state.publishDate.toDate()}
          onChange={this.onChangePublishDate}
          className="react-datepicker-input"
          dateFormat="yyyy-MM-dd"
          maxDate={moment().toDate()}
          onFocus={(e) => e.target.readOnly = true}
          shouldCloseOnSelect={true}
          disabledKeyboardNavigation
        />
      </div>
    </div>
  }

  _renderTagList() {
    return <div style={{ display: "flex", marginTop: 20, alignItems: "center" }}>
      <div style={{ marginRight: 10, width: "10%" }}>태그 목록</div>
      <div style={{ width: "60%" }}>
        <Chips
          value={this.state.tagList}
          onChange={this.onChangeTagList}
          fromSuggestionsOnly={false}
          createChipKeys={[13, 9]}
        />
      </div>
    </div>
  }

  _renderInputThumbnail() {
    return <div style={{ display: "grid", marginTop: 20, paddingTop: 10, borderTop: "1px solid #d9d9d9" }}>
      <div style={{ marginBottom: 10 }}>썸네일</div>
      {
        this.state.thumbnailImage ?
          <img alt="" src={this.state.thumbnailImage + `?v=${this.util.getEPOCHSeconds()}`} style={{ marginBottom: 10 }} />
          :
          <div style={{ color: "red", marginBottom: 10 }}>등록된 썸네일이 없습니다.</div>
      }
      <ApolloProvider client={this.state.clientUpload}>
        <UploadFile
          handleUploadFile={this.handleUploadFile}
          objectId={this.defaultState ? this.defaultState._id : null}
        >
        </UploadFile>
      </ApolloProvider>
    </div>
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={{ pathname: this.state.redirect }} />
    }

    if (!this.state.isLoading) {
      return <div className="container">
        <div style={{ display: "flex", width: "100%", padding: "0px 55px", justifyContent: "flex-end", marginBottom: 10 }}>
          <button onClick={this.onClickBack}>목록</button>
          <button onClick={this.onClickSave}>저장</button>
          <button onClick={this.onClickDelete}>삭제</button>
        </div>
        {this._renderWriterList()}
        {this._renderCategoryList()}
        {this._renderRecommend()}
        {this._renderInputURL()}
        {this._renderInputTitle()}
        {this._renderInputSubTitle()}
        {this._renderInputDesc()}
        {this._renderPublishDate()}
        {this._renderTagList()}
        {this._renderInputThumbnail()}
      </div>
    }
    else {
      return <div style={{ textAlign: "center" }}>로딩중...</div>
    }
  }
}

export default (insightPostDetail);