import React from "react";

import { Route } from "react-router-dom";
import ManageTracking from "../components/manageTracking/ManageTracking";


export default function ManageTrackingPage(props) {
  return (
    <>
      <Route exact path={props.match.path} component={ManageTracking} />
    </>
  );
}
