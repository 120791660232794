export const countryCode = [
  { 'code': 'non', 'krName': '미설정', 'enName': 'N/A' },
  { 'code': 'GH', 'krName': '가나', 'enName': 'Ghana' },
  { 'code': 'GA', 'krName': '가봉', 'enName': 'Gabon' },
  { 'code': 'GY', 'krName': '가이아나', 'enName': 'Guyana' },
  { 'code': 'GM', 'krName': '감비아', 'enName': 'Gambia' },
  { 'code': 'GG', 'krName': '건지섬', 'enName': 'Guernsey' },
  { 'code': 'GP', 'krName': '과들루프', 'enName': 'Guadeloupe' },
  { 'code': 'GT', 'krName': '과테말라', 'enName': 'Guatemala' },
  { 'code': 'GU', 'krName': '괌', 'enName': 'Guam' },
  { 'code': 'GD', 'krName': '그레나다', 'enName': 'Grenada' },
  { 'code': 'GR', 'krName': '그리스', 'enName': 'Greece' },
  { 'code': 'GL', 'krName': '그린란드', 'enName': 'Greenland' },
  { 'code': 'GN', 'krName': '기니', 'enName': 'Guinea' },
  { 'code': 'GW', 'krName': '기니비사우', 'enName': 'Guinea-Bissau' },
  { 'code': 'NA', 'krName': '나미비아', 'enName': 'Namibia' },
  { 'code': 'NR', 'krName': '나우루', 'enName': 'Nauru' },
  { 'code': 'NG', 'krName': '나이지리아', 'enName': 'Nigeria' },
  { 'code': 'AQ', 'krName': '남극', 'enName': 'Antarctica' },
  { 'code': 'SS', 'krName': '남수단', 'enName': 'South Sudan' },
  { 'code': 'ZA', 'krName': '남아프리카 공화국', 'enName': 'South Africa' },
  { 'code': 'NL', 'krName': '네덜란드', 'enName': 'Netherlands' },
  { 'code': 'NP', 'krName': '네팔', 'enName': 'Nepal' },
  { 'code': 'NO', 'krName': '노르웨이', 'enName': 'Norway' },
  { 'code': 'NF', 'krName': '노퍽섬', 'enName': 'Norfolk Island' },
  { 'code': 'NC', 'krName': '누벨칼레도니', 'enName': 'New Caledonia' },
  { 'code': 'NZ', 'krName': '뉴질랜드', 'enName': 'New Zealand' },
  { 'code': 'NU', 'krName': '니우에', 'enName': 'Niue' },
  { 'code': 'NE', 'krName': '니제르', 'enName': 'Niger' },
  { 'code': 'NI', 'krName': '니카라과', 'enName': 'Nicaragua' },
  { 'code': 'KR', 'krName': '대한민국', 'enName': 'Korea, Republic of' },
  { 'code': 'DK', 'krName': '덴마크', 'enName': 'Denmark' },
  { 'code': 'DO', 'krName': '도미니카 공화국', 'enName': 'Dominican Republic' },
  { 'code': 'DM', 'krName': '도미니카 연방', 'enName': 'Dominica' },
  { 'code': 'DE', 'krName': '독일', 'enName': 'Germany' },
  { 'code': 'TL', 'krName': '동티모르', 'enName': 'Timor-Leste' },
  { 'code': 'LA', 'krName': '라오스', 'enName': "Lao People's Democratic Republic" },
  { 'code': 'LR', 'krName': '라이베리아', 'enName': 'Liberia' },
  { 'code': 'LV', 'krName': '라트비아', 'enName': 'Latvia' },
  { 'code': 'RU', 'krName': '러시아', 'enName': 'Russian Federation' },
  { 'code': 'LB', 'krName': '레바논', 'enName': 'Lebanon' },
  { 'code': 'LS', 'krName': '레소토', 'enName': 'Lesotho' },
  { 'code': 'RE', 'krName': '레위니옹', 'enName': 'Réunion' },
  { 'code': 'RO', 'krName': '루마니아', 'enName': 'Romania' },
  { 'code': 'LU', 'krName': '룩셈부르크', 'enName': 'Luxembourg' },
  { 'code': 'RW', 'krName': '르완다', 'enName': 'Rwanda' },
  { 'code': 'LY', 'krName': '리비아', 'enName': 'Libya' },
  { 'code': 'LT', 'krName': '리투아니아', 'enName': 'Lithuania' },
  { 'code': 'LI', 'krName': '리히텐슈타인', 'enName': 'Liechtenstein' },
  { 'code': 'MG', 'krName': '마다가스카르', 'enName': 'Madagascar' },
  { 'code': 'MQ', 'krName': '마르티니크', 'enName': 'Martinique' },
  { 'code': 'MH', 'krName': '마셜 제도', 'enName': 'Marshall Islands' },
  { 'code': 'YT', 'krName': '마요트', 'enName': 'Mayotte' },
  { 'code': 'MO', 'krName': '마카오', 'enName': 'Macao' },
  { 'code': 'MK', 'krName': '북마케도니아', 'enName': 'North Macedonia' },
  { 'code': 'MW', 'krName': '말라위', 'enName': 'Malawi' },
  { 'code': 'MY', 'krName': '말레이시아', 'enName': 'Malaysia' },
  { 'code': 'ML', 'krName': '말리', 'enName': 'Mali' },
  { 'code': 'IM', 'krName': '맨섬', 'enName': 'Isle of Man' },
  { 'code': 'MX', 'krName': '멕시코', 'enName': 'Mexico' },
  { 'code': 'MC', 'krName': '모나코', 'enName': 'Monaco' },
  { 'code': 'MA', 'krName': '모로코', 'enName': 'Morocco' },
  { 'code': 'MU', 'krName': '모리셔스', 'enName': 'Mauritius' },
  { 'code': 'MR', 'krName': '모리타니', 'enName': 'Mauritania' },
  { 'code': 'MZ', 'krName': '모잠비크', 'enName': 'Mozambique' },
  { 'code': 'ME', 'krName': '몬테네그로', 'enName': 'Montenegro' },
  { 'code': 'MS', 'krName': '몬트세랫', 'enName': 'Montserrat' },
  { 'code': 'MD', 'krName': '몰도바', 'enName': 'Moldova, Republic of' },
  { 'code': 'MV', 'krName': '몰디브', 'enName': 'Maldives' },
  { 'code': 'MT', 'krName': '몰타', 'enName': 'Malta' },
  { 'code': 'MN', 'krName': '몽골', 'enName': 'Mongolia' },
  { 'code': 'US', 'krName': '미국', 'enName': 'United States of America' },
  { 'code': 'UM', 'krName': '미국령 군소 제도', 'enName': 'United States Minor Outlying Islands' },
  { 'code': 'VI', 'krName': '미국령 버진아일랜드', 'enName': 'Virgin Islands (U.S.)' },
  { 'code': 'MM', 'krName': '미얀마', 'enName': 'Myanmar' },
  { 'code': 'FM', 'krName': '미크로네시아 연방', 'enName': 'Micronesia (Federated States of)' },
  { 'code': 'VU', 'krName': '바누아투', 'enName': 'Vanuatu' },
  { 'code': 'BH', 'krName': '바레인', 'enName': 'Bahrain' },
  { 'code': 'BB', 'krName': '바베이도스', 'enName': 'Barbados' },
  { 'code': 'VA', 'krName': '바티칸 시국', 'enName': 'Holy See' },
  { 'code': 'BS', 'krName': '바하마', 'enName': 'Bahamas' },
  { 'code': 'BD', 'krName': '방글라데시', 'enName': 'Bangladesh' },
  { 'code': 'BM', 'krName': '버뮤다', 'enName': 'Bermuda' },
  { 'code': 'BJ', 'krName': '베냉', 'enName': 'Benin' },
  { 'code': 'VE', 'krName': '베네수엘라', 'enName': 'Venezuela (Bolivarian Republic of)' },
  { 'code': 'VN', 'krName': '베트남', 'enName': 'Viet Nam' },
  { 'code': 'BE', 'krName': '벨기에', 'enName': 'Belgium' },
  { 'code': 'BY', 'krName': '벨라루스', 'enName': 'Belarus' },
  { 'code': 'BZ', 'krName': '벨리즈', 'enName': 'Belize' },
  { 'code': 'BQ', 'krName': '보네르섬', 'enName': 'Bonaire, Sint Eustatius and Saba' },
  { 'code': 'BA', 'krName': '보스니아 헤르체고비나', 'enName': 'Bosnia and Herzegovina' },
  { 'code': 'BW', 'krName': '보츠와나', 'enName': 'Botswana' },
  { 'code': 'BO', 'krName': '볼리비아', 'enName': 'Bolivia (Plurinational State of)' },
  { 'code': 'BI', 'krName': '부룬디', 'enName': 'Burundi' },
  { 'code': 'BF', 'krName': '부르키나파소', 'enName': 'Burkina Faso' },
  { 'code': 'BV', 'krName': '부베섬', 'enName': 'Bouvet Island' },
  { 'code': 'BT', 'krName': '부탄', 'enName': 'Bhutan' },
  { 'code': 'MP', 'krName': '북마리아나 제도', 'enName': 'Northern Mariana Islands' },
  { 'code': 'BG', 'krName': '불가리아', 'enName': 'Bulgaria' },
  { 'code': 'BR', 'krName': '브라질', 'enName': 'Brazil' },
  { 'code': 'BN', 'krName': '브루나이', 'enName': 'Brunei Darussalam' },
  { 'code': 'WS', 'krName': '사모아', 'enName': 'Samoa' },
  { 'code': 'SA', 'krName': '사우디아라비아', 'enName': 'Saudi Arabia' },
  { 'code': 'GS', 'krName': '사우스조지아 사우스샌드위치 제도', 'enName': 'South Georgia and the South Sandwich Islands' },
  { 'code': 'SM', 'krName': '산마리노', 'enName': 'San Marino' },
  { 'code': 'ST', 'krName': '상투메 프린시페', 'enName': 'Sao Tome and Principe' },
  { 'code': 'MF', 'krName': '생마르탱', 'enName': 'Saint Martin (French part)' },
  { 'code': 'BL', 'krName': '생바르텔레미', 'enName': 'Saint Barthélemy' },
  { 'code': 'PM', 'krName': '생피에르 미클롱', 'enName': 'Saint Pierre and Miquelon' }, 
  { 'code': 'EH', 'krName': '서사하라', 'enName': 'Western Sahara' },
  { 'code': 'SN', 'krName': '세네갈', 'enName': 'Senegal' },
  { 'code': 'RS', 'krName': '세르비아', 'enName': 'Serbia' },
  { 'code': 'SC', 'krName': '세이셸', 'enName': 'Seychelles' },
  { 'code': 'LC', 'krName': '세인트루시아', 'enName': 'Saint Lucia' },
  { 'code': 'VC', 'krName': '세인트빈센트 그레나딘', 'enName': 'Saint Vincent and the Grenadines' },
  { 'code': 'KN', 'krName': '세인트키츠 네비스', 'enName': 'Saint Kitts and Nevis' },
  { 'code': 'SH', 'krName': '세인트헬레나', 'enName': 'Saint Helena, Ascension and Tristan da Cunha' },
  { 'code': 'SO', 'krName': '소말리아', 'enName': 'Somalia' },
  { 'code': 'SB', 'krName': '솔로몬 제도', 'enName': 'Solomon Islands' },
  { 'code': 'SD', 'krName': '수단', 'enName': 'Sudan' },
  { 'code': 'SR', 'krName': '수리남', 'enName': 'Suriname' },
  { 'code': 'LK', 'krName': '스리랑카', 'enName': 'Sri Lanka' },
  { 'code': 'SJ', 'krName': '스발바르 얀마옌', 'enName': 'Svalbard and Jan Mayen' },
  { 'code': 'SE', 'krName': '스웨덴', 'enName': 'Sweden' },
  { 'code': 'CH', 'krName': '스위스', 'enName': 'Switzerland' },
  { 'code': 'ES', 'krName': '스페인', 'enName': 'Spain' },
  { 'code': 'SK', 'krName': '슬로바키아', 'enName': 'Slovakia' },
  { 'code': 'SI', 'krName': '슬로베니아', 'enName': 'Slovenia' },
  { 'code': 'SY', 'krName': '시리아', 'enName': 'Syrian Arab Republic' },
  { 'code': 'SL', 'krName': '시에라리온', 'enName': 'Sierra Leone' },
  { 'code': 'SX', 'krName': '신트마르턴', 'enName': 'Sint Maarten (Dutch part)' },
  { 'code': 'SG', 'krName': '싱가포르', 'enName': 'Singapore' },
  { 'code': 'AE', 'krName': '아랍에미리트', 'enName': 'United Arab Emirates' },
  { 'code': 'AW', 'krName': '아루바', 'enName': 'Aruba' },
  { 'code': 'AM', 'krName': '아르메니아', 'enName': 'Armenia' },
  { 'code': 'AR', 'krName': '아르헨티나', 'enName': 'Argentina' },
  { 'code': 'AS', 'krName': '아메리칸사모아', 'enName': 'American Samoa' },
  { 'code': 'IS', 'krName': '아이슬란드', 'enName': 'Iceland' },
  { 'code': 'HT', 'krName': '아이티', 'enName': 'Haiti' },
  { 'code': 'IE', 'krName': '아일랜드', 'enName': 'Ireland' },
  { 'code': 'AZ', 'krName': '아제르바이잔', 'enName': 'Azerbaijan' },
  { 'code': 'AF', 'krName': '아프가니스탄', 'enName': 'Afghanistan' },
  { 'code': 'AD', 'krName': '안도라', 'enName': 'Andorra' },
  { 'code': 'AL', 'krName': '알바니아', 'enName': 'Albania' },
  { 'code': 'DZ', 'krName': '알제리', 'enName': 'Algeria' },
  { 'code': 'AO', 'krName': '앙골라', 'enName': 'Angola' },
  { 'code': 'AG', 'krName': '앤티가 바부다', 'enName': 'Antigua and Barbuda' },
  { 'code': 'AI', 'krName': '앵귈라', 'enName': 'Anguilla' },
  { 'code': 'ER', 'krName': '에리트레아', 'enName': 'Eritrea' },
  { 'code': 'SZ', 'krName': '에스와티니', 'enName': 'Eswatini' },
  { 'code': 'EE', 'krName': '에스토니아', 'enName': 'Estonia' },
  { 'code': 'EC', 'krName': '에콰도르', 'enName': 'Ecuador' },
  { 'code': 'ET', 'krName': '에티오피아', 'enName': 'Ethiopia' },
  { 'code': 'SV', 'krName': '엘살바도르', 'enName': 'El Salvador' },
  { 'code': 'GB', 'krName': '영국', 'enName': 'United Kingdom of Great Britain and Northern Ireland' },
  { 'code': 'VG', 'krName': '영국령 버진아일랜드', 'enName': 'Virgin Islands (British)' },
  { 'code': 'IO', 'krName': '영국령 인도양 지역', 'enName': 'British Indian Ocean Territory' },
  { 'code': 'YE', 'krName': '예멘', 'enName': 'Yemen' },
  { 'code': 'OM', 'krName': '오만', 'enName': 'Oman' },
  { 'code': 'AU', 'krName': '오스트레일리아', 'enName': 'Australia' },
  { 'code': 'AT', 'krName': '오스트리아', 'enName': 'Austria' },
  { 'code': 'HN', 'krName': '온두라스', 'enName': 'Honduras' },
  { 'code': 'AX', 'krName': '올란드 제도', 'enName': 'Åland Islands' },
  { 'code': 'JO', 'krName': '요르단', 'enName': 'Jordan' },
  { 'code': 'UG', 'krName': '우간다', 'enName': 'Uganda' },
  { 'code': 'UY', 'krName': '우루과이', 'enName': 'Uruguay' },
  { 'code': 'UZ', 'krName': '우즈베키스탄', 'enName': 'Uzbekistan' },
  { 'code': 'UA', 'krName': '우크라이나', 'enName': 'Ukraine' },
  { 'code': 'WF', 'krName': '왈리스 푸투나', 'enName': 'Wallis and Futuna' },
  { 'code': 'IQ', 'krName': '이라크', 'enName': 'Iraq' },
  { 'code': 'IR', 'krName': '이란', 'enName': 'Iran' },
  { 'code': 'IL', 'krName': '이스라엘', 'enName': 'Israel' },
  { 'code': 'EG', 'krName': '이집트', 'enName': 'Egypt' },
  { 'code': 'IT', 'krName': '이탈리아', 'enName': 'Italy' },
  { 'code': 'IN', 'krName': '인도', 'enName': 'India' },
  { 'code': 'ID', 'krName': '인도네시아', 'enName': 'Indonesia' },
  { 'code': 'JP', 'krName': '일본', 'enName': 'Japan' },
  { 'code': 'JM', 'krName': '자메이카', 'enName': 'Jamaica' },
  { 'code': 'ZM', 'krName': '잠비아', 'enName': 'Zambia' },
  { 'code': 'JE', 'krName': '저지섬', 'enName': 'Jersey' },
  { 'code': 'GQ', 'krName': '적도 기니', 'enName': 'Equatorial Guinea' },
  { 'code': 'KP', 'krName': '조선민주주의인민공화국', 'enName': "Korea (Democratic People's Republic of)" },
  { 'code': 'GE', 'krName': '조지아', 'enName': 'Georgia' },
  { 'code': 'CN', 'krName': '중국', 'enName': 'China' },
  { 'code': 'CF', 'krName': '중앙아프리카 공화국', 'enName': 'Central African Republic' },
  { 'code': 'DJ', 'krName': '지부티', 'enName': 'Djibouti' },
  { 'code': 'GI', 'krName': '지브롤터', 'enName': 'Gibraltar' },
  { 'code': 'ZW', 'krName': '짐바브웨', 'enName': 'Zimbabwe' },
  { 'code': 'TD', 'krName': '차드', 'enName': 'Chad' },
  { 'code': 'CZ', 'krName': '체코', 'enName': 'Czechia' },
  { 'code': 'CL', 'krName': '칠레', 'enName': 'Chile' },
  { 'code': 'CM', 'krName': '카메룬', 'enName': 'Cameroon' },
  { 'code': 'CV', 'krName': '카보베르데', 'enName': 'Cabo Verde' },
  { 'code': 'KZ', 'krName': '카자흐스탄', 'enName': 'Kazakhstan' },
  { 'code': 'QA', 'krName': '카타르', 'enName': 'Qatar' },
  { 'code': 'KH', 'krName': '캄보디아', 'enName': 'Cambodia' },
  { 'code': 'CA', 'krName': '캐나다', 'enName': 'Canada' },
  { 'code': 'KE', 'krName': '케냐', 'enName': 'Kenya' },
  { 'code': 'KY', 'krName': '케이맨 제도', 'enName': 'Cayman Islands' },
  { 'code': 'KM', 'krName': '코모로', 'enName': 'Comoros' },
  { 'code': 'CR', 'krName': '코스타리카', 'enName': 'Costa Rica' },
  { 'code': 'CC', 'krName': '코코스 제도', 'enName': 'Cocos (Keeling) Islands' },
  { 'code': 'CI', 'krName': '코트디부아르', 'enName': "Côte d'Ivoire" },
  { 'code': 'CO', 'krName': '콜롬비아', 'enName': 'Colombia' },
  { 'code': 'CG', 'krName': '콩고 공화국', 'enName': 'Congo' },
  { 'code': 'CD', 'krName': '콩고 민주 공화국', 'enName': 'Congo, Democratic Republic of the' },
  { 'code': 'CU', 'krName': '쿠바', 'enName': 'Cuba' },
  { 'code': 'KW', 'krName': '쿠웨이트', 'enName': 'Kuwait' },
  { 'code': 'CK', 'krName': '쿡 제도', 'enName': 'Cook Islands' },
  { 'code': 'CW', 'krName': '퀴라소', 'enName': 'Curaçao' },
  { 'code': 'HR', 'krName': '크로아티아', 'enName': 'Croatia' },
  { 'code': 'CX', 'krName': '크리스마스섬', 'enName': 'Christmas Island' },
  { 'code': 'KG', 'krName': '키르기스스탄', 'enName': 'Kyrgyzstan' },
  { 'code': 'KI', 'krName': '키리바시', 'enName': 'Kiribati' },
  { 'code': 'CY', 'krName': '키프로스', 'enName': 'Cyprus' },
  { 'code': 'TW', 'krName': '타이완', 'enName': 'Taiwan, Province of China' },
  { 'code': 'TJ', 'krName': '타지키스탄', 'enName': 'Tajikistan' },
  { 'code': 'TZ', 'krName': '탄자니아', 'enName': 'Tanzania, United Republic of' },
  { 'code': 'TH', 'krName': '태국', 'enName': 'Thailand' },
  { 'code': 'TC', 'krName': '터크스 케이커스 제도', 'enName': 'Turks and Caicos Islands' },
  { 'code': 'TR', 'krName': '터키', 'enName': 'Turkey' },
  { 'code': 'TG', 'krName': '토고', 'enName': 'Togo' },
  { 'code': 'TK', 'krName': '토켈라우', 'enName': 'Tokelau' },
  { 'code': 'TO', 'krName': '통가', 'enName': 'Tonga' },
  { 'code': 'TM', 'krName': '투르크메니스탄', 'enName': 'Turkmenistan' },
  { 'code': 'TV', 'krName': '투발루', 'enName': 'Tuvalu' },
  { 'code': 'TN', 'krName': '튀니지', 'enName': 'Tunisia' },
  { 'code': 'TT', 'krName': '트리니다드 토바고', 'enName': 'Trinidad and Tobago' },
  { 'code': 'PA', 'krName': '파나마', 'enName': 'Panama' },
  { 'code': 'PY', 'krName': '파라과이', 'enName': 'Paraguay' },
  { 'code': 'PK', 'krName': '파키스탄', 'enName': 'Pakistan' },
  { 'code': 'PG', 'krName': '파푸아뉴기니', 'enName': 'Papua New Guinea' },
  { 'code': 'PW', 'krName': '팔라우', 'enName': 'Palau' },
  { 'code': 'PS', 'krName': '팔레스타인', 'enName': 'Palestine, State of' },
  { 'code': 'FO', 'krName': '페로 제도', 'enName': 'Faroe Islands' },
  { 'code': 'PE', 'krName': '페루', 'enName': 'Peru' },
  { 'code': 'PT', 'krName': '포르투갈', 'enName': 'Portugal' },
  { 'code': 'FK', 'krName': '포클랜드 제도', 'enName': 'Falkland Islands (Malvinas)' },
  { 'code': 'PL', 'krName': '폴란드', 'enName': 'Poland' },
  { 'code': 'PR', 'krName': '푸에르토리코', 'enName': 'Puerto Rico' },
  { 'code': 'FR', 'krName': '프랑스', 'enName': 'France' },
  { 'code': 'GF', 'krName': '프랑스령 기아나', 'enName': 'French Guiana' },
  { 'code': 'TF', 'krName': '프랑스령 남방 및 남극 지역', 'enName': 'French Southern Territories' },
  { 'code': 'PF', 'krName': '프랑스령 폴리네시아', 'enName': 'French Polynesia' },
  { 'code': 'FJ', 'krName': '피지', 'enName': 'Fiji' },
  { 'code': 'FI', 'krName': '핀란드', 'enName': 'Finland' },
  { 'code': 'PH', 'krName': '필리핀', 'enName': 'Philippines' },
  { 'code': 'PN', 'krName': '핏케언 제도', 'enName': 'Pitcairn' },
  { 'code': 'HM', 'krName': '허드 맥도널드 제도', 'enName': 'Heard Island and McDonald Islands' },
  { 'code': 'HU', 'krName': '헝가리', 'enName': 'Hungary' },
  { 'code': 'HK', 'krName': '홍콩', 'enName': 'Hong Kong' }
];

export const categoryList = {
  FASHION: "패션",
  BEAUTY: "뷰티",
  FOOD: "푸드/먹방",
  ENTN: "엔터테인먼트",
  LIFE: "Vlog/일상",
  TRAVEL: "여행",
  ASMR: "ASMR",
  GAME: "게임",
  PET: "펫/동물",
  TECH: "IT/과학기술",
  FILM: "영화/애니",
  CAR: "자동차",
  MUSIC: "음악",
  SPORTS: "스포츠",
  FUN: "FUN",
  POLITICS: "시사/정치",
  EDU: "교육",
  ECONOMY: "경제",
  INFO: "지식/정보",
  NEWS: "뉴스",
  SOCIETY: "사회/종교",
  KIDS: "키즈",
  ETC: "기타"
}