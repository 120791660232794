import React from "react";

import { Route } from "react-router-dom";
import StatsFunc from "../components/stats/Stats"

export default function Stats(props) {
  return (
    <>
        <Route exact path={props.match.path} component={StatsFunc} />
    </>
  );
}
