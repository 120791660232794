import React from "react";
import { Route } from "react-router-dom";

import NewMembers from "../components/member/NewMembers";
import BackOfficeMainCampaign from "../components/main/BackOfficeMainCampaign";
import BackOfficeMainRequestAdPrice from "../components/main/BackOfficeMainRequestAdPrice"



export default function Main(props) {
  return (
    <>
     <Route exact path={props.match.path} component={NewMembers} />
     <Route exact path={props.match.path} component={BackOfficeMainCampaign} />
     <Route exact path={props.match.path} component={BackOfficeMainRequestAdPrice} />

    </>
  );
}
