import React from "react";

import { Route } from "react-router-dom";

import InfluencerChannelsAdd from "../components/channel/InfluencerChannelsAdd";


export default function Channel(props) {
  return (
    <>
      <Route exact path={`${props.match.path}/influencerChannelsAdd`} component={InfluencerChannelsAdd} />
    </>
  );
}
