import React from 'react';
import { Redirect } from "react-router-dom";

import DatePicker from 'react-datepicker';
import { ToastContainer, toast } from 'react-toastify';
import ApiService from '../../services/ApiService';

class enterpriseEdit extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.groupId = window.location.pathname.replace("/enterprise/edit/", "");
    this.state = {
      // isLoading: false,
      // isLoadingAddMember: false,
      // titleValue: this.defaultState ? (this.defaultState.title || null) : null,
      // maxCountValue: this.defaultState ? (this.defaultState.maxCount || 10) : 10,
      // typeValue: this.defaultState && this.defaultState.type ? this.defaultState.type._id : this.defaultType._id,
      // start_at: this.defaultState ? this.defaultState.start_at : Math.floor(new Date().valueOf() / 1000),
      // expire_at: this.defaultState ? this.defaultState.expire_at : Math.floor(new Date().valueOf() / 1000),
      // user: this.defaultState ? this.defaultState.user : []
      isLoading: true,
      isLoadingAddMember: false,
      titleValue: "",
      maxCountValue: 10,
      typeValue: [],
      start_at: Math.floor(new Date().valueOf() / 1000),
      expire_at: Math.floor(new Date().valueOf() / 1000),
      user: [],
      typeData: [],
      memoValue: "",
      redirect: null
    }
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    if (!this.state.isLoading)
      this.setState({ isLoading: true });

    let params = {
      from: 0,
      size: 100
    };

    let resTypeData = await this.api.getEnterpriseTypeList(params);
    resTypeData = resTypeData ? (resTypeData.getEnterpriseType || []) : [];

    if (this.groupId !== "new") {
      let resData = await this.api.getEnterpriseGroupById(this.groupId);
      resData = resData ? (resData.getEnterpriseGroupById || null) : null;

      if (resData) {
        let _titleValue = resData.title;
        let _maxCountValue = resData.maxCount || 10;
        let _typeValue = resData.type || [];
        let _start_at = resData.start_at;
        let _expire_at = resData.expire_at;
        let _user = resData.user;
        let _memoValue = resData.memo;
        if (typeof _memoValue === "string") {
          _memoValue = _memoValue.replace(/<br>/g, "\n");
        }

        this.setState({
          isLoading: false,
          typeData: resTypeData,
          titleValue: _titleValue,
          maxCountValue: _maxCountValue,
          typeValue: _typeValue,
          start_at: _start_at,
          expire_at: _expire_at,
          user: _user,
          memoValue: _memoValue
        });
      } else {
        alert("잘못된 접근입니다.");
        this.setState({ redirect: "/enterprise/list" });
      }
    } else {
      this.setState({
        typeData: resTypeData,
        isLoading: false
      });
    }
  }

  setToast = (str) => {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  };

  onClickSave = async () => {
    if (!this.state.titleValue || this.state.titleValue === "") {
      alert("이름을 입력해주세요.");
      return;
    }

    if (!this.state.maxCountValue || typeof this.state.maxCountValue !== "number") {
      alert("인원제한을 설정해주세요.")
      return;
    }

    if (!Array.isArray(this.state.user) || this.state.user.length === 0) {
      alert("멤버를 추가해주세요.");
      return;
    }

    if (this.state.user.length > this.state.maxCountValue) {
      alert("제한 인원수는 멤버의 수보다 작을 수 없습니다.");
      return;
    }

    if (!Array.isArray(this.state.typeValue) || this.state.typeValue.length === 0) {
      alert("타입을 설정해주세요.");
      return;
    }
    

    if (!this.state.isLoading)
      this.setState({ isLoading: true });

    let params = {
      groupId: this.groupId !== "new" ? this.groupId : null,
      what: this.groupId !== "new" ? "edit" : "add",
      title: this.state.titleValue,
      maxCount: this.state.maxCountValue,
      type: JSON.stringify(JSON.stringify(this.state.typeValue)),
      start_at: this.state.start_at,
      expire_at: this.state.expire_at,
      user: JSON.stringify(JSON.stringify(this.state.user)),
      memo: this.state.memoValue ? this.state.memoValue.replace(/\n/g, "<br>") : ""
    }

    let resData = await this.api.manageEnterprise(params);
    if (resData && resData.manageEnterprise.result && resData.manageEnterprise.data) {
      this.setToast("정상적으로 반영되었습니다.");
    } else {
      this.setToast("반영에 실패하였습니다.");
    }

    this.setState({ isLoading: false });
  }

  onChangeMaxCount = (event) => {
    let result = Number(event.target.value);

    if (result < this.state.user.length) {
      alert("제한 인원수는 멤버의 수보다 작을 수 없습니다.");
      return;
    }

    this.setState({ maxCountValue: result });
  }

  onChangeStartDate = (date) => {
    let result = Math.floor(date.valueOf() / 1000);
    this.setState({ start_at: result });
  }

  onChangeExpireDate = (date) => {
    let result = Math.floor(date.valueOf() / 1000);
    this.setState({ expire_at: result });
  }

  onClickMasterMember = (_userId) => {
    let result = [].concat(this.state.user);

    result.map(member => {
      if (member.userId === _userId)
        return member.isMaster = true;
      return member.isMaster = false;
    });

    this.setState({ user: result });
  }

  onClickAuthMember = (_userId) => {
    let result = [].concat(this.state.user);

    result.map(member => {
      if (member.userId === _userId)
        return member.isAuth = !member.isAuth
    });

    this.setState({ user: result });
  }

  onClickRemoveMember = (userId) => {
    this.setState({ user: this.state.user.filter(i => i.userId !== userId )});
  }

  onClickAddMember = async () => {
    let addInputElement = document.getElementById("enterprise-member-add");
    if (/.+@.+\.[A-Za-z]+$/.test(addInputElement.value) === false) {
      alert("잘못된 이메일 형식입니다.");
      return;
    }

    if (Array.isArray(this.state.user) && this.state.user.filter(item => item.email === addInputElement.value).length > 0) {
      alert("이미 추가된 멤버입니다.");
      return;
    }

    if (this.state.user.length >= this.state.maxCountValue) {
      alert("제한 인원수를 초과하였습니다.");
      return;
    }

    this.setState({ isLoadingAddMember: true });

    let resData = await this.api.getUserIdByEmail(addInputElement.value);
    if (resData.getUserIdByEmail.userId) {
      let result = [].concat(this.state.user);
      result.push({
        userId: resData.getUserIdByEmail.userId,
        email: addInputElement.value,
        isMaster: this.state.user.length === 0 ? true : false,
        isAuth: false
      });

      this.setState({
        isLoadingAddMember: false,
        user: result
      });
    }
    else {
      alert("존재하지 않는 유저입니다.");
      this.setState({ isLoadingAddMember: false });
    }
  }

  isCheckType = (objectId) => {
    if (Array.isArray(this.state.typeValue)) {
      if (this.state.typeValue.filter(i => i._id === objectId).length > 0) {
        return true;
      }
    }

    return false;
  }

  onCheckedType = (event, item) => {
    let isChecked = event.target.checked;
    let result = this.state.typeValue;
    if (!result) result = [];

    if (isChecked) {
      let insertValue = {
        _id: item._id,
        label: item.label,
        value: item.value
      };

      result.push(insertValue);
      this.setState({ typeValue: result });
    } else {
      this.setState({
        typeValue: result.filter(i => i._id !== item._id)
      });
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={{ pathname: this.state.redirect }} />
    }

    if (!this.state.isLoading) {
      return <div className="container">
        <div style={{ display: "flex", width: "100%", padding: "0px 55px", justifyContent: "flex-end", marginBottom: 10 }}>
          <button onClick={this.onClickSave}>저장</button>
        </div>
        <div className="enterprise-input-wrap">
          <span className="enterprise-input-title">
            이름
          </span>
          <input type="text" className="enterprise-input" value={this.state.titleValue} id="enterprise-title" onChange={(e) => this.setState({ titleValue: e.target.value })} />
        </div>
        <div className="enterprise-input-wrap">
          <span className="enterprise-input-title">
            인원 제한
          </span>
          <select value={this.state.maxCountValue} onChange={this.onChangeMaxCount} style={{ width: 100, height: 30 }}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
        </div>
        <div className="enterprise-input-wrap">
          <span className="enterprise-input-title">
            타입
          </span>
          {/* <select value={this.state.typeValue} onChange={(e) => this.setState({ typeValue: e.target.value })} style={{ width: 200, height: 30 }}>
            {
              this.state.typeData.map(item => {
                return <option value={item._id}>{item.label}</option>
              })
            }
          </select> */}
          <div className="enterprise-input">
            {
              this.state.typeData.map((item, index) => {
                return <div style={{ display: "flex", alignItems: "center" }}>
                  <input type="checkbox" id={`checkbox-type|${index}`} defaultChecked={this.isCheckType(item._id)} onChange={(e) => this.onCheckedType(e, item)}/>
                  <label style={{ marginLeft: 10, marginBottom: 0 }} for={`checkbox-type|${index}`}>
                    {item.label}
                  </label>
                </div>
              })
            }
          </div>
        </div>
        <div className="enterprise-input-wrap">
          <span className="enterprise-input-title">
            시작일(KST)
          </span>
          <div className="enterprise-input" style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 190 }}>
              <DatePicker
                selected={new Date(this.state.start_at * 1000)}
                onChange={date => this.onChangeStartDate(date)}
                dateFormat="yyyy/MM/dd h:mm aa"
                showTimeInput
                maxDate={new Date(this.state.expire_at * 1000)}
                onFocus={(e) => e.target.readOnly = true}
              />
            </div>
            <span style={{ marginLeft: 10, color: "red", fontSize: 14 }}>
              백오피스 설정은 KST입니다. 블링은 UTC (-9시간)
            </span>
          </div>
        </div>
        <div className="enterprise-input-wrap">
          <span className="enterprise-input-title">
            종료일(KST)
          </span>
          <div className="enterprise-input" style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: 190 }}>
              <DatePicker
                selected={new Date(this.state.expire_at * 1000)}
                onChange={date => this.onChangeExpireDate(date)}
                dateFormat="yyyy/MM/dd h:mm aa"
                showTimeInput
                minDate={new Date(this.state.start_at * 1000)}
                onFocus={(e) => e.target.readOnly = true}
              />
            </div>
            <span style={{ marginLeft: 10, color: "red", fontSize: 14 }}>
              백오피스 설정은 KST입니다. 블링은 UTC (-9시간)
            </span>
          </div>
        </div>
        <div className="enterprise-input-wrap">
          <span className="enterprise-input-title">
            멤버
          </span>
          <div className="enterprise-input">
            <ul style={{ marginBottom: 0 }}>
              {
                this.state.user.map(item => {
                  return <li className="enterprise-member-item">
                    <div className="enterprise-member-email-wrap">
                      {item.email}
                    </div>
                    <div className="enterprise-member-action-button-wrap">
                      <button className={item.isAuth ? "enterprise-member-action-button-master selected" : "enterprise-member-action-button-master"}
                              onClick={() => this.onClickAuthMember(item.userId)}>A</button>
                      <button className={item.isMaster ? "enterprise-member-action-button-master selected" : "enterprise-member-action-button-master"}
                              onClick={() => this.onClickMasterMember(item.userId)}>M</button>
                      <button onClick={() => this.onClickRemoveMember(item.userId)}>삭제</button>
                    </div>
                  </li>
                })
              }
            </ul>
          </div>
        </div>
        {
          this.state.isLoadingAddMember ?
            <></>
            :
            <div className="enterprise-input-wrap">
              <span className="enterprise-input-title">
                멤버 추가
              </span>
              <div className="enterprise-input" style={{ display: "flex" }}>
                <input type="text" id="enterprise-member-add" style={{ width: "50%" }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.onClickAddMember();
                    }
                  }} />
                <div className="enterprise-member-action-button-wrap">
                  <button onClick={this.onClickAddMember}>추가</button>
                </div>
              </div>
            </div>
        }
        <div className="enterprise-input-wrap">
          <span className="enterprise-input-title">
            메모
          </span>
          <div className="enterprise-input">
            <textarea type="text" style={{ width: "100%", height: "100px" }} value={this.state.memoValue} onChange={(e) => this.setState({ memoValue: e.target.value })} />
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={30000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
          pauseOnHover={false}
        />
      </div>
    }
    else {
      return <div style={{ width: "100%", textAlign: "center" }}>로딩중...</div>
    }
  }
}

export default enterpriseEdit;