import React, { useEffect, useState, useCallback, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import ApiService from '../../services/ApiService';
import CampaignModal from './CampaignModal';

import moment from 'moment';
import numeral from 'numeral';

const api = new ApiService();
const BZZNBYD_EMAIL = [
  "qpal0757@naver.com", // cynthia
  "jimmy0152@nate.com", // cindy
  "vlingenter01@gmail.com",
  "vlingguest@gmail.com",
  "yongdol45@gmail.com", // tony
  "yongdol-ui-munh-8386@pages.plusgoogle.com", //tony
  "mlksh@hanmail.net", // tony
  "breaktimemobile@gmail.com", // barney
  "hamhwan1@naver.com", // barney
  "hamhwan01@naver.com", // barney
  "hamhwan1@gmail.com", // barney
  "hamhwan01@gmail.com", // barney
  "hamhwan02@gmail.com", // barney
  "hamhwan03@gmail.com", // barney
  "hamhwan04@gmail.com", // barney
  "hamhwan05@gmail.com", // barney
  "hamhwan06@gmail.com", // barney
  "hamhwan07@gmail.com", // barney
  "hamhwan08@gmail.com", // barney
  "hamhwan09@gmail.com", // barney
  "chiczza@naver.com", // matt
  "jl3229@naver.com", // matt
  "jeya0729@naver.com", // matt
  "wo1548@naver.com", // brian
  "yaejin9503@nate.com", //cindy
  "jjjwodls@naver.com", // jaess
  "jimmy0152@naver.com", // cindy
  "recordeun@naver.com", // ketty
  "jyjo_ov@nate.com", // jenny
  "ybsyong@hanmail.net", // david
  "ybsyong@daum.net", // david
  "ybsyong@naver.com", // david
  "ybsyong@gmail.com", // david
];

export default function CampaignList() {
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const [finishedCampaign, setFinishedCampaign] = useState([]);
  const [unfinishedCampaign, setUnfinishedCampaign] = useState([]);

  const [modalData, setModalData] = useState([]);
  const [isExceptBzznbyd, setIsExceptBzznbyd] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [campaignDataUpdated, setCampaignDataUpdated] = useState(false);

  const { lockScroll, openScroll } = useBodyScrollLock();

  const gridRef_finished = useRef();
  const gridRef_unfinished = useRef();

  const columnDefs = [
    {
      headerName: '캠페인',
      children: [
        { headerName: "No.", field: "index", sortable: true, width: 60, resizable: true, pinned: 'left' },
        { headerName: "등급", field: "grade", filter: true, width: 100, tooltipValueGetter: (params) => params.value, enableTooltip: true, resizable: true, pinned: 'left' },
        { headerName: "만든사람", field: "email", filter: true, width: 160, tooltipValueGetter: (params) => params.value, enableTooltip: true, resizable: true, pinned: 'left' },
        { headerName: "캠페인명", field: "campaignName", filter: true, width: 160, tooltipValueGetter: (params) => params.value, enableTooltip: true, resizable: true, pinned: 'left' },
        { headerName: "컨설팅", field: "isConsulting", filter: true, sortable: true, width: 80, resizable: true, pinned: 'left' },
        { headerName: "마법사", field: "isWizard", filter: true, sortable: true, width: 80, resizable: true, pinned: 'left' },
      ],
      resizable: true,
    },
    {
      headerName: '진행정보',
      children: [
        { headerName: "생성일", field: "createdAt", sortable: true, width: 100, resizable: true },
        { headerName: "발송일", field: "sendAt", sortable: true, width: 100, resizable: true },
        {
          headerName: "단계", field: "step", filter: true, sortable: true, width: 80, resizable: true,
          cellStyle: params => {
            if (params.value === '발송') return { background: '#ccff99' };
            else if (params.value === '발송중') return { background: 'red' };
            else return null;
          }
        },
      ]
    },
    {
      headerName: '참여(명)',
      children: [
        { headerName: "발송", field: "involvedCount", sortable: true, width: 60, resizable: true },
        { headerName: "도착", field: "deliveryCount", sortable: true, width: 60, resizable: true },
        { headerName: "오픈", field: "openCount", sortable: true, width: 60, resizable: true },
        { headerName: "클릭", field: "clickCount", sortable: true, width: 60, resizable: true },
        { headerName: "채팅중", field: "activeCount", sortable: true, width: 60, resizable: true },
        { headerName: "참여 인플루언서", field: "involved", filter: true, width: 550, columnGroupShow: 'open', tooltip: (params) => params.value, enableTooltip: true, resizable: true },
      ]
    },
    {
      headerName: '광고정보',
      children: [
        { headerName: "회사(브랜드)명", field: "productName", filter: true, width: 120, tooltip: (params) => params.value, enableTooltip: true, resizable: true },
        { headerName: "광고 이름", field: "title", filter: true, width: 120, tooltip: (params) => params.value, enableTooltip: true, resizable: true },
        { headerName: "광고 설명", field: "desc", filter: true, width: 160, tooltip: (params) => params.value, enableTooltip: true, resizable: true },
        { headerName: "최소예산", field: 'minBudget', width: 90, resizable: true },
        { headerName: "최대예산", field: 'maxBudget', width: 90, resizable: true },
      ]
    }
  ];

  useEffect(() => {
    const handleCampaigns = async () => {
      try {
        const resData = await api.getCampaigns({size: 100});
        resData?.getCampaigns && setCampaignData(resData.getCampaigns);
        setLoading(false);
      } catch (e) {
        setError('Failed to fetch campaigns.');
        setLoading(false);
      }
    }
    handleCampaigns();
    if (campaignDataUpdated) {
      setCampaignDataUpdated(false);
      setShowModal(false);
    }
  }, [campaignDataUpdated]);

  useEffect(() => {
    if (campaignData) {
      onGridReady(campaignData);
    }
  }, [campaignData])

  const onGridReady = async (data) => {
    
    const resultData = data.map((item, index) => {
      if (index === 0) console.log('item', item.grade)
      if (!item.involved) item.involved = [];
      let createdAt = moment(item.createdAt).locale('en').format('YYYY-MM-DD HH:mm');
      let sendAt = item.sendAt ? moment(item.sendAt).locale('en').format('YYYY-MM-DD HH:mm') : "-";
      if (sendAt.includes("9999") || sendAt.includes("10000")) sendAt = "-";
      let step = "발송"
      let deliveryCount = 0;
      let openCount = 0;
      let clickCount = 0;
      item.involved.forEach((involved) => {
        let eventHistory = involved.eventHistory;
        if (involved.snsDeliveryEmail) {
          deliveryCount++;
        }
        if (involved.pixelOpenEmail) {
          openCount++;
        }
        if (involved.snsClickEmail) {
          clickCount++;
        }
        if (eventHistory && eventHistory.length > 0) {
          const lastEvent = eventHistory[eventHistory.length - 1];
          if (lastEvent.eventType === 'FindEmail') step = "발송중";
        }
      })

      const resultItem = {
        index: (index + 1),
        objectId: item._id,
        campaignName: item.campaignName,
        productName: item.productName,
        title: item.title,
        adType: item.adType,
        videoType: item.videoType,
        desc: item.description,
        grade: item.grade,
        language: item.language,
        email: item.email,
        makerId: item.makerId,
        userId: item.makerId,
        minBudget: numeral(item.minBudget).format('0,0'),
        maxBudget: numeral(item.maxBudget).format('0,0'),
        createdAt: createdAt,
        sendAt: sendAt,
        step: step,
        isConsulting: !item.isConsulting ? "X" : "O",
        isWizard: !item.isWizard ? "X" : "O",
        involvedCount: item.involved.length,
        deliveryCount: deliveryCount,
        openCount: openCount,
        clickCount: clickCount,
        involved: getInvolvedIds(item.involvedChannels),
        activeCount: item.involved.filter(i => i.state === "active").length,
        involved_all: item.involved,
        involvedChannels: item.involvedChannels
      };
      return resultItem;
    })

    let makerIds = resultData.map(row => row.makerId);
    makerIds = Array.from(new Set(makerIds));
    // let memberNameArrs = await getMemberData(makerIds)

    const updatedResultData = resultData.map(res => {
      // let member = memberNameArrs.find(member => member.id === res.makerId);
      // if (member && member.name && member.email) {
        // res.makerId = member.name + "(" + member.email + ")";
        // res.email = member.email;
      //   res.grade = "-";
      //   if (member.grade === "normal") res.grade = "베이직";
      //   else if (member.grade === "standard") res.grade = "스탠다드";
      //   else if (member.grade === "premium") res.grade = "스타트업";
      //   else if (member.grade === "professional") res.grade = "프로페셔널";
      //   else if (member.grade === "enterprise") res.grade = "엔터프라이즈";
      // } else {
      //   res.makerId = "탈퇴계정(" + res.makerId + ")";
      //   res.email = "-";
      // }
      res.isBzznbyd = (res.email && (res.email.includes("@bzznbyd") || BZZNBYD_EMAIL.includes(res.email))) ? true : false;

      return res;
    })

    const finishedCampaignData = updatedResultData.filter((x) => (x.step === "발송"));
    const unfinishedCampaignData = updatedResultData.filter((x) => (x.step === "발송중"));

    setFinishedCampaign(finishedCampaignData);
    setUnfinishedCampaign(unfinishedCampaignData);
  }

  const getInvolvedIds = useCallback((involvedData) => {
    if (!involvedData || !Array.isArray(involvedData)) return "";
    const result = involvedData.map(item => item?.title&&item?.description).join(", ");
    return result;
  }, []);

  const getMemberData = async (makerIds) => {
    console.log(makerIds)
    const resData = await api.getMembers({ userIds: makerIds });
    
    if (!resData?.getMembers?.result || !Array.isArray(resData.getMembers.result)) {
      return [];
    }

    const memberNameArrs = resData.getMembers.result.map((m) => ({
      id: m._id,
      email: m.email,
      name: m.name,
      grade: m.grade,
    }));

    return memberNameArrs;
  };

  const onBtnExport = (finished) => {
    try {
      const date = new Date(new Date().valueOf() + 1000 * 60 * 60 * 9).toISOString().split('T')[0];
      finished ? gridRef_finished.current.api.exportDataAsCsv({fileName: `campagin_${date}`}) : gridRef_unfinished.current.api.exportDataAsCsv({fileName: `campagin_${date}`});
    } catch(e) {
      console.log(e)
    }
  }

  const handleCellClick = async (params) => {
    lockScroll();
    setShowModal(true);

    const memberData = await api.getMember(params.data.userId)

    let member = memberData.getMember.result[0]

    let finalMemberData = params.data
    if (member && member.name && member.email) {
      finalMemberData.makerId = member.name + "(" + member.email + ")";
      finalMemberData.email = member.email;
      finalMemberData.grade = "-";
      if (member.grade === "normal") finalMemberData.grade = "베이직";
      else if (member.grade === "standard") finalMemberData.grade = "스탠다드";
      else if (member.grade === "premium") finalMemberData.grade = "스타트업";
      else if (member.grade === "professional") finalMemberData.grade = "프로페셔널";
      else if (member.grade === "enterprise") finalMemberData.grade = "엔터프라이즈";
    } else {
      finalMemberData.makerId = "탈퇴계정(" + finalMemberData.makerId + ")";
      finalMemberData.email = "-";
    }

    setModalData(finalMemberData)
  };

  const renderGrid = ({ columnDefs, rowData, onCellClicked, finished, title }) => {
    const gridOptions = {
      headerHeight: 30,
      groupHeaderHeight: 30,
      rowHeight: 30,
    };

    return (
      <>
        <button onClick={() => {onBtnExport(finished)}} style={{margin: "0 0 15px 0"}}>Download CSV export file</button>
        <div>{title}</div>
        <div className="ag-theme-balham campaignList" style={{ width: "100%", height: "595px" }}>
          <AgGridReact
            ref={finished ? gridRef_finished : gridRef_unfinished}
            gridOptions={gridOptions}
            columnDefs={columnDefs}
            rowData={isExceptBzznbyd ? rowData.filter(data => !data.isBzznbyd) : rowData}
            enableCellTextSelection={true}
            onCellClicked={onCellClicked}
          />
        </div>
      </>
    )
  }

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <div className="campaign-list-wrap">
          <div className="campaign-list-row-wrap">
            <div className="campaign-list-function-wrap">
              버즈앤비 제거 <input type="checkbox" checked={isExceptBzznbyd} onChange={(e) => setIsExceptBzznbyd(e.target.checked)} />
            </div>
          </div>
          <hr />
          {renderGrid({
            columnDefs: columnDefs,
            rowData: finishedCampaign,
            onCellClicked: null,
            finished: true,
            title: '발송 완료된 캠페인'
          })}
          <hr />
          {renderGrid({
            columnDefs: columnDefs,
            rowData: unfinishedCampaign,
            onCellClicked: handleCellClick,
            finished: false,
            title: '발송 완료되지 않은 캠페인'
          })}
          {showModal && (
            <CampaignModal
              modalData={modalData}
              setShowModal={setShowModal}
              setCampaignDataUpdated={setCampaignDataUpdated}
              openScroll={openScroll}
              api={api} />
          )}
        </div>
      )}
    </div>
  )
}

export function useBodyScrollLock() {
  const lockScroll = useCallback(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  const openScroll = useCallback(() => {
    document.body.style.removeProperty('overflow');
  }, []);

  return { lockScroll, openScroll };
}