import gql from 'graphql-tag';

export const getMailData = gql`
  query channel(
    $id: String!
    $userId: String
  ) { 
    channel(
      id: $id
      userId: $userId
    ) { 
      channelId
      mails
      emails {
        backoffice
        certification
        defaultMail
        description
      }
      title
      description
    }
  }
`;

export const postMailData = gql`
  mutation addMail(
    $channelId: String!
    $mails: [JSON]!
  ){
    addMail(
      channelId: $channelId
      mails: $mails
    )
  }
`;

export const sendAdsProposal = gql`
  mutation resendCampaign(
    $objectId: String!
    $makerId: String!
    $makerName: String
    $title: String
    $description: String
    $productName: String
    $adType: String
    $adTypeMsg: String
    $minBudget: Float
    $maxBudget: Float
    $involvedIds: [JSON]
    $isConsulting: Boolean
    $campaignType: String!
    $step: Float
    $useQuota: Int
    $videoType: String
    $email: String
    $language: String
  ) {
    resendCampaign(
      objectId: $objectId
      makerId: $makerId
      makerName: $makerName
      title: $title
      description: $description
      productName: $productName
      adType: $adType
      adTypeMsg: $adTypeMsg
      minBudget: $minBudget
      maxBudget: $maxBudget
      involvedIds: $involvedIds
      isConsulting: $isConsulting
      campaignType: $campaignType
      step: $step
      useQuota: $useQuota
      videoType: $videoType
      email: $email
      language: $language
    )
  }
`;

export const unsendableAdsProposal = gql`
mutation unsendableCampaign(
  $objectId: String!
  $makerId: String!
  $makerName: String
  $title: String
  $description: String
  $productName: String
  $adType: String
  $adTypeMsg: String
  $minBudget: Float
  $maxBudget: Float
  $involvedIds: [JSON]
  $isConsulting: Boolean
  $campaignType: String!
  $step: Float
  $useQuota: Int
  $videoType: String
  $email: String
  $language: String
  $remainQuotas: Int
) {
  unsendableCampaign(
    objectId: $objectId
    makerId: $makerId
    makerName: $makerName
    title: $title
    description: $description
    productName: $productName
    adType: $adType
    adTypeMsg: $adTypeMsg
    minBudget: $minBudget
    maxBudget: $maxBudget
    involvedIds: $involvedIds
    isConsulting: $isConsulting
    campaignType: $campaignType
    step: $step
    useQuota: $useQuota
    videoType: $videoType
    email: $email
    language: $language
    remainQuotas: $remainQuotas
  )
}
`;

export const getQuotas = gql`
  query getQuotas(
    $userId: String!
    $type: String
  ) {
    getQuotas(
      userId: $userId
      type: $type
    ) { 
      totalQuotas
      usedQuotas
      remainQuotas
    }
  }
`;