import React from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import Chips from "react-chips";
import update from 'react-addons-update';

import ApiService from '../../services/ApiService';
import UtilService from '../../services/UtilService';

import Button from '@material-ui/core/Button';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import * as moment from 'moment';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { ApolloProvider } from '@apollo/react-hooks';
import UploadFile from './UploadFile';
import ClinetFileUpload from '../commons/ClientFileUpload';

class MetaTagDetail extends React.Component {

  constructor(props) {

    super(props);
    this.api = new ApiService();
    this.util = new UtilService();
    this.metaTagId = this.props.match.params.id;
    this.state = {
      metaTagData : {},
      clientUpload : ClinetFileUpload(),
      bannerImg : ""
    }
  }

  // ============================================================= Life cycle 
  componentDidMount() {
    
    //데이터 주입
    this.initialize();
  }

  // ============================================================= Component function
  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
    
    let metaTagId = this.metaTagId;
    let resData = null;
    let _clientUpload = await ClinetFileUpload();
    let bannerImg = "";
    if(metaTagId !== "create"){
      let params = {id : metaTagId};
      resData = await this.api.getMetaTag(params);
      if(resData && resData.getMetaTag){
        resData = resData.getMetaTag;
        bannerImg = resData.banner;
      }
      
      this.setState({
        metaTagData : resData,
        clientUpload : _clientUpload,
        bannerImg : bannerImg
      });
      
    }else{
      resData = metaTagId;
      
      this.setState({
        metaTagData : resData,
      });

    }
    
  }

  onDateChange = async (value) => {
    let params = {
      date: moment(value).format("YYYY-MM-DD")
    }

    let resData = await this.api.getHottrendKeyword(params);
    resData = resData.trendData.length > 0 ? resData.trendData[0].trends : []

    let tempregDate = this.state.IsCreate ? params.date : this.state.regDate;

    this.setState({
      regDate: tempregDate,
      keywordData: resData
    })
  }

  keywordRender() {
    if (this.state.keywordData.length > 0) {
      return this.state.keywordData.map((data, index) => {
        return (
          <>
            <div className="hottrend-keyword-item">
              {data.id}
            </div>
          </>
        );
      });
    }
    return <div className="no-data-hottrend-keyword">
      해당 날짜에 조회된 데이터가 없습니다
    </div>
  }

  onChange = data => {
    let disabled = true;
    if (data.length != 0) {
      disabled = false;
    }

    this.setState({
      IsActivePreviewKr: disabled,
      keywordKrData: data
    });
  }

  getActionButton() {
    if (this.state.metaTagData === "create")
    {
      return (
        <>
          <Button variant="contained" color="primary" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickCreate}>등록</Button>
          <Button variant="contained" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickCancel}>취소</Button>
        </>
      );
    }
    return (
      <>
        <Button variant="contained" color="secondary" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickDelete}>삭제</Button>
        <Button variant="contained" color="primary" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickModify}>수정</Button>
        <Button variant="contained" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickCancel}>취소</Button>
      </>
    )
  }

  onClickGetPreview = async (id) => {
    if (id == "BtnPreviewKr") {
      let keywordData = this.ArrayToString(this.state.keywordKrData);
      let params = {
        keywords: keywordData
      }

      let resData = await this.api.getHottrendPreview(params);
      console.log(resData);
      this.setState({
        previewData: resData.trendDataPreview
      });
    }
    else if (id == "BtnPreviewGlobal") {
      let keywordData = this.ArrayToString(this.state.keywordGlobalData);
      let params = {
        keywords: keywordData
      }

      let resData = await this.api.getHottrendPreview(params);
      this.setState({
        previewData: resData.trendDataPreview
      });
    }
  }

  onClickDelete = async () => {
      
      let delConfirm = window.confirm("정말로 삭제하시겠습니까?");

      if(!delConfirm){
        return;
      }

      let params = {
        id : this.metaTagId
      };

      let resData = await this.api.deleteMetaTag(params);
      if(resData?.deleteMetaTag?.result === 1){
        alert("삭제 하였습니다!");
        this.setState({
          redirect: "/metaTagList"
        })
      }else{
        alert("삭제에 실패했습니다. 개발자에게 문의주세요.");
      }
  }

  onClickCreate = async () => {
    let page_name = document.getElementById("page_name").value;
    let formData = document.getElementById("metaForm");
    if(page_name.trim().length > 0){
      let params = {};
      formData = Array.from(formData).filter(data => data.tagName === "INPUT");
      formData.map(input => {
        params[input.id] = input.value;
      });
      let resData = await this.api.insertMetaTag(params);
      if(resData?.insertMetaTag?.result === 1){
        alert("등록에 성공하였습니다.")
        this.setState({
          redirect: "/metaTagList"
        })
      }else{
        alert("등록에 실패하였습니다. 개발자에게 문의주세요");
      }
    }else{
      alert("페이지명은 필수 입력입니다.");
      return;
    }
  }

  onClickModify = async () => {
    let page_name = document.getElementById("page_name").value;
    let formData = document.getElementById("metaForm");
    if(page_name.trim().length > 0){
      let params = {};
      formData = Array.from(formData).filter(data => data.tagName === "INPUT" && data.type !=="file");
      formData.map(input => {
        params[input.id] = input.value;
      });
      params["id"] = this.metaTagId;
      let resData = await this.api.updateMetaTag(params);
      if(resData?.updateMetaTag?.result === 1){
        alert("등록에 성공하였습니다.")
        this.setState({
          redirect: "/metaTagList"
        })
      }else{
        alert("등록에 실패하였습니다. 개발자에게 문의주세요");
      }
    }else{
      alert("페이지명은 필수 입력입니다.");
      return;
    }
  }

  onClickCancel = () => {
    this.setState({
      redirect: "/metaTagList"
    })
  }

  ArrayToString(data) {
    let result = "";
    for(let i = 0; i < data.length; i++) {
      let str = data[i].toString();
      if (str.indexOf('+') != -1) {
        let tempArr = str.split('+');
        let tempStr = "";
        for (let j = 0; j < tempArr.length; j++) {
          tempStr += (tempStr == "" ? "" : ",") + `"${tempArr[j]}"`;
        }
        tempStr = `[${tempStr}]`;
        result += (result == "" ? "" : ",") + tempStr;
      }
      else {
        let tempStr = `["${str}"]`;
        result += (result == "" ? "" : ",") + tempStr;
      }
    }
    result = `[${result}]`;
    return result;
  }

  // ArrayToJson(keywords) {
  //   //let tempStr = `[{"id":"${themaName}","keywords":${this.ArrayToString(keywords)}}]`

  //   //tempStr = JSON.stringify(tempStr);
  //   //return tempStr;
  // }

  mngPreviewButton = () => {
    console.log("mngPreviewButton");
    if (document.getElementById("themaKrInput").value && this.state.keywordKrData.length != 0) {
      this.setState({
        IsActivePreviewKr: false
      })
    }
    else {
      this.setState({
        IsActivePreviewKr: true
      })
    }
  }

  setThumbnails(urlStr) {
    return urlStr.replace("default", "hqdefault");
  }

  

  PreviewDataToDiv(previewData) {
    return (
      <>
      <div className="col-lg-4">
          <div className="video-list-portlet">
            <div fit={true} className="video-list-body">
              <div className="video-list-thumbnails">
                <Link to={`/todayvideo/realtime/${previewData.videoId}`}>
                  <img src={this.setThumbnails(previewData.thumbnails)} className="video-list-thumbnails-img" width="300px" alt="image" />
                </Link>
              </div>
              <div className="video-list-desc">
                <div className="">{moment(previewData.publishedAt).format("YYYY-MM-DD")}</div>
                <div className="video-list-desc-title">{previewData.title}</div>
                <div className="video-list-desc-bottom">
                  <div className="video-list-desc-dailycount"> + {previewData.viewCount}</div>
                  <div>조회수 증가</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  handleUploadFile = (bannerUploadFile) => {
    console.log("success");
    this.setState({
      bannerImg : bannerUploadFile.filename
    });
  }

  metaInputChange = (e) => {
    let currentId = e.target.id;
    let value = e.target.value;
    this.setState({
      metaTagData: update(
        this.state.metaTagData,
        {
          [currentId] : {
            $set: value
          }
        }
      )
    })
  }

  resetBannerImg = () => {
    this.setState({
      bannerImg : ""
    });
  }

  render() {
    let curState = this.state;
    let metaTagData = curState.metaTagData;
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect} />
    }

    return (
      <>
      <form id="metaForm">
          <div className="form-group">
            <label for="page_name">페이지명</label>
            <input type="text" id="page_name" className="form-control" placeholder="페이지명" onChange = {this.metaTagId === "create" ? "" : this.metaInputChange} value={metaTagData.page_name}/>
          </div>
          <div className="form-group">
            <label for="url">URL</label>
            <input type="text" id="url" className="form-control" placeholder="URL" onChange = {this.metaTagId === "create" ? "" : this.metaInputChange} value={metaTagData.url}/>
          </div>
          <div className="form-group">
            <label for="title">타이틀</label>
            <input type="text" id="title" className="form-control" placeholder="title" onChange = {this.metaTagId === "create" ? "" : this.metaInputChange} value={metaTagData.title}/>
          </div>
          <div className="form-group">
            <label for="description">description</label>
            <input type="text" id="description" className="form-control" onChange = {this.metaTagId === "create" ? "" : this.metaInputChange} placeholder="description" value={metaTagData.description}/>
          </div>
          <div className="form-group">
            <label for="site_name">site_name</label>
            <input type="text" id="site_name" className="form-control" onChange = {this.metaTagId === "create" ? "" : this.metaInputChange} placeholder="site_name" value={metaTagData.site_name}/>
          </div>
          {this.metaTagId === "create" ? 
            <></> 
              : 
            <div className="form-group">
            
            <div>
              <ApolloProvider client={this.state.clientUpload}>
                <UploadFile
                  handleUploadFile={this.handleUploadFile}
                  authId={this.authId}
                  objectId={this.metaTagId}
                >
                </UploadFile>
              </ApolloProvider>
            </div>
            <label for="banner">배너 이미지(기본 배너 이미지 : "")</label>
            <Button id="banner" variant="contained" color="primary" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.resetBannerImg}>배너 초기화</Button>
            <div>
              {this.state.bannerImg ? <img src={this.state.bannerImg+`?v=${this.util.getEPOCHSeconds()}`} width="300px" alt="image" /> : <></>}
            </div>
            <div style={{marginBottom : "10px"}}>만약 파일 업로드를 하지 않으면 기본 배너 이미지를 사용합니다.</div>
            <input id="banner" type="text" className="form-control" placeholder="banner" value={this.state.bannerImg}/>
          </div>
          }
          
      </form>
      <div className="row">
        <div className="col-lg-12">
          <center>
            {this.getActionButton()}
          </center>
        </div>
      </div>
      </>
    )
  }
}

export default (MetaTagDetail);