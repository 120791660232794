import React from "react";

import { Route } from "react-router-dom";
import ManageFavorite from "../components/manageFavorite/ManageFavorite";


export default function ManageFavoritePage(props) {
  return (
    <>
      <Route exact path={props.match.path} component={ManageFavorite} />
    </>
  );
}
