import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { createGradeList } from '../gql';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';

export default function InputModal({ handleCloseModal, setUpdateData, SUB_FIELDS }) {
  const classes = useStyles();

  const [createGradeFunc] = useMutation(createGradeList);
  const { _id, ...subFieldsWithoutId } = SUB_FIELDS;
  const [newField, setNewField] = useState({
    name: '',
    grade: '',
    level: 0,
    quotas: { ...subFieldsWithoutId },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const inputValue = name === "level" ? parseInt(value) : value
    setNewField((prevNewField) => ({ ...prevNewField, [name]: inputValue }));
  };

  const handleQuotasChange = (event, key) => {
    const { value } = event.target;
    const inputValue = parseInt(value)
    setNewField((prevNewField) => ({
      ...prevNewField,
      quotas: { ...prevNewField.quotas, [key]: inputValue },
    }));
  };

  const handleSubmit = async () => {
    try {
      console.log("newField", newField)
      const input = {
        name: newField.name,
        grade: newField.grade,
        level: newField.level,
        quotas: { ...newField.quotas }
      }
      await createGradeFunc({
        fetchPolicy: "network-only",
        variables: { input }
      });
    } catch (error) {
      console.error(error);
    }
    setUpdateData(true)
    setNewField({
      name: '',
      grade: '',
      level: 0,
      quotas: { ...subFieldsWithoutId },
    });
    handleCloseModal();
  }

  return (
    <Dialog open={true} onClose={handleCloseModal}>
      <DialogTitle>Add Field</DialogTitle>
      <DialogContent className={classes.dialogContent} alignItems='center'>
        <DialogContentText>
          Please enter the details of the new field.
        </DialogContentText>
        <form className={classes.modalContent}>
          <TextField
            name="name"
            label="Name"
            value={newField.name}
            onChange={handleChange}
            required
            className={classes.textField}
          />
          <TextField
            name="grade"
            label="Grade"
            value={newField.grade}
            onChange={handleChange}
            required
            className={classes.textField}
          />
          <TextField
            name="level"
            label="Level"
            type="number"
            inputProps={{ min: 0, max: 999 }}
            InputLabelProps={{
              style: { textAlign: 'center' },
            }}
            value={newField.level ? parseInt(newField.level) : ''}
            onChange={handleChange}
            required
            className={classes.textField}
          />
          <div>
            <label>Quotas</label>
            <Grid container className={classes.subFields} spacing={2} style={{ marginTop: '10px' }}>
              {Object.entries(subFieldsWithoutId).map(([key, value], index) => (
                <Grid item xs={6} key={index}>
                  <TextField
                    label={key}
                    type="number"
                    inputProps={{ min: 0, max: 9999 }}
                    value={newField.quotas[key] !== undefined ? parseInt(newField.quotas[key]) : ''}
                    onChange={(event) => handleQuotasChange(event, key)}
                    required
                    className={classes.textField}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(3),
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  subFields: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& .MuiInputLabel-formControl': {
      top: '-4px',
      left: '16px',
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(0, -4px) scale(0.75)',
    },
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));