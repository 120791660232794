import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import moment from 'moment';
import Modal from 'react-responsive-modal';

// components
import InventoryList from './InventoryList';
import BannerList from '../Banner/BannerList';
import BannerDetail from '../Banner/BannerDetail';

// util
import _arrayToInventoryData from './_arrayToInventoryData';

// css
import styles from './modal.module.scss';

// gql
import { getInventory_withBanners, upsertInventory, deleteInventory } from './gql';
import { upsertBanner } from '../Banner/gql'

//
export default function InventoryDetail() {
  const history = useHistory();
  const { inventoryId } = useParams();
  const { error: query_error, data: query_data, refetch: getInventory_refetch } = useQuery(getInventory_withBanners, {
    variables: {
      _id: inventoryId
    }
  });
  const [upsertInventoryFn] = useMutation(upsertInventory);
  const [deleteInventoryFn] = useMutation(deleteInventory);
  const [upsertBannerFn] = useMutation(upsertBanner);
  // ===== [ 테이블 ] =====
  const [inventoryRowData, setInventoryRowData] = useState([]);
  const [bannerRowData, setBannerRowData] = useState([]);
  useEffect(() => {
    if (query_data) {
      console.log(query_data)
      if (query_data.getInventory) {
        setInventoryRowData(prev => {
          const rawData = [].concat([query_data.getInventory]);
          return _arrayToInventoryData(rawData)
        })
        setBannerRowData(prev => {
          const rawBannerData = [].concat(query_data.getInventory.banners);
          const newState = rawBannerData.map((raw, idx) => ({
            ...raw,
            index: idx + 1,
            bannerLogo: raw.bannerLogoURL ? 'O' : 'X',
            bannerPC: raw.bannerURL ? 'O' : 'X',
            bannerMobile: raw.bannerMobileURL ? 'O' : 'X',
            position: [],
            Pday: `${moment.duration(moment(Date.now()).diff(raw.startAt * 1000)).days()}`,
            Dday:`${moment.duration(moment(Date.now()).diff(raw.expireAt * 1000)).days()}`,
            startAt_str: moment(raw.startAt * 1000).format('YYYY-MM-DD | HH:mm:ss'),
            expireAt_str: moment(raw.expireAt * 1000).format('YYYY-MM-DD | HH:mm:ss'),
          }))
          return newState
        })
      } else {
        history.push(`/banner/inventory`);
      }
    }
  }, [query_data]);

  const onCellValueChanged = async (params) => {
    console.log(params);
    const colId = params.column.getId();
    if (colId.includes('userId') && params.newValue !== params.oldValue) {
      const userId = params.data.userId.trim(); // "8e0a98e7-b61b-4ebd-b9c2-9643e7aba143"  예외 처리 필요...
      const upsert_result = await upsertInventoryFn({
        variables: {
          _id: params.data.inventoryId,
          userId
        }
      })
      if (upsert_result.data) {
        getInventory_refetch();
      }
    }
  };

  const deleteInventorybyId = async (inventoryId) => {
    if (window.confirm("정말 삭제 하시겠습니까?")) {
      const delete_result = await deleteInventoryFn({ variables: { _id: inventoryId } })
      console.log(inventoryId)
      console.log(delete_result.data.deleteInventory.result)
      if (delete_result.data) {
        if (delete_result.data.deleteInventory && delete_result.data.deleteInventory.result.deletedCount === 1) {
          history.push('/banner/inventory', 'refetch');
        }
      } else {
        alert("알수 없는 오류");
      }
    }
  }

  const _upsertBanner = async (inventoryId) => {
    const result = await upsertBannerFn({ variables: { inventoryId, type: 'wide_top' } })
    if (result.data) {
      getInventory_refetch();
    }
  }
  //#region [모달]
  const [isModal, setIsModal] = useState(false);
  const [selectedBannerId, setSelectedBannerId] = useState('')
  const _onpenModal = (selectedData) => {
    setSelectedBannerId(selectedData._id)
    setIsModal(true)
  }
  const _closeMoadl = () => {
    console.log("Modal close")
    setIsModal(false);
    getInventory_refetch();
  }
  //#endregion [모달]

  // ======================
  if (query_error) throw query_error

  return (
    <div>
      <section>
        <p>인벤토리 정보</p>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <button onClick={() => history.push('/banner/inventory')}>{`<- 목록`}</button>
          <button
            style={{ marginLeft: "auto" }}
            onClick={() => deleteInventorybyId(inventoryRowData[0].inventoryId)}
          >
            완전 삭제
          </button>
        </div>
        <InventoryList rowData={inventoryRowData} onCellValueChanged={onCellValueChanged} />
      </section>
      <section style={{ marginTop: "50px" }}>
        <p>배너 리스트</p>
        <div style={{ display: "flex", marginBottom: "10px" }}>
          <button
            style={{ marginLeft: "auto" }}
            onClick={() => _upsertBanner(inventoryRowData[0].inventoryId)}
          >
            배너 생성
          </button>
        </div>
        <BannerList
          rowData={bannerRowData}
          onCellValueChanged={onCellValueChanged}
          rowClickCB={(selectedData) => _onpenModal(selectedData)}
        />
      </section>
      <Modal
        classNames={{ modal: styles.wrapper }}
        center={true}
        open={isModal}
        onClose={_closeMoadl}
      >
        {selectedBannerId &&
          <BannerDetail bannerId={selectedBannerId} modalClose={_closeMoadl} />
        }
      </Modal>
    </div>
  )
}