import React, { useEffect, useState, useRef } from 'react';

import Datasheet from "react-datasheet";
import 'react-datasheet/lib/react-datasheet.css';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import ApiService from '../../services/ApiService';

export default function MainChannelListAdd(props) {
  const nation = props.location.state.nation;
  const api = new ApiService();
  const [gridData, setGridData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [channelType, setChannelType] = useState("hot");
  const gridRef = useRef();

  // 데이터 API 호출
  const getMainChannelData = async (params) => {
    let parameter = {};
    parameter.isChannelIds = params.isChannelIds;
    parameter.nation = params.nation;
    if (params.channelIds) {
      parameter.channelIds = params.channelIds;
    }
    let resData = await api.getMainChannels(parameter);
    return resData;
  }

  // 가져온 데이터를 'hot' / 'found'에 따라 배열로
  const dataSet = async (value) => {
    let data = [];
    let resData = await getMainChannelData({ isChannelIds: false, nation: nation });
    if (value === "hot") data = resData ? resData.mainChannels ? resData.mainChannels.hot ? resData.mainChannels.hot : [] : [] : [];
    if (value === "found") data = resData ? resData.mainChannels ? resData.mainChannels.found ? resData.mainChannels.found : [] : [] : [];
    return data;
  };

  // 배열로 저장된 데이터를 표로 보여주기
  const dataInit = async () => {
    let dataSheeteArrs = [];
    let data = await dataSet(channelType);
    for (let i = 0; i < 21; i++) {
      if (i === 0) {
        dataSheeteArrs.push([{ readOnly: true, value: '', width: "10%" }, { value: "채널ID", readOnly: true }]);
      } else {
        let dataId = '';
        if (data.length + 1 > i) {
          dataId = data[i - 1].channelId;
        }
        dataSheeteArrs.push([{ readOnly: true, value: i, width: "10%" }, { value: dataId }]);
      }
    };
    setGridData(dataSheeteArrs);
    setRowData([]);
  };

  // render 시 데이터 불러오는 함수 실행
  useEffect(() => {
    dataInit();
  }, [nation]);

  // AgGridReact(오른쪽 표) 관련 함수들
  const onGridReady = async (params) => {
    params.api.sizeColumnsToFit();
    let resData = await api.getAddNewChannels();
    setRowData(resData.newChannels)
  };

  // 채널명 미리보기 버튼  
  const confirmNewChannels = async () => {
    let channelIds = [];
    gridData.map(item => {
      item.map(i => {
        if (isNaN(i.value)) {
          channelIds.push(i.value);
        }
        return i;
      })
      return item;
    });

    channelIds = channelIds.slice(1, channelIds.length);

    let parameter = { isChannelIds: true, channelIds: JSON.stringify(channelIds), nation: nation };
    let resData = await getMainChannelData(parameter);
    resData = resData ? resData.mainChannels ? resData.mainChannels : [] : [];

    let rowData = [];
    channelIds.map(c => {
      resData.map(data => {
        if (c === data.channelId) {
          let obj = {};
          obj.channelId = data.channelId;
          obj.channelTitle = data.title;
          rowData.push(obj);
        }
        return data;
      })
      return c
    })
    setRowData(rowData);
  }

  // 데이터 반영 버튼
  const addNewChannels = async () => {
    let channelIdArr = gridData.map((item, index) => {
      if (index > 0 && item[1].value !== "") {
        return item[1].value;
      } else { return "" }
    });

    //undefined , null 데이터 필터링
    channelIdArr = channelIdArr.filter(function (item) {
      return item;
    });

    if (channelIdArr.length > 0) {
      let resData = "";
      let parameter = {
        nation: nation,
        type: channelType,
        channelIds: JSON.stringify(channelIdArr)
      }
      resData = await api.addMainChannels(parameter);

      if (resData.addMainChannels === "success") {
        let data = [];
        let tempData = await dataSet(channelType);
        data = tempData.map(t => { return { channelId: t.channelId, channelTitle: t.title } });
        alert("저장되었습니다.");
        setRowData(data);
      } else {
        alert("저장 실패 했습니다.");
      }
    } else {
      alert("데이터가 없습니다.");
    }
  };

  // 요즘 뜨는 / 당신이 딱 셀렉트 버튼
  const onChangeOption = async (params) => {
    let value = params.target.value;
    setChannelType(value);
    let dataSheeteArrs = [];
    let data = [];
    data = await dataSet(value);
    for (let i = 0; i < 21; i++) {
      if (i === 0) {
        dataSheeteArrs.push([{ readOnly: true, value: '', width: "10%" }, { value: "채널ID", readOnly: true }]);
      } else {
        let dataId = '';
        if (data.length + 1 > i) {
          dataId = data[i - 1].channelId;
        }
        dataSheeteArrs.push([{ readOnly: true, value: i, width: "10%" }, { value: dataId }]);
      }
    } 
    setGridData(dataSheeteArrs)
    setRowData([]);
  };

  return (
    <>
      <div style={{ marginBottom: "10px", fontSize: "22px", fontWeight: "bold" }}>
        {nation === "KR" ? "한국" : "글로벌"}
      </div>
      <div className="row">
        <div className="col-lg-2">
          <select onChange={(params) => { onChangeOption(params) }}>
            <option value="hot">요즘 뜨는 크리에이터 추가</option>
            <option value="found">당신이 딱 찾던 크리에이터 추가</option>
          </select>
        </div>
        <div className="col-lg-10">
        </div>
      </div>
      <div className="section-line"></div>
      <div className="row" style={{ height: "400px" }}>
        <div className="col-lg-6">
          <div className="scrollbar backoffic-scrollbar">
            <Datasheet
              className="influencer-channels-datasheet"
              data={gridData}
              valueRenderer={(cell) => cell.value}
              onContextMenu={(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
              onCellsChanged={changes => {
                const newGrid = gridData.map(row => [...row])
                changes.forEach(({ cell, row, col, value }) => {
                  newGrid[row][col] = { ...newGrid[row][col], value }
                })
                setGridData(newGrid);
              }}
            />
          </div>
          <button onClick={() => { confirmNewChannels() }} style={{ marginLeft: "10px" }}>채널명 미리보기</button>
        </div>
        <div className="col-lg-6">
          <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              modules={AllCommunityModules}
              floatingFilter={true}
              columnDefs={[
                { headerName: "채널 ID", field: "channelId", filter: true, width: 350 },
                { headerName: "채널 명", field: "channelTitle", filter: true, width: 350 },
              ]}
              defaultColDef={{
                editable: false,
                enableRowGroup: true,
                enablePivot: true,
                enableValue: true,
                sortable: true,
                resizable: true,
              }}
              debug={true}
              pagination={true}
              rowSelection={"multiple"}
              onGridReady={onGridReady}
              rowData={rowData}
            >
            </AgGridReact>
          </div>
          <div style={{ marginBottom: 10 }}></div>
          <button onClick={() => { addNewChannels() }}>데이터 반영</button>
        </div>
      </div>
    </>
  )
}