import React, { useState } from 'react'
import ApiService from '../../services/ApiService';
import Button from '@material-ui/core/Button';
import { Auth } from 'aws-amplify';
import Modal from "react-responsive-modal";
import { deleteOpenapiCookie } from '../../utils/cookie';

const CONFIRM_REASON_INFO = [
  { value: 'company', info: '회사(업체)명 불일치' },
  { value: 'address', info: '사업장 소재지 불일치' },
  { value: 'number', info: '사업자번호 불일치' },
  { value: 'companyImage', info: '사업자등록증 확인 불가' },
]

const API = new ApiService();

const AdvertiserInfo = ({ history }) => {

  const advertiserInfo = history.location.state.advertiser;

  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmReason, setConfirmReason] = useState([]);

  const onCloseModal = () => setConfirmModal(false);

  const confirmAction = async (status) => {
    let user = await Auth.currentAuthenticatedUser();
    deleteOpenapiCookie();
    let session = await Auth.currentSession();
    let groups = session.getIdToken().payload["cognito:groups"];
    if (groups.indexOf('Authorized') === -1) {
      alert("인증되지 않은 사용자입니다.");
      Auth.signOut();
    }
    let creator = "";
    if (user?.attributes?.name) {
      creator = user.attributes.name;
    } else {
      throw new Error("user 정보가 없습니다. user정보를 확인해 주세요.");
    }

    if (status === "finish" && confirmReason.length > 0) {
      alert("승인은 거절 사유가 없어야 됩니다.");
      return;
    } else if (status === "decline" && confirmReason.length === 0) {
      alert("거절은 1개 이상의 사유가 있어야 됩니다.");
      return;
    }

    let params = {};
    params.confirmReason = confirmReason;
    params.creator = creator;
    params.status = status;
    params._id = advertiserInfo._id

    let result = await API.checkAdvertiser(params);
    if (result.checkAdvertiser === "200") {
      alert("확인이 완료되었습니다.");
      history.push({
        pathname: "/advertiserList"
      });
    } else {
      throw new Error("확인 도중 문제가 발생하였습니다.");
    }
  }

  const confirmAdvertiser = () => {
    setConfirmModal(true);
  }

  const confirmReasonSelect = (e, index) => {
    if (e.target.checked) {
      setConfirmReason([...confirmReason, CONFIRM_REASON_INFO[index], ...confirmReason.slice(index + 1)]);
    } else {
      setConfirmReason([...confirmReason.slice(0, index), ...confirmReason.slice(index + 1)]);
    }
  }

  return (
    <>
      <div className="form-group">
        <h5>회사(업체) 이름</h5>
        <br />
        <div id="companyName">
          {advertiserInfo.companyName}
        </div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="name">담당자 성함/직책</h5>
        <br />
        <div id="name">
          {advertiserInfo.name}
        </div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="phoneNumber">담당자 연락처</h5>
        <br />
        <div>{advertiserInfo.phoneNumber}</div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="email">이메일</h5>
        <br />
        <div>{advertiserInfo.email}</div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="businessAddress">사업장 소재지</h5>
        <br />
        <div>{advertiserInfo.businessAddress}</div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="businessNumber">사업장 번호</h5>
        <br />
        <div>{advertiserInfo.businessNumber}</div>
      </div>
      <hr />
      <div className="form-group">
        <h5 for="businessLicenseImg">사업자 등록증 사본</h5>
        <br />
        <img src={advertiserInfo.businessLicenseImg} alt="사업자 등록 사본" />
      </div>
      <Modal
        styles={{
          modal: {
            width: '500px',
            height: "auto",
            background: "#ffffff",
            borderRadius: '5px',
            alignSelf: "center",
            fontFamily: "Noto Sans KR"
          }
        }}
        open={confirmModal}
        onClose={onCloseModal}
        center={true}>
        <div>
          <h5>승인 또는 거절 사유를 입력해주세요.</h5>
          <hr />
          {CONFIRM_REASON_INFO.map((reason, index) => {
            return (
              <div>
                <input key={`confirm_${index}`} type="checkBox" style={{ marginRight: "5px" }} onChange={(e) => confirmReasonSelect(e, index)} />{reason.info}
              </div>)
          })}
          <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
            <Button variant="contained" onClick={(e) => confirmAction("finish")} color="primary" style={{ padding: "10px 35px" }}>
              승인
            </Button>
            <Button variant="contained" color="secondary" onClick={(e) => confirmAction("decline")} style={{ margin: "auto 30px", padding: "10px 35px" }}>
              거절
            </Button>
            <Button onClick={onCloseModal} variant="contained" style={{ padding: "10px 35px" }}>
              취소
            </Button>
          </div>
        </div>
      </Modal>
      <hr />
      {!advertiserInfo.state ?
        <div className="row" style={{ marginBottom: "30px" }}>
          <div className="col-lg-12">
            <center>
              <Button onClick={confirmAdvertiser} variant="contained" color="primary" style={{ margin: "auto 30px", padding: "10px 35px" }}>
                확인
              </Button>
              <Button variant="contained" onClick={() => history.push({ pathname: "/advertiserList" })} style={{ margin: "auto 30px", padding: "10px 35px" }}>
                취소
              </Button>
            </center>
          </div>
        </div> :
        <>
          <div className="form-group">
            <h5 for="stateTypeInfo">승인 여부</h5>
            <br />
            {advertiserInfo.stateTypeInfo}
          </div>
          {advertiserInfo.stateTypeInfo === "거절" ?
            <>
              <hr />
              <div className="form-group">
                <h5 for="stateTypeInfo">거절 사유</h5>
                <br />
                <div>{advertiserInfo.reason}</div>
              </div>
            </>
            :
            <></>
          }

        </>
      }
    </>
  )
}

export default AdvertiserInfo;