import moment from 'moment';


export default function _arrayToInventoryData(rawData = []) {
  return (
    rawData.map((row, idx) => ({
      inventoryId: row._id,
      bannerInitCnt: row.banners.filter(b => b.status === "init").length,
      bannerWIPCnt: row.banners.filter(b => b.status === "inProgress").length,
      bannerPauseCnt: row.banners.filter(b => b.status === "pause").length,
      bannerEXPCnt: row.banners.filter(b => b.status === "expired").length,
      index: idx + 1,
      userName: row.userInfo.name || `-`,
      userEmail: row.userInfo.email || `-`,
      userId: row.userId || `-`,
      createAt: moment(row.createAt * 1000).format('YYYY-MM-DD | HH:mm:ss'),
      updateAt: row.updateAt ? moment(row.updateAt * 1000).format('YYYY-MM-DD | HH:mm:ss') : `-`,
    }))
  )
}
