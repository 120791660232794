import React from "react";

import { Route } from "react-router-dom";

import MainChannelListAdd from "../components/channel/MainChannelListAdd";

export default function MainChannelList(props) {
  return (
    <>
      <Route exact path={props.match.path} component={MainChannelListAdd} />
    </>
  )
}