import React from "react";

import { Link } from "react-router-dom";

function Menu() {

  return (
    <>
      <div id="main-menu" className="main-menu collapse navbar-collapse">
        <ul className="nav navbar-nav">
          <li className="active">
            <Link to={`/main`}>
              <i className="menu-icon fa fa-dashboard"></i>메인
            </Link>
          </li>

          {/* 회원 */}
          <li>
            <Link to={`/member`}> <i className="menu-icon fa fa-user-o"></i>회원 관리</Link>
          </li>

          {/* 엔터프라이즈 */}
          <li>
            <Link to={`/enterprise/list`}><i className="menu-icon fa fa-user-o"></i>엔터프라이즈 관리</Link>
          </li>

          {/* 광고 단가 변경 */}
          <li className="menu-item-has-children dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="menu-icon fa fa-krw"></i>광고단가 변경
            </a>
            <ul className="sub-menu children dropdown-menu">
              <li><i className="menu-icon fa fa-barcode"></i><Link to={`/requestAdPrice`}>변경리스트</Link></li>
              <li><i className="menu-icon fa fa-barcode"></i><Link to={`/requestAdPriceEdit`}>변경하기</Link></li>
            </ul>
          </li>

          {/* 멤버쉽 관리 */}
          <li className="menu-item-has-children dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="menu-icon fa fa-file-text-o"></i>멤버쉽 관리
            </a>
            <ul className="sub-menu children dropdown-menu">
              <li><i className="menu-icon fa fa-list"></i><Link to={`/membership/grade`}>등급 관리</Link></li>
              <li><i className="menu-icon fa fa-list"></i><Link to={`/membership/product`}>요금 관리</Link></li>
            </ul>
          </li>

          {/* 채널 */}
          <li>
            <Link to={`/channel/influencerChannelsAdd`}> <i className="menu-icon fa fa-youtube"></i>채널 추가</Link>
            {/* <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i className="menu-icon fa fa-tasks"></i>인플루언서</a>
                    <ul className="sub-menu children dropdown-menu">
                        <li><i className="menu-icon fa fa-user-o"></i><Link to={`/channel/influencerChannelsAdd`}>인플루언서 신규 채널 추가</Link></li>
                    </ul> */}
          </li>

          {/* 채널 연락처 추가 */}
          <li>
            <Link to={`/addmail`}> <i className="menu-icon fa fa-youtube"></i>채널 연락처 추가</Link>
          </li>

          {/* 대문 관리 */}
          {/* <li>
						<Link to={`/mainChannelList`}> <i className="menu-icon fa fa-picture-o"></i>대문 관리</Link>
					</li> */}

          {/* 대문 관리 */}
          <li className="menu-item-has-children dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="menu-icon fa fa-picture-o"></i>대문 관리
            </a>
            <ul className="sub-menu children dropdown-menu">
              <li><i className="menu-icon fa fa-won"></i>
                <Link to={{
                  pathname: `/mainChannelList/kr`,
                  state: { nation: "KR" }
                }}>한국</Link></li>
              <li><i className="menu-icon fa fa-globe"></i>
                <Link to={{
                  pathname: `/mainChannelList/us`,
                  state: { nation: "US" }
                }}>글로벌</Link></li>
            </ul>
          </li>

          {/* stats */}
          <li>
            <Link to={`/stats`}> <i className="menu-icon fa fa-bar-chart"></i>채널 통계</Link>
          </li>

          {/* Hot Trend */}
          {/* <li>
                    <Link to={`/hottrend`}> <i className="menu-icon fa-sun-o"></i>Hot Trend 관리</Link>
                </li> */}

          {/* Campaign */}
          <li>
            <Link to={`/campaigns`}> <i className="menu-icon fa fa-list-alt"></i>Campaign 관리</Link>
          </li>

          {/* 공지사항 */}
          {/* <li>
                    <Link to={`/notication`}> <i className="menu-icon fa fa-bell-o"></i>공지사항 관리</Link>
                </li> */}

          {/* 인사이트 관리 */}
          <li className="menu-item-has-children dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="menu-icon fa fa-file-text-o"></i>인사이트 관리
            </a>
            <ul className="sub-menu children dropdown-menu">
              <li><i className="menu-icon fa fa-list"></i><Link to={`/insight/writer`}>필자 관리</Link></li>
              <li><i className="menu-icon fa fa-list"></i><Link to={`/insight/category`}>카테고리 관리</Link></li>
              <li><i className="menu-icon fa fa-list"></i><Link to={`/insight/post`}>포스팅 관리</Link></li>
            </ul>
          </li>

          {/* 메일 발송 */}
          <li>
            <Link to={`/sendMail`}> <i className="menu-icon fa fa-bell-o"></i>메일 발송</Link>
          </li>

          {/* 채팅 */}
          {/* <li className="menu-item-has-children dropdown">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                        <i className="menu-icon fa fa-weixin"></i>채팅 관리
                    </a>
                    <ul className="sub-menu children dropdown-menu">
                        <li><i className="menu-icon fa fa-weixin"></i><Link to={`/chatting`}>신고 리스트</Link></li>
                        <li><i className="menu-icon fa fa-weixin"></i><Link to={`/chatting`}>메세지 보내기</Link></li>
                    </ul>
                </li> */}

          {/* Meta tag 관리 */}
          <li>
            <Link to={`/metaTagList`}> <i class="menu-icon fa fa-list"></i>메타태그 관리</Link>
          </li>

          <li>
            <Link to={`/couponList`}> <i class="menu-icon fa fa-list"></i>쿠폰 관리</Link>
          </li>
          <li>
            <Link to={`/advertiserList`}> <i class="menu-icon fa fa-list"></i>광고주 관리</Link>
          </li>

          {/* 배너 관리 */}
          <li className="menu-item-has-children dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="menu-icon fa fa-file-text-o" />배너 관리
            </a>
            <ul className="sub-menu children dropdown-menu">
              <li><i className="menu-icon fa fa-list" /><Link to={`/banner/inventory`}>인벤토리 관리</Link></li>
            </ul>
          </li>

          {/* 게이트웨이 접근 */}
          <li className="menu-item-has-children dropdown">
            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="menu-icon fa fa-list" />게이트웨이 접근 관리
            </a>
            <ul className="sub-menu children dropdown-menu">
              <li><i className="menu-icon fa fa-user-o"></i><Link to={`/gateway/whitelist`}>화이트리스트</Link></li>
              {/* <li><i className="menu-icon fa fa-user-o"></i><Link to={`/gateway/sandbox`}>sandbox studio</Link></li> */}
            </ul>
          </li>

          {/* 즐겨찾기 관리 */}
          <li>
            <Link to={`/manageFavorite`}> <i className="menu-icon fa fa-youtube"></i>즐겨찾기 관리</Link>
          </li>

          {/* 트래킹 관리 */}
          <li>
            <Link to={`/manageTracking`}> <i className="menu-icon fa fa-youtube"></i>앰플리튜드 트래킹 관리</Link>
          </li>
        </ul>
      </div>
    </>
  );
}


export default Menu;
