import React, { useState, useEffect } from 'react';

import styles from './gateway.module.scss';
import { deleteGatewayWhiteList, getGatewayWhiteList } from './gql'
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import AddWhiteListModal from './addWhiteListModal';
import EditWhiteListModal from './editWhiteListModal';

export default function WhiteList() {
	//useQuery 관련 코드
	const { loading, error, data, refetch} = useQuery(getGatewayWhiteList);
	const [deleteGatewayIpFunc] = useMutation(deleteGatewayWhiteList);

	//ip 추가, 수정 창 관련 코드
	const [ipValue, setIpValue] = useState('');
	const [nameValue, setNameValue] = useState('');
	const [isAddModalOpen, setAddModalOpen] = useState(false);
	const [isEditModalOpen, setEditModalOpen] = useState(false);
  const handleAddModalOpen = () => { setAddModalOpen(true); };
  const handleAddModalClose = () => { setAddModalOpen(false); refetch(); };
	const handleEditModalOpen = (ip,name) => { 
		setIpValue(ip);
		setNameValue(name);
		setEditModalOpen(true); 
	};
  const handleEditModalClose = () => { setEditModalOpen(false); refetch(); };

	//delete 처리 핸들러
	const deleteIpHandleOnClick = (ip, name) => async () => {
		const deleteFlag = window.confirm(`사용자의 IP를 삭제하시겠습니까? \n ${ip}, ${name}`);

		if (deleteFlag) {
			try {
				const result = await deleteGatewayIpFunc({
					fetchPolicy: 'network-only',
					variables: {
						ip: ip,
						name: name,
					}
				});
				if(!result.data.deleteGatewayWhiteList) {
					throw new Error('API request failed');
				}
				refetch();
			} catch(err) {
				alert('API 요청에 실패했습니다.');
				console.log(err);
			}
		}
	};

	if (loading) return <p>로딩중</p>
  if (error) return <p>오류가 발생했습니다. 다시 시도해주세요.</p>
	
	const { ip, name, addedTime } = data.getGatewayWhiteList;

	return (
		<>
			<div>
				<div class="row container d-flex justify-content-center">
						<div class="col-lg-12 grid-margin stretch-card">
							<div class="card">
								<div class="card-body">
									<h4 class="card-title">게이트웨이 화이트리스트</h4>
									<small>ip 추가 시, sandbox gateway(gw.sandbox.vling.net)에 한해서 api 응답을 정상적으로 받으실 수 있습니다.</small><br/><br/>
									<button class ="" onClick={handleAddModalOpen}>추가</button>
									<div class="table-responsive">
										<table class="table table-striped">
											<thead>
												<tr>
													<th>IP 주소</th>
													<th>사용자 이름</th>
													<th>추가 시각</th>
													<th>종료 시각</th>
													<th>설정</th>
												</tr>
											</thead>
											<tbody>
											{ip.map((ipValue, index) => (
												<tr key={index}>
													<td>{ipValue}</td>
													<td>{name[index]}</td>
													<td>{moment(parseInt(addedTime[index])).format('YYYY-MM-DD HH:mm:ss')}</td>
													<td>{moment(parseInt(addedTime[index])).startOf('hour').add(1, 'hour').add(1,'day').format('YYYY-MM-DD HH:mm:ss')}</td>
													<td>
														<button onClick={() => handleEditModalOpen(ipValue, name[index])}>수정</button>
														<button onClick={deleteIpHandleOnClick(ipValue, name[index])}>삭제</button>
													</td>
												</tr>
											))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
				</div>
			</div>
			{isEditModalOpen && <EditWhiteListModal onClose={handleEditModalClose} oldIp={ipValue} oldName={nameValue} />}
			{isAddModalOpen && <AddWhiteListModal onClose={handleAddModalClose} />}
		</>
	)
};