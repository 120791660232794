import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

const columnDefs = [
  { headerName: "날짜", field: "date", sizeColumnsToFit: true },
  { headerName: "등급", field: "grade", sizeColumnsToFit: true },
  { headerName: "분류", field: "type", sizeColumnsToFit: true },
  { headerName: "대상 채널 아이디", field: "channelId", sizeColumnsToFit: true },
  {
    headerName: "대상 채널 링크", field: "link", sizeColumnsToFit: true,
    cellRenderer: function (params) { return "<a href='https://vling.net/channel/" + params.data.link + "' target=_blank>Vling</a>  <a href='https://www.youtube.com/channel/" + params.data.link + "' target=_blank>Youtube</a>" }
  },
]

const defaultColDef = {
  editable: false,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
  sortable: true,
  resizable: true,
  filter: true
}

const getQuotasInfoGql = gql`
  query getQuotasInfoByUserId(
    $userId: String!
  ) {
    getQuotasInfoByUserId (
      userId: $userId
    )
  }
`

export default function QuotaRecord({ uniqueId, userGrade, membership }) {
  const [rowData, setRowData] = useState([])

  const { data } = useQuery(getQuotasInfoGql, { variables: { userId: uniqueId } })

  useEffect(() => {
    onGridReady();
  }, [data]);

  const getUserGrade = (params) => {
    let timestamp = new Date(params).getTime() + 32400000;
    let result = '';
    for (let i = 0; i < membership.length; i++) {
      if (timestamp >= membership[i].start_at && timestamp < membership[i].expire_at) {
        result = membership[i].grade
        break;
      }
    }
    if (result === '') { return '정회원' }
    else { return result; }
  }

  const onGridReady = () => {
    let gridData = [];
    if (data && Array.isArray(data.getQuotasInfoByUserId)) {
      data.getQuotasInfoByUserId.map(el => {
        gridData.push({
          date: moment(el.start_at).format("YYYY-MM-DD HH:mm"),
          grade: getUserGrade(el.start_at),
          type: el.type === 'ad-info' ? el.type === 'channel-ad-info' ? '광고 제안' : '광고 단가' : '시청자 분석',
          channelId: el.channelId,
          link: el.channelId
        })
        return el;
      })
      setRowData(gridData);
    }
  }

  return (
    <div className="member-detail-history-wrap">
      <div className="member-detail-history-title">서비스 이용 이력</div>
      <div className="ag-theme-balham" style={{ height: "175px", width: "100%" }}>
        <AgGridReact
          modules={AllCommunityModules}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          onGridReady={onGridReady}
          rowData={rowData}
          enableCellTextSelection={true}
          floatingFilter={true} />
      </div>
    </div>
  )
}