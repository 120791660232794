import React from "react";

import { Route } from "react-router-dom";
import CampaignList from "../components/campaign/CampaignList";

export default function HotTrend(props) {
  return (
    <>
      <Route exact path={props.match.path} component={CampaignList} />
    </>
  )
}