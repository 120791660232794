export function deleteOpenapiCookie() {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i].trim();
    if (cookie.includes("CognitoIdentity")) {
      switch (process.env.REACT_APP_BACKEND_HOST) {
        case "http://localhost:8848":
          document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=localhost; path=/`;
          break;
        case "https://admin.dev.vling.net:8848":
          document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.vling.net; path=/`;
          break;
        case "https://admin.vling.net:8848":
          document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=.vling.net; path=/`;
          break;
        default:
          document.cookie = `${cookie}; expires=Thu, 01 Jan 1970 00:00:01 GMT;domain=localhost; path=/`;
          break;
      }
    }
  }
}
