import React from 'react';

import { AgGridReact } from 'ag-grid-react';
import Button from '@material-ui/core/Button';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import ApiService from '../../services/ApiService';
import numeral from 'numeral';

class CampaignList extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.gridApi = null;
    this.columnDefs = [
      {
        headerName: '캠페인',
        children: [          
          { headerName: "No.", field: "index", sortable: true, width: 60, resizable: true, pinned: 'left'  },
          { headerName: "만든사람", field: "makerId", filter: true, width: 160, tooltip: (params) => params.value, enableTooltip: true, resizable: true, pinned: 'left' },
          { headerName: "캠페인명", field: "campaignName", filter: true, width: 160, tooltip: (params) => params.value, enableTooltip: true, resizable: true, pinned: 'left' },
          { headerName: "컨설팅", field: "isConsulting", sortable: true, width: 60, resizable: true, pinned: 'left' },
        ],
        resizable: true,
      },    
      {
        headerName: '진행정보',
        children: [
          { headerName: "생성일", field: "createdAt", sortable: true, width: 100, resizable: true },
          { headerName: "발송일", field: "sendAt", sortable: true, width: 100, resizable: true },
          { headerName: "단계", field: "step", sortable: true, width: 60, resizable: true,
            cellStyle: params => {
              if (params.value === '발송') return { background: '#ccff99' };
              else return null;
            }
          },
        ]
      },
      {
        headerName: '광고정보',
        children: [
          { headerName: "회사(브랜드)명", field: "productName", filter: true, width: 120, tooltip: (params) => params.value, enableTooltip: true, resizable: true },
          { headerName: "광고 이름", field: "title", filter: true, width: 120, columnGroupShow: 'open', tooltip: (params) => params.value, enableTooltip: true, resizable: true },
          { headerName: "광고 설명", field: "desc", filter: true, width: 160, columnGroupShow: 'open', tooltip: (params) => params.value, enableTooltip: true, resizable: true },
          { headerName: "최소예산", field: 'minBudget', columnGroupShow: 'open', width: 90, resizable: true },
          { headerName: "최대예산", field: 'maxBudget', columnGroupShow: 'open', width: 90, resizable: true },
        ]
      },
      {
        headerName: '대상(명)',
        children: [
          { headerName: "발송", field: "involvedCount", sortable: true, width: 80, resizable: true },
          { headerName: "채팅중", field: "activeCount", sortable: true, width: 80, resizable: true },
          { headerName: "참여 인플루언서", field: "involved", filter: true, width: 550, columnGroupShow: 'open', tooltip: (params) => params.value, enableTooltip: true, resizable: true },
        ]
      },
    ];
    this.state = {
      startDate: moment().format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
      isOpenStartDatepicker: false,
      isOpenEndDatepicker: false,
      rowData: [],
      memberData: []
    }
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;
    // console.log(this.gridApi);

    // api 호출
    let query = {
      startDate: new Date(new Date().valueOf() - (1000 * 60 * 60 * 24 * 14)).toISOString()
      // endDate: moment(),
    };
    let resData = await this.api.getCampaigns(query);
    resData = resData ? (resData.getCampaigns ? resData.getCampaigns : null) : null;
    // console.log(resData);

    if (resData !== null) {
      // resData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      let resultData = [];
      resData.map((item, _index) => {
        if(!item.involved) item.involved = [];
        let createdAt = moment(item.createdAt).locale('en').format('YYYY-MM-DD HH:mm');
        let sendAt = item.sendAt? moment(item.sendAt).locale('en').format('YYYY-MM-DD HH:mm') : "-";
        if(sendAt.includes("9999") || sendAt.includes("10000")) sendAt = "-";
        
        let step = "발송";
        if(item.step === 1 || item.step === 2) step = (item.step+1) + "단계";   

        resultData.push({
          index: (_index + 1),
          campaignName: item.campaignName,
          productName: item.productName,
          title: item.title,
          desc: item.description,
          makerId: item.makerId,
          minBudget: numeral(item.minBudget).format('0,0'),
          maxBudget: numeral(item.maxBudget).format('0,0'),
          createdAt: createdAt,
          sendAt: sendAt,
          step: step,
          isConsulting: !item.isConsulting ? "X" : "O",
          involvedCount: item.involved.length,
          activeCount: item.involved.filter(i => i.state !== "sended").length,
          involved: this.getInvolvedIds(item.involved),
        });
      });

      /**
   * 20-07-14 최근 10일에 생성된 캠페인 리스트 보이게 하는 코드 추가 
   * 나머지는 CampaignList와 동일 
  //  */
      // const N_DAYS = 10;
      // let daysAgo = new Date(new Date().valueOf() + (1000*60*60*9) - (1000*60*60*24 * N_DAYS)).valueOf();
      // resultData = resultData.filter(r=> { return new Date(r.createdAt).valueOf() > daysAgo; })

      let makerIds = resultData.map(row => row.makerId);
      makerIds = Array.from(new Set(makerIds)); //중복이 제거 된 아이디 값
      // console.log("makerIds", makerIds);

      let memberNameArrs = await this.getMemberData(makerIds);
      //필터링 
      // memberNameArrs.map( item => { 
      //   resultData.map(m => {
      //     if (item.id === m.makerId) {
      //       m.makerId = item.name + "(" + item.email +")"; 
      //     }
      //   });
      // });
      resultData.map(res => {
        let member = memberNameArrs.find(member => member.id === res.makerId);
        if (member && member.name && member.email) res.makerId = member.name + "(" + member.email + ")";
        else res.makerId = "탈퇴계정(" + res.makerId + ")";

        return res;
      });


      this.setState({ rowData: resultData });
    }
  }


  getMemberData = async (makerIds) => {
    let resDate = await this.api.getMembers({ userIds: makerIds });
    let memberDate = [];
    if (resDate && resDate.getMembers && resDate.getMembers.result && Array.isArray(resDate.getMembers.result)) memberDate = resDate.getMembers.result;
    //console.log("memberDate: ", memberDate);

    let memberNameArrs = [];
    if (memberDate !== null || memberDate.length > 0) {
      memberDate.map(m => {
        memberNameArrs.push({
          id: m._id,
          email: m.email,
          name: m.name
        });
      })
    }
    this.setState({ memberData: memberNameArrs });
    return memberNameArrs;
  }

  getInvolvedIds = (involvedData) => {
    let result = "";
    
    involvedData.map((item, index) => {
      if (item.Channel && item.Channel.title) result += item.Channel.title
      if (item.Channel && item.Channel.description) result += item.Channel.description
      if (index !== involvedData.length - 1) {
        result += ", ";
      }
    });

    return result;
  }

  onChangedStartDate = (event) => {
    this.setState({ startDate: moment(event).format('YYYY-MM-DD') });
  }

  onChangedEndDate = (event) => {
    this.setState({ endDate: moment(event).format('YYYY-MM-DD') });
  }

  onClickDatepickerToggle = (days) => {
    var resultStartDate = moment().add(-days, 'days');

    this.setState({
      startDate: moment(resultStartDate).format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD')
    });
  }

  onChangedPageSize = () => {

  }

  render() {
    return <div className="campaign-list-wrap">
      <div className="campaign-list-row-wrap" style={{ fontWeight: "bold", textAlign: "center" }}>
        <font> 최근 14일 이내에 생성된 캠페인 리스트 </font>
      </div>
      <div className="ag-theme-balham" style={{ width: "100%", height: "500px" }}>
        <AgGridReact
          onGridReady={this.onGridReady}
          columnDefs={this.columnDefs}
          rowData={this.state.rowData}
          enableCellTextSelection={true}
        />
      </div>
    </div>

  }
}

export default CampaignList;