import React from "react";
import { Redirect } from "react-router-dom";
import { Calendar } from "react-datepicker2";
import { Link } from "react-router-dom";
import Chips from "react-chips";

import ApiService from '../../services/ApiService';
import UtilService from '../../services/UtilService';

// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from '@material-ui/pickers';

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

// import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import * as moment from 'moment';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import { IoTThingsGraph } from "aws-sdk";
// import Moment from "react-moment";

class HotTrendDetail extends React.Component {

  constructor(props) {

    super(props);
    this.api = new ApiService();
    this.util = new UtilService();
    this.state = {
      modules: AllCommunityModules,
      columnDefs: [
        { headerName: "등록일", field: "regDate", width: 80, editable: true },
        { headerName: "미리보기", field: "preview", width: 50, editable: true },
        { headerName: "테마명", field: "themaName", width: 150, editable: true },
        { headerName: "키워드", field: "keywords", width: 400, editable: true },
      ],
      defaultColDef: {
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: true,
        filter: true
      },
      rowSelection: "multiple",
      paginationPageSize: 10,
      paginationNumberFormatter: function (params) {
        return params.value.toLocaleString();
      },
      keywordData: [],
      rowData: [],
      regDate: this.props.location.state.selectedData ? this.props.location.state.selectedData.regDate : moment().add(1, 'days').format("YYYY-MM-DD"),
      ThemaKrData: this.props.location.state.selectedData ? this.props.location.state.selectedData.themaName : "",
      keywordKrData: this.props.location.state.selectedData ? this.props.location.state.selectedData.keyword : [],
      ThemaGlobalData: "", //this.props.location.state.selectedData ? this.props.location.state.selectedData.,
      keywordGlobalData: [],
      HighlightDate: this.props.location.state.HighlightDate ? this.props.location.state.HighlightDate : [],
      previewData: [],
      redirect: null,
      IsCreate: this.props.location.state.selectedData ? false : true,
      defaultDate: moment().add(1, 'days'),
      IsActivePreviewKr: this.props.location.state.selectedData ? false : true,
      IsReadOnly: this.props.location.state.selectedData ? true : false
    }
  }

  // ============================================================= Life cycle 
  componentDidMount() {
    //데이터 주입
    this.initialize();
  }

  // ============================================================= Component function
  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
    document.getElementById("themaKrInput").value = this.state.ThemaKrData;
    // document.getElementById("themaGlobalInput").value = this.state.ThemaGlobalData;

    let params = {
      date: moment(this.state.defaultDate).format("YYYY-MM-DD")
    }
    
    let resData = await this.api.getHottrendKeyword(params);
    resData = resData.trendData.length > 0 ? resData.trendData[0].trends : []

    this.setState({
      keywordData: resData,
    });
  }

  handleDelete = () => {

  }

  onDateChange = async (value) => {
    let params = {
      date: moment(value).format("YYYY-MM-DD")
    }

    let resData = await this.api.getHottrendKeyword(params);
    resData = resData.trendData.length > 0 ? resData.trendData[0].trends : []

    let tempregDate = this.state.IsCreate ? params.date : this.state.regDate;

    this.setState({
      regDate: tempregDate,
      keywordData: resData
    })
  }

  keywordRender() {
    if (this.state.keywordData.length > 0) {
      return this.state.keywordData.map((data, index) => {
        return (
          <>
            <div className="hottrend-keyword-item">
              {data.id}
            </div>
          </>
        );
      });
    }
    return <div className="no-data-hottrend-keyword">
      해당 날짜에 조회된 데이터가 없습니다
    </div>
  }

  onChange = data => {
    let disabled = true;
    if (data.length !== 0) {
      disabled = false;
    }

    this.setState({
      IsActivePreviewKr: disabled,
      keywordKrData: data
    });
  }

  getActionButton() {
    if (this.state.IsCreate)
    {
      return (
        <>
          <Button variant="contained" color="primary" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickCreate}>등록</Button>
          <Button variant="contained" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickCancel}>취소</Button>
        </>
      );
    }
    return (
      <>
        <Button variant="contained" color="secondary" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickDelete}>삭제</Button>
        <Button variant="contained" color="primary" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickModify}>수정</Button>
        <Button variant="contained" style={{margin: "auto 30px", padding: "10px 35px"}} onClick={this.onClickCancel}>취소</Button>
      </>
    )
  }

  onClickGetPreview = async (id) => {
    if (id === "BtnPreviewKr") {
      let keywordData = this.ArrayToString(this.state.keywordKrData);
      let params = {
        keywords: keywordData
      }

      let resData = await this.api.getHottrendPreview(params);
      console.log(resData);
      this.setState({
        previewData: resData.trendDataPreview
      });
    }
    else if (id === "BtnPreviewGlobal") {
      let keywordData = this.ArrayToString(this.state.keywordGlobalData);
      let params = {
        keywords: keywordData
      }

      let resData = await this.api.getHottrendPreview(params);
      this.setState({
        previewData: resData.trendDataPreview
      });
    }
  }

  onClickDelete = async () => {
    if (!this.state.IsCreate) {
      console.log(this.state.ThemaKrData);
      let params = {
        date: this.state.regDate,
        nation: "KR",
        themeName: this.state.ThemaKrData
      }

      try {
        let resData = await this.api.deleteHottrend(params);
        console.log(resData)
        alert("삭제 하였습니다!");
        this.onClickCancel();
      } catch(ex) {
        alert("삭제에 실패하였습니다!");
        console.log(ex);
      }
    }
  }

  onClickCreate = async () => {
    if (moment.duration(moment().diff(this.state.regDate, 'YYYY-MM-DD')).asDays() > 0) {
      alert("과거 날짜(오늘 포함)에 등록은 불가합니다!")
      return;
    }

    if (document.getElementById("themaKrInput").value) {
      let themaName = document.getElementById("themaKrInput").value;
      if (this.state.keywordKrData.length !== 0) {
        let params = {
          date: this.state.regDate,
          nation: "KR",
          themeName: themaName,
          trends: this.ArrayToString(this.state.keywordKrData)
        }

        try {
          let resData = await this.api.updateHottrend(params);
          console.log(resData)
          alert("등록 되었습니다!");
          this.onClickCancel();
        } catch(ex) {
          alert("등록에 실패하였습니다!");
          console.log(ex);
        }
      }
      else {
        alert("키워드를 입력하세요!");
      }
    }
    else {
      alert("테마명을 입력하세요!");
    }
  }

  onClickModify = async () => {
    if (document.getElementById("themaKrInput").value && this.state.keywordKrData.length !== 0) {
      let params = {
        date: this.state.regDate,
        nation: "KR",
        themeName: this.state.ThemaKrData,
        trends: this.ArrayToString(this.state.keywordKrData)
      }

      try {
        let resData = await this.api.updateHottrend(params);
        console.log(resData)
        alert("수정 되었습니다!");
        this.onClickCancel();
      } catch(ex) {
        alert("수정에 실패하였습니다!");
        console.log(ex);
      }
    }
    // else if (document.getElementById("themaGlobalInput").value && this.state.keywordGlobalData.length !== 0) {
    //   let params = {
    //     date: this.state.regDate,
    //     nation: "",
    //     trends: this.ArrayToJson(this.state.keywordGlobalData)
    //   }

    //   let resData = this.api.updateHottrendOnlyKeywods(params);
    //   console.log(resData)
    // }
  }

  onClickCancel = () => {
    this.setState({
      redirect: "/hottrend"
    })
  }

  ArrayToString(data) {
    let result = "";
    for(let i = 0; i < data.length; i++) {
      let str = data[i].toString();
      if (str.indexOf('+') !== -1) {
        let tempArr = str.split('+');
        let tempStr = "";
        for (let j = 0; j < tempArr.length; j++) {
          tempStr += (tempStr === "" ? "" : ",") + `"${tempArr[j]}"`;
        }
        tempStr = `[${tempStr}]`;
        result += (result === "" ? "" : ",") + tempStr;
      }
      else {
        let tempStr = `["${str}"]`;
        result += (result === "" ? "" : ",") + tempStr;
      }
    }
    result = `[${result}]`;
    return result;
  }

  // ArrayToJson(keywords) {
  //   //let tempStr = `[{"id":"${themaName}","keywords":${this.ArrayToString(keywords)}}]`

  //   //tempStr = JSON.stringify(tempStr);
  //   //return tempStr;
  // }

  mngPreviewButton = () => {
    console.log("mngPreviewButton");
    if (document.getElementById("themaKrInput").value && this.state.keywordKrData.length !== 0) {
      this.setState({
        IsActivePreviewKr: false
      })
    }
    else {
      this.setState({
        IsActivePreviewKr: true
      })
    }
  }

  setThumbnails(urlStr) {
    return urlStr.replace("default", "hqdefault");
  }

  renderPreview() {
    if (this.state.previewData.length === 0) {
      return (
        <center>
          <div className="channelList-not-data-wrap">
            검색된 데이터가 없습니다
          </div>
        </center>
      );
    }
    else {
      return this.state.previewData.map((preview, index) => {
        if (index !== 0 && (index + 1) % 3 === 0) {
          return <div className="row">
            {this.PreviewDataToDiv(this.state.previewData[index - 2])}
            {this.PreviewDataToDiv(this.state.previewData[index - 1])}
            {this.PreviewDataToDiv(this.state.previewData[index])}
          </div>
        }
        else {
          if (!this.state.previewData[index + 1]) {
            if(index !== 0 && (index + 1) % 3 === 2) {
              return <div className="row">
                {this.PreviewDataToDiv(this.state.previewData[index - 1])}
                {this.PreviewDataToDiv(this.state.previewData[index])}
              </div>
            }
            else if (index !== 0 && (index + 1) % 3 === 1) {
              return <div className="row">
                {this.PreviewDataToDiv(this.state.previewData[index])}
              </div>
            }
            else if (index === 1) {
              return <div className="row">
                {this.PreviewDataToDiv(this.state.previewData[index - 1])}
                {this.PreviewDataToDiv(this.state.previewData[index])}
              </div>
            }
            else if (index === 0) {
              return <div className="row">
                {this.PreviewDataToDiv(this.state.previewData[index])}
              </div>
            }else{ return null }
          }else{ return null }
        }
      });
    }
  }

  PreviewDataToDiv(previewData) {
    return (
      <>
      <div className="col-lg-4">
          <div className="video-list-portlet">
            <div fit={true} className="video-list-body">
              <div className="video-list-thumbnails">
                <Link to={`/todayvideo/realtime/${previewData.videoId}`}>
                  <img src={this.setThumbnails(previewData.thumbnails)} className="video-list-thumbnails-img" width="300px" alt="" />
                </Link>
              </div>
              <div className="video-list-desc">
                <div className="">{moment(previewData.publishedAt).format("YYYY-MM-DD")}</div>
                <div className="video-list-desc-title">{previewData.title}</div>
                <div className="video-list-desc-bottom">
                  <div className="video-list-desc-dailycount"> + {previewData.viewCount}</div>
                  <div>조회수 증가</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect} />
    }

    return (
      <>
        <div className="row">
          <div className="col-lg-6">
              <Calendar
                id="hottrend-keyword-calendar"
                className="custom-date-picker"
                inputFormat="YYYY-MM-DD"
                showTodayButton={false}
                onChange={value => this.onDateChange(value)}
                ranges={this.state.HighlightDate}
                value={this.state.defaultDate}/>
          </div>
          <div className="col-lg-6">
              <div className="hottrend-keyword-box-background">
                <div className="hottrend-keyword-box">
                  <div className="hottrend-keyword-items">
                    <center>
                      {this.keywordRender()}
                    </center>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div className="row" style={{ margin: "50px 0px 20px 0px" }}>
          <div className="col-lg-12">
            <table class="table table-bordered">
              <colgroup>
                <col width="5%"></col>
                <col width="5%"></col>
                <col width="30%"></col>
                <col width="30%"></col>
              </colgroup>
              <thead>
                <tr>
                  <th scope="col">등록일</th>
                  <th scope="col">미리보기</th>
                  <th scope="col">테마명</th>
                  <th scope="col">키워드</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {/* <th scope="row" rowSpan="2">{this.state.regDate}</th> */}
                  <th scope="row">{this.state.regDate}</th>
                  <td><Button id="BtnPreviewKr" onClick={() => this.onClickGetPreview("BtnPreviewKr")} disabled={this.state.IsActivePreviewKr} variant="contained" color="primary">미리보기</Button></td>
                  <td><Input id="themaKrInput" type="text" placeholder="Korea 테마명을 입력해주세요" readOnly={this.state.IsReadOnly} onChange={this.mngPreviewButton} style={{ width: "90%", height: "90%" }}></Input></td>
                  <td><Chips
                    value={this.state.keywordKrData}
                    onChange={this.onChange}
                    fromSuggestionsOnly={false}
                    createChipKeys={[13, 9]} />
                  </td>
                </tr>
                {/* <tr>
                  <td><Button id="BtnPreviewGlobal" onClick={()=> this.onClickGetPreview("BtnPreviewGlobal") } variant="contained" color="primary">미리보기</Button></td>
                  <td><Input id="themaGlobalInput" type="text" placeholder="Global 테마명을 입력해주세요" style={{ width: "90%", height: "90%" }}></Input></td>
                  <td><Chips
                    value={this.state.keywordGlobalData}
                    onChange={this.onChange}
                    fromSuggestionsOnly={false}
                    createChipKey={[13, ' ']} />
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <center>
              {this.getActionButton()}
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <center>
              <div className="hottrend-preview-background" style={{padding: "5px"}}>키워드 동영상 미리보기</div>
            </center>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            {this.renderPreview()}
          </div>
        </div>
      </>
    )
  }
}

export default (HotTrendDetail);