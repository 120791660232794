import React, { useCallback, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DatePicker from 'react-datepicker';
import moment from 'moment';

// upload
import ApolloUploadProviderWrapper from '../../../services/ApolloUploadProviderWrapper'
import UploadFile from './UploadFile';

// css
import styles from './BannerDetail.module.scss';

// gql
import { getBanner, deleteBanner, upsertBanner, deleteBannerImg } from './gql';


export default function BannerDetail({ bannerId, modalClose }) {
  const [displayData, setDisplayData] = useState();
  const { error, loading, data, refetch } = useQuery(getBanner, { variables: { _id: bannerId } })
  const [upsertBannerFn] = useMutation(upsertBanner)
  const [deleteBannerFn] = useMutation(deleteBanner);
  const [deleteImgFn] = useMutation(deleteBannerImg);
  //
  const [representativeEmail, setRepresentativeEmail] = useState("")
  const [labelInput, setlabelInput] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [descInput, setDescInput] = useState("");
  const [desc_subInput, setDesc_subInput] = useState("");

  useEffect(() => {
    if (data && data.getBanner) {
      const raw = data.getBanner
      setDisplayData({
        ...raw,
        // _id: raw._id,
        // positon: raw.positon,
        // bannerURL: raw.bannerURL,
        // bannerMobileURL: raw.bannerMobileURL,
        // bannerLogoURL: raw.bannerLogoURL,
        // inventoryId: raw.inventoryId,
        startAt_str: raw.startAt ? moment(raw.startAt * 1000).format('YYYY-MM-DD | HH:mm:ss') : '-',
        expireAt_str: raw.expireAt ? moment(raw.expireAt * 1000).format('YYYY-MM-DD | HH:mm:ss') : '-',
      })
    }
  }, [data])

  // 배너 title
  const _setBannerText = async ({ _id, inventoryId, key = "title", value = "" }) => {
    console.log(displayData)
    if (!value) {
      alert(`${key} 을(를) 입력해 주세요.`)
    }
    const params = { _id, inventoryId }
    params[key] = value;

    console.log(params);
    const result = await upsertBannerFn({
      variables: params
    });
    console.log(result);
    refetch();
  }
  // 배너 상태 변경 
  const _statusBanner = async ({ _id, inventoryId, status_code = "INIT" }) => {
    if (!isExsitURL() && status_code === "WIP") {
      alert("배너 이미지를 모두 업로드 하세요.")
      return
    }
    const result = await upsertBannerFn({
      variables: { _id, inventoryId, status_code }
    })
    console.log(result);
    refetch();
  }
  // 배너 날짜 (시작, 종료) 변경
  const _changeDate = async ({ _id, inventoryId, key = "", value }) => {
    if (key) {
      const param = {
        _id, inventoryId,
        ...(key && { [key]: value })
      }
      const result = await upsertBannerFn({
        variables: param
      })
      console.log(result)
      refetch();
    }
  }
  // 배너 이미지 업로드 검증 URL 존재 여부
  const isExsitURL = useCallback(() => {
    if (displayData) {
      // validation : 이미지 모두 업로드?
      return !!displayData.bannerURL && !!displayData.bannerMobileURL && !!displayData.bannerLogoURL
    }
    return false
  }, [displayData])


  // 배너 1개 삭제
  const _deleteBanner = async ({ _id }) => {
    if (window.confirm("삭제 시 되돌릴 수 없습니다.")) {
      const result = await deleteBannerFn({ variables: { _id } })
      console.log(result)
      modalClose();
    }
  }

  // 배너 이미지 업로드 후, 작동
  const uploadImgCB = () => {
    refetch()
  }

  // 배너 이미지 삭제
  const deleteImg = async ({ _id, fileURL }) => {
    const result = await deleteImgFn({ variables: { _id, fileURL } })
    // console.log(result)
    refetch();
  }
  // ===============
  if (error) throw error;

  return (
    <>
      <div>
        <div className={styles.header}>배너 상세</div>
        <section >
          <div className={styles.row2}>
            <p>담당자 이메일</p>
            <span>현재 값 : [ {displayData?.representativeEmail || "-"} ]</span>
            <div>
              <input maxLength={100} placeholder={"담당자 이메일"} value={representativeEmail} onChange={e => setRepresentativeEmail(e.target.value)} />
              <button
                onClick={() => _setBannerText({
                  _id: displayData?._id,
                  inventoryId: displayData?.inventoryId,
                  key: "representativeEmail",
                  value: representativeEmail
                })}
              >
                {displayData?.representativeEmail ? "수정" : "저장"}
              </button>
            </div>
          </div>
          <div className={styles.row2}>
            <p>라벨 (배너 트래킹을 위한 값으로 1회만 입력 가능합니다.)</p>
            {displayData?.label
              ? <>
                <span>{displayData?.label}</span>
              </>
              : <>
                <input
                  maxLength={20}
                  placeholder={"저장 후 수정 불가"}
                  value={labelInput}
                  onChange={e => setlabelInput(e.target.value)} />
                <button
                  onClick={() => _setBannerText({
                    _id: displayData?._id,
                    inventoryId: displayData?.inventoryId,
                    key: "label",
                    value: labelInput
                  })}
                >
                  저장
                </button>
              </>
            }

          </div>
          <div className={styles.row2}>
            <p>타이틀 [띄어쓰기 포암 13자]</p>
            <span>현재 값 : [ {displayData?.title || "-"} ]</span>
            <div>
              <input maxLength={13} placeholder={"타이틀"} value={titleInput} onChange={e => setTitleInput(e.target.value)} />
              <button
                onClick={() => _setBannerText({
                  _id: displayData?._id,
                  inventoryId: displayData?.inventoryId,
                  key: "title",
                  value: titleInput
                })}
              >
                {displayData?.title ? "수정" : "저장"}
              </button>
            </div>
          </div>
          <div className={styles.row2}>
            <p>설명(부재목 [띄어쓰기 포함 21자])</p>
            <span>현재 값 : [ {displayData?.desc || "-"} ]</span>
            <div>
              <input maxLength={21} placeholder={"설명"} value={descInput} onChange={e => setDescInput(e.target.value)} />
              <button
                onClick={() => _setBannerText({
                  _id: displayData?._id,
                  inventoryId: displayData?.inventoryId,
                  key: "desc",
                  value: descInput
                })}
              >
                {displayData?.title ? "수정" : "저장"}
              </button>
            </div>
          </div>
          <div className={styles.row2}>
            <p>추가 설명(주황색 [띄어쓰기 포함 45자] )</p>
            <span>현재 값 : [ {displayData?.desc_sub || "-"} ]</span>
            <div>
              <input maxLength={45} placeholder={"추가설명"} value={desc_subInput} onChange={e => setDesc_subInput(e.target.value)} />
              <button
                onClick={() => _setBannerText({
                  _id: displayData?._id,
                  inventoryId: displayData?.inventoryId,
                  key: "desc_sub",
                  value: desc_subInput
                })}
              >
                {displayData?.title ? "수정" : "저장"}
              </button>
            </div>
          </div>
          <div className={styles.row}>
            <p>시작일 (한국시간 - 24시 표기)</p>
            <span>
              {displayData &&
                <DatePicker
                  selected={new Date((displayData?.startAt || parseInt(Date.now() / 1000)) * 1000)}
                  onChange={(date) => _changeDate({ ...displayData, key: "startAt", value: parseInt(new Date(date).getTime() / 1000) })}
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  showTimeInput
                />
              }
            </span>
          </div>
          <div className={styles.row}>
            <p>종료일 (한국시간 - 24시 표기)</p>
            <span>
              {displayData &&
                <DatePicker
                  selected={new Date(displayData?.expireAt * 1000)}
                  onChange={(date) => _changeDate({ ...displayData, key: "expireAt", value: parseInt(new Date(date).getTime() / 1000) })}
                  timeInputLabel="Time:"
                  dateFormat="yyyy-MM-dd HH:mm:ss"
                  showTimeInput
                />
              }
            </span>
          </div>
          <div className={styles.row2}>
            <p>배너-LOGO</p>
            <div className={styles.bannerImg}>
              {displayData?.bannerLogoURL
                ? <>
                  <img className={styles.logo} src={displayData?.bannerLogoURL} alt='' />
                  <span>
                    변경하려면, <button onClick={() => { deleteImg({ _id: displayData._id, fileURL: displayData.bannerLogoURL }) }}>삭제</button> 후 업로드 하세요.
                  </span>
                </>
                : <>
                  <label htmlFor={"upload_logo"}>
                    <img src={`${window.origin}/images/banner/logo_sample.png`} alt='sample' />
                  </label>
                  <span>
                    그림(max 100X100)을 클릭하여 파일을 업로드 하세요
                  </span>
                </>
              }
              <ApolloUploadProviderWrapper>
                <UploadFile
                  inputId={"upload_logo"}
                  bannerId={displayData?._id}
                  fileType={"LOGO"}
                  uploadImgCB={uploadImgCB}
                >
                </UploadFile>
              </ApolloUploadProviderWrapper>
            </div>
          </div>
          <div className={styles.row2}>
            <p>배너-PC</p>
            <div className={styles.bannerImg}>
              {displayData?.bannerURL
                ? <>
                  <img src={displayData?.bannerURL} alt='' />
                  <span>
                    변경하려면, <button onClick={() => { deleteImg({ _id: displayData._id, fileURL: displayData.bannerURL }) }}>삭제</button> 후 업로드 하세요.
                  </span>
                </>
                : <>
                  <label htmlFor={"upload_pc"}>
                    <img src={`${window.origin}/images/banner/pc_sample.png`} alt='sample' />
                  </label>
                  <span>그림((1050~1920)X250)을 클릭하여 파일을 업로드 하세요</span>
                </>
              }
              <ApolloUploadProviderWrapper>
                <UploadFile
                  inputId={"upload_pc"}
                  bannerId={displayData?._id}
                  fileType={"PC"}
                  uploadImgCB={uploadImgCB}
                >
                </UploadFile>
              </ApolloUploadProviderWrapper>
            </div>
          </div>
          <div className={styles.row2}>
            <p>배너-Mobile</p>
            <div className={styles.bannerImg}>
              {displayData?.bannerMobileURL
                ? <>
                  <img src={displayData?.bannerMobileURL} alt='' />
                  <span>
                    변경하려면, <button onClick={() => { deleteImg({ _id: displayData._id, fileURL: displayData.bannerMobileURL }) }}>삭제</button> 후 업로드 하세요.
                  </span>
                </>
                : <>
                  <label htmlFor={"upload_mobile"}>
                    <img src={`${window.origin}/images/banner/mobile_sample.png`} alt='sample' />
                  </label>
                  <span>
                    그림(756X283)을 클릭하여 파일을 업로드 하세요
                  </span>
                </>
              }
              <ApolloUploadProviderWrapper>
                <UploadFile
                  inputId={"upload_mobile"}
                  bannerId={displayData?._id}
                  fileType={"MOBILE"}
                  uploadImgCB={uploadImgCB}
                >
                </UploadFile>
              </ApolloUploadProviderWrapper>
            </div>
          </div>
        </section>
      </div>
      <section className={styles.bottom_absolute}>
        <button onClick={() => { _deleteBanner({ _id: displayData?._id }) }}>배너 삭제</button>
        <button
          onClick={() => {
            _statusBanner({
              _id: displayData?._id,
              inventoryId: displayData?.inventoryId,
              status_code: "PAUSE"
            })
          }}
        >
          중지
        </button>
        <button
          onClick={() => {
            _statusBanner({
              _id: displayData?._id,
              inventoryId: displayData?.inventoryId,
              status_code: "WIP"
            })
          }}
        >
          활성화
        </button>
        <span>현재 상태 : {displayData?.status}</span>
        <button onClick={() => { modalClose() }}>
          닫기
        </button>
      </section>
    </>
  )
}
