import React from "react";

import { Route } from "react-router-dom";
import insightWriter from "../../components/insight/insightWriter";

export default function writer(props) {
  return (
    <>
      <Route exact path={props.match.path} component={insightWriter} />
    </>
  )
}