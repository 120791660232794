import gql from 'graphql-tag';

export const addGatewayIp = gql`
  mutation IngressCreateIp($ip: String!, $name: String!) {
    ingressCreateIp(ip: $ip, name: $name)
  }
`;

export const deleteGatewayIp = gql`
  mutation ingressDeleteIp($ip: String!, $name: String!, $isCidr: Boolean) {
  ingressDeleteIp(ip: $ip, name: $name, isCidr: $isCidr)
  }
`;

export const updateGatewayIp = gql`
  mutation IngressUpdateIp($oldip: String!, $oldname: String!, $newip: String!, $newname: String!) {
  ingressUpdateIp(oldip: $oldip, oldname: $oldname, newip: $newip, newname: $newname)
}
`;

export const getGatewayIp = gql`
  query IngressReadIp {
    ingressReadIp {
      ip
      name
      addedTime
    }
  }
`;

export const getUserIp = gql`
  query getUserIp {
    getUserIp {
      ip
    }
  }
`;

export const getGatewayWhiteList = gql`
  query getGatewayWhiteList {
    getGatewayWhiteList {
        ip
        name
        addedTime
      }
  }
`;

export const createGatewayWhiteList = gql`
  mutation createGatewayWhiteList($ip: String!, $name: String!) {
    createGatewayWhiteList(ip: $ip, name: $name)
  }
`;

export const deleteGatewayWhiteList = gql`
  mutation deleteGatewayWhiteList($ip: String!, $name: String!) {
    deleteGatewayWhiteList(ip: $ip, name: $name)
  }
`;

export const updateGatewayWhiteList = gql`
  mutation updateGatewayWhiteList($oldip: String!, $oldname: String!, $newip: String!, $newname: String!) {
    updateGatewayWhiteList(oldip: $oldip, oldname: $oldname, newip: $newip, newname: $newname)
  }
`;