import React from 'react';
import { Redirect } from "react-router-dom";

import Auth from '@aws-amplify/auth';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import * as moment from 'moment';
import update from 'react-addons-update'
import UtilService from '../../services/UtilService';
import ApiService from '../../services/ApiService';


import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { deleteOpenapiCookie } from '../../utils/cookie';


class RequestAdPriceDetail extends React.Component {
  
  constructor(props) {
    console.log(props);
    super(props);
    this.util = new UtilService();
    this.api = new ApiService();
    this.onChangeDatepicker = this.onChangeDatepicker.bind(this);
    this.authData = null;
    this.memberId = this.props.match.params.id;
    this.state = {        
        edit: false,
        redirect: null,
        memberHistroyOptions :{
            modules: AllCommunityModules,
            columnDefs: [
                { headerName: "날짜", field: "date"  , sizeColumnsToFit: true},
                { headerName: "등급", field: "grade" , sizeColumnsToFit: true},
                { headerName: "비고", field: "desc" , sizeColumnsToFit: true},
            ],        
            defaultColDef: {
                // width: 300,
                // sizeColumnsToFit: true,
                editable: false,
                enableRowGroup: true,
                enablePivot: true,
                enableValue: true,
                sortable: true,
                resizable: true,
                filter: true
            },
            rowSelection: "multiple",
            paginationPageSize: 10,
            paginationNumberFormatter: function(params) {
            return params.value.toLocaleString();
            },
            rowData: [
                // { date: "2020-01-05", grade: "정회원", desc: "회원 가입"},
                // { date: "2020-01-25", grade: "스타트업 (체험)", desc: "스타트업 (체험) 7일 가입"},
                // { date: "2020-02-01", grade: "정회원", desc: "스타트업 (체험) 7일 해제"},
                // { date: "2020-02-19", grade: "엔터 프라이즈", desc: "엔터프라이즈 180일 가입 2020-02-19 ~ 2020-08-19 블리자드 엔터테이먼트"},
            ]
        },
        averageHistroyOptions :{
            modules: AllCommunityModules,
            columnDefs: [
                { headerName: "변경일", field: "regDate" },
                { headerName: "채널명", field: "channelTitle"},
                { headerName: "기본 PPL", field: "ppl"},
                { headerName: "브랜디드", field: "branded"},
                { headerName: "구독자", field: "subscriberCount"},
                { headerName: "일일조회수", field: "viewCount"},
                { headerName: "사유", field: "reason"}
            ],        
            defaultColDef: {
            editable: false,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true
            },
            rowSelection: "multiple",
            paginationPageSize: 10,
            paginationNumberFormatter: function(params) {
            return params.value.toLocaleString();
            },
            rowData: []
        },

        channelHistroyOptions :{
            modules: AllCommunityModules,
            columnDefs: [
                { headerName: "인증날짜", field: "reg_at" },
                { headerName: "분류", field: "type"},
                { headerName: "인증URL", field: "url"},
            ],        
            defaultColDef: {
            editable: false,
            enableRowGroup: true,
            enablePivot: true,
            enableValue: true,
            sortable: true,
            resizable: true,
            filter: true
            },
            rowSelection: "multiple",
            paginationPageSize: 10,
            paginationNumberFormatter: function(params) {
            return params.value.toLocaleString();
            },
            rowData: []
        },
        memberHistory : {
            channelId: "",
            channelTitle: "",
            pplBefore : "",
            brandedBefore : "",
        },
        channelList : []
    };
  }
 

  // ============================================================= Life cycle 
  
  componentDidMount() {
    //데이터 주입
    this.initialize();

  }


   
  // ============================================================= Component function
   

  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {    
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
    deleteOpenapiCookie();
    this.authData = await Auth.currentSession();   
    let memberResult = await this.api.getMemberAttribute(this.props.match.params.id);

    if (memberResult.memberAttribute) {
       let channelList = await memberResult.memberAttribute.certification.map( async (item,index)=>{
            let resDate = await this.api.getChannel(item.target_id);
            return resDate.channel;
        });
        this.setState({channelList : await Promise.all(channelList) })
    }


  }
  
  locationList = () => {
    this.setState({redirect : "/member"});
  }

  editFormMember = () => {
    this.setState({edit : true});
  }

  editMember = () => {

  }

  cancelMember = () => {
    this.setState({
      edit : false,
      memberHistory : {
        channelId: "",
        pplBefore : "",
        brandedBefore : "",
      }
    });
  }


  removeMember = () => {
    alert("삭제 안되요");
  }

  onChangeGradeStartDate = () => {

  }
  
  onChangeGradeEndDate = () => {

  }

  onChangeDatepicker(month){
    let gradeStartDate = document.getElementById("gradeStratDate");
    let startDateValue = gradeStartDate.value
    if (!startDateValue) {
      alert("시작일을 입력해주세요.");
    } else {
      let changeDateValue = moment(startDateValue).add(month, 'months');

      // state 값으로 연동
      document.getElementById("gradeEndDate").value = changeDateValue.format("YYYY-MM-DD");
    }

  }

  onChangeAuthChannels = async (event) => {
      let channelId = event.target.value;
      let resData =  await this.api.getMemberHistory("AdUnitPriceAll" , this.memberId,  channelId , null , null);
      let lastUnitHistory = resData.memberHistorys[0];

      let channel = {};
      this.state.channelList.map((item, index)=>{
        if (item.channelId == channelId) {
          channel = item;
        }
      })

      let pplBefore = 0;
      let brandedBefore = 0;

      if (lastUnitHistory) {
        pplBefore = lastUnitHistory.priceUnit.ppl;
        brandedBefore = lastUnitHistory.priceUnit.branded;
      } else {
        pplBefore = channel.minAdvertisingUnitPrice;
        brandedBefore = channel.maxAdvertisingUnitPrice;
      }
      
      this.setState({
        memberHistory: {
            pplBefore : pplBefore,
            brandedBefore : brandedBefore,
            influencerId : this.props.match.params.id,
            channelId : channelId,
            channelTitle : channel.title,
            viewCount : channel.viewCount,
            subscriberCount : channel.subscriberCount,
        }
      })
  }

  adUnitPriceAdd = async () => { 

    let regId = this.authData.idToken.payload.email
    let ppl = document.getElementById("ppl").value;
    let branded = document.getElementById("branded").value;
    let reason = document.getElementById("reason").value;

    let args = {
      historyId : "",
      type : "AdUnitPriceAdmin",
      channelId : this.state.memberHistory.channelId,
      channelTitle : this.state.memberHistory.channelTitle,
      subscriberCount : this.state.memberHistory.subscriberCount,
      viewCount : this.state.memberHistory.viewCount,
      influencerId : this.state.memberHistory.influencerId , 
      ppl : ppl ? ppl : 0,
      branded : branded ? branded : 0,
      reason : reason,
      regId : regId,
      regDate : moment().toISOString(),
    }
    let resData = await this.api.addMemberHistory(args);
    if (resData) {  
      this.cancelMember();
    }
  }

  onAverageGridReady = async(params)=> {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    let resData =  await this.api.getMemberHistory("AdUnitPriceAll" , this.memberId, "",  null , null);
    if (resData.memberHistorys) {
      console.log(resData.memberHistorys);
      let memberHistroys = resData.memberHistorys.map((item) => {
        
        if (item.type == "AdUnitPriceAdmin" || (item.type == "AdUnitPriceUser" && item.priceUnit.status == "complate")) {
          console.log(item.channel);
          if (item.channel) {
            return {
              regDate : item.regDate,
              channelTitle : item.channel.title,
              ppl : item.priceUnit.ppl,
              branded : item.priceUnit.branded,
              subscriberCount : item.channel.subscriberCount,
              viewCount : item.channel.viewCount,
              reason : item.reason
            }
          }
        
        } 
      });

      let result = memberHistroys.filter((item)=>{
        return item;
      })

      this.setState({ 
        averageHistroyOptions: update(
          this.state.averageHistroyOptions, 
          {
            rowData: { $set: result }
          }
        )
      });
    }
  };
  
  onChannelGridReady = async(params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    let memberResult = await this.api.getMemberAttribute(this.props.match.params.id);
    console.log(memberResult);

    if (memberResult.memberAttribute) {
      let certification = memberResult.memberAttribute.certification;
      let result = certification.map((cert, index)=>{
          return {
            reg_at : cert.reg_at,
            type : cert.type,
            url : "http://localhost:3000/channel/"+cert.target_id,          
          }
       
      })

      this.setState({ 
        channelHistroyOptions: update(
          this.state.channelHistroyOptions, 
          {
            rowData: { $set: result }
          }
        )
      });

    }

  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    
    this.gridApi.sizeColumnsToFit();
  };

  _randerContent () {
        if (this.state.edit) {
            return (
                <>
                    <div className="row">
                        <div className="col-lg-9">
                            <div>회원관리 > 회원수정</div>
                        </div>
                        <div className="col-lg-3">
                        </div>
                    </div>
            
                    <div className="section-line"></div>
            
                    <div className="row member-detail-data-wrap">
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">가입일</label>
                            <div>2020-20-23</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">등급</label>
                            <div>정회원</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">회원구분</label>
                            <div>인플루언서</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">이메일 (계정)</label>
                            <div>lucas@buzznbyd.com</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">채널 인증</label>
                            <div>N/A</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">닉네임</label>
                            <div>루카스</div>
                        </div>
                    </div>
            
                    <div className="section-line"></div>

                    <div className="row">
                        <div className="col-lg-2">
                            <div>변경등급</div>
                        </div>
                        <div className="col-lg-10">
                            <InputLabel id="member-grade-select-label">등급</InputLabel>
                            <Select
                                labelId="member-grade-select-label"
                                id="member-grade-select"
                                value=''
                                >
                                <MenuItem value={"UCOmHUn--16B90oW2L6FRR3A"}>정회원</MenuItem>
                                <MenuItem value={"UCOmHUn--16B90oW2L6FRR3A"}>스타트업</MenuItem>
                                <MenuItem value={"UCOmHUn--16B90oW2L6FRR3A"}>스타트업 (체험)</MenuItem>
                                <MenuItem value={"UCOmHUn--16B90oW2L6FRR3A"}>프로페셔널</MenuItem>
                                <MenuItem value={"UCOmHUn--16B90oW2L6FRR3A"}>엔터프라이즈</MenuItem>
                                <MenuItem value={"UCOmHUn--16B90oW2L6FRR3A"}>정지</MenuItem>
                            </Select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <label>기간</label>
                        </div>
                        <div className="col-lg-4">
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="gradeStratDate"
                                    key="gradeStratDate"
                                    label="시작일"
                                    format="yyyy-MM-dd"
                                    autoOk={true}
                                    //   value={selectedDate}
                                    onChange={this.onChangeGradeStartDate}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}/>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-lg-4">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  margin="normal"
                                  id="gradeEndDate"
                                  key="gradeEndDate"
                                  label="종료일"
                                  format="yyyy-MM-dd"
                                  autoOk={true}
                                  //   value={selectedDate}
                                  onChange={this.onChangeGradeEndDate}
                                  KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                  }}/>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-lg-2">
                            <button id="oneMonthBtn" onClick={() => this.onChangeDatepicker(1)}>1개월</button>
                            <button id="threeMonthBtn" onClick={() => this.onChangeDatepicker(3)}>3개월</button>
                            <button id="sixMonthBtn" onClick={() =>  this.onChangeDatepicker(6)}>6개월</button>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2">
                            <div>메모</div>
                        </div>
                        <div className="col-lg-10">
                          <TextField
                              variant="outlined"
                              id="standard-start-adornment"
                              fullWidth={true}
                              multiline
                              rows={10}
                              rowsMax={10}
                              />
                        </div>
                    </div>

                    <div className="row member-detail-action-btn-area">
                        <div className="col-lg-12">
                            <button onClick={this.cancelMember}>변경</button>
                            <button onClick={this.cancelMember}>취소</button>
                        </div>
                    </div>

                    <div className="section-line"></div>
                    
                    <div className="row">
                        <div className="col-lg-2">
                            <label>인증된 채널</label>
                        </div>
                        <div className="col-lg-10">
                            <InputLabel id="auth-channels-select-label">채널</InputLabel>
                            <Select
                                labelId="auth-channels-select-label"
                                id="auth-channels-select"
                                value={this.state.memberHistory.channelId}
                                onChange={this.onChangeAuthChannels}
                                autoWidth={true}>
                                <MenuItem value="">노인증채널</MenuItem>
                                {
                                    this.state.channelList.map((item, index)=> {
                                        return <MenuItem value={item.channelId}>{item.title}</MenuItem>
                                    })
                                }
                            </Select>
                        </div>
                    </div>

                    <div className={this.state.memberHistory.channelId == "" ? "display-none " : "row"}>
                        <div className="col-lg-2">
                            <label>기본 PPL (현재가)</label>
                        </div>
                        <div className="col-lg-4">
                          <Input placeholder="Placeholder" variant="outlined" value={this.state.memberHistory.pplBefore} />
                        </div>
                        <div className="col-lg-2">
                            <label>브랜디드 (현재가)</label>
                        </div>
                        <div className="col-lg-4">
                          <Input placeholder="Placeholder" variant="outlined" value={this.state.memberHistory.brandedBefore} />
                        </div>
                    </div>

                    <div className="row" className={this.state.memberHistory.channelId == "" ? "display-none " : "row"}>
                        <div className="col-lg-2">
                            <label>기본 PPL (변경)</label>
                        </div>
                        <div className="col-lg-4">
                          <Input placeholder="Placeholder" variant="outlined" id="ppl"/>
                        </div>
                        <div className="col-lg-2">
                          <label>브랜디드 (변경)</label>
                        </div>
                        <div className="col-lg-4">
                          <Input placeholder="Placeholder" variant="outlined" id="branded"/>
                        </div>
                    </div>
                    <div className="row" className={this.state.memberHistory.channelId == "" ? "display-none " : "row"}>
                        <div className="col-lg-2">
                            <label>메모</label>
                        </div>
                        <div className="col-lg-10">
                          <TextField
                            variant="outlined"                            
                            id="reason"
                            fullWidth={true}
                            multiline
                            rows={10}
                            rowsMax={10}
                            />
                        
                        </div>
                    </div>

                    <div className="row member-detail-action-btn-area">
                        <div className="col-lg-12">
                            <button onClick={this.adUnitPriceAdd}>변경</button>
                            <button onClick={this.cancelMember}>취소</button>
                        </div>
                    </div>
            
                </>
            )
        } else {
            return (
                <>
                    <div className="row">
                        <div className="col-lg-9">
                            <div>회원관리 > 회원정보</div>
                        </div>
                        <div className="col-lg-3">
                            <div className="content-top-action-btn">
                                <button onClick={this.editFormMember}>수정</button>
                                <button onClick={this.removeMember}>삭제</button>
                                <button onClick={this.locationList}>목록</button>
                            </div>
                        </div>
                    </div>
            
                    <div className="section-line"></div>
            
                    <div className="row member-detail-data-wrap">
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">가입일</label>
                            <div>2020-20-23</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">등급</label>
                            <div>정회원</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">회원구분</label>
                            <div>인플루언서</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">이메일 (계정)</label>
                            <div>lucas@buzznbyd.com</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">채널 인증</label>
                            <div>N/A</div>
                        </div>
                        <div className="col-lg-2">
                            <label className="member-detail-data-title">닉네임</label>
                            <div>루카스</div>
                        </div>
                    </div>
            
                    <div className="section-line"></div>
            
                    <div className="member-detail-history-wrap">
                        <div className="member-detail-history-title">회원 등급 변경 이력</div>
                        <div className="ag-theme-balham" style={{height: "175px", width: "100%" }}>
                            <AgGridReact
                                // skipHeaderOnAutoSize={true}
                                modules={this.state.memberHistroyOptions.modules}
                                columnDefs={this.state.memberHistroyOptions.columnDefs}
                                autoGroupColumnDef={this.state.memberHistroyOptions.autoGroupColumnDef}
                                defaultColDef={this.state.memberHistroyOptions.defaultColDef}
                                // debug={true}
                                rowSelection={this.state.memberHistroyOptions.rowSelection}
                                onGridReady={this.onGridReady}
                                rowData={this.state.memberHistroyOptions.rowData}>                            
                            </AgGridReact>
                        </div>
                    </div>
            
                    <div className="member-detail-history-wrap">
                        <div className="member-detail-history-title">평균 광고 단가 변경 이력</div>
                        <div className="ag-theme-balham" style={{height: "175px", width: "100%" }}>
                            <AgGridReact
                                modules={this.state.averageHistroyOptions.modules}
                                columnDefs={this.state.averageHistroyOptions.columnDefs}
                                autoGroupColumnDef={this.state.averageHistroyOptions.autoGroupColumnDef}
                                defaultColDef={this.state.averageHistroyOptions.defaultColDef}
                                // debug={true}
                                rowSelection={this.state.averageHistroyOptions.rowSelection}
                                onGridReady={this.onAverageGridReady}
                                rowData={this.state.averageHistroyOptions.rowData}>                            
                            </AgGridReact>
                        </div>
                    </div>
            
                    <div className="member-detail-history-wrap">
                        <div className="member-detail-history-title">채널 인증 정보</div>
                        <div className="ag-theme-balham" style={{height: "175px", width: "100%" }}>
                            <AgGridReact
                                modules={this.state.channelHistroyOptions.modules}
                                columnDefs={this.state.channelHistroyOptions.columnDefs}
                                autoGroupColumnDef={this.state.channelHistroyOptions.autoGroupColumnDef}
                                defaultColDef={this.state.channelHistroyOptions.defaultColDef}
                                // debug={true}
                                rowSelection={this.state.channelHistroyOptions.rowSelection}
                                onGridReady={this.onChannelGridReady}
                                rowData={this.state.channelHistroyOptions.rowData}>                            
                            </AgGridReact>
                        </div>
                    </div>
                </>
            )
        }
    }

  // ============================================================= Component Render

  render() {    
    if (this.state.redirect) {
        return <Redirect push={true} to={this.state.redirect} />
    }

    return (
    <>
        {this._randerContent()}
    </>
    );
  }

  

}

export default (RequestAdPriceDetail);