import React from 'react';
// import { Redirect } from "react-router-dom";

import ApiService from '../../services/ApiService';

// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from '@material-ui/pickers';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";
import ChildMessageRenderer from "./agGrid/ChildMessageRenderer.jsx";

import UnApprovalModal from './modal/UnApprovalModal';
import TextField from '@material-ui/core/TextField';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import * as moment from 'moment';
// import update from 'react-addons-update'

class RequestAdPriceList extends React.Component {
  
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
        modules: AllCommunityModules,
        columnDefs: [
            { headerName: "가입일", field: "regDate" },
            { headerName: "등급", field: "grade"},
            { headerName: "상태", field: "status"},
            { headerName:"요청내용" ,field : "requestReson"},
            { headerName:"응답내용" ,field : "reason"},
            { headerName: "이메일(계정)", field: "email"},
            { headerName: "채널인증", field: "title" },
            { headerName: "닉네임", field: "nickname" },
            { headerName: "기본 PPL(변경)", field: "ppl" },
            { headerName: "브랜디드(변경)", field: "branded" },       
            {
              headerName: "승인/미승인",
              field: "value",
              cellRenderer: "childMessageRenderer",
              cellRendererParams: {
                onOpenModal: this.onOpenModal
              },
              colId: "params",
              width: 180              
            }           
        ],        
        defaultColDef: {
          editable: false,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          resizable: true,
          filter: true,
        },
        rowData: [],
        context: { componentParent: this },
        frameworkComponents: {    
          childMessageRenderer: ChildMessageRenderer
        },
        redirect: null,
        gridApi : null,
        gridColumnApi : null,
        open : false
      };
  }

  // ============================================================= Life cycle 
  
  componentDidMount() {
    //데이터 주입
    this.initialize();

  }

  // ============================================================= Component function
   
  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {    
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
 
    
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    let resDate = await this.api.getAdUnitPriceUsers();
    
    if (resDate.memberHistorys) {
      let influencerIds = resDate.memberHistorys.map(m => m.influencerId);
      influencerIds = Array.from(new Set(influencerIds));
      let getMembers = await this.api.getMembers({ userIds: influencerIds });
      let getMemberResults = (getMembers && getMembers.getMembers && getMembers.getMembers.result && Array.isArray(getMembers.getMembers.result))? getMembers.getMembers.result: [];
      // console.log(getMemberResults);

      let gridColumResult = await resDate.memberHistorys.map(async (item,index)=>{
        let email = "";
        let nickname = "";
        let memberInfo = getMemberResults.find(member => member._id === item.influencerId); 
        if (memberInfo && memberInfo.name && memberInfo.email) {
            nickname = memberInfo.name;
            email = memberInfo.email;
        }

        return {
            historyId : item._id,
            channelId : item.channel.channelId,
            subscriberCount : item.channel.subscriberCount,
            viewCount : item.channel.viewCount,
            title : item.channel.title,
            influencerId : item.influencerId,
            requestReson : item.priceUnit.requestReson,
            status : item.priceUnit.status,
            ppl : item.priceUnit.ppl,
            branded : item.priceUnit.branded,
            regId : item.regId,
            regDate : item.regDate,
            email: email,
            nickname : nickname,
            reason : item.reason
        } 
      });

      // let gridColumResult = await resDate.memberHistorys.map(async (item,index)=>{

      //     let getMemberResult = await this.api.getMember(item.influencerId);
      //     let email = "";
      //     let nickname = "";
          
      //     let memberInfo = getMemberResult.getMember.result; 
      //     if (memberInfo) {
      //       memberInfo.map(member => {
      //         nickname = member.name;
      //         email = member.email;
      //         return ""
      //       })
      //     }
      //   return {
      //       historyId : item._id,
      //       channelId : item.channel.channelId,
      //       subscriberCount : item.channel.subscriberCount,
      //       viewCount : item.channel.viewCount,
      //       title : item.channel.title,
      //       influencerId : item.influencerId,
      //       requestReson : item.priceUnit.requestReson,
      //       status : item.priceUnit.status,
      //       ppl : item.priceUnit.ppl,
      //       branded : item.priceUnit.branded,
      //       regId : item.regId,
      //       regDate : item.regDate,
      //       email: email,
      //       nickname : nickname,
      //       reason : item.reason
      //   }        
      // })

      let requestData =await Promise.all(gridColumResult); 

      let fiveDaysago = new Date(new Date().valueOf() + (1000*60*60*9) - (1000*60*60*(24+24+24+24))).toISOString().split("T")[0]; 
      let fiveDays_getTime = new Date(fiveDaysago).getTime(); 

      let recentFiveDaysData = requestData.filter(r => {return new Date(r.regDate).getTime() > fiveDays_getTime}); 

      
      recentFiveDaysData.map(r =>{
        r.regDate = moment(r.regDate).format('YYYY-MM-DD hh:mm:ss')
        return ""
      })
      
        
      this.setState({ 
        gridApi : this.gridApi,
        gridColumnApi : this.gridColumnApi,
        rowData: recentFiveDaysData,
      });
    }
  };
 
  

  onNotiFormatter(params) {
    return params.value === 1 ? "승인" : "미승인";
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById("page-size").value;
    this.setState({
        paginationPageSize : value
    })
  }

  onChangeGradeStartDate = () => {

  }
  
  onChangeGradeEndDate = () => {

  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
      this.setState({ open: false });
  };



  // ============================================================= Component Render

  render() {    
     
    return (
    <>
    
    <UnApprovalModal  
      open={this.state.onOpenModal} 
      onCloseModal={this.onCloseModal} 
      requestUnapproved={this.requestUnapproved}
    />
        <div  style={{textAlign: "center", marginTop: "65px", marginBottom : "30px", fontWeight: "bold"}}>
           최근 5일 이내의 생성된 광고 단가 변경 요청 리스트</div>
        <div className="row" style={{height: "500px"}}>
            <div className="col-lg-12">

                <div className="ag-theme-balham" style={{height: "100%", width: "100%" }}>
                    <AgGridReact
                        modules={this.state.modules}
                        floatingFilter={true}
                        columnDefs={this.state.columnDefs}
                        autoGroupColumnDef={this.state.autoGroupColumnDef}
                        defaultColDef={this.state.defaultColDef}
                        rowSelection={this.state.rowSelection}                        
                        onGridReady={this.onGridReady}
                        rowData={this.state.rowData}
                        frameworkComponents={this.state.frameworkComponents}
                    >                            
                    </AgGridReact>
                </div>
            </div>
        </div>

        <div className="section-line"></div>

        <div>사유</div>
        <div>
          <TextField
            variant="outlined"
            id="reason"
            fullWidth={true}
            multiline
            rows={5}
            rowsMax={5}
            inputProps={{
              maxLength: 60,
            }}
            />
        </div>
    </>
    );
  }

  

}

export default (RequestAdPriceList);