import React, { useEffect, useState } from 'react';

import ProductBrand from './price/ProductBrand';

import styles from './MembershipPage.module.scss';

export default function ProductPage() {

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>멤버쉽_요금 관리</h1>
      <div className={styles.content}>
        <div className={styles.brand}>
          <ProductBrand />
        </div>
        {/* <div className={styles.youtuber}>
          <h6 className={styles.sub_title}>유튜버</h6>
          <ProductBrand />
        </div> */}
      </div>
    </div>
  );
}