import React from 'react';

import update from 'react-addons-update';
import Auth from '@aws-amplify/auth';
import { ApolloProvider } from '@apollo/react-hooks';
import ApiService from '../../services/ApiService';
import UtilService from '../../services/UtilService';

import UploadFile from './UploadFile';
import ClinetFileUpload from '../commons/ClientFileUpload';
import { deleteOpenapiCookie } from '../../utils/cookie';

class insightWriter extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.util = new UtilService();
    this.authId = null;
    this.state = {
      isLoading: true,
      data: [],
      clientUpload : ClinetFileUpload(),
    }
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    deleteOpenapiCookie();
    let session = await Auth.currentSession();
    if (session) this.authId = session.getIdToken().payload.sub;

    let params = {
      from: 0,
      size: 100
    };

    let resData = await this.api.getWriterList(params);
    resData = resData ? (resData.getWriterList ? resData.getWriterList : []) : []

    let _clientUpload = await ClinetFileUpload();

    this.setState({
      data: resData,
      clientUpload: _clientUpload,
      isLoading: false
    });
  }

  onClickModify = (index) => {
    this.setState({
      data: update(
        this.state.data,
        {
          [index]: {
            isModifyName: { $set: true }
          }
        }
      )
    });
  }

  handleModify = async (oldName, objectId, index) => {
    let input = document.getElementById(`input-name|${objectId}`);
    if (input) {
      let inputValue = input.value;
      
      if (oldName !== inputValue) {
        let params = {
          objectId: objectId,
          type: "name",
          newName: inputValue
        };

        let resData = await this.api.updateWriter(params);
        resData = resData ? (resData.updateWriter ? resData.updateWriter : null) : null;

        if (resData) {
          this.setState({
            data: update(
              this.state.data,
              {
                [index]: { $set: resData }
              }
            )
          });
        } else {
          alert("변경에 실패하였습니다.");
        }
      } else {
        this.setState({
          data: update(
            this.state.data,
            {
              [index]: {
                isModifyName: { $set: false }
              }
            }
          )
        })
      }
    }
  }

  onClickAdd = async () => {
    let params = {
      newName: "-",
    };

    let resData = await this.api.updateWriter(params);
    resData = resData ? (resData.updateWriter ? resData.updateWriter : null) : null;

    if (resData) {
      let tempData = [].concat(this.state.data);
      tempData.push(resData);

      this.setState({ data: tempData });
    }
  }

  handleUploadFile = (result, index) => {
    if (result && result.data && result.data.updateWriter) {
      console.log(result.data.updateWriter.thumbnail);
      this.setState({
        data: update(
          this.state.data,
          {
            [index]: {
              thumbnail: { $set: result.data.updateWriter.thumbnail + `?v=${this.util.getEPOCHSeconds()}` }
            }
          }
        )
      });
    }
  }

  _renderWriterList() {
    return this.state.data.map((item, index) => {
      return <tr>
        <td>
          {
            item.thumbnail ?
              <img alt="" src={item.thumbnail + `?v=${this.util.getEPOCHSeconds()}`} width="40px" height="auto" />
              :
              <>-</>
          }
        </td>
        <td style={{ verticalAlign: "middle" }}>
          <ApolloProvider client={this.state.clientUpload}>
            <UploadFile
              handleUploadFile={this.handleUploadFile}
              authId={this.authId}
              objectId={item._id}
              index={index}
            >
            </UploadFile>
          </ApolloProvider>
        </td>
        <td style={{ verticalAlign: "middle" }}>
          {
            item.isModifyName ?
              <input defaultValue={item.name} id={`input-name|${item._id}`} />
              :
              item.name ?
                <>{item.name}</>
                :
                <>-</>
          }
        </td>
        <td style={{ verticalAlign: "middle" }}>
          {
            item.isModifyName ?
            <button onClick={() => this.handleModify(item.name, item._id, index)}>저장</button>
            :
            <button onClick={() => this.onClickModify(index)}>변경</button>
          }
        </td>
      </tr>
    })
  }

  _renderData() {
    if (this.state.data.length > 0) {
      return <div class="page-content page-container" id="page-content">
        <div class="row container d-flex justify-content-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">필자 관리</h4>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th colSpan={2}>썸네일</th>
                        <th colSpan={2}>이름</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this._renderWriterList()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } else {
      return <div style={{ textAlign: "center" }}>데이터가 없습니다.</div>
    }
  }

  render() {
    if (!this.state.isLoading) {
      return <div className="container">
        <div style={{ display: "flex", width: "100%", padding: "0px 55px", justifyContent: "flex-end", marginBottom: 10 }}>
          <button onClick={this.onClickAdd}>추가</button>
        </div>
        {this._renderData()}
      </div>
    }
    else {
      return <div style={{ textAlign: "center" }}>로딩중...</div>
    }
  }
}

export default (insightWriter);