import React from "react";

import { Route } from "react-router-dom";
import insightCategory from "../../components/insight/insightCategory";

export default function category(props) {
  return (
    <>
      <Route exact path={props.match.path} component={insightCategory} />
    </>
  )
}