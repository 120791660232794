// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-2",
    "aws_cognito_region": "ap-northeast-2",
    "aws_user_pools_id": "ap-northeast-2_J21ahUYas",
    "aws_user_pools_web_client_id": "g59c2u9p8aihloap49v63j6pu",
    "authenticationFlowType": 'USER_PASSWORD_AUTH',
    "oauth": {
        "domain": "verify-admin.dev.vling.net",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3001",
        "redirectSignOut": "http://localhost:3001",
        "responseType": "code"
    }
};


export default awsmobile;
