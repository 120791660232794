import React, { useState } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';

export default function InventoryList({ loading = false, rowData = [], rowClickCB = () => { } ,onCellValueChanged}) {
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => { setGridApi(params.api) };
  const rowClick = () => {
    const [selectedRows] = gridApi.getSelectedRows();
    console.log(selectedRows);
    rowClickCB(selectedRows);
  }
  return (
    <div className="ag-theme-balham" style={{ width: "100%", height: rowData.length === 1 ? "100px" : "595px" }}>
      {loading
        ? <div>loading....</div>
        : <AgGridReact
          rowData={rowData}
          onGridReady={onGridReady}
          onSelectionChanged={rowClick}
          enableCellTextSelection={true}
          rowSelection={'single'}
          onCellValueChanged={onCellValueChanged}
        >
          <AgGridColumn {...{ headerName: "No.", field: "index", sortable: false, resizable: false, width: 45 }} />
          <AgGridColumn {...{ headerName: "이름", field: "userName", sortable: false, resizable: true, width: 100 }} />
          <AgGridColumn {...{ headerName: "이메일", field: "userEmail", sortable: false, resizable: true, width: 200 }} />
          <AgGridColumn {...{ headerName: `아이디${rowData.length === 1 ?"(사용자 아이디를 입력하세요(더블클릭))":""}`, field: "userId", sortable: false, resizable: true, width: 290, editable: rowData.length === 1 }} />
          <AgGridColumn {...{ headerName: "대기", field: "bannerInitCnt", sortable: true, resizable: true, width: 70 }} />
          <AgGridColumn {...{ headerName: "진행중", field: "bannerWIPCnt", sortable: true, resizable: true, width: 70 }} />
          <AgGridColumn {...{ headerName: "중지", field: "bannerPauseCnt", sortable: true, resizable: true, width: 70 }} />
          <AgGridColumn {...{ headerName: "만료", field: "bannerEXPCnt", sortable: true, resizable: true, width: 70 }} />
          <AgGridColumn {...{ headerName: "생성일", field: "createAt", sortable: true, resizable: true, width: 180 }} />
          <AgGridColumn {...{ headerName: "업데이트", field: "updateAt", sortable: true, resizable: true, width: 180 }} />
        </AgGridReact>
      }
    </div>
  )
}
