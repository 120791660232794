import React from "react";

import Header from "../layout/header/Header";
import Aside from "../layout/aside/Aside";
import Routes from "../../router/Routes";

function Layout({}) {

  //첫 렌더링 됬을 시 스크롤 고정 탑
  window.scrollTo(0, 0);

  return (
      <>

        {/* 사이드 바 메뉴 */}
        <Aside/>

        <div id="right-panel" className="right-panel">

            {/* 헤더 */}
           <Header/>

           {/* 컨텐츠 */}
            <div className="content mt-3">     
              <Routes />
            </div>
        </div>
      </>
   );
}


export default Layout;
