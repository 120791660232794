import React, { useState, useEffect } from 'react';
import { createGatewayWhiteList, getUserIp } from './gql'
import { useMutation, useLazyQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';

export default function({ onClose }) {
  const classes = useStyles();
  const [ipValue, setIpValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [ipErrorMessage, setIpErrorMessage] = useState('');
  const [userIp, setUserIp] = useState('');

  const handleIpInputChange = (event) => {setIpValue(event.target.value);};
  const handleNameInputChange = (event) => {setNameValue(event.target.value);};

  const [getUserIpFunc] = useLazyQuery(getUserIp);
  const [addGatewayIpFunc] = useMutation(createGatewayWhiteList);

  //모달창 띄울 시 IP 주소 가져오는 외부 API 사용
  useEffect(() => {
    const fetchIp = async () => {
      try {
        const { data } = await getUserIpFunc();
        setIpValue(data.getUserIp.ip[0]);
      } catch(err) {
        console.log('Public IP Address API request error: ',err);
      }
    };
    fetchIp();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    //ip형식이 맞는지 검증(정규표현식)
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    if (!ipv4Regex.test(ipValue)) {
      setIpErrorMessage('IP주소 형식에 맞지 않습니다.');
      return;
    }

    //게이트웨이 접근 API 요청
    try {
      const result = await addGatewayIpFunc({
        fetchPolicy: 'network-only',
        variables: {
          ip: ipValue,
          name: nameValue,
        }
      })
      if(!result.data.createGatewayWhiteList) {
        throw new Error('API request failed');
      }
      onClose();
    } catch(err) {
      alert('중복된 IP 입니다.');
			console.log(err);
    }
  };

  return (
    <>
      <div className={classes.modal}>
        <div className={classes.modalContent}>
          <span className={classes.close} onClick={onClose}>&times;</span>
          <h3>사용자 IP 추가</h3>
          <small>추가된 IP는 24시간 후 자동 삭제됩니다.</small><br/><br/>
          <form onSubmit={handleSubmit}>
          <table className={classes.table}>
              <tbody>
                <tr>
                  <td>IP 주소</td>
                  <td>
                    <input type="text" value={ipValue} onChange={handleIpInputChange} placeholder="Enter IP address" required/>
                    {ipErrorMessage && <p className={classes.errorMessage}>{ipErrorMessage}</p>}
                  </td>
                </tr>
                <tr>
                  <td>사용자 이름</td>
                  <td><input type="text" value={nameValue} onChange={handleNameInputChange} placeholder="Enter User Name" required/></td>
                </tr>
              </tbody>
            </table>
            <div className={classes.submitButton}>
              <button type="submit">추가</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
    
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'block',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  modalContent: {
    backgroundColor: '#fff',
    position: 'absolute',
    width: '25%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 20,
    borderRadius: 8,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    minWidth: 200,
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
    fontSize: 18,
  },
  errorMessage: {
    color: 'red',
    fontSize: 10
  },
  table: {
    padding: 0,
    margin: 0
  },
  submitButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10
  }
}));