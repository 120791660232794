import React from "react";


export default function Chatting(props) {
  return (
    <>
      <div>채팅이라네 뚜시 </div>
    </>
  );
}
