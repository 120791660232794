import React, { useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import DatePicker from 'react-datepicker';
import { ko } from 'date-fns/esm/locale';

import "react-datepicker/dist/react-datepicker.css"
import styles from './NewMembers.module.css';

const getNewMembersGql = gql`
  query getNewMembers(
    $startDate: Int
    $endDate: Int
  ) {
    getNewMembers (
      startDate: $startDate
      endDate: $endDate
    )
  }
`
const getTimeStamp = (param) => {
  return Math.floor(param.valueOf() / 1000);
}

export default function NewMembers() {
  const [startDate, setStartDate] = useState(Math.floor((new Date().valueOf() - (1000 * 60 * 60 * 24 * 4)) / 1000));
  const [endDate, setEndDate] = useState(getTimeStamp(new Date()));

  // 신규 회원 데이터 요청
  const { data } = useQuery(getNewMembersGql, { variables: { startDate, endDate } });
  const memberData = data?.getNewMembers;

  const calPercent = (numerator, denominator) => {
    if (numerator === 0) { return '0%' }
    else { return `${(numerator / denominator * 100).toFixed(2)}%` }
  }

  const tableBody = memberData?.map((el, index) => {
    return (
      <tr key={index}>
        <td>{el.date}</td>
        <td>{el.all.all}<font style={{ color: "grey" }}> ({el.all.experienced})</font><span> {calPercent(el.all.experienced, el.all.all)}</span></td>
        <td>{el.advertiser.all}<font style={{ color: "grey" }}> ({el.advertiser.experienced})</font><span> {index === 0 ? calPercent(el.advertiser.experienced, el.advertiser.all) : ''}</span></td>
        <td>{el.agency.all}<font style={{ color: "grey" }}> ({el.agency.experienced})</font><span> {index === 0 ? calPercent(el.agency.experienced, el.agency.all) : ''}</span></td>
        <td>{el.influencer.all}<font style={{ color: "grey" }}> ({el.influencer.experienced})</font><span> {index === 0 ? calPercent(el.influencer.experienced, el.influencer.all) : ''}</span></td>
        <td>{el.mcn.all}<font style={{ color: "grey" }}> ({el.mcn.experienced})</font><span> {index === 0 ? calPercent(el.mcn.experienced, el.mcn.all) : ''}</span></td>
        <td>{el.normal.all}<font style={{ color: "grey" }}> ({el.normal.experienced})</font><span> {index === 0 ? calPercent(el.normal.experienced, el.normal.all) : ''}</span></td>
        <td>{el.notMailVerified.all}</td>
      </tr>
    )
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>가입인원수
          <span className={styles.description}> (스타트업체험인원수)</span>
          <span> 체험비율%</span>
        </div>
        <div className={styles.chooseDate}>날짜 선택: </div>
        <div className={styles.datePickerContainer}>
          <DatePicker
            className={styles.datePicker}
            selected={startDate * 1000}
            placeholderText=' 시작 날짜'
            locale={ko}
            dateFormat="yyyy-MM-dd"
            onChange={date => setStartDate(getTimeStamp(date))}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
          <DatePicker
            className={styles.datePicker}
            selected={endDate * 1000}
            placeholderText=' 마지막 날짜'
            locale={ko}
            dateFormat="yyyy-MM-dd"
            onChange={date => setEndDate(getTimeStamp(date))}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>가입일자</th>
              <th>전체</th>
              <th>광고주</th>
              <th>광고대행사</th>
              <th>크리에이터</th>
              <th>mcn</th>
              <th>일반회원</th>
              <th>미인증</th>
            </tr>
          </thead>
          <tbody>
            {tableBody}
          </tbody>
        </table>
      </div>
    </div>
  )
}