import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  GET_FAVORITE_FOLDERS,
  CREATE_FOLDER_MUTATION,
  UPDATE_FOLDER_MUTATION,
  DELETE_FOLDER_MUTATION,
  ADD_ITEM_TO_FOLDER,
  REMOVE_ITEM_FROM_FOLDER,
  ADD_PERMISSION_TO_FOLDER,
  REMOVE_PERMISSION_FROM_FOLDER,
} from "./gql";

import styles from './ManageFavorite.module.scss';

export default function ManageFavorite() {
  const [userIdInput, setUserIdInput] = useState('');
  const [userId, setUserId] = useState('admin@bzznbyd.com');
  const [activeTab, setActiveTab] = useState('channel'); // 'video' 또는 'channel'로 초기화
  const { data, loading, error } = useQuery(GET_FAVORITE_FOLDERS, {
    variables: { userId },
    skip: !userId,
  });
  const [newFolderName, setNewFolderName] = useState('');
  const [newItemInputs, setNewItemInputs] = useState({});
  const [enterpriseIdInputs, setEnterpriseIdInputs] = useState({});

  const [createFolder] = useMutation(CREATE_FOLDER_MUTATION);
  const [updateFolder] = useMutation(UPDATE_FOLDER_MUTATION);
  const [deleteFolder] = useMutation(DELETE_FOLDER_MUTATION);
  const [addItemToFolder] = useMutation(ADD_ITEM_TO_FOLDER);
  const [removeItemFromFolder] = useMutation(REMOVE_ITEM_FROM_FOLDER);
  const [addPermissionToFolder] = useMutation(ADD_PERMISSION_TO_FOLDER);
  const [removePermissionFromFolder] = useMutation(REMOVE_PERMISSION_FROM_FOLDER);

  useEffect(() => {
    if (data && data.getFavoriteFolders) {
      const initialInputs = {};
      data.getFavoriteFolders.forEach(folder => {
        initialInputs[folder._id] = '';
      });
      setNewItemInputs(initialInputs);
      setEnterpriseIdInputs(initialInputs);
    }
  }, [data]);

  // const handleSubmitUserId = () => {
  //   setUserId(userIdInput.trim());
  // };

  const handleNewItemChange = (folderId, value) => {
    setNewItemInputs(prev => ({ ...prev, [folderId]: value }));
  };

  const handleChangeEnterpriseIdInput = (folderId, value) => {
    setEnterpriseIdInputs(prev => ({ ...prev, [folderId]: value }));
  };

  const handleChangeTab = (tab) => {
    setActiveTab(tab);
  };

  const handleCreateFolderClick = () => {
    if (!newFolderName.trim()) {
      alert('폴더 이름을 입력해주세요.');
      return;
    }
    createFolder({
      variables: { userId, folderType: activeTab, folderName: newFolderName },
      refetchQueries: [{ query: GET_FAVORITE_FOLDERS, variables: { userId } }],
    }).then(() => {
      alert('폴더가 생성되었습니다.');
      setNewFolderName('');
    });

  };

  const handleUpdateFolderClick = (folderId) => {
    const newFolderName = prompt('새 폴더 이름을 입력하세요:');

    if (newFolderName === null) return;
    if (!newFolderName.trim()) return;

    updateFolder({
      variables: { userId, folderId, folderType: activeTab, newFolderName },
      refetchQueries: [{ query: GET_FAVORITE_FOLDERS, variables: { userId } }],
    }).then(() => {
      alert('폴더명이 변경되었습니다.');
    });

  };

  const handleDeleteFolderClick = (folderId) => {
    if (window.confirm('정말 이 폴더를 삭제하시겠습니까?')) {
      deleteFolder({
        variables: { userId, folderId, folderType: activeTab },
        refetchQueries: [{ query: GET_FAVORITE_FOLDERS, variables: { userId } }],
      }).then(() => {
        alert('폴더가 삭제되었습니다.');
      });
    }
  };

  const handleAddItemToFolderClick = (folderId) => {
    if (!newItemInputs[folderId].trim()) {
      alert('아이템 ID를 입력해주세요.');
      return;
    }
    addItemToFolder({
      variables: { userId, folderId, folderType: activeTab, itemId: newItemInputs[folderId] },
      refetchQueries: [{ query: GET_FAVORITE_FOLDERS, variables: { userId } }],
    });
  };

  const handleRemoveItemFromFolderClick = (folderId, itemId) => {
    if (window.confirm(activeTab === 'channel' ? '정말 이 채널을 삭제하시겠습니까?' : '정말 이 영상을 삭제하시겠습니까?')) {
      removeItemFromFolder({
        variables: { userId, folderId, folderType: activeTab, itemId },
        refetchQueries: [{ query: GET_FAVORITE_FOLDERS, variables: { userId } }],
      });
      alert('아이템이 삭제되었습니다.');
    }
  };

  const handleAddPermissionToFolderClick = (folderId) => {
    const enterpriseId = enterpriseIdInputs[folderId];
    if (!enterpriseId.trim()) {
      alert('엔터프라이즈 ID를 입력해주세요.');
      return;
    }
    addPermissionToFolder({
      variables: { userId, folderType: activeTab, folderId, permissionUserId: enterpriseId },
      refetchQueries: [{ query: GET_FAVORITE_FOLDERS, variables: { userId } }],
    }).then(() => {
      alert('권한이 추가되었습니다.');
      setEnterpriseIdInputs(prev => ({ ...prev, [folderId]: '' }));
    });
  };

  const handleRemovePermissionFromFolderClick = (folderId, enterpriseId) => {
    if (window.confirm('정말 이 사용자의 권한을 삭제하시겠습니까?')) {
      removePermissionFromFolder({
        variables: { userId, folderType: activeTab, folderId, permissionUserId: enterpriseId },
        refetchQueries: [{ query: GET_FAVORITE_FOLDERS, variables: { userId } }],
      }).then(() => {
        alert('권한이 제거되었습니다.');
      });
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className={styles["manage-favorite"]}>
      <h1 className={styles["manage-favorite__title"]}>즐겨찾기 관리</h1>

      {/* <div className={styles["user-id-input"]}>
        <input
          type="text"
          placeholder="사용자 ID 입력"
          value={userIdInput}
          onChange={(e) => setUserIdInput(e.target.value)}
          className={styles["manage-favorite__input"]}
        />
        <button onClick={handleSubmitUserId} className={styles["manage-favorite__button"]}>
          조회
        </button>
      </div> */}

      {data && <div className={styles.tabs}>
        <button
          className={`${styles.tab} ${activeTab === 'channel' ? styles.active : ''}`}
          onClick={() => handleChangeTab('channel')}
        >
          채널 폴더
        </button>
        <button
          className={`${styles.tab} ${activeTab === 'video' ? styles.active : ''}`}
          onClick={() => handleChangeTab('video')}
        >
          영상 폴더
        </button>
      </div>}
      {data && <div className={styles["manage-favorite__creation"]}>
        <input
          type="text"
          placeholder="새 폴더 이름"
          value={newFolderName}
          onChange={(e) => setNewFolderName(e.target.value)}
          className={styles["manage-favorite__input"]}
        />
        <button onClick={handleCreateFolderClick} className={styles["manage-favorite__button"]}>폴더 생성</button>
      </div>}
      {data && data.getFavoriteFolders.filter(folder => folder.folderType === activeTab).map((folder, index) => (
        <div key={index} className={styles["favorite-folder"]}>
          <p>공유 링크: /listup?type=channel&folder_id={folder._id}</p>
          <div className={styles["favorite-folder-box"]}>
            <div>
              <h2 className={styles["favorite-folder__name"]}>{folder.folder_name}</h2>
              <p>{folder.items.length}개</p>
            </div>
            <div>
              <button onClick={() => handleUpdateFolderClick(folder._id)} className={styles["manage-favorite__button"]}>이름 변경</button>
              <button onClick={() => handleDeleteFolderClick(folder._id)} className={styles["manage-favorite__button"]}>폴더 삭제</button>
            </div>
          </div>
          <div className={styles["favorite-folder__items"]}>
            {folder.items.map((item, itemIndex) => (
              <div key={itemIndex} className={styles["favorite-item"]}>
                <span className={styles["favorite-item__id"]}>{item.title}</span>
                <span className={styles["favorite-item__id"]}>{item.itemId}</span>
                <span className={styles["favorite-item__id"]}>{item.registeredAt}</span>
                <button onClick={() => handleRemoveItemFromFolderClick(folder._id, item.itemId)} className={styles["manage-favorite__button"]}>{activeTab === 'channel' ? "채널 삭제" : "영상 삭제"}</button>
              </div>
            ))}
          </div>
          <input
            type="text"
            placeholder={activeTab === 'channel' ? "채널 ID 입력" : "영상 ID 입력"}
            value={newItemInputs[folder._id]}
            onChange={(e) => handleNewItemChange(folder._id, e.target.value)}
            className={styles["manage-favorite__input"]}
          />
          <button onClick={() => handleAddItemToFolderClick(folder._id)} className={styles["manage-favorite__button"]}>{activeTab === 'channel' ? "채널 추가" : "영상 추가"}</button>
          {folder.permissionUserIds?.map((user, index) => (
            <div key={index} className={styles["favorite-item"]}>
              <span className={styles["favorite-item__id"]}>{user}</span>
              <button onClick={() => handleRemovePermissionFromFolderClick(folder._id, user)} className={styles["manage-favorite__button"]}>권한 삭제</button>
            </div>
          ))}
          <input
            type="text"
            placeholder="엔터프라이즈 ID 입력"
            value={enterpriseIdInputs[folder._id] || ''}
            onChange={(e) => handleChangeEnterpriseIdInput(folder._id, e.target.value)}
            className={styles["manage-favorite__input"]}
          />
          <button onClick={() => handleAddPermissionToFolderClick(folder._id)} className={styles["manage-favorite__button"]}>권한 추가</button>
        </div>
      ))}
    </div>
  );
};