import gql from 'graphql-tag';

export const GET_USERS_WITH_TRACKING_ENABLED_QUERY = gql`
  query getAmplitudeTrackingEnabled($page: Int!, $pageSize: Int!) {
    getAmplitudeTrackingEnabled(page: $page, pageSize: $pageSize)
  }
`;

export const ADD_AMPLITUDE_TRACKING_ENABLED_MUTATION = gql`
  mutation addAmplitudeTrackingEnabled($userId: String!) {
    addAmplitudeTrackingEnabled(userId: $userId)
  }
`;

export const REMOVE_AMPLITUDE_TRACKING_ENABLED_MUTATION = gql`
  mutation removeAmplitudeTrackingEnabled($userId: String!) {
    removeAmplitudeTrackingEnabled(userId: $userId)
  }
`;

export const SAVE_MEMO_MUTATION = gql`
  mutation saveMemo($userId: String!, $memo: String!) {
    saveMemo(userId: $userId, memo: $memo)
  }
`;