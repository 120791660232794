import React from "react";

import { Route } from "react-router-dom";
import ProductPage from "../../components/membership/ProductPage"

export default function product(props) {
  return (
    <>
      <Route exact path={props.match.path} component={ProductPage} />
    </>
  );
}
