import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Main from "../pages/Main";
import Channel from "../pages/Channel";
import Chatting from "../pages/Chatting";
import Member from "../pages/Member";
import SendMail from "../pages/SendMail";
import HotTrend from "../pages/HotTrend";
import Stats from "../pages/Stats";
import RequestAdPrice from "../pages/RequestAdPrice";
import RequestAdPriceEdit from "../pages/RequestAdPriceEdit";
import Campaigns from "../pages/Campaigns";
import InsightWriter from "../pages/insight/insightWriter";
import InsightCategory from "../pages/insight/insightCategory";
import InsightPost from "../pages/insight/insightPost";
import MetaTag from "../pages/MetaTag";
import MainChannelList from "../pages/MainChannelList";

import EnterpriseList from "../pages/enterprise/enterpriseList";
import EnterpriseDetail from "../pages/enterprise/enterpriseDetail";
import EnterpriseEdit from "../pages/enterprise/enterpriseEdit";
import CouponList from "../components/cupon/CouponList";
import AddCoupon from "../components/cupon/AddCoupon";
import CouponInfo from "../components/cupon/CouponInfo"
import CouponEdit from "../components/cupon/CouponEdit";
import AdvertiserList from "../components/advertiserManage/AdvertiserList";
import AdvertiserInfo from "../components/advertiserManage/AdvertiserInfo";

import BannerPage from '../pages/BannerPage';
import AddMail from '../pages/AddMail';
import Grade from '../pages/membership/grade';
import Product from '../pages/membership/product';
import Sandbox from "../pages/gateway/Sandbox";
import WhiteList from "../pages/gateway/WhiteList";
import ManageFavoritePage from "../pages/ManageFavorite";
import ManageTrackingPage from "../pages/ManageTracking";

export default function Routes(props) {

  return (
    <Suspense >
      <Switch>
        {
          //루트 path로 로케이션 될때 main으로 이동
          <Redirect exact from="/" to="/main" />
        }

        {/* Router V4 버전부터 Route에 설정된 componnent에 임의에 props를 전달할 수 있다. */}
        {/* Main 관련 router */}
        <Route exact path={"/main"} render={(props) => <Main {...props} />} />

        {/* Member 관련 router */}
        <Route path={"/member"} render={(props) => <Member {...props} />} />

        {/* Channel 관련 router */}
        <Route path={"/channel"} render={(props) => <Channel {...props} />} />

        {/* chatting 관련 router */}
        <Route path={"/chatting"} render={(props) => <Chatting {...props} />} />

        {/* sendmail 관련 router */}
        <Route path={"/sendMail"} render={(props) => <SendMail {...props} />} />

        {/* Stats 관련 router */}
        <Route path={"/stats"} render={(props) => <Stats {...props} />} />

        {/* HotTrend 관련 router */}
        <Route path={"/hottrend"} render={(props) => <HotTrend {...props} />} />

        {/* Campaign 관련 router */}
        <Route path={"/campaigns"} render={(props) => <Campaigns {...props} />} />

        {/* RequestAdPrice 관련 router */}
        <Route path={"/requestAdPrice"} render={(props) => <RequestAdPrice {...props} />} />
        <Route path={"/RequestAdPriceEdit"} render={(props) => <RequestAdPriceEdit {...props} />} />

        {/* insight 관련 router */}
        <Route path={"/insight/writer"} render={(props) => <InsightWriter {...props} />} />
        <Route path={"/insight/category"} render={(props) => <InsightCategory {...props} />} />
        <Route path={"/insight/post"} render={(props) => <InsightPost {...props} />} />

        {/* enterprise 관련 router */}
        <Route path={"/enterprise/list"} render={(props) => <EnterpriseList {...props} />} />
        <Route path={"/enterprise/detail/:id"} render={(props) => <EnterpriseDetail {...props} />} />
        <Route path={"/enterprise/edit/:id"} render={(props) => <EnterpriseEdit {...props} />} />

        {/* insight 관련 router */}
        <Route path={"/insight/writer"} render={(props) => <InsightWriter {...props} />} />
        <Route path={"/insight/category"} render={(props) => <InsightCategory {...props} />} />
        <Route path={"/insight/post"} render={(props) => <InsightPost {...props} />} />

        {/* 메타태그 관련 router */}
        <Route path={"/metaTagList"} render={(props) => <MetaTag {...props} />} />

        {/* 대문 페이지 관련 router */}
        <Route path={"/mainChannelList/kr"} render={(props) => <MainChannelList {...props} />} />
        <Route path={"/mainChannelList/us"} render={(props) => <MainChannelList {...props} />} />

        <Route path={"/couponList"} render={(props) => <CouponList {...props} />} />
        <Route path={"/addCoupon"} render={(props) => <AddCoupon {...props} />} />
        <Route path={"/couponInfo"} render={(props) => <CouponInfo {...props} />} />
        <Route path={"/couponInfoEdit"} render={(props) => <CouponEdit {...props} />} />

        <Route path={"/advertiserList"} render={(props) => <AdvertiserList {...props} />} />
        <Route path={"/advertiserInfo"} render={(props) => <AdvertiserInfo {...props} />} />

        {/* 배너 페이지 관련 router */}
        <Route path={"/banner"} render={(props) => <BannerPage {...props} />} />

        {/* 채널 연락처 추가 */}
        <Route path={"/addmail"} render={(props) => <AddMail {...props} />} />

        {/* 멤버쉽 관리 */}
        <Route path={"/membership/grade"} render={(props) => <Grade {...props} />}></Route>
        <Route path={"/membership/product"} render={(props) => <Product {...props} />}></Route>

        {/* Gateway 관련 router */}
        {/* <Route path={"/gateway/sandbox"} render={(props) => <Sandbox {...props} />}/> */}
        <Route path={"/gateway/whitelist"} render={(props) => <WhiteList {...props} />}/>

        {/* 즐겨찾기 관리 */}
        <Route path={"/manageFavorite"} render={(props) => <ManageFavoritePage {...props} />} />

        {/* 즐겨찾기 관리 */}
        <Route path={"/manageTracking"} render={(props) => <ManageTrackingPage {...props} />} />

        <Route path="/" />
      </Switch>
    </Suspense>
  );
}
