import React, { useState } from 'react';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';

export default function BannerList({ rowData = [], onCellValueChanged = () => { }, rowClickCB = null }) {
  const [gridApi, setGridApi] = useState(null);
  const onGridReady = (params) => { setGridApi(params.api) };
  const rowClick = () => {
    // https://www.ag-grid.com/react-data-grid/grid-api/#reference-selection
    const [selectedRows] = gridApi.getSelectedRows();
    selectedRows && console.log(selectedRows)
    selectedRows && rowClickCB && rowClickCB(selectedRows);
    const [selectedRowNode] = gridApi.getSelectedNodes();
    selectedRowNode && selectedRowNode.setSelected(false); // 자동 선택 해제
  }
  return (
    <div className="ag-theme-balham" style={{ width: "100%", height: "600px" }}>
      <AgGridReact
        {...{
          rowData: rowData,
          onGridReady: onGridReady,
          onSelectionChanged: rowClick,
          // onCellValueChanged: onCellValueChanged,
          // enableCellTextSelection: true,
          rowSelection: 'single',
        }}
      >
        <AgGridColumn {...{ headerName: "No.", field: "index", sortable: false, resizable: false, width: 45 }} />
        <AgGridColumn {...{ headerName: "라벨", field: "label", sortable: false, resizable: true, width: 100 }} />
        <AgGridColumn {...{ headerName: "타입", field: "type", sortable: false, resizable: true, width: 100 }} />
        <AgGridColumn {...{ headerName: "상태", field: "status", sortable: true, resizable: true, width: 90 }} />
        <AgGridColumn {...{ headerName: "시작일", field: "Pday", sortable: false, resizable: true, width: 80 }} />
        <AgGridColumn {...{ headerName: "잔여일", field: "Dday", sortable: false, resizable: true, width: 80 }} />
        <AgGridColumn {...{ headerName: "LOGO", field: "bannerLogo", sortable: true, resizable: true, width: 70 }} />
        <AgGridColumn {...{ headerName: "배너PC", field: "bannerPC", sortable: true, resizable: true, width: 70 }} />
        <AgGridColumn {...{ headerName: "배너MO", field: "bannerMobile", sortable: true, resizable: true, width: 70 }} />
        {/* <AgGridColumn {...{ headerName: "노출위치", field: "positon", sortable: false, resizable: true, width: 200 }} /> */}
        <AgGridColumn {...{ headerName: "시작일", field: "startAt_str", sortable: true, resizable: true, width: 180 }} />
        <AgGridColumn {...{ headerName: "종료일(기본 30일)", field: "expireAt_str", sortable: true, resizable: true, width: 180 }} />
      </AgGridReact>
    </div>
  )
}
