import gql from 'graphql-tag';

export const BannerShortFragment = gql`
  fragment BannerShortFragment on Banner {
    _id
    status
  }
`
export const BannerDefaultFragment = gql`
  fragment BannerDefaultFragment on Banner {
    _id
    representativeEmail
    label
    title
    desc
    desc_sub
    type
    status
    createAt
    updataAt
    startAt
    expireAt
    positon
    bannerLogoURL
    bannerURL
    bannerMobileURL
    inventoryId
  }
`

export const getBanner = gql`
  ${BannerDefaultFragment}
  query getBanner(
    $_id: String!
  ) {
    getBanner(
      _id: $_id
    ) {
      ...BannerDefaultFragment
      #inventoryInfo: Inventory
    }
  }
`

export const upsertBanner = gql`
  mutation upsertBanner(
    $_id: String
    $representativeEmail: String
    $label: String
    $title: String
    $desc: String
    $desc_sub: String
    $type: String
    $status_code: String
    $startAt: Int
    $expireAt: Int
    $position: [JSON]
    $bannerURL: String
    $bannerMobileURL: String
    $bannerLogoURL: String
    #인벤토리
    $inventoryId: String!
  ) {
    upsertBanner(
      _id: $_id
      representativeEmail: $representativeEmail
      label: $label
      title: $title
      desc: $desc
      desc_sub: $desc_sub
      type: $type
      status_code: $status_code
      startAt: $startAt
      expireAt: $expireAt
      position: $position
      bannerURL: $bannerURL
      bannerMobileURL: $bannerMobileURL
      bannerLogoURL: $bannerLogoURL
      inventoryId: $inventoryId
    ) {
      result
    }
  }
`
export const deleteBanner = gql`
  mutation deleteBanner(
    $_id: String!
  ) {
    deleteBanner(
      _id: $_id
    ) {
      result
    }
  }
`

export const uploadBannerImg = gql`
  mutation uploadBannerImg(
    $_id: String!
    $file: Upload!
    $fileType: String
  ) {
    uploadBannerImg(   
      _id: $_id
      file: $file
      fileType: $fileType
    ) {
      result
    }
  }
`

export const deleteBannerImg = gql`
  mutation deleteBannerImg(
      $_id: String!
      $fileURL: String!
    ) {
      deleteBannerImg(
        _id: $_id
        fileURL: $fileURL
      ) {
        result
      }
    }
`