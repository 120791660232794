import React from "react";

import { Route } from "react-router-dom";
import RequestAdPriceEdit from "../components/requestAdPrice/RequestAdPriceEdit"

export default function RequestAdPrice(props) {
  return (
    <>
        <Route exact path={props.match.path} component={RequestAdPriceEdit} />
    </>
  );
}
