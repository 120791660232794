import React from "react";

import { Route } from "react-router-dom";
import MetaTagList from "../components/metaTag/MetaTagList";
import MetaTagDetail from "../components/metaTag/MetaTagDetail";


export default function MetaTag(props) {
  return (
    <>
      <Route exact path={props.match.path} component={MetaTagList} />
      <Route path={`${props.match.path}/:id`} component={MetaTagDetail} />
    </>
  )
}