import React from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ToastContainer, toast } from 'react-toastify';

const SINGLE_UPLOAD_MUTATION = gql`
mutation updateWriter($objectId : String! $newThumbnail: Upload!) {
  updateWriter(objectId: $objectId type: "thumbnail" newThumbnail: $newThumbnail)
}`;

const setToast = (str) => {
  toast.success(str, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}

const UploadFile = (props) => {
  const [uploadFileMutation] = useMutation(SINGLE_UPLOAD_MUTATION);
  const apolloClient = useApolloClient();

  const onChange = ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    let profileInput = document.getElementById(`profileUpload|${props.objectId}`);
    if (file.size / 1024 / 1024 > 1) {
      alert("파일 용량은 1MB보다 적어야 됩니다.");
      profileInput.value = "";
    }
    let userId = "";
    if (!props.authId) {
      alert("사용자 정보가 존재하지 않습니다. 다시 로그인해주세요.")
      return;
    } else {
      userId = props.authId;
    }

    let fileName = profileInput.value.replace(/^.*[\\/]/, '');
    let fileNames = fileName.split(".");
    let ext = fileNames[1].toLowerCase();
    if (!ext || (ext !== "png" && ext !== "jpg")) {
      alert("파일 확장자는 jpg 또는 png 파일이여야 됩니다.");
      return;
    }

    validity.valid &&
      uploadFileMutation({ variables: { objectId: props.objectId, newThumbnail: file } }).then((getResult) => {
        let result = apolloClient.resetStore(); //무슨 기능인지 확인하기
        let userFileName = userId + "." + ext;
        console.log("[uploadFile]",result,userFileName)
        if (getResult && getResult.data && getResult.data.updateWriter) {
          // if (getResult.data.thumbnailsUpload.filename === userFileName) {
            setToast("프로필 변경에 성공했습니다.");
            props.handleUploadFile(getResult, props.index);
          // }
        } else {
          alert("프로필 변경에 실패했습니다. 문의 부탁드립니다.");
        }

        profileInput.value = "";
      }).catch((err) => {
        let errorMsg = err.toString();
        if (errorMsg.indexOf("extention") > -1) {
          alert("파일 확장자는 jpg 또는 png 파일이여야 됩니다.");
        }
        profileInput.value = "";
      })
  }


  return (
    <>
      <input id={`profileUpload|${props.objectId}`} className="setting-detail-file-upload" type="file" required onChange={onChange} />
      <ToastContainer
        position="top-right"
        autoClose={100000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  );
};

export default UploadFile;