import React from 'react';
// import { Redirect } from "react-router-dom";

import ApiService from '../../services/ApiService';

// import DateFnsUtils from '@date-io/date-fns';
// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,
//   DatePicker
// } from '@material-ui/pickers';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";
import ChildMessageRenderer from "./agGrid/ChildMessageRenderer.jsx";

// import UnApprovalModal from './modal/UnApprovalModal';
// import TextField from '@material-ui/core/TextField';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import * as moment from 'moment';
// import update from 'react-addons-update'

class RequestAdPriceList extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      modules: AllCommunityModules,
      columnDefs: [
        {
          headerName: '-',
          children: [          
            { headerName: "No.", field: "index", width: 40 },  
            { headerName: "백오피스", field: "isBackoffice", width: 40 },
            { headerName: "신청일시", field: "regDate", width: 80 },
          ],
        },
        {
          headerName: '변경한사람',
          children: [          
            { headerName: "닉네임", field: "nickname", width: 60 },
            { headerName: "변경한사람", field: "email", width: 80 },
          ],
        },
        {
          headerName: '대상채널',
          children: [          
            { headerName: "대상채널", field: "title", width: 100 },
            { headerName: "채널id", field: "channelId", columnGroupShow: 'open', width: 120 },
            { headerName: "구독자(변경당시)", field: "subscriberCount", columnGroupShow: 'open', width: 100, cellStyle: {"textAlign":"right"} },
          ],
        },
        {
          headerName: '변경내용',
          children: [          
            { headerName: "기본PPL", field: "ppl", width: 60, cellStyle: {"text-align":"right"} },
            { headerName: "브랜디드", field: "branded", width: 60, cellStyle: {"text-align":"right"} },
            { headerName: "변경사유", field: "reason", width: 60 },
          ],
        },
        // {
        //   headerName: "승인/미승인",
        //   field: "value",
        //   cellRenderer: "childMessageRenderer",
        //   cellRendererParams: {
        //     onOpenModal: this.onOpenModal
        //   },
        //   colId: "params",
        //   width: 180
        // }
      ],
      defaultColDef: {
        // editable: false,
        // enableRowGroup: true,
        // enablePivot: true,
        // enableValue: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowData: [],
      context: { componentParent: this },
      frameworkComponents: {
        childMessageRenderer: ChildMessageRenderer
      },
      redirect: null,
      gridApi: null,
      gridColumnApi: null,
      open: false
    };
  }

  // ============================================================= Life cycle 

  componentDidMount() {
    //데이터 주입
    this.initialize();

  }

  // ============================================================= Component function

  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {


  }

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    let resDate = await this.api.getAdUnitPriceUsers();

    if (resDate.memberHistorys) {
      let influencerIds = resDate.memberHistorys.map(m => m.influencerId);
      influencerIds = Array.from(new Set(influencerIds));
      let getMembers = await this.api.getMembers({ userIds: influencerIds });
      let getMemberResults = (getMembers && getMembers.getMembers && getMembers.getMembers.result && Array.isArray(getMembers.getMembers.result)) ? getMembers.getMembers.result : [];
      // console.log(getMemberResults);

      let gridColumResult = await resDate.memberHistorys.map(async (item, index) => {
        let email = "";
        let nickname = "";
        if(item.reason && item.reason === "backoffice") {
          email = "-";
          nickname = "backoffice";
        }
        let memberInfo = getMemberResults.find(member => member._id === item.influencerId);
        if (memberInfo && memberInfo.name && memberInfo.email) {
          nickname = memberInfo.name;
          email = memberInfo.email;
        }

        return {
          index: index+1,
          isBackoffice: (item.reason === "backoffice")? "O" : "X",
          historyId: item._id,
          channelId: item.channel.channelId,
          subscriberCount: parseInt(item.channel.subscriberCount).toLocaleString(),
          // viewCount: item.channel.viewCount,
          title: item.channel.title,
          influencerId: item.influencerId,
          // requestReson: item.priceUnit.requestReson,
          // status: item.priceUnit.status,
          ppl: parseInt(item.priceUnit.ppl).toLocaleString(),
          branded: parseInt(item.priceUnit.branded).toLocaleString(),
          regId: item.regId,
          regDate: moment(new Date(item.regDate)).format('YYYY-MM-DD HH:mm'),
          email: email,
          nickname: nickname,
          reason: item.reason
        }
      });



      // let gridColumResult = await resDate.memberHistorys.map(async (item,index)=>{

      //     let getMemberResult = await this.api.getMember(item.influencerId);
      //     let email = "";
      //     let nickname = "";

      //     if (getMemberResult.getMember) {
      //       getMemberResult.getMember.result.UserAttributes.map((item,index)=>{
      //         if (item.Name === "nickname") {
      //           nickname = item.Value;
      //         } else if (item.Name === "email") {
      //           email = item.Value;
      //         }
      //         return ""
      //       })
      //     }

      //     return {
      //       historyId : item._id,
      //       channelId : item.channel.channelId,
      //       subscriberCount : item.channel.subscriberCount,
      //       viewCount : item.channel.viewCount,
      //       title : item.channel.title,
      //       influencerId : item.influencerId,
      //       requestReson : item.priceUnit.requestReson,
      //       status : item.priceUnit.status,
      //       ppl : item.priceUnit.ppl,
      //       branded : item.priceUnit.branded,
      //       regId : item.regId,
      //       regDate : item.regDate,
      //       email: email,
      //       nickname : nickname,
      //       reason : item.reason
      //     }
      // })

      this.setState({
        gridApi: this.gridApi,
        gridColumnApi: this.gridColumnApi,
        rowData: await Promise.all(gridColumResult),
      });
    }

  };



  onNotiFormatter(params) {
    return params.value === 1 ? "승인" : "미승인";
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById("page-size").value;
    this.setState({
      paginationPageSize: value
    })
  }

  onChangeGradeStartDate = () => {

  }

  onChangeGradeEndDate = () => {

  }

  onOpenModal = () => {
    this.setState({ open: true });
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };



  // ============================================================= Component Render

  render() {

    return (
      <>
        {/* <UnApprovalModal
          open={this.state.onOpenModal}
          onCloseModal={this.onCloseModal}
          requestUnapproved={this.requestUnapproved}
        /> */}
        <div>
          <b>광고단가 변경이력</b>
        </div>
        <div>
          &nbsp;
        </div>
        <div className="row" style={{ height: "600px" }}>
          <div className="col-lg-12">
            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                modules={this.state.modules}
                // floatingFilter={true}
                columnDefs={this.state.columnDefs}
                // autoGroupColumnDef={this.state.autoGroupColumnDef}
                defaultColDef={this.state.defaultColDef}
                // rowSelection={this.state.rowSelection}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}
                frameworkComponents={this.state.frameworkComponents}
                enableCellTextSelection={true}
              >
              </AgGridReact>
            </div>
          </div>
        </div>

        {/* <div className="section-line"></div> */}
        {/* <div>사유</div>
        <div>
          <TextField
            variant="outlined"
            id="reason"
            fullWidth={true}
            multiline
            rows={5}
            rowsMax={5}
            inputProps={{
              maxLength: 60,
            }}
            />
        </div> */}
      </>
    );
  }



}

export default (RequestAdPriceList);