// lib
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { AgGridReact, AgGridColumn } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import moment from 'moment';

// components
import InventoryList from './Inventory/InventoryList';

// import { ApolloProvider } from '@apollo/react-hooks';

// util
import _arrayToInventoryData from './Inventory/_arrayToInventoryData';
// import ClientFileUpload from '../commons/ClientFileUpload';
// import UploadFile from './BannerWide/UploadFile'

// gql
import { getInventoryList, upsertInventory } from './Inventory/gql';

/** 시나리오
 * 1. 리스트 뷰 + 새 인벤토리 생성
 * 2. 인벤토리 선택 -> 수정, 업로드
 */
export default function Inventory() {
  const history = useHistory();
  const { pathname, state: route_state } = useLocation();
  const { loading, error: query_error, data: query_data, refetch } = useQuery(getInventoryList, {
    variables: { startDate: "", endDate: "", }
  });
  useEffect(() => {
    if (route_state === "refetch") {
      history.location.state = undefined; // 초기화
      refetch();
    }
  }, [route_state])

  const [insertFn] = useMutation(upsertInventory);

  // ===== [ 테이블 ] =====
  const [rowData, setRowData] = useState([]);

  const rowClickCB = (selectedRows) => {
    // const [selectedRows] = gridApi.getSelectedRows();
    // console.log(selectedRows);
    history.push(`${pathname}/${selectedRows.inventoryId}`)
  }

  useEffect(() => {
    console.log(query_data)
    if (query_data) {
      setRowData(prev => {
        const rawData = [].concat(query_data.getInventoryList);
        return _arrayToInventoryData(rawData)
      })
    }
  }, [query_data])
  //

  // === [ 생성 후 리패치 ] === 
  const insertInventory = async () => {
    const insert_result = await insertFn({ variables: {} })
    if (insert_result.data) {
      refetch()
    }
  }
  //

  if (query_error) throw query_error
  // const [authDate, setAuthDate] = useState("")
  // useEffect(() => {
  //   (async () => {
  //     const session = await Auth.currentSession();
  //     session && setAuthDate(session.getIdToken().payload.sub);
  //   })();
  // }, [])
  //


  return (
    <div>
      <section>
        <p>인벤토리</p>
        <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: "10px" }}>
          <button onClick={insertInventory}>새 인벤토리 생성</button>
        </div>
        <InventoryList loading={loading} rowData={rowData} rowClickCB={rowClickCB} />
      </section>
      {/* <ApolloProvider client={ClientFileUpload()}>
        <UploadFile
        // handleUploadFile={this.handleUploadFile}
        // authId={authDate}
        // objectId={item._id}
        // index={index}
        >
        </UploadFile>
      </ApolloProvider> */}

    </div>
  )
}
