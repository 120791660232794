import React, { Fragment, useState, useEffect } from 'react';
import styles from './Stats.module.css'; // 상단에서 import!!!
import ApiService from '../../services/ApiService';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import { countryCode, categoryList } from '../../dict/dictionary';

const api = new ApiService();
const Stats = (props) => {
  const [gridApi, setGridApi] = useState(null);
  const [gridApiCategory, setGridApiCategory] = useState(null);
  // console.log(props) // {history, location, match, staticContext}  
  //const nationCodeList = ["KR", "ID", "TW", "TH", "JP"];
  //const nationName = { KR: "한국", ID: "인도네시아", TW: "대만", TH: "태국", JP: "일본" };  
  // const [nationCodeList  ,setNationCodeList]= useState([]); 
  // const [nationName , setNationNames] = useState({});

  const [isHaveMail, setIsHaveMail] = useState(false);
  const [isCertificated, setIsCertificated] = useState(false);

  const [bucketSubscribers, setBucketSubscribers] = useState([]);
  const [bucketCategory, setBucketCategory] = useState([]);

  const [columnDefsSubscribers, setColumnDefsSubscribers] = useState([]);
  const [columnDefsCategory, setColumnDefsCategory] = useState([]);

  const subscribersList = {
    "0 - 1": "0명(비공개)",
    "1 - 1000": "1 ~ 999명",
    "1000 - 10000": "1천 ~ 9,999명",
    "10000 - 100000": "1만 ~ 99,999명",
    "100000 - 1000000": "10만 ~ 999,999명",
    "1000000 - 10000000": "100만 ~ 9,999,999명",
    "10000000 - undefined": "1000만 이상",
    "total": "전체"
  };


  let refIsHaveMail;
  let refIsCertificated;

  const getData = async () => {
    let result = await api.getStats("ALL", refIsHaveMail && refIsHaveMail.checked, refIsCertificated && refIsCertificated.checked);
    if(result && result.totalStat && result.totalStat.subscriberCount && result.totalStat.category) {
      let buckets;
      let columns;
      let datas;
     
      // subscriberCount ------
      buckets = result.totalStat.subscriberCount;
      columns = buckets.column.map((bucket, index) => { return { headerName: bucket, field: bucket, width: (index===0)? 180 : 90, sortable: true, resizable: true }});
      columns = columns.map(c => {
        let name = countryCode.find(code => code.code === c.headerName);
        if(name && name.krName) c.headerName = name.krName;
        return c;
      })
      setColumnDefsSubscribers(columns);

      datas = buckets.count;
      datas = datas.map(data => {
        if(subscribersList[data.subscriberCount]) data.subscriberCount = subscribersList[data.subscriberCount];
        return data;
      })
      setBucketSubscribers(datas);

      // category ------
      buckets = result.totalStat.category;
      columns = buckets.column.map((bucket, index) => { return { headerName: bucket, field: bucket, width: (index===0)? 180 : 90, sortable: true, resizable: true }});
      columns = columns.map(c => {
        let name = countryCode.find(code => code.code === c.headerName);
        if(name && name.krName) c.headerName = name.krName;
        return c;
      })
      setColumnDefsCategory(columns);

      datas = buckets.count;
      datas = datas.map(data => {
        if(categoryList[data.category]) data.category = categoryList[data.category];
        else if(subscribersList[data.category]) data.category = subscribersList[data.category];
        return data;
      })
      setBucketCategory(datas);
    }
  }

  const onGridReady = async (params) => {
    setGridApi(params.api);
    getData();
  }

  const onGridReadyCategory = async (params) => {
    setGridApiCategory(params.api);
  }
  
  const _onChangeEtc = e => {
    const { checked, value, name } = e.target;
    // console.log(checked, value, name);
    // true, on, isHaveMail / isCertificated
    if (name === "isHaveMail") {
      setIsHaveMail(checked);
    } else {
      setIsCertificated(checked);
    }

    getData();
  }

  const onBtnExport = () => {
    gridApi.exportDataAsCsv();
  };

  const onBtnExportCategory = () => {
    gridApiCategory.exportDataAsCsv();
  };

  /** ========== Stats Render ========== */
  return (
    <div>
      <div className={styles.CheckBox}>
        <span className={styles.label}>
          이메일 보유 
        </span>
        <input
          type="checkbox"
          ref={(ref) => {refIsHaveMail = ref} }
          name="isHaveMail"
          checked={isHaveMail}
          onChange={_onChangeEtc}
        />
        <span className={styles.label}>
          인증 채널 
        </span>
        <input
          type="checkbox"
          ref={(ref) => { refIsCertificated = ref} }
          name="isCertificated"
          checked={isCertificated}
          onChange={_onChangeEtc}
        />
      </div>
      <div className={styles.csvDown}>
        <button onClick={() => onBtnExport()}>
          CSV 다운
        </button>
      </div>
      <div className={styles.channelList}>
        <div className="ag-theme-balham" style={{ width: "100%", height: "280px" }}>
          <AgGridReact
            onGridReady={onGridReady}
            // gridOptions={gridOptions}
            columnDefs={columnDefsSubscribers}
            rowData={bucketSubscribers}
            enableCellTextSelection={true}
          />
        </div>
      </div>
      <div className={styles.csvDown}>
        <button onClick={() => onBtnExportCategory()}>
          CSV 다운
        </button>
      </div>
      <div className={styles.channelList}>
        <div className="ag-theme-balham" style={{ width: "100%", height: "700px" }}>
          <AgGridReact
            onGridReady={onGridReadyCategory}
            // gridOptions={gridOptions}
            columnDefs={columnDefsCategory}
            rowData={bucketCategory}
            enableCellTextSelection={true}
          />
        </div>
      </div>
    </div>
  )
}

export default Stats;