import React from "react";

import { Route } from "react-router-dom";
import insightPost from "../../components/insight/insightPost";
import insightPostDetail from "../../components/insight/insightPostDetail";

export default function post(props) {
  return (
    <>
      <Route exact path={props.match.path} component={insightPost} />
      <Route path={`${props.match.path}/:id`} component={insightPostDetail} />
    </>
  )
}