import React, { useEffect, useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';

import numeral from 'numeral';

import { getMailData, postMailData, sendAdsProposal } from './gql';

const purposeOptions = [
  { value: 'branded', label: '브랜디드' },
  { value: 'ppl', label: 'PPL' },
  { value: 'shorts', label: 'Shorts (쇼츠)' },
  { value: 'offline', label: '오프라인 행사' },
  { value: 'broadcast', label: '방송 출연' },
  { value: 'purchase', label: '공동구매' },
  { value: 'model', label: '광고 모델 섭외' },
  { value: 'others', label: '기타' },
]

const methodOptions = [
  { value: 'creator', label: '기획 및 제작까지 모두 유튜버에게 맡김' },
  { value: 'concept', label: '생각한 컨셉을 전달하고 콘텐츠 제작은 유튜버에게 맡김' },
  { value: 'planning', label: '광고주가 기획한 내용을 전달하고 콘텐츠 제작은 유튜버에게 맡김' },
  { value: 'others',  label: '기타' }
];

const LOADING_TEXT = "Loading...";

export default function CampaignModalList(props) {
  const { index, modalData, channelId, thumbnails, title, onCheckboxChange, setCampaignDataUpdated , description} = props;
  const [apiValue, setApiValue] = useState("");
  const [mails, setMails] = useState([]);
  const [resendMail, setResendMail] = useState([]);
  const [state, setState] = useState('');

  const [postMailDataFunc] = useMutation(postMailData);
  const [sendAdsProposalFunc] = useMutation(sendAdsProposal);

  const [channelDataUpdated, setChannelDataUpdated] = useState(false);

  const [getMail, { refetch, loading }] = useLazyQuery(getMailData, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      console.log(data)
      const emailData = data?.channel?.emails
      const emailList = [...emailData?.backoffice || [], ...emailData?.certification || [], ...emailData?.description || [], ...emailData?.defaultMail || []]
      if (emailData) {
        setMails(emailList);
        setResendMail(emailList);
      }
    },
    onError: (error) => {
      console.error(error);
    },
  });

  useEffect(() => {
    getMail({ fetchPolicy: 'network-only', variables: { id: channelId } })
  }, [getMail, channelId])

  useEffect(() => {
    const involved = modalData.involved_all.find((row) => row.involvedId === channelId);
    
    if (involved.eventHistory) {
      if (involved.eventHistory.length === 1) {
        setState(involved.eventHistory[0].eventType === 'FindEmail' ? 'FindEmail' : (involved.eventHistory[0].eventType === 'NoEmail' ? 'NoEmail' : 'sended'));
      } else {
        setState(involved.eventHistory[involved.eventHistory.length - 1].eventType === 'FindEmail' ? 'FindEmail' : (involved.eventHistory[involved.eventHistory.length - 1].eventType === 'fail' ? 'fail' : 'sended'));
      }
    }
  }, [modalData, channelId])

  useEffect(() => {
    if (channelDataUpdated) {
      setChannelDataUpdated(false);
      refetch();
    }


  }, [channelDataUpdated]);

  const handleApiRequestSend = async (channelId) => {
    const involvedId = modalData.involvedChannels.find((x) => x.channelId === channelId);
    const videoType = methodOptions.find((row) => row.value === modalData.videoType).label;
    const adTypeMsg = purposeOptions.find((row) => row.value === modalData.adType).label;

    if (involvedId.mails.length === 0) {
      if (mails.length > 0) involvedId.mails.push(mails[0]);
      else if (resendMail.length > 0) involvedId.mails.push(resendMail[0])
      else {
        alert('발송 대상 이메일이 없습니다.');
        return;
      }
    }

    const variables = {
      objectId: modalData.objectId,
      makerId: modalData.userId,
      makerName: modalData.makerId,
      title: modalData.title,
      description: modalData.desc,
      productName: modalData.productName,
      adType: modalData.adType,
      adTypeMsg: adTypeMsg,
      minBudget: numeral(modalData.minBudget).value() * 1,
      maxBudget: numeral(modalData.maxBudget).value() * 1,
      involvedIds: involvedId,
      isConsulting: modalData.isConsulting === "O",
      campaignType: "Target",
      step: 3,
      useQuota: 1,
      videoType,
      email: modalData.email,
      language: modalData.language,
    };

    if (variables && channelId && window.confirm(`재발송 하시겠습니까 ? (mail: ${involvedId?.mails[0]})`)) {
      try {
        await sendAdsProposalFunc({
          variables
        })
        alert('재발송 되었습니다.');
      } catch (e) {
        console.error('Error', e.message);
        alert('오류가 발생했습니다. 다시 시도해주세요.')
      }
    }

    setCampaignDataUpdated(true);
  };

  const onInputChange = async (channelId, value, refetch) => {
    if (value && window.confirm(`입력하신 메일정보(${value})가 맞습니까 ?`)) {
      const newMails = [value];
      try {
        await postMailDataFunc({
          variables: {
            channelId,
            mails: newMails
          }
        })
        alert(`이메일 ${value} 이 추가되었습니다.`);
        setResendMail([value]);
        setChannelDataUpdated(true);
      } catch (e) {
        console.error('Error', e.message);
        alert('오류가 발생했습니다. 다시 시도해주세요.')
      }
    } else {
      alert('업데이트 할 이메일을 입력하세요.');
    }
  }

  const handleUpdateClick = () => {
    onInputChange(channelId, apiValue);
    setApiValue('');
  }

  return (
    <div className="channel_container">
      {
        state === 'FindEmail' ? (
          <input className='check_box' type="checkbox" onChange={(event) => onCheckboxChange(event, channelId, thumbnails, title, description)} />
        ) : state === 'NoEmail' ? (
          <div className="check_state">발송불가</div>
        ) : (
          <div className="check_state">발송완료</div>
        )
      }
      <div className='title_container'>
        <div className='thumbnails_box'>
          <img className='thumbnails' src={`${thumbnails}`} alt="" />
        </div>
        <div className='info'>
          <div className="info_title">{`${title}`}</div>
          <div className="info_channelId">{`${channelId}`}</div>
        </div>
      </div>
      {
        loading ? (
          <p>{LOADING_TEXT}</p>
        ) :
          state === 'FindEmail' && mails.length === 0 ? (
            <div className="registered_mail">
              <div className="mail_title">등록된 이메일이 없습니다. 업데이트 바랍니다.</div>
              <div className="mail_data">
                <input
                  className="mail_input"
                  type="text"
                  value={apiValue}
                  onChange={(e) => setApiValue(e.target.value)}
                  placeholder="업데이트 할 email을 입력하세요."
                />
                <button className="mail_btn" onClick={handleUpdateClick}>Update</button>
              </div>
            </div>
          ) : (
            <div className="unregistered_mail">
              <div className="mail_title">등록된 이메일</div>
              {
                mails.map((x, index) => (
                  <div className="mail_data">{x}</div>
                ))
              }
            </div>
          )
      }
      {
        state === 'FindEmail' && (
          <div className='channel_container_btn'>
            <button className='btn' onClick={() => handleApiRequestSend(channelId)}>재발송</button>
          </div>
        )
      }
    </div>
  );
}