import { GraphQLClient, request } from 'graphql-request'
import Auth from '@aws-amplify/auth';
import { deleteOpenapiCookie } from '../utils/cookie';


var base_url = process.env.REACT_APP_BACKEND_HOST;
var grapql_base_url = process.env.REACT_APP_BACKEND_HOST + "/graphql";

class ApiService {

  /**
   * get Grapql 공통
   * @param {*} query 
   */
  async getGrapql(query) {
    try {
      deleteOpenapiCookie();
      let session = await Auth.currentSession();
      let header = {};
      if (session) header.Authorization = `Buzz ${session.getIdToken().getJwtToken()}#${session.getAccessToken().getJwtToken()}`;
      // console.log(header.Authorization);
      // console.log("getGraphql1", header);
      // console.log(1)
      // delete header.cookie;
      // console.log(2)
      // console.log("getGraphql2", header);
      const client = new GraphQLClient(grapql_base_url, {
        headers: header,
      });

      return new Promise((resolve, reject) => {
        client.request(query)
          .then(data => {
            // console.log(data);
            //성공
            if (!data.error) {
              resolve(data);
              //실패
            } else {
            }
          });
      });

    } catch (error) {
      // console.log(error);

      // Auth가 없을 경우
      return new Promise((resolve, reject) => {
        request(grapql_base_url, query)
          .then(data => {
            // console.log(data);
            //성공
            if (!data.error) {
              resolve(data);
              //실패
            } else {

            }
          })
      })
    }
  }



  /**
   * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   *                                     [ Member - ]
   * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   */

  /**
 * Cognic 사용자 정보를 가져옵니다.
 * 
 * @param {*} id 
 */
  getMember(cognic_id) {

    let parameter = `cognic_id: "${cognic_id}",`;

    let query = `mutation{
                    getMember (`+ parameter + `){
                        result	
                    }
                }`

    console.log(query);

    return this.getGrapql(query);
  }

  /**
   * @description 회원 이메일을 통해 cognic id를 가져옵니다.
   * @param {String} email 
   */
  getMemberIdByEmail(email) {
    let parameter = `email: "${email}",`;

    let query = `mutation{
                    getMemberIdByEmail (`+ parameter + `) {
                        result
                    }
                  }`

    console.log(query);

    return this.getGrapql(query);
  }


  /**
 * 
 * @param {*} id 
 */
  updateAdPrice(channels) {

    // let parameter = `channels: ${channels}`;
    let parameter = `channels: [ `;
    channels.forEach(ch => {
      parameter += '{ ';
      parameter += ' channelId: "' + ch.channelId + '"';
      parameter += ' ppl: ' + ch.ppl;
      parameter += ' branded: ' + ch.branded;
      parameter += ' }';
    });
    parameter += ` ]`;

    // channelId: "123", ppl: 1000, branded: 1000 }]`
    let query = `mutation{
                  updateAdPrice(`+ parameter + `)
                }`;
    console.log(query);

    return this.getGrapql(query);
  }



  /** 
  * 
  * @param {*} id 
  */
  getStats(nations, isHaveMail, isCertificated) {
    let parameter = "";


    if (nations && nations !== "ALL") parameter += `nations: "${nations}" `;
    if (isHaveMail) parameter += `isHaveMail: ${isHaveMail} `;
    if (isCertificated) parameter += `isCertificated: ${isCertificated} `;

    let query = `{
      totalStat
    }`;
    if (parameter) {
      query = `{
        totalStat(`+ parameter + `) 
      }`;
    }

    console.log(query);
    return this.getGrapql(query);
  }


  /**사용자 멤버쉽 만료일 변경 */
  updateMemberGrade(grade, startDate, expireDate, new_expire_at, userId) {
    let parameter = `grade: "${grade}",` +
      `startDate: ${startDate},` +
      `expireDate: ${expireDate},` +
      `new_expire_at: ${new_expire_at},` +
      `userId: "${userId}",`;

    let query = `mutation{
      updateMemberGrade (`+ parameter + `){
          result	
      }
    }`
    console.log(query);
    return this.getGrapql(query);
  }

  /**사용자 멤버쉽 추가 */
  insertMemberGrade(title, startDate, expireDate, grade, email, userId, memo) {
    let parameter = `title: "${title}",` +
      `startDate: ${startDate},` +
      `expireDate: ${expireDate},` +
      `grade: "${grade}",` +
      `email: "${email}",` +
      `userId: "${userId}",` +
      `memo: "${memo}",`;


    let query = `mutation{
      insertMemberGrade (`+ parameter + `){
        result	
      }
    }`
    console.log(query);
    return this.getGrapql(query);
  }



  /**
   * 전체 사용자 목록 정보를 가져옵니다.
   * 
   */
  getMembers(params) {
    // let parameter = `pageIndex: ${pageIndex},` +
    //   `pageSize: ${pageSize},` +
    //   `paginationToken : "${paginationToken}"`;

    let query = `mutation{
      getMembers {
        result	
      }
    }`;

    if (params.userIds && Array.isArray(params.userIds) && params.userIds.length > 0) {
      let parameter = `userIds : "${params.userIds}" `;
      query = `mutation{
        getMembers (`+ parameter + `){
          result	
        }
      }`;
    } else if (params.endDate) {
      let parameter = `endDate : ${params.endDate} `;
      query = `mutation{
        getMembers (`+ parameter + `){
          result	
        }
      }`;
    }

    console.log(query);
    return this.getGrapql(query);
  }



  /**
   * 고객 관리용 메일발송
   * @param {*} title 
   * @param {*} content 
   */
  backofficeSendMail(sender, receiver, title, content) {
    content = content.replace(/\"/g, "'");
    let query = `
            mutation{
              sendMail(type:"Backoffice", from: "${sender}" to:"${receiver}", title: "${title}", content: "${content}")
            }
          `;
    console.log(query);
    return this.getGrapql(query);
  }



  /**
   * 사용자 연혁 정보를 가져옵니다.
   * 
   * @param {*} id 
   */
  getMemberHistory(type, influencerId, channelId, pageIndex, pageSize) {

    let parameter = `type: "${type}",` +
      `channelId: "${channelId}",` +
      `influencerId: "${influencerId}",` +
      `pageIndex: ${pageIndex},` +
      `pageSize: ${pageSize}`;

    let query = `{
                memberHistorys(`+ parameter + `) {
                        _id,
                        type,
                        channel {
                            channelId,
                            subscriberCount,
                            viewCount,
                            title
                        },
                        influencerId
                        priceUnit {
                            requestReson
                            status
                            ppl
                            branded
                        }
                        reason,
                        regId,
                        regDate     
                    }
                }`


    console.log(query);

    return this.getGrapql(query);
  }



  /**
   * 광고단가 요청 한 목록을 가져옵니다.
   *   
   */
  getAdUnitPriceUsers() {

    let parameter = `type: "AdUnitPriceUser"`;

    let query = `{
              memberHistorys(`+ parameter + `) {
                      _id,
                      type,
                      channel {
                          channelId,
                          subscriberCount,
                          viewCount,
                          title
                      },
                      influencerId
                      priceUnit {
                          requestReson
                          status
                          ppl
                          branded
                      }
                      reason,
                      regId,
                      regDate     
                  }
              }`


    console.log(query);

    return this.getGrapql(query);
  }


  /**
   * 새로운 사용자 연혁을 만들 때
   * @param {*} args 
   */
  addMemberHistory(args) {

    let parameter = `historyId: "${args.historyId}",` +
      `type: "${args.type}" ,` +
      `channelId : "${args.channelId}",` +
      `channelTitle : "${args.channelTitle}",` +
      `subscriberCount : ${args.subscriberCount},` +
      `viewCount : ${args.viewCount},` +
      `influencerId : "${args.influencerId}",` +
      `status : "${args.status}",` +
      `ppl : ${args.ppl},` +
      `branded : ${args.branded},` +
      `reason: "${args.reason}",` +
      `regId: "${args.regId}",` +
      `regDate: "${args.regDate}"`;

    let query = `mutation {
                addMemberHistory (`+ parameter + `) {
                        _id,
                        type,
                        channel {
                            channelId,
                            subscriberCount,
                            viewCount,
                        },
                        influencerId
                        priceUnit {
                            requestReson
                            status
                            ppl
                            branded
                        }
                        reason,
                        regId,
                        regDate                             
                    }
                }`

    console.log(query);

    return this.getGrapql(query);
  }

  /**
   * 사용자 삭제
   * @param {*} user_id 
   * @returns 
   */
  deleteMemberAttribute(userId) {
    let parameter = `userId: "${userId}"`;
    let query = `mutation {
      deleteMemberAttribute(`+ parameter + `)
    }`;
    return this.getGrapql(query);
  }


  /**
   * 사용자 속성값 가져오기 
   * @param {*} channelId
   * @param {*} code
   */
  getMemberAttribute(cognic_id) {
    let parameter = `cognic_id: "${cognic_id}"`;
    let query = `{
                memberAttribute(`+ parameter + `) {
                    _id,
                    certification
                  }
              }`;

    return this.getGrapql(query);
  }

  /**
* 여러 사용자 속성값 배열로 가져오기 
* @param {*} channelId
* @param {*} code
*/
  getMemberAttributeArr(cognic_id) {
    let parameter = "cognic_id:" + JSON.stringify(cognic_id);
    // parameter = `cognic_id: ["801903e7-0722-40c1-a0fd-2edd87f2cd3b"]`;
    let query = `{
              memberAttributeArr(`+ parameter + `) {
                  _id,
                  certification
                }
            }`;
    // console.log(query);
    return this.getGrapql(query);
  }

  /**
   * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   *                                     [ Channels - ]
   * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   */

  /**
   * 신규인플루언서 추가 상태 목록
   * @param {*} channelId   
   */
  getAddNewChannels() {
    let query = `{newChannels}`
    return this.getGrapql(query);
  }

  /**
  * 신규인플루언서 추가
  * @param {*} channelId   
  */
  addNewChannels(channelIdArr) {
    let parameter = `channelIdArr: ${JSON.stringify(channelIdArr)}`;

    let query = `mutation {
                 addNewChannels (`+ parameter + `) 
                }`

    console.log(query);

    return this.getGrapql(query);
  }

  /**
   * 신규인플루언서 삭제
   */
  deleteNewChannels(channelIdArr) {
    let parameter = `channelIdArr: ${JSON.stringify(channelIdArr)}`;

    let query = `mutation {
                deleteNewChannels (`+ parameter + `) 
                }`

    console.log(query);

    return this.getGrapql(query);
  }

  /**
  * 디테일 정보 가져오기
  * @param {*} channelId   
  */
  getChannel(channelId, userId) {
    let parameter = `id: "${channelId}"` +
      `userId: "${userId}"`;

    let query = `{
                channel(`+ parameter + `) {
                    channelId
                    title
                    description
                    banner
                    activePercent
                    favorablePercent
                    subscriberCount
                    viewCount
                    dailyAverageViewCount
                    dailyViewCount
                    cpv
                    thumbnails
                    mails
                    mcn
                    minAdvertisingUnitPrice 
                    maxAdvertisingUnitPrice  
                    advertisingUnitPrice
                    subscriberChange
                    replyRatio
                    category
                    dailyViewCountSummary
                    advertisingUnitPriceSummary
                    favorablePercentSummary
                    activePercentSummary
                    isFavorite
                    links {
                        name
                        href
                    }
                    gender {
                        M
                        F
                        N
                    }
                    language {
                        lang
                        count
                    }
                    wordCount {
                        word
                        count
                    }
                    stat {
                        searchDate                        
                        dailyViewCount
                        subscriberCount
                        videoCount
                        favorablePercent
                        activePercent
                    }
                    video {
                        videoId
                        channelId
                        publishedAt
                        title
                        description
                        thumbnails
                        viewCount
                        likeCount
                        dislikeCount
                        commentCount
                    }
                    age {
                        min
                        max
                        percent
                    }
                    similarChannels {
                        channelId
                        title
                        description
                        thumbnails
                        dailyViewCount
                        subscriberCount
                    }
                  }
              }`

    //   console.log(query);

    return this.getGrapql(query);
  }

  /**
   * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   *                                     [ Hot Trend - ]
   * ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
   */

  getHottrendList() {
    let query = `{
                      trendData {
                        date
                        nation
                        trends {
                          id
                          keywords
                        }
                      }
                    }`

    console.log(query);
    return this.getGrapql(query);
  }

  getHottrendKeyword(params) {
    let parameter = `date: "${params.date}"`;

    let query = `{
                      trendData(` + parameter + `) {
                        trends {
                          id
                        }
                      }
                    }`

    //console.log(query);
    return this.getGrapql(query);
  }

  getHottrendHighligthDate() {
    let query = `{
                      trendData {
                        date
                      }
                    }`

    //console.log(query);
    return this.getGrapql(query);
  }

  getHottrendPreview(params) {
    let parameter = `keywords: ${params.keywords}`;

    let query = `{
                      trendDataPreview (` + parameter + `) {
                        videoId
                        thumbnails
                        title
                        viewCount
                        publishedAt
                      }
                    }`

    console.log(query);
    return this.getGrapql(query);
  }

  updateHottrend(params) {
    let parameter = `date: "${params.date}",` +
      `nation: "${params.nation}",` +
      `themeName: "${params.themeName}",` +
      `keywords: ${params.trends}`;

    let query = `mutation {
                    updateTrendData(` + parameter + `) {
                      date
                    }
                  }`

    console.log(query);
    return this.getGrapql(query);
  }

  deleteHottrend(params) {
    console.log(params);
    let parameter = `date: "${params.date}",` +
      `nation: "${params.nation}",` +
      `themeName: "${params.themeName}"`;

    let query = `mutation {
                    deleteTrendData(` + parameter + `) {
                      date
                    }
                  }`;

    console.log(query);
    return this.getGrapql(query);
  }

  // updateHottrendOnlyKeywods(params) {
  //   let parameter = `date: "${params.date}",`+
  //                   `nation: "${params.nation}",`+
  //                   `trends: ${params.trends}`;

  //   let query = `mutation {
  //                 updateTrendDataOnlyKeyword(` + parameter + `) {
  //                   date
  //                   nation
  //                   trends {
  //                     id
  //                     sortIndex
  //                     keywords
  //                   }
  //                 }
  //               }`

  //   console.log(query);
  //   return this.getGrapql(query);
  // }

  getCampaigns(params) {
    let size = (params && params.size) ? params.size : 500;
    if (size > 500) size = 500;

    let parameter = `size: ${size} `;

    if (params && params.startDate) parameter += `startDate: "${params.startDate}" `;
    if (params && params.endDate) parameter += `endDate: "${params.endDate}" `;

    let query = `{
        getCampaigns(` + parameter + `)
      }`

    console.log(query);
    return this.getGrapql(query);
  }

  getWriterList(params) {
    let parameter = `from: ${params.from}` +
      `,size: ${params.size}`;

    let query = `{
      getWriterList(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  updateWriter(params) {
    let parameter = "";

    if (params.objectId)
      parameter += `objectId: "${params.objectId}"`;
    if (params.type)
      parameter += `type: "${params.type}"`;
    if (params.newName)
      parameter += `newName: "${params.newName}"`;
    if (params.newThumbnail)
      parameter += `newThumbnail: "${params.newThumbnail}"`;

    let query = `mutation {
      updateWriter(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  getCategoryList(params) {
    let parameter = `from: ${params.from}` +
      `,size: ${params.size}`;

    let query = `{
      getCategoryList(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  updateCategory(params) {
    let parameter = "";

    if (params.objectId)
      parameter += `objectId: "${params.objectId}"`;
    if (params.type)
      parameter += `,type: "${params.type}"`;
    if (params.name)
      parameter += `,name: "${params.name}"`;
    if (params.order)
      parameter += `,order: ${params.order}`;
    if (params.isShow)
      parameter += `,isShow: ${params.isShow}`;
    if (params.language)
      parameter += `,language: "${params.language}"`
    if (params.key)
      parameter += `,key: "${params.key}"` 

    let query = `mutation {
      updateCategory(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  getPostList(params) {
    let parameter = `from: ${params.from}` +
      `size: ${params.size}`;

    let query = `{
      getPostList(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  updatePost(params) {
    let parameter = `type: "${params.type}"`;

    if (params.objectId)
      parameter += `,objectId: "${params.objectId}"`;
    if (params.url)
      parameter += `,url: "${params.url}"`;
    if (params.writer)
      parameter += `,writer: "${params.writer}"`;
    if (params.category)
      parameter += `,category: "${params.category}"`;
    if (params.recommend)
      parameter += `,recommend: ${params.recommend}`;
    if (params.title)
      parameter += `,title: "${params.title}"`;
    if (params.subTitle)
      parameter += `,subTitle: "${params.subTitle}"`;
    if (params.desc)
      parameter += `,desc: "${params.desc}"`;
    if (params.publishDate)
      parameter += `,publishDate: ${params.publishDate}`;
    if (params.tagList)
      parameter += `,tagList: ${params.tagList}`;
    if (params.writerThumbnail)
      parameter += `,writerThumbnail: "${params.writerThumbnail}"`;
    if (params.writerInfo)
      parameter += `,writerInfo: ${params.writerInfo}`;
    if (params.categoryInfo)
      parameter += `,categoryInfo: ${params.categoryInfo}`;

    let query = `mutation {
      updatePost(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  deletePost(params) {
    let parameter = `url: "${params.url}"`;

    let query = `mutation {
      deletePost(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }



  // enterprise
  getEnterpriseTypeList(params) {
    let parameter = `from: ${params.from}` +
      `size: ${params.size}`;

    let query = `{
      getEnterpriseType(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }


  getEnterpriseList(params) {
    let parameter = `from: ${params.from}` +
      `size: ${params.size}`;

    let query = `{
      getEnterprise(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }


  getEnterpriseSettings(params) {
    let parameter = `groupId: "${params.groupId}"`
    parameter += ` type: "${params.type}"`

    let query = `{
      getEnterpriseSettings(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  getEnterpriseHistories(params) {
    let parameter = `groupId: "${params.groupId}"`
    parameter += ` type: "${params.type}"`
    parameter += ` userIds: "${params.userIds}"`

    if (params.size) parameter += ` size: ${params.size}`

    let query = `{
      getEnterpriseHistories(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }



  getEnterpriseGroupById(groupId) {
    let query = `{
      getEnterpriseGroupById(groupId: "${groupId}")
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  manageEnterprise(params) {
    let parameter = `what: "${params.what}"` +
      `,title: "${params.title}"` +
      `,maxCount: ${params.maxCount}` +
      `,type: ${params.type}` +
      `,start_at: ${params.start_at}` +
      `,expire_at: ${params.expire_at}` +
      `,user: ${params.user}` +
      `,memo: "${params.memo}"`;

    if (params.groupId) parameter += `,groupId: "${params.groupId}"`;

    let query = `mutation {
      manageEnterprise(` + parameter + `)
    }`

    console.log(query);
    return this.getGrapql(query);
  }


  getUserIdByEmail(email) {
    let query = `{
      getUserIdByEmail(email: "${email}")
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  getMetaTagList() {
    let query = `{
      getMetaTagList
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  getMetaTag(params) {

    let parameter = `id: "${params.id}"`;

    let query = `{
      getMetaTag(${parameter})
    }`;

    console.log(query);
    return this.getGrapql(query);
  }

  insertMetaTag(params) {
    let parameter = `pageName : "${params.page_name}"`;

    if (params.url)
      parameter += `url : "${params.url}"`;
    if (params.title)
      parameter += `title : "${params.title}"`
    if (params.description)
      parameter += `description : "${params.description}"`
    if (params.site_name)
      parameter += `siteName : "${params.site_name}"`

    let query = `mutation{
      insertMetaTag(${parameter})
      {
        result
      }
    }`;

    console.log(query);
    return this.getGrapql(query);
  }

  updateMetaTag(params) {

    let parameter = `id : "${params.id}"`;
    parameter += `pageName : "${params.page_name}"`;
    if (params.url)
      parameter += `url : "${params.url}"`;
    if (params.title)
      parameter += `title : "${params.title}"`
    if (params.description)
      parameter += `description : "${params.description}"`
    if (params.site_name)
      parameter += `siteName : "${params.site_name}"`
    if (params.banner)
      parameter += `banner : "${params.banner}"`

    let query = `mutation{
      updateMetaTag(${parameter})
      {
        result
      }
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  deleteMetaTag(params) {
    let query = `mutation{
      deleteMetaTag(id : "${params.id}"){
        result
      }
    }`
    console.log(query);
    return this.getGrapql(query);
  }

  //국가 데이터 불러오기 
  getNations(param) {
    let query = `query{
        getNations(isShow : ${param})
      }`
    console.log(query);
    return this.getGrapql(query);
  }

  //대문 채널 불러오기 
  getMainChannels(params) {
    let parameter = `isChannelIds: ${params.isChannelIds}, nation: "${params.nation}"`;
    if (params.channelIds) {
      parameter += `channelIds: ${params.channelIds}`;
    }
    let query = `query{
        mainChannels(${parameter})
      }`
    console.log(query);
    return this.getGrapql(query);
  }

  //대문 채널 입력하기
  addMainChannels(params) {
    let parameter = `nation: "${params.nation}", type: "${params.type}", channelIds: ${params.channelIds}`;

    let query = `mutation{
        addMainChannels(${parameter})
      }`
    console.log(query);
    return this.getGrapql(query);
  }

  getCouponList() {
    let query = `query{
        getCouponList
      }`

    console.log(query);
    return this.getGrapql(query);
  }

  makeCoupon(params) {
    let parameter = `grade : "${params.grade}" 
      expiryDate : "${params.expiryDate}"
      code : "${params.code}"
      term : "${params.term}"
      description : "${params.description}"
      creator : "${params.creator}"
      `;

    let query = `mutation{
        makeCoupon(${parameter})
      }`

    console.log(query);
    return this.getGrapql(query);
  }

  deleteCoupon(params) {
    let parameter = `_id : "${params.id}"`;

    let query = `mutation{
        deleteCoupon(${parameter})
      }`

    console.log(query);
    return this.getGrapql(query);
  }

  editCoupon(params) {
    let parameter = `
    _id : "${params._id}"
    grade : "${params.grade}" 
    expiryDate : "${params.expiryDate}"
    code : "${params.code}"
    term : "${params.term}"
    description : "${params.description}"`;

    let query = `mutation{
      editCoupon(${parameter})
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  getAdvertiserList() {
    let query = `query{
        getAdvertiserList
    }`

    console.log(query);
    return this.getGrapql(query);
  }

  checkAdvertiser(params) {

    let confirmReason = "[";
    params.confirmReason.map(reason => {
      confirmReason += `{
        value : "${reason.value}"
        info : "${reason.info}"
      },
      `
    });
    confirmReason += "]"

    let parameter = `
    _id : "${params._id}"
    creator : "${params.creator}" 
    status : "${params.status}"
    `
    if (params.confirmReason.length > 0) {
      parameter += `confirmReason : ${confirmReason}`
    }

    let query = `mutation{
      checkAdvertiser(${parameter})
    }`;

    console.log(query);
    return this.getGrapql(query);
  }

  getAdvertiser(_id) {
    let query = `query{
      getAdvertiser(_id : "${_id}")
    }`

    console.log(query);
    return this.getGrapql(query);
  }


}


export default ApiService;