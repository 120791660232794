import React from "react";

import Menu from "./menu/Menu"

function Aside({}) {
  return (
      <>
        <aside id="left-panel" className="left-panel">
            <nav className="navbar navbar-expand-sm navbar-default">

                <div className="navbar-header">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-menu" aria-controls="main-menu" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa fa-bars"></i>
                    </button>
                    <a className="navbar-brand" href="./">VLING Back Office</a>
                    <a className="navbar-brand hidden" href="./">VLING Back Office</a>
                </div>


                {/* 메뉴 */}
                <Menu/>

            </nav>
        </aside>
      </>
   );
}


export default Aside;
