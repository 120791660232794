import React from "react";

import { Route } from "react-router-dom";
import HotTrendList from "../components/hottrend/HotTrendList";
import HotTrendDetail from "../components/hottrend/HotTrendDetail";

export default function HotTrend(props) {
  return (
    <>
      <Route exact path={props.match.path} component={HotTrendList} />
      <Route path={`${props.match.path}/:id`} component={HotTrendDetail} />
    </>
  )
}