import React, {Component} from "react";



import ApiService from '../../../services/ApiService';

export default class ChildMessageRenderer  extends Component {
    constructor(props) {
        super(props);
        console.log(props);
        this.api = new ApiService();    
    }

    requestComplate = async () => {

        if (window.confirm('승인하시겠습니까?')){
            let data = this.props.data;

            let args = {
                historyId : data.historyId,
                type : "AdUnitPriceUser",
                status : "complate",
                channelId : data.channelId,
                channelTitle : data.title,
                channelDescription : data.description,
                subscriberCount : data.subscriberCount,
                viewCount : data.viewCount,
                influencerId : data.influencerId , 
                ppl : data.ppl,
                branded : data.branded,
                reason : "승인되었습니다.",
                regId : data.regId,
                regDate : data.regDate,
            }

            let resDate = await this.api.addMemberHistory(args);

            if (resDate) {
                document.location.href = "requestAdPrice";
            }
        }
    }

    requestUnapproved = async () => {
        if (window.confirm('거부하시겠습니까?')){
            let data = this.props.data;

            let reason = document.getElementById("reason").value;

            if ( reason == null || reason == "" || reason == undefined) {
                alert("사유가 없습니다. 사유를 등록해주세요.");
            } else {
            let args = {
                historyId : data.historyId,
                type : "AdUnitPriceUser",
                status : "unapproved",
                channelId : data.channelId,
                channelTitle : data.title,
                channelDescription: data.description,
                subscriberCount : data.subscriberCount,
                viewCount : data.viewCount,
                influencerId : data.influencerId , 
                ppl : data.ppl,
                branded : data.branded,
                reason : reason,
                regId : data.regId,
                regDate : data.regDate,
            }

            let resDate = await this.api.addMemberHistory(args);

            if (resDate) {
                document.location.href = "requestAdPrice";
            }

         }
        }
    }

    _randerButton () {
        let data = this.props.data;
        console.log(data.status);
        if (data.status == "request") {
            return <>
                <span><button onClick={this.requestComplate}>승인</button></span>
                <span><button onClick={this.requestUnapproved}>거부</button></span>
            </>
        } else {
            return <></>
        }
    }
      
 
    render() {
        return (
            <>
                {this._randerButton()}
            </>
        );
    }
};


