import React from 'react';

import update from 'react-addons-update';
import ApiService from '../../services/ApiService';

class insightCategory extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      isLoading: true,
      data: []
    }
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    let params = {
      from: 0,
      size: 100
    };

    let resData = await this.api.getCategoryList(params);
    resData = resData ? (resData.getCategoryList ? resData.getCategoryList : []) : []

    this.setState({
      data: resData,
      isLoading: false
    });
  }

  onClickModify = (index) => {
    this.setState({
      data: update(
        this.state.data,
        {
          [index]: {
            isModify: { $set: true }
          }
        }
      )
    });
  }

  handleModify = async (objectId, index) => {
    let resultName = document.getElementById(`input-name|${index}`).value;
    let resultKey = document.getElementById(`input-key|${index}`).value;
    let resultOrder = document.getElementById(`input-order|${index}`).value;
    let radioShowsBtns = document.getElementsByName(`input-isShow|${index}`);
    let radioLanguageBtns = document.getElementsByName(`input-language|${index}`);
    let resultIsShow = null;
    let resultLanguage = null;

    

    for (let i = 0; i < radioShowsBtns.length; ++i) {
      if (radioShowsBtns[i].checked) {
        resultIsShow = radioShowsBtns[i].value;
        break;
      }
    }

    for (let i = 0; i < radioLanguageBtns.length; ++i) {
      
      if (radioLanguageBtns[i].checked) {
        resultLanguage = radioLanguageBtns[i].value;
        break;
      }
    }

    let params = {
      objectId: objectId,
      type: "edit",
      name: resultName,
      order: resultOrder,
      isShow: resultIsShow,
      language: resultLanguage,
      key: resultKey
    };

    let resData = await this.api.updateCategory(params);
    resData = resData ? (resData.updateCategory ? resData.updateCategory : null) : null;
    if (resData) {
      this.setState({
        data: update(
          this.state.data,
          {
            [index]: { $set: resData }
          }
        )
      })
    }
    else {
      this.setState({
        data: update(
          this.state.data,
          {
            [index]: {
              isModify: { $set: false }
            }
          }
        )
      });
    }
  }

  onClickAdd = async () => {
    let params = {
      type: "add"
    };

    let resData = await this.api.updateCategory(params);
    resData = resData ? (resData.updateCategory ? resData.updateCategory : null) : null;

    if (resData) {
      let tempData = [].concat(this.state.data);
      tempData.push(resData);

      this.setState({ data: tempData });
    }
  }

  _renderCategoryList() {
    return this.state.data.map((item, index) => {
      return <tr>
        <td>
          {
            item.isModify ?
            <input type="text" defaultValue={item.name} id={`input-name|${index}`} />
            :
            <>{item.name}</>
          }
        </td>
        <td>
          {
            item.isModify ?
            <input type="number" min={0} max={999} maxLength={3} defaultValue={item.order} id={`input-order|${index}`} />
            :
            <>{item.order}</>
          }
        </td>
        <td>
          {
            item.isModify ?
              <div>
                <input type="radio" name={`input-isShow|${index}`} defaultChecked={item.isShow} value={true} /><label>보이기</label>
                <input type="radio" name={`input-isShow|${index}`} defaultChecked={!item.isShow} value={false} /><label>숨기기</label>
              </div>
              :
              <>{item.isShow ? "O" : "X"}</>
          }
        </td>
        <td>
          {
            item.isModify ?
              <div>
                <input type="radio" name={`input-language|${index}`} defaultChecked={item.language} value={"ko"} /><label>ko</label>
                <input type="radio" name={`input-language|${index}`} defaultChecked={!item.language} value={"en"} /><label>en</label>
              </div>
              :
              <>{item.language === "ko" ? "ko" : "en"}</>
          }
        </td>
        <td>
          {
            item.isModify ?
            <input type="text" defaultValue={item.key} id={`input-key|${index}`} />
            :
            <>{item.key}</>
          }
        </td>
        <td>
          {
            item.isModify ?
              <button onClick={() => this.handleModify(item._id, index)}>저장</button>
              :
              <button onClick={() => this.onClickModify(index)}>변경</button>
          }
        </td>
      </tr>
    });
  }

  _renderData() {
    if (this.state.data.length > 0) {
      return <div class="page-content page-container" id="page-content">
        <div class="row container d-flex justify-content-center">
          <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">카테고리 관리</h4>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>이름</th>
                        <th>정렬순서</th>
                        <th>보이기</th>
                        <th>언어</th>
                        <th>key</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this._renderCategoryList()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    } else {
      return <div style={{ textAlign: "center" }}>데이터가 없습니다.</div>
    }
  }

  render() {
    if (!this.state.isLoading) {
      return <div className="container">
        <div style={{ display: "flex", width: "100%", padding: "0px 55px", justifyContent: "flex-end", marginBottom: 10 }}>
          <button onClick={this.onClickAdd}>추가</button>
        </div>
        {this._renderData()}
      </div>
    }
    else {
      return <div style={{ textAlign: "center" }}>로딩중...</div>
    }
  }
}

export default (insightCategory);