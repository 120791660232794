import React from "react";

import { Route } from "react-router-dom";
import GradePage from "../../components/membership/GradePage"

export default function grade(props) {
  return (
    <>
      <Route exact path={props.match.path} component={GradePage} />
    </>
  );
}
