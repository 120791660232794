// lib
import React from 'react';
import { Route } from 'react-router-dom';

// components
import Inventory from '../components/banner/Inventory';
import InventoryDetail from '../components/banner/Inventory/InventoryDetail';

export default function BannerPage({ match }) { // .../banner/...
  return (
    <>
      <Route exact path={`${match.path}/inventory`} render={props => <Inventory {...props} />} />
      <Route path={`${match.path}/inventory/:inventoryId`} render={props => <InventoryDetail {...props} />} />
    </>
  )
}
