import React from 'react';
import { Redirect } from "react-router-dom";
import Modal from "react-responsive-modal";
import Button from '@material-ui/core/Button';
import Auth from '@aws-amplify/auth';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import * as moment from 'moment';
import update from 'react-addons-update'
import UtilService from '../../../services/UtilService';
import ApiService from '../../../services/ApiService';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import QuotaRecord from './QuotaRecord';
import GradeRecord from './GradeRecord';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { deleteOpenapiCookie } from '../../../utils/cookie';


class MemberDetail extends React.Component {
  constructor(props) {
    super(props);
    this.util = new UtilService();
    this.api = new ApiService();
    this.onChangeDatepicker = this.onChangeDatepicker.bind(this);
    this.authData = null;
    this.memberId = this.props.match.params.id;

    this.state = {
      isRemoveModal: false,
      edit: false,
      redirect: null,
      averageHistroyOptions: {
        modules: AllCommunityModules,
        columnDefs: [
          { headerName: "변경일", field: "regDate" },
          { headerName: "채널명", field: "channelTitle" },
          { headerName: "기본 PPL", field: "ppl" },
          { headerName: "브랜디드", field: "branded" },
          { headerName: "구독자", field: "subscriberCount" },
          { headerName: "일일조회수", field: "viewCount" },
          { headerName: "사유", field: "reason" }
        ],
        defaultColDef: {
          editable: false,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          resizable: true,
          filter: true
        },
        rowSelection: "multiple",
        paginationPageSize: 10,
        paginationNumberFormatter: function (params) {
          return params.value.toLocaleString();
        },
        rowData: []
      },

      channelHistroyOptions: {
        modules: AllCommunityModules,
        columnDefs: [
          { headerName: "인증날짜", field: "reg_at" },
          { headerName: "분류", field: "type" },
          { headerName: "인증URL", field: "url" },
        ],
        defaultColDef: {
          editable: false,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          resizable: true,
          filter: true
        },
        rowSelection: "multiple",
        paginationPageSize: 10,
        paginationNumberFormatter: function (params) {
          return params.value.toLocaleString();
        },
        rowData: []
      },
      memberHistory: {
        channelId: "",
        channelTitle: "",
        pplBefore: "",
        brandedBefore: "",
      },
      channelList: [],
      email_verified: "",
      emailon: "",
      pushon: "",
      usertype: "",
      sub: "",
      lang: "",
      nickname: "",
      locale: "",
      email: "",
      reg_at: "",
      userGrade: "",
      beforeDate: new Date(),
      afterDate: new Date(),
      membership: [],
      selectValue: "premium",
      enterInput: "",
      expire_at: "",
      textValue: "",
      uniqueId: ""
    };
  }



  // ============================================================= Life cycle 

  componentDidMount() {
    //데이터 주입
    this.initialize();

  }



  // ============================================================= Component function


  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
    deleteOpenapiCookie();
    this.authData = await Auth.currentSession();
    //let memberResult = await this.api.getMemberAttribute(this.props.match.params.id);

    let getMemberResult = await this.api.getMember(this.props.match.params.id);
    // let email_verified = "";
    // let emailon = "";
    // let pushon = "";
    let email = "";
    // let sub = "";
    // let lang = "";
    let reg_at = "";
    let nickname = "";
    let usertype = "";
    let userGrade = "";
    let membership = [];
    let uniqueId = "";
    // let locale = "";

    let memberInfo = getMemberResult.getMember.result;
    let now = parseInt(new Date().valueOf() / 1000);

    memberInfo.map(member => {
      email = member.email;
      reg_at = moment(new Date(member.reg_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
      nickname = member.name;
      usertype = member.usertype;
      uniqueId = member._id;
      userGrade = "정회원"

      if (member.membership && Array.isArray(member.membership)) {
        member.membership.map(m => {

          if (m.start_at < now && m.expire_at > now) {
            if (m.grade === "enterprise") {
              userGrade = "엔터프라이즈";
            } else if (m.grade === "professional") {
              userGrade = "프로페셔널";
            } else if (m.grade === "premium" && !m.isExperience) {
              userGrade = "스타트업";
            } else if (m.grade === "premium") {
              userGrade = "스타트업(체험)"
            } else if (m.grade === "standard" && !m.isExperience) {
              userGrade = "스탠다드";
            } else if (m.grade === "standard") {
              userGrade = "스탠다드(체험)"
            }
          }
          m.start_at = parseInt(m.start_at * 1000);
          m.expire_at = parseInt(m.expire_at * 1000);
          return m
        })
      }

      membership = member.membership;
      return member;
    })

    /*
    let email = memberInfo.email; 
    let reg_at = memberInfo.reg_at; 
    let nickname = memberInfo.name; 
    let usertype =memberInfo.usertype; 
*/
    // if (getMemberResult.getMember) {
    //   getMemberResult.getMember.result.UserAttributes.map((item,index)=>{
    //     if (item.Name == "custom:emailon") {
    //       emailon = item.Value;
    //     } else if (item.Name == "custom:usertype") {
    //       usertype = item.Value;
    //     } else if (item.Name == "sub") {
    //       sub = item.Value;
    //     } else if (item.Name == "email_verified") {
    //       email_verified = item.Value;
    //     } else if (item.Name == "custom:pushon") {
    //       pushon = item.Value;
    //     } else if (item.Name == "custom:lang") {
    //       lang = item.Value;
    //     } else if (item.Name == "nickname") {
    //       nickname = item.Value;
    //     } else if (item.Name == "locale") {
    //       locale = item.Value;
    //     } else if (item.Name == "email") {
    //       email = item.Value;
    //     }
    //   })
    // }

    // let channelList = [];
    // if (memberResult && memberResult.memberAttribute && memberResult.memberAttribute.certification) {
    //   let certification = Object.keys(memberResult.memberAttribute.certification);
    //    channelList = await certification.map( async (item,index)=>{
    //         let resDate = await this.api.getChannel(item);
    //         return resDate.channel;
    //     });
    //}

    this.setState({
      // email_verified : email_verified,
      //   emailon : emailon,
      //   pushon : pushon,
      usertype: usertype,
      userGrade: userGrade,
      //   sub : sub,
      //   lang : lang,
      nickname: nickname,
      //   locale : locale,
      email: email,
      reg_at: reg_at,
      membership: membership,
      uniqueId: uniqueId,
      //   channelList : await Promise.all(channelList) 
    })

  }

  locationList = () => {
    this.setState({ redirect: "/member" });
  }

  editFormMember = () => {
    this.setState({ edit: true });
  }

  editMember = async () => {
    let title = this.state.enterInput
    let startDate = this.state.beforeDate;
    let expireDate = this.state.afterDate;
    let grade = this.state.selectValue;
    let userId = this.props.match.params.id;
    let email = this.state.email;
    let membership = this.state.membership;
    let memo = this.state.textValue;
    let confirm = true;

    if (grade === "enterprise" && (title === "" || title.length === 0)) {
      alert("title을 입력해주세요");
      return
    }

    startDate = parseInt(startDate.valueOf() / 1000);
    expireDate = parseInt(expireDate.valueOf() / 1000);

    //가지고 있는 등급 추가 불가능 
    if (membership || Array.isArray(membership)) {
      membership.map(m => {
        if (m.grade === grade) {
          alert("이미 가지고 있는 등급입니다.");
          confirm = false;
        }
        return m;
      })
    }
    if (confirm) {
      let insertGrade = await this.api.insertMemberGrade(title, startDate, expireDate, grade, email, userId, memo);
      confirm = true;
      alert("멤버쉽 추가 완료!");
    }
    this.dataInit();
  }

  cancelMember = () => {
    this.setState({
      edit: false,
      memberHistory: {
        channelId: "",
        pplBefore: "",
        brandedBefore: "",
      }
    });
  }

  handleChange = (event) => {
    this.setState({ selectValue: event.target.value });
  }

  handleInputChange = (event) => {
    this.setState({ enterInput: event.target.value });
  }

  handleTextChange = (event) => {
    this.setState({ textValue: event.target.value });
  }

  removeCheckMember = () => {
    this.setState({
      isRemoveModal: true
    });
  }
  onCloseModal = () => {
    this.setState({
      isRemoveModal: false
    });
  }

  removeMember = async () => {
    try {
      let res = await this.api.deleteMemberAttribute(this.memberId);
      console.log("res", res);
      alert("삭제 완료");
    } catch (err) {
      alert("삭제 실패");
      alert(JSON.stringify(err, 2, null));
    }

    this.setState({
      isRemoveModal: false,
      redirect: "/member"
    });

  }

  onChangeGradeStartDate = async (date) => {
    await this.setState({
      beforeDate: date
    })
  }

  onChangeGradeEndDate = async (date) => {
    await this.setState({
      afterDate: date
    })
  }

  onChangeDatepicker(month) {
    // let gradeStartDate = document.getElementById("gradeStratDate");
    // let startDateValue = gradeStartDate.value
    // if (!startDateValue) {
    //   alert("시작일을 입력해주세요.");
    // } else {
    //   let changeDateValue = moment(startDateValue).add(month, 'months');

    //   // state 값으로 연동
    //   document.getElementById("gradeEndDate").value = changeDateValue.format("YYYY-MM-DD");
    // }

    let changeDateValue = moment(this.state.beforeDate).add(month, 'months');

    this.setState({
      afterDate: changeDateValue
    });
  }

  onChangeAuthChannels = async (event) => {
    // let channelId = event.target.value;
    // let resData =  await this.api.getMemberHistory("AdUnitPriceAll" , this.memberId,  channelId , null , null);
    // let lastUnitHistory = resData.memberHistorys[0];

    // let channel = {};
    // this.state.channelList.map((item, index)=>{
    //   if (item.channelId == channelId) {
    //     channel = item;
    //   }
    // })

    // let pplBefore = 0;
    // let brandedBefore = 0;

    // if (lastUnitHistory) {
    //   pplBefore = lastUnitHistory.priceUnit.ppl;
    //   brandedBefore = lastUnitHistory.priceUnit.branded;
    // } else {
    //   pplBefore = channel.minAdvertisingUnitPrice;
    //   brandedBefore = channel.maxAdvertisingUnitPrice;
    // }

    // this.setState({
    //   memberHistory: {
    //       pplBefore : pplBefore,
    //       brandedBefore : brandedBefore,
    //       influencerId : this.props.match.params.id,
    //       channelId : channelId,
    //       channelTitle : channel.title,
    //       viewCount : channel.viewCount,
    //       subscriberCount : channel.subscriberCount,
    //   }
    // })
  }

  adUnitPriceAdd = async () => {

    let regId = this.authData.idToken.payload.email
    let ppl = document.getElementById("ppl").value;
    let branded = document.getElementById("branded").value;
    let reason = document.getElementById("reason").value;

    let args = {
      historyId: "",
      type: "AdUnitPriceAdmin",
      channelId: this.state.memberHistory.channelId,
      channelTitle: this.state.memberHistory.channelTitle,
      subscriberCount: this.state.memberHistory.subscriberCount,
      viewCount: this.state.memberHistory.viewCount,
      influencerId: this.state.memberHistory.influencerId,
      ppl: ppl ? ppl : 0,
      branded: branded ? branded : 0,
      reason: reason,
      regId: regId,
      regDate: moment().toISOString(),
    }
    let resData = await this.api.addMemberHistory(args);
    if (resData) {
      this.cancelMember();
    }
  }

  onAverageGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    let resData = await this.api.getMemberHistory("AdUnitPriceAll", this.memberId, "", null, null);
    if (resData.memberHistorys) {
      let memberHistroys = resData.memberHistorys.map((item) => {
        if (item.type === "AdUnitPriceAdmin" || (item.type === "AdUnitPriceUser" && item.priceUnit.status === "done")) {
          if (item.channel) {
            return {
              regDate: moment(item.regDate).format("YYYY-MM-DD HH:mm"),
              channelTitle: item.channel.title,
              ppl: item.priceUnit.ppl,
              branded: item.priceUnit.branded,
              subscriberCount: item.channel.subscriberCount,
              viewCount: item.channel.viewCount,
              reason: item.reason
            }
          }
        }
        return item;
      });

      let result = memberHistroys.filter((item) => {
        return item;
      })

      this.setState({
        averageHistroyOptions: update(
          this.state.averageHistroyOptions,
          {
            rowData: { $set: result }
          }
        )
      });
    }
  };

  onChannelGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    let memberResult = await this.api.getMemberAttribute(this.props.match.params.id);

    if (memberResult && memberResult.memberAttribute && memberResult.memberAttribute.certification) {
      let certification = memberResult.memberAttribute.certification;
      let keys = Object.keys(certification);
      let result = keys.map((key, index) => {
        return {
          reg_at: moment(certification[key].reg_at * 1000).format("YYYY-MM-DD hh:mm"),
          type: certification[key].type,
          url: "https://www.vling.net/channel/" + certification[key].target_id,
        };

      });

      this.setState({
        channelHistroyOptions: update(
          this.state.channelHistroyOptions,
          {
            rowData: { $set: result }
          }
        )
      });

    }

  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  };

  changeExpireDate = async (expireDate, grade, startDate, event) => {

    startDate = parseInt(new Date(startDate).valueOf() / 1000);
    let old_expireDate = parseInt(new Date(expireDate).valueOf() / 1000);
    let new_expire_at = parseInt(event.valueOf() / 1000);

    let getMemberResult = await this.api.updateMemberGrade(grade, startDate, old_expireDate, new_expire_at, this.props.match.params.id);
    if (getMemberResult) {
      alert("만료일 수정이 완료 되었습니다.");
      this.dataInit();
    }
  }

  expireDateChangeCalendar = (grade, start_at, expire_at) => {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDateTimePicker
          locale="ko"
          label="만료일 변경"
          margin="normal"
          id={expire_at + "_expireDate"}
          key="expireDate"
          format="yyyy-MM-dd hh:mm"
          selected={new Date()}
          placeholderText="만료일 변경"
          value={expire_at}
          disabledKeyboardNavigation
          onChange={this.changeExpireDate.bind(this, expire_at, grade, start_at)}
        />
      </MuiPickersUtilsProvider>
    )
  }

  userMembershipRecord = () => {
    let membership = this.state.membership;
    let grade = "";

    if (membership && Array.isArray(membership)) {
      return membership.map(m => {
        if (m.grade === "enterprise") {
          grade = "엔터프라이즈"
        } else if (m.grade === "professional") {
          grade = "프로페셔널"
        } else if (m.grade === "premium") {
          grade = "스타트업"
        } else if (m.grade === "standard") {
          grade = "스탠다드"
        }

        return (
          <tr>
            <td className="member-change-expiredate-table-data">{this.state.userGrade === grade ? <img alt="star" src="/images/star.png" width="20px"></img> : ""}</td>
            <td className="member-change-expiredate-table-data">{grade}</td>
            <td className="member-change-expiredate-table-data">{moment(new Date(m.start_at)).format('YYYY-MM-DD hh:mm')}</td>
            <td>
              {this.expireDateChangeCalendar(m.grade, m.start_at, m.expire_at)}
            </td>
            <td className="member-change-expiredate-table-data">{m.memo ? m.memo : ""}</td>
          </tr>
        )
      })
    }
  }

  _randerContent() {
    if (this.state.edit) {
      return (
        <>
          <div className="row">
            <div className="col-lg-9">
              <div>회원관리  회원수정</div>
            </div>
            <div className="col-lg-3">
            </div>
          </div>

          <div className="section-line"></div>

          <div className="row member-detail-data-wrap">
            <div className="col-lg-2">
              <label className="member-detail-data-title">가입일</label>
              <div>{this.state.reg_at}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">등급</label>
              <div>{this.state.userGrade} </div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">회원구분</label>
              <div>{this.state.usertype}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">이메일 (계정)</label>
              <div>{this.state.email}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">닉네임</label>
              <div>{this.state.nickname}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">아이디</label>
              <div>{this.state.uniqueId}</div>
            </div>
          </div>

          <div className="section-line"></div>
          <hr color="black" size="4"></hr><br />
          <div className="row">
            <div className="col-lg-2">
              <div><b>추가등급</b></div>
            </div>
            <div className="col-lg-4">
              <InputLabel id="member-grade-select-label">등급</InputLabel>
              <select value={this.state.selectValue} onChange={this.handleChange}>
                <option value="standard" >스탠다드</option>
                <option value="premium" >스타트업</option>
                <option value="professional">프로페셔널</option>
                {/* <option value="enterprise">엔터프라이즈</option> */}
              </select>
              <span>  </span>
              {/* {this.state.selectValue === "enterprise" ?
                <input type="text" placeholder="title 입력란" value={this.state.enterInput} onChange={this.handleInputChange} /> : <></>} */}
            </div>

          </div>
          <div className="row">
            <div className="col-lg-2">
              <label>기간</label>
            </div>
            <div className="col-lg-4">
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  margin="normal"
                  id="gradeStratDate"
                  key="gradeStratDate"
                  label="시작일"
                  format="yyyy-MM-dd hh:mm"
                  autoOk={true}
                  value={this.state.beforeDate}
                  minDate={new Date()}
                  maxDate={new Date()}
                  onChange={this.onChangeGradeStartDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-lg-4">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="gradeEndDate"
                  key="gradeEndDate"
                  label="종료일"
                  format="yyyy-MM-dd hh:mm"
                  minDate={this.state.beforeDate}
                  value={this.state.afterDate}
                  autoOk={true}
                  //   value={selectedDate}
                  onChange={this.onChangeGradeEndDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }} />
              </MuiPickersUtilsProvider>
            </div>
            <div className="col-lg-2">
              <button id="oneMonthBtn" onClick={() => this.onChangeDatepicker(1)}>1개월</button>
              <button id="threeMonthBtn" onClick={() => this.onChangeDatepicker(3)}>3개월</button>
              <button id="sixMonthBtn" onClick={() => this.onChangeDatepicker(6)}>6개월</button>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <div>메모</div>
            </div>
            <div className="col-lg-10">
              <TextField
                variant="outlined"
                id="standard-start-adornment"
                value={this.state.textValue}
                onChange={this.handleTextChange}
                fullWidth={true}
                multiline
                rows={10}
                rowsMax={10}
              />
            </div>
          </div>

          <div className="row member-detail-action-btn-area">
            <div className="col-lg-12">
              <button onClick={this.editMember}>추가</button>
              <button onClick={this.cancelMember}>취소</button>
            </div>
          </div>

          <div className="section-line"></div>


          {/**함수로 처리 */}
          <div style={{ marginTop: "50px" }}>
            <div className="row">
              <div className="col-lg-2">
                <div>멤버쉽 이력</div>
              </div>
              <table>
                <tr>
                  <td className="member-change-expiredate-table">현재 사용 멤버쉽</td>
                  <td className="member-change-expiredate-table">멤버쉽 종류</td>
                  <td className="member-change-expiredate-table">시작 일자 </td>
                  <td className="member-change-expiredate-table">만료일 변경</td>
                  <td className="member-change-expiredate-table">메모 정보</td>
                </tr>
                {this.userMembershipRecord()}
              </table>
            </div>
          </div>


          <div className={this.state.memberHistory.channelId === "" ? "display-none " : "row"}>
            <div className="col-lg-2" >
              <label>기본 PPL (현재가)</label>
            </div>
            <div className="col-lg-4">
              <Input placeholder="Placeholder" variant="outlined" value={this.state.memberHistory.pplBefore} />
            </div>
            <div className="col-lg-2">
              <label>브랜디드 (현재가)</label>
            </div>
            <div className="col-lg-4">
              <Input placeholder="Placeholder" variant="outlined" value={this.state.memberHistory.brandedBefore} />
            </div>
          </div>

          <div className={this.state.memberHistory.channelId === "" ? "display-none " : "row"}>
            <div className="col-lg-2">
              <label>기본 PPL (변경)</label>
            </div>
            <div className="col-lg-4">
              <Input placeholder="Placeholder" variant="outlined" id="ppl" />
            </div>
            <div className="col-lg-2">
              <label>브랜디드 (변경)</label>
            </div>
            <div className="col-lg-4">
              <Input placeholder="Placeholder" variant="outlined" id="branded" />
            </div>
          </div>
          <div className={this.state.memberHistory.channelId === "" ? "display-none " : "row"}>
            <div className="col-lg-2">
              <label>메모</label>
            </div>
            <div className="col-lg-10">
              <TextField
                variant="outlined"
                id="reason"
                fullWidth={true}
                multiline
                rows={10}
                rowsMax={10}
              />

            </div>
          </div>

          {/* <div className="row member-detail-action-btn-area">
                      <div className="col-lg-12">
                          <button onClick={this.adUnitPriceAdd}>변경</button>
                          <button onClick={this.cancelMember}>취소</button>
                      </div>
                  </div> */}

        </>
      )
    } else {
      return (
        <>
          <div className="row">
            <div className="col-lg-9">
              <div>회원관리  회원정보</div>
            </div>
            <div className="col-lg-3">
              <div className="content-top-action-btn">
                <button onClick={this.editFormMember}>수정</button>
                <button onClick={this.removeCheckMember}>삭제</button>
                <button onClick={this.locationList}>목록</button>
              </div>
            </div>
          </div>

          <div className="section-line"></div>

          <div className="row member-detail-data-wrap">
            <div className="col-lg-2">
              <label className="member-detail-data-title">가입일</label>
              <div>{this.state.reg_at}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">등급</label>
              <div>{this.state.userGrade}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">회원구분</label>
              <div>{this.state.usertype}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">이메일 (계정)</label>
              <div>{this.state.email}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">닉네임</label>
              <div>{this.state.nickname}</div>
            </div>
            <div className="col-lg-2">
              <label className="member-detail-data-title">아이디</label>
              <div>{this.state.uniqueId}</div>
            </div>
          </div>

          <div className="section-line"></div>

          {/* <QuotaRecord uniqueId={this.state.uniqueId} userGrade={this.state.userGrade} membership={this.state.membership}/> */}

          <GradeRecord membership={this.state.membership} />

          <div className="member-detail-history-wrap">
            <div className="member-detail-history-title">평균 광고 단가 변경 이력</div>
            <div className="ag-theme-balham" style={{ height: "175px", width: "100%" }}>
              <AgGridReact
                modules={this.state.averageHistroyOptions.modules}
                columnDefs={this.state.averageHistroyOptions.columnDefs}
                autoGroupColumnDef={this.state.averageHistroyOptions.autoGroupColumnDef}
                defaultColDef={this.state.averageHistroyOptions.defaultColDef}
                // debug={true}
                rowSelection={this.state.averageHistroyOptions.rowSelection}
                onGridReady={this.onAverageGridReady}
                enableCellTextSelection={true}
                rowData={this.state.averageHistroyOptions.rowData}>
              </AgGridReact>
            </div>
          </div>

          <div className="member-detail-history-wrap">
            <div className="member-detail-history-title">채널 인증 정보</div>
            <div className="ag-theme-balham" style={{ height: "175px", width: "100%" }}>
              <AgGridReact
                modules={this.state.channelHistroyOptions.modules}
                columnDefs={this.state.channelHistroyOptions.columnDefs}
                autoGroupColumnDef={this.state.channelHistroyOptions.autoGroupColumnDef}
                defaultColDef={this.state.channelHistroyOptions.defaultColDef}
                // debug={true}
                rowSelection={this.state.channelHistroyOptions.rowSelection}
                onGridReady={this.onChannelGridReady}
                enableCellTextSelection={true}
                rowData={this.state.channelHistroyOptions.rowData}>
              </AgGridReact>
            </div>
          </div>
        </>
      )
    }
  }

  // ============================================================= Component Render

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect} />
    }

    return (
      <>
        {this._randerContent()}
        <Modal
          styles={{
            modal: {
              width: '500px',
              height: "auto",
              background: "#ffffff",
              borderRadius: '5px',
              alignSelf: "center",
              fontFamily: "Noto Sans KR"
            }
          }}
          open={this.state.isRemoveModal}
          onClose={this.onCloseModal}
          center={true}>
          <div>
            <h5>진짜 삭제</h5>
            <hr />
            <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
              <Button variant="contained" onClick={(e) => this.removeMember()} color="primary" style={{ padding: "10px 35px" }}>
                진짜 삭제
              </Button>
              <Button onClick={this.onCloseModal} variant="contained" style={{ padding: "10px 35px" }}>
                취소
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }



}

export default (MemberDetail);