import React from 'react';
import { Redirect } from "react-router-dom";
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { ToastContainer, toast } from 'react-toastify';
import ApiService from '../../services/ApiService';
import Styles from './enterpriseDetail.module.css';
import { AgGridReact } from 'ag-grid-react';
// import { Iot } from 'aws-sdk';

class enterpriseDetail extends React.Component {
  constructor(props) {
    super(props);
    this.api = new ApiService();
    let path = window.location.pathname.replace("/enterprise/detail/", "").split("/");
    this.groupId = path[0];
    this.tab = (path && path[1])? path[1]: "default";

    this.isEditing = false;
    // if(this.groupId === "new") this.isEditing = true;

    this.state = {
      tab: this.tab,
      keywords: [], // 클라우드랭킹, 영상큐레이션
      groups: [], // 이지트래커
      histories: [], // 사용이력(현재 클라우드랭킹 대시보드 조회이력만)
      isGroupsGet: false,
      isKeywordsGet: false,
      isEditing: false,
      // isLoading: false,
      // isLoadingAddMember: false,
      // titleValue: this.defaultState ? (this.defaultState.title || null) : null,
      // maxCountValue: this.defaultState ? (this.defaultState.maxCount || 10) : 10,
      // typeValue: this.defaultState && this.defaultState.type ? this.defaultState.type._id : this.defaultType._id,
      // start_at: this.defaultState ? this.defaultState.start_at : Math.floor(new Date().valueOf() / 1000),
      // expire_at: this.defaultState ? this.defaultState.expire_at : Math.floor(new Date().valueOf() / 1000),
      // user: this.defaultState ? this.defaultState.user : []
      isLoading: true,
      isLoadingAddMember: false,
      titleValue: "",
      maxCountValue: 10,
      typeValue: [],
      start_at: Math.floor(new Date().valueOf() / 1000),
      expire_at: Math.floor(new Date().valueOf() / 1000),
      user: [],
      typeData: [],
      // enterpriseSettings: [],
      memoValue: "",
      redirect: null
    }
  }

  componentDidMount() {
    this.initInfo();
    // if (this.groupId !== "new") this.initSetting();
  }

  initInfo = async () => {
    if(!this.state.isLoading) this.setState({ isLoading: true });

    let params = {
      from: 0,
      size: 100
    };
    let resTypeData = await this.api.getEnterpriseTypeList(params);
    resTypeData = resTypeData ? (resTypeData.getEnterpriseType || []) : [];

    if (this.groupId !== "new") {
      let resData = await this.api.getEnterpriseGroupById(this.groupId);
      resData = resData ? (resData.getEnterpriseGroupById || null) : null;

      if (resData) {
        let _titleValue = resData.title;
        let _maxCountValue = resData.maxCount || 10;
        let _typeValue = resData.type || [];
        let _start_at = resData.start_at;
        let _expire_at = resData.expire_at;
        let _user = resData.user;
        let _memoValue = resData.memo;
        if (typeof _memoValue === "string") {
          _memoValue = _memoValue.replace(/<br>/g, "\n");
        }

        this.setState({
          isLoading: false,
          isEditing: false,
          typeData: resTypeData,
          titleValue: _titleValue,
          maxCountValue: _maxCountValue,
          typeValue: _typeValue,
          start_at: _start_at,
          expire_at: _expire_at,
          user: _user,
          memoValue: _memoValue
        });
      } else {
        alert("잘못된 접근입니다.");
        this.setState({ redirect: "/enterprise/list" });
      }
    } else {
      this.setState({
        typeData: resTypeData,
        isLoading: false,
        isEditing: true,
      });
    }
  }

  // initSetting = async () => {
  //   let settings = await this.api.getEnterpriseSettings(this.groupId);
  //   let enterpriseSettings = (settings && settings.getEnterpriseSettings) ? settings.getEnterpriseSettings : [];
  //   // console.log(getEnterpriseSettings);
  //   this.setState({
  //     enterpriseSettings
  //   });
  // }

  setToast = (str) => {
    toast.success(str, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false
    });
  };

  onClickEdit = async () => {
    this.setState({ isEditing: true });
  }

  onClickList = async () => {
    this.setState({ redirect: "/enterprise/list" });
  }

  onClickCancel = async () => {
    this.initInfo();
    // state 초기화 로직 추가 필요
    // this.setState({ isEditing: false });
  }

  onClickSave = async () => {
    if (!this.state.titleValue || this.state.titleValue === "") {
      alert("이름을 입력해주세요.");
      return;
    }

    if (!this.state.maxCountValue || typeof this.state.maxCountValue !== "number") {
      alert("인원제한을 설정해주세요.")
      return;
    }

    if (!Array.isArray(this.state.user) || this.state.user.length === 0) {
      alert("멤버를 추가해주세요.");
      return;
    }

    if (this.state.user.length > this.state.maxCountValue) {
      alert("제한 인원수는 멤버의 수보다 작을 수 없습니다.");
      return;
    }

    if (!Array.isArray(this.state.typeValue) || this.state.typeValue.length === 0) {
      alert("타입을 설정해주세요.");
      return;
    }


    if (!this.state.isLoading)
      this.setState({ isLoading: true });

    let params = {
      groupId: this.groupId !== "new" ? this.groupId : null,
      what: this.groupId !== "new" ? "edit" : "add",
      title: this.state.titleValue,
      maxCount: this.state.maxCountValue,
      type: JSON.stringify(JSON.stringify(this.state.typeValue)),
      start_at: this.state.start_at,
      expire_at: this.state.expire_at,
      user: JSON.stringify(JSON.stringify(this.state.user)),
      memo: this.state.memoValue ? this.state.memoValue.replace(/\n/g, "<br>") : ""
    }

    let resData = await this.api.manageEnterprise(params);
    if (resData && resData.manageEnterprise.result && resData.manageEnterprise.data) {
      this.setToast("정상적으로 반영되었습니다.");
    } else {
      this.setToast("반영에 실패하였습니다.");
    }

    this.setState({ isLoading: false, isEditing: false });
    if(this.groupId === "new") {
      this.setState({ redirect: "/enterprise/list" });
    }
  }

  onChangeMaxCount = (event) => {
    let result = Number(event.target.value);

    if (result < this.state.user.length) {
      alert("제한 인원수는 멤버의 수보다 작을 수 없습니다.");
      return;
    }

    this.setState({ maxCountValue: result });
  }

  onChangeStartDate = (date) => {
    let result = Math.floor(date.valueOf() / 1000);
    this.setState({ start_at: result });
  }

  onChangeExpireDate = (date) => {
    let result = Math.floor(date.valueOf() / 1000);
    this.setState({ expire_at: result });
  }

  onClickMasterMember = (_userId) => {
    let result = [].concat(this.state.user);

    result.map(member => {
      if (member.userId === _userId)
        return member.isMaster = true;
      return member.isMaster = false;
    });

    this.setState({ user: result });
  }

  onClickAuthMember = (_userId) => {
    let result = [].concat(this.state.user);

    result.map(member => {
      if (member.userId === _userId)
        return member.isAuth = !member.isAuth
    });

    this.setState({ user: result });
  }

  onClickRemoveMember = (userId) => {
    this.setState({ user: this.state.user.filter(i => i.userId !== userId) });
  }

  onClickAddMember = async () => {
    let addInputElement = document.getElementById("enterprise-member-add");
    if (/.+@.+\.[A-Za-z]+$/.test(addInputElement.value) === false) {
      alert("잘못된 이메일 형식입니다.");
      return;
    }

    if (Array.isArray(this.state.user) && this.state.user.filter(item => item.email === addInputElement.value).length > 0) {
      alert("이미 추가된 멤버입니다.");
      return;
    }

    if (this.state.user.length >= this.state.maxCountValue) {
      alert("제한 인원수를 초과하였습니다.");
      return;
    }

    this.setState({ isLoadingAddMember: true });

    let resData = await this.api.getUserIdByEmail(addInputElement.value);
    if (resData.getUserIdByEmail.userId) {
      let result = [].concat(this.state.user);
      result.push({
        userId: resData.getUserIdByEmail.userId,
        email: addInputElement.value,
        isMaster: this.state.user.length === 0 ? true : false,
        isAuth: false
      });

      this.setState({
        isLoadingAddMember: false,
        user: result
      });
    }
    else {
      alert("존재하지 않는 유저입니다.");
      this.setState({ isLoadingAddMember: false });
    }
  }

  isCheckType = (objectId) => {
    if (Array.isArray(this.state.typeValue)) {
      if (this.state.typeValue.filter(i => i._id === objectId).length > 0) {
        return true;
      }
    }

    return false;
  }

  onCheckedType = (event, item) => {
    let isChecked = event.target.checked;
    let result = this.state.typeValue;
    if (!result) result = [];

    if (isChecked) {
      let insertValue = {
        _id: item._id,
        label: item.label,
        value: item.value
      };

      result.push(insertValue);
      this.setState({ typeValue: result });
    } else {
      this.setState({
        typeValue: result.filter(i => i._id !== item._id)
      });
    }
  }

  onClickChangeTab = (tab) => {
    this.setState({ tab });
  }
 
  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={{ pathname: this.state.redirect }} />
    }

    let tab = this.state.tab;
    let _renderBody = () => {};
    if(tab === "cloudRanking") _renderBody = this._renderCloudRanking();
    else if(tab === "easyTracker") _renderBody = this._renderEasyTracker();
    else _renderBody = this._renderDefault();

    if (!this.state.isLoading) {
      return <>
        <div className="enterprise-input-wrap">
          <button onClick={this.onClickList}>목록 돌아가기</button>
        </div>
        {(this.groupId !== "new")?
          <div className={Styles.title}>
            <button className={ tab === "default"? Styles.selected: Styles.unselected } onClick={() => this.setState({ tab: "default"})}>기본정보</button>
            <button className={ tab === "cloudRanking"? Styles.selected: Styles.unselected } onClick={() => this.setState({ tab: "cloudRanking"})}>클라우드랭킹/영상큐레이션</button>
            <button className={ tab === "easyTracker"? Styles.selected: Styles.unselected } onClick={() => this.setState({ tab: "easyTracker"})}>이지트래커</button>
          </div>
          :
          <></>
        }

        {_renderBody}
        
        <ToastContainer
          position="top-right"
          autoClose={30000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange={false}
          draggable={false}
          pauseOnHover={false}
        />
      </>
    } else {
      return <div style={{ width: "100%", textAlign: "center" }}>로딩중...</div>
    }
  }

  _renderDefault() {
    return <div className="enterprise_container">
      {this.state.isEditing ?
        <div style={{ display: "flex", width: "100%", padding: "0px 55px", justifyContent: "flex-end", marginBottom: 10 }}>
          <button onClick={this.onClickCancel}>취소</button>
          <button onClick={this.onClickSave}>저장</button>
        </div>
        :
        <div style={{ display: "flex", width: "100%", padding: "0px 55px", justifyContent: "flex-end", marginBottom: 10 }}>
          <button onClick={this.onClickEdit}>편집</button>
        </div>
      }
      <div className="enterprise-input-wrap">
        <span className="enterprise-input-title">
          이름
          </span>
        {this.state.isEditing ?
          <input type="text" className="enterprise-input" value={this.state.titleValue} id="enterprise-title" onChange={(e) => this.setState({ titleValue: e.target.value })} />
          :
          this.state.titleValue
        }
      </div>
      <div className="enterprise-input-wrap">
        <span className="enterprise-input-title">
          인원 제한
          </span>
        {this.state.isEditing ?
          <select value={this.state.maxCountValue} onChange={this.onChangeMaxCount} style={{ width: 100, height: 30 }}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
          :
          this.state.maxCountValue
        }
      </div>
      <div className="enterprise-input-wrap">
        <span className="enterprise-input-title">
          타입
          </span>

        <div className="enterprise-input">
          {this.state.isEditing ?
            this.state.typeData.map((item, index) => {
              return <div style={{ display: "flex", alignItems: "center" }}>
                <input type="checkbox" id={`checkbox-type|${index}`} defaultChecked={this.isCheckType(item._id)} onChange={(e) => this.onCheckedType(e, item)} />
                <label style={{ marginLeft: 10, marginBottom: 0 }} for={`checkbox-type|${index}`}>
                  {item.label}
                </label>
              </div>
            })
            :
            this.state.typeData.map((item, index) => {
              if (this.isCheckType(item._id)) {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" id={`checkbox-type|${index}`} defaultChecked disabled />
                    <label style={{ marginLeft: 10, marginBottom: 0 }} for={`checkbox-type|${index}`}>
                      {item.label}
                    </label>
                  </div>
                )
              } else {
                return (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input type="checkbox" id={`checkbox-type|${index}`} disabled />
                    <label style={{ marginLeft: 10, marginBottom: 0, color: "#aaaaaa" }} for={`checkbox-type|${index}`}>
                      {item.label}
                    </label>
                  </div>
                )
              }
            })
          }
        </div>
      </div>
      <div className="enterprise-input-wrap">
        <span className="enterprise-input-title">
          시작일(KST)
          </span>
        <div className="enterprise-input" style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 190 }}>
            {this.state.isEditing ?
              <DatePicker
                selected={new Date(this.state.start_at * 1000)}
                onChange={date => this.onChangeStartDate(date)}
                dateFormat="yyyy/MM/dd hh:mm aa"
                showTimeInput
                maxDate={new Date(this.state.expire_at * 1000)}
                onFocus={(e) => e.target.readOnly = true}
              />
              :
              moment(this.state.start_at * 1000).locale('en').format('YYYY-MM-DD hh:mm A')
            }
          </div>
          <span style={{ marginLeft: 10, color: "red", fontSize: 14 }}>
            백오피스 설정은 KST입니다. 블링은 UTC (-9시간)
            </span>
        </div>
      </div>
      <div className="enterprise-input-wrap">
        <span className="enterprise-input-title">
          종료일(KST)
          </span>
        <div className="enterprise-input" style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: 190 }}>
            {this.state.isEditing ?
              <DatePicker
                selected={new Date(this.state.expire_at * 1000)}
                onChange={date => this.onChangeExpireDate(date)}
                dateFormat="yyyy/MM/dd hh:mm aa"
                showTimeInput
                minDate={new Date(this.state.start_at * 1000)}
                onFocus={(e) => e.target.readOnly = true}
              />
              :
              moment(this.state.expire_at * 1000).locale('en').format('YYYY-MM-DD hh:mm A')
            }
          </div>
          <span style={{ marginLeft: 10, color: "red", fontSize: 14 }}>
            백오피스 설정은 KST입니다. 블링은 UTC (-9시간)
            </span>
        </div>
      </div>
      <div className="enterprise-input-wrap">
        <span className="enterprise-input-title">
          멤버
          </span>
        <div className="enterprise-input">
          <ul style={{ marginBottom: 0 }}>
            {this.state.isEditing ?
              this.state.user.map(item => {
                return <li className="enterprise-member-item">
                  <div className="enterprise-member-email-wrap">
                    {item.email}
                  </div>
                  <div className="enterprise-member-action-button-wrap">
                    <button className={item.isAuth ? "enterprise-member-action-button-master selected" : "enterprise-member-action-button-master"}
                      onClick={() => this.onClickAuthMember(item.userId)}>A</button>
                    <button className={item.isMaster ? "enterprise-member-action-button-master selected" : "enterprise-member-action-button-master"}
                      onClick={() => this.onClickMasterMember(item.userId)}>M</button>
                    <button onClick={() => this.onClickRemoveMember(item.userId)}>삭제</button>
                  </div>
                </li>
              })
              :
              this.state.user.map(item => {
                return <li className="enterprise-member-item">
                  <div className="enterprise-member-email-wrap">
                    {item.email}
                  </div>
                  <div className="enterprise-member-action-button-wrap">
                    <button className={item.isAuth ? "enterprise-member-action-button-master selected" : "enterprise-member-action-button-master"}
                    >A</button>
                    <button className={item.isMaster ? "enterprise-member-action-button-master selected" : "enterprise-member-action-button-master"}
                    >M</button>
                    {/* <button >삭제</button> */}
                  </div>
                </li>
              })
            }
          </ul>
        </div>
      </div>
      {
        this.state.isEditing ?
          this.state.isLoadingAddMember ?
            <></>
            :
            <div className="enterprise-input-wrap">
              <span className="enterprise-input-title">
                멤버 추가
              </span>
              <div className="enterprise-input" style={{ display: "flex" }}>
                <input type="text" id="enterprise-member-add" style={{ width: "50%" }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.onClickAddMember();
                    }
                  }} />
                <div className="enterprise-member-action-button-wrap">
                  <button onClick={this.onClickAddMember}>추가</button>
                </div>
              </div>
            </div>
          :
          <></>
      }
      <div className="enterprise-input-wrap">
        <span className="enterprise-input-title">
          메모
          </span>
        <div className="enterprise-input">
          {this.state.isEditing ?
            <textarea type="text" style={{ width: "100%", height: "100px" }} value={this.state.memoValue} onChange={(e) => this.setState({ memoValue: e.target.value })} />
            :
            this.state.memoValue
          }
        </div>
      </div>
    </div>

  }

  onGridReadyCloudRanking = async (params) => {
    if(!this.state.isKeywordsGet) {
      this.setState({ isKeywordsGet: true });

      let params = {
        groupId: this.groupId,
        type: "cloudRanking"
      };
      let settings = await this.api.getEnterpriseSettings(params);
      let enterpriseSettings = (settings && settings.getEnterpriseSettings) ? settings.getEnterpriseSettings : [];
      // console.log(enterpriseSettings);
      let keywords = [];

      let index = 1;
      enterpriseSettings.forEach(user => {
        user.keywords.forEach(keyword => {
          let type = (keyword.type && keyword.type === "videoCuration")? "VC" : "CR";
          let category = (type === "VC")? JSON.stringify(keyword.category) : (keyword.type === "youtube")? JSON.stringify(keyword.categories): JSON.stringify(keyword.categories);
          let row = {
            index: index++,
            email: user.email,
            name: user.name,
            type: type,
            platform: (keyword.platform)? keyword.platform: "youtube",
            title: keyword.title,
            makedAt: moment(new Date(keyword.makedAt * 1000)).format('YYYY-MM-DD HH:mm'),
            category: category,
            filter: JSON.stringify(keyword.filter),
            must_not: JSON.stringify(keyword.must_not),
            mustNotChannel: (keyword.mustNotChannel)? keyword.mustNotChannel.length : "-",
            mustNotVideo: (keyword.mustNotVideo)? keyword.mustNotVideo.length : "-",
            nations: keyword.nations
          };
          keywords.push(row);
        })
      });
      this.setState({ keywords });

      params.userIds = this.state.user.map(user => user.userId);
      let historiesGet = await this.api.getEnterpriseHistories(params);
      let histories = (historiesGet && historiesGet.getEnterpriseHistories) ? historiesGet.getEnterpriseHistories : [];
      this.setState({ histories });      
    }
  }

  onGridReadyEasyTracker = async (params) => {
    if(!this.state.isGroupsGet) { // 처음 한번만 가져오게
      this.setState({ isGroupsGet: true });

      let params = {
        groupId: this.groupId,
        type: "easyTracker"
      };
      this.api.getEnterpriseSettings(params).then((response) => {
        let groups = [];
        let users = response ? (response.getEnterpriseSettings || []) : [];

        let index = 1;
        users.forEach(user => {
          user.groups.forEach(group => {
            let row = {
              index: index++,
              email: user.email,
              name: user.name,
              type: group.type,
              title: group.title,
              platform: (group.platform)? group.platform: "youtube",
              channelLength: group.channels.length,
              channels: group.channels.map(ch => ch.channelId),
            };
            groups.push(row);
          })
        });
        
        this.setState({ groups });
      });
    }

    // { headerName: "No.", field: "index", sortable: true, resizable: true, width: 50 },
    // { headerName: "이메일", field: "email", sortable: true, filter: true, width: 210, resizable: true },
    // { headerName: "닉네임", field: "name", sortable: true, filter: true, width: 120, resizable: true },
    // { headerName: "타입", field: "type", resizable: true, sortable: true, width: 120 },
    // { headerName: "그룹명", field: "title", sortable: true, filter: true, resizable: true, width: 180 },
    // { headerName: "채널수", field: "channelLength", resizable: true, sortable: true, width: 60 },
    // { headerName: "등록 채널들", field: "channels", resizable: true, width: 100 },
  }

  // getRowStyle = (params) => {
  //   // let data = params.data;
  //   // // let nowEpoch = Math.floor(new Date().valueOf() / 1000);
  //   // // if (nowEpoch >= data.expireEpoch) {
  //   // //   return { backgroundColor: "#d9d9d9" }
  //   // // }
  //   // // return null;
  //   // if(data.isExpireEpoch) return { backgroundColor: "#d9d9d9" };
  //   // return null;
  //   return null;
  // }


  _renderCloudRanking() {
    if(!this.state.isKeywordsGet) {
      this.onGridReadyCloudRanking();
    }

    let keywords = this.state.keywords;
    // console.log(keywords);

    this.columnDefs = [
      { headerName: "타입", field: "type", resizable: true, filter: true, sortable: true, width: 80 },
      { headerName: "No.", field: "index", sortable: true, resizable: true, width: 50 },
      {
        headerName: '유저',
        children: [   
          { headerName: "이메일", field: "email", sortable: true, filter: true, width: 150, resizable: true },
          { headerName: "닉네임", field: "name", sortable: true, filter: true, width: 120, resizable: true },
        ]
      },      
      { headerName: "생성일", field: "makedAt", resizable: true, sortable: true, width: 100 },
      {
        headerName: '키워드그룹',
        children: [          
          { headerName: "플랫폼", field: "platform", resizable: true, sortable: true, width: 80 },
          { headerName: "키워드그룹 제목", field: "title", sortable: true, filter: true, resizable: true, width: 180 },
          { headerName: "검색키워드", field: "filter", resizable: true, width: 200, tooltipValueGetter: (params) => params.value },
          { headerName: "카테고리", field: "category", resizable: true, width: 200, tooltipValueGetter: (params) => params.value },
          { headerName: "국가", field: "nations", resizable: true, width: 100 },
          { headerName: "제외키워드", field: "must_not", resizable: true, width: 150, tooltipValueGetter: (params) => params.value },
          { headerName: "제외채널수", field: "mustNotChannel", resizable: true, width: 100 },
          { headerName: "제외영상수", field: "mustNotVideo", resizable: true, width: 100 },
        ],
        resizable: true,
      }, 
      // { headerName: "타입", field: "type", filter: true,  resizable: true },
      // { headerName: "CR", field: "cloudRanking", resizable: true,  width: 40,
      //   cellStyle: params => {
      //     if (params.value !== 'X') return { background: '#33CECA' };
      //     else return null;
      //   }
      // },
      // { headerName: "ET", field: "easyTracker", resizable: true,  width: 40,
      //   cellStyle: params => {
      //     if (params.value !== 'X') return { backgroundColor: '#FFB400' };
      //     else return null;
      //   }
      // },
      // { headerName: "VC", field: "videoCuration", resizable: true,  width: 40, 
      //   cellStyle: params => {
      //     if (params.value !== 'X') return { backgroundColor: '#C290FF' };
      //     else return null;
      //   }
      // },
      // { headerName: "시작일(UTC)", field: "start_at", sortable: true,  resizable: true, width: 100 },
      // { headerName: "종료일(UTC)", field: "expire_at", sortable: true,  resizable: true, width: 100 },
      // { headerName: "메모", field: "memo", filter: true,  resizable: true }
    ];


    let histories = this.state.histories;
    let users = this.state.user;
    histories = histories.map(history => {
      let userObj = users.find(u => u.userId === history.userId);
      let searchTargetObj = users.find(u => u.userId === history.searchUserId);

      history.user = (userObj && userObj.name && userObj.email)? userObj.name+"("+userObj.email+")": "-";
      history.searchTarget = (searchTargetObj && searchTargetObj.name && searchTargetObj.email)? searchTargetObj.name+"("+searchTargetObj.email+")": "-";
      if(history.user === history.searchTarget) history.searchTarget = "좌동";


      return history;
    });

    return <div className="enterprise_container">
      <div className="ag-theme-balham" style={{ width: "100%", height: "595px" }}>
        <AgGridReact
          // onGridReady={this.onGridReadyCloudRanking}
          // getRowStyle={this.getRowStyle}
          columnDefs={this.columnDefs}
          rowData={keywords}
          rowSelection="multiple"
        // onSelectionChanged={this.onSelectionChanged}
        />
      </div>
      <div className={Styles.title}>
        &nbsp;
      </div>
      <div className={Styles.title}>
        대시보드 조회 이력(최근 100건)
      </div>
      {(histories.length>0)?
        <div>
          <table border="1">
            <thead>
              <tr bgcolor="#dedede">
                <th>조회일시</th>
                <th>유저</th>
                <th>조회대상</th>
                <th>플랫폼</th>
                <th>랭킹기준일</th>
                <th>최근 n개월 영상</th>
                <th>엔터프라이즈 타입</th>
                {/* <th>조회일시</th> */}
              </tr>
            </thead>
            <tbody>
              {histories.map(history => {
                return <tr>
                  <td>{moment(new Date(history.now)).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{history.user}</td>
                  <td>{history.searchTarget}</td>
                  <td>{history.platform}</td>
                  <td>{history.startDate}</td>
                  <td>{history.searchPeriod}</td>
                  <td>{history.enterpriseType ?? ""}</td>
                  {/* <td>{moment(new Date(history.now)).format('YYYY-MM-DD HH:mm')}</td> */}
                </tr>
              })}              
            </tbody>

          </table>
        </div>
        :
        <div>
          조회이력 없음
        </div>
      }
    </div>
  }



  _renderEasyTracker() {
    if(!this.state.isGroupsGet) {
      this.onGridReadyEasyTracker();
    }

    this.columnDefs = [
      { headerName: "No.", field: "index", sortable: true, resizable: true, width: 50 },
      { headerName: "이메일", field: "email", sortable: true, filter: true, width: 210, resizable: true },
      { headerName: "닉네임", field: "name", sortable: true, filter: true, width: 120, resizable: true },
      { headerName: "타입", field: "type", resizable: true, sortable: true, width: 100 },
      { headerName: "그룹명", field: "title", sortable: true, filter: true, resizable: true, width: 160 },
      { headerName: "채널수", field: "channelLength", resizable: true, sortable: true, width: 60 },
      { headerName: "등록 채널들", field: "channels", resizable: true, width: 400 },
    ];

    let groups = this.state.groups;
    return <div className="enterprise_container">
      <div className="ag-theme-balham" style={{ width: "100%", height: "595px" }}>
        <AgGridReact
          // onGridReady={this.onGridReadyEasyTracker}
          // getRowStyle={this.getRowStyle}
          columnDefs={this.columnDefs}
          rowData={groups}
          rowSelection="multiple"
        />
      </div>
      <div className={Styles.title}>

      </div>
    </div>
  }

  _renderEasyTracker_old() {
    if(!this.state.isGroupsGet) { // 처음 한번만 가져오게
      this.setState({ isGroupsGet: true });

      let params = {
        groupId: this.groupId,
        type: "easyTracker"
      };
      this.api.getEnterpriseSettings(params).then((response) => {
        // let groups = [];
        // let users = response ? (response.getEnterpriseSettings || []) : [];

        // let index = 1;
        // users.forEach(user => {
        //   user.groups.forEach(group => {
        //     let row = {
        //       index: index++,
        //       email: user.email,
        //       name: user.name,
        //       type: group.type,
        //       title: group.title,
        //       platform: (group.platform)? group.platform: "youtube",
        //       channels: group.channels.map(ch => ch.channelId),
        //     };
        //     groups.push(row);
        //   })
        // });
        let groups = response ? (response.getEnterpriseSettings || []) : [];
        groups = groups.filter(group => (group.groups && group.groups.length>0));
        this.setState({ groups });
      });
    }
    
    let groups = this.state.groups;
    return <div className="enterprise_container">
      {groups.map(user => {
        return <p>
          <b>{user.email}({user.name}): {user.groups.length}개의 그룹<br/></b>
          {user.groups.map((group, index) => {
            return <span>
              {group.title}({(group.channels)? group.channels.length: 0})
              {(user.groups.length !== index)? ", ": ""}             
              {/* {index+1} | {group.title} | {group.platform} | {(group.channels)? group.channels.length: 0} |
              {JSON.stringify(group.channels.map(ch => ch.channelId))}<br /> */}
            </span>
          })}         
        </p>
      })}
    </div>
  }

}

export default enterpriseDetail;