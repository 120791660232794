import React from 'react';
import { Redirect } from "react-router-dom";

import ApiService from '../../services/ApiService';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import * as moment from 'moment';
import NewMembers from './NewMembers';
import MonthlyGrade from './grade/MonthlyGrade';
import MrrAnalysis from '../subscription/MrrAnalysis';

class MemberList extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      modules: AllCommunityModules,
      columnDefs: [
        { headerName: "가입일 ( 마지막 로그인 )", field: "regDate" },
        { headerName: "최근 2주 로그인 여부", field: "lately_login" },
        { headerName: "등급", field: "grade" },
        { headerName: "멤버쉽 만료일", field: "expire_date" },
        { headerName: "멤버쉽 만료 여부", field: "expire_date_true" },
        { headerName: "회원구분", field: "usertype" },
        { headerName: "회원 고유 아이디", field: "_id" },
        { headerName: "멤버쉽 체험 여부", field: "experience_check" },
        { headerName: "쿠폰 사용 여부", field: "coupon_code" },
        { headerName: "이메일(계정)", field: "email" },
        { headerName: "닉네임", field: "nickname" },
        { headerName: "채널인증", field: "certifications" },
        { headerName: "이메일알람", field: "emailon", valueFormatter: this.onNotiFormatter },
        { headerName: "푸시알람", field: "pushon", valueFormatter: this.onNotiFormatter },
        { headerName: "언어", field: "lang" },
      ],
      defaultColDef: {
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: true,
        filter: true
      },
      rowSelection: "multiple",
      paginationPageSize: 100,
      paginationToken: null,
      paginationNumberFormatter: function (params) {
        return params.value.toLocaleString();
      },
      rowData: [],
      redirect: null
    };
  }

  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    this.dataInit();
  }

  dataInit = async (search) => {

  }


  /*멤버쉽 종류부터 확인 지금 멤버쉽 종류와 
    안에 있는 멤버쉽 종류가 같다면 
    그 expire_date를 뽑아냄*/
  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();

    // let resDate = await this.api.getMembers(0, this.state.paginationPageSize , this.state.paginationToken);
    let resDate = await this.api.getMembers({});
    let memberDate = [];
    let test = [];

    if (resDate && resDate.getMembers && resDate.getMembers.result && Array.isArray(resDate.getMembers.result)) memberDate = resDate.getMembers.result;

    let fourteenDaysago = new Date().valueOf() + (1000 * 60 * 60 * 9) - (1000 * 60 * 60 * (24 * 14))

    let rowData = memberDate.map(m => {
      m.nickname = m.name;

      //쿠폰 체험 여부
      if (m.isExperienceProCount) {
        m.membership.map(member => {
          if (member?.coupon?.code) {
            m.coupon_code = member.coupon.code;
          } else {
            m.coupon_code = "쿠폰사용";
          }
        })
      }

      if ((m.grade !== "enterprise" || m.grade !== "엔터프라이즈") && (m.isExperience === true || m.experiencePremiumCount >= 1 || m.experienceStandardCount >= 1)) {
        m.experience_check = "O";
      } else {
        m.experience_check = "X"
        test.push(m);
      }

      m.expire_date = "미체험";
      if (m.grade === "enterprise") {
        m.membership.map(member => {
          if (member.grade === m.grade) {
            m.expire_date = moment(new Date(member.expire_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
          }
        })
        m.grade = "엔터프라이즈";
      }
      else if (m.grade === "professional") {
        m.membership.map(member => {
          if (!(member.isExperience)) {
            m.expire_date = moment(new Date(member.expire_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
          }
        })
        m.grade = "프로페셔널";
      }
      else if (m.grade === "premium") {
        m.membership.map(member => {
          if (!(member.isExperience)) {
            m.expire_date = moment(new Date(member.expire_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
          }
        })
        m.grade = "스타트업";
      }
      else if (m.grade === "standard") {
        m.membership.map(member => {
          if (!(member.isExperience)) {
            m.expire_date = moment(new Date(member.expire_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
          }
        })
        m.grade = "스탠다드";
      }
      else {
        m.membership.map(member => {
          if (member) {
            m.expire_date = moment(new Date(member.expire_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
          }
        })
        m.grade = "정회원";
      }
      if (m.isExperience) {
        m.membership.map(member => {
          m.expire_date = moment(new Date(member.expire_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
        })
        m.grade = m.grade + "(체험)";
      }

      let login_confirm = m.login_at * 1000

      if (login_confirm > fourteenDaysago) {
        m.lately_login = "O"
      } else {
        m.lately_login = "X"
      }

      if (m.login_at) {
        m.login_at = moment(new Date(m.login_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
      } else {
        m.login_at = ""
      }

      if (m.login_at !== "") {
        m.regDate = moment(new Date(m.reg_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm") + " ( " + m.login_at + " )";
      } else {
        m.regDate = moment(new Date(m.reg_at * 1000).valueOf()).format("YYYY-MM-DD HH:mm");
      }

      //멤버쉽 만료 여부 now > 멤버쉽 만료일 O 아니라면 x 
      let now = parseInt(new Date().valueOf() / 1000);

      if (parseInt((new Date(m.expire_date).valueOf()) / 1000) < now) {
        m.expire_date_true = "O";
      } else if (m.expire_date === "미체험") {
        m.expire_date_true = "";
      }
      else {
        m.expire_date_true = "X";
      }

      let certs = [];
      if (m.certification) {
        for (let cert in m.certification) {
          certs.push(cert);
        }
      }
      m.certifications = JSON.stringify(certs);
      return m;
    });

    this.setState({
      rowData: rowData,
      // paginationToken : resDate.getMembers.result.PaginationToken
    });
  };

  onNotiFormatter(params) {
    return params.value === 1 ? "승인" : "미승인";
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById("page-size").value;
    this.setState({
      paginationPageSize: value
    })
  }

  onSelectionChanged() {
    var selectedRows = this.gridApi.getSelectedRows();
    this.setState({ redirect: "/member/" + selectedRows[0]._id });
  }

  onSearchButton = async () => {
    var emailInput = document.getElementById("user-email").value
    let user = await this.api.getMemberIdByEmail(emailInput)
    if (!user?.getMemberIdByEmail?.result || user.getMemberIdByEmail.result.length !== 1) {
      alert('사용자 정보가 없습니다.')
      return
    } 
    this.setState({ redirect: "/member/" + user.getMemberIdByEmail.result[0]._id });
  }

  onChangeDatepicker(month) {
    let gradeStartDate = document.getElementById("gradeStratDate");
    if (!gradeStartDate.value) {
      alert("시작일을 입력해주세요.");
    } else {

    }

  }

  onBtnExport = () => {
    this.gridApi.exportDataAsCsv();
  };

  /* 20.07.30 스타트업 체험하는 회원 수 구하는 함수 수정
    _getpremium() , numOfexperiencePremium() 

    20.07.13 날짜별 스타트업 체험 회원수 구하는 함수 추가 
    _getDateExperiencePremium() */

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect} />
    }

    return (
      <>
        <NewMembers />

        <MonthlyGrade />

        {/* <MrrAnalysis /> */}

        <div className="section-line" />
        <div style={{ height: "600px" }}>
          <div className="list-pagination-wrap">
            {/* <div className="list-pagination-content">
              Page Size:
              <select onChange={this.onPageSizeChanged.bind(this)} id="page-size">
                <option value="100" selected="">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>

              <button onClick={() => this.onBtnExport()}>
                CSV 다운
              </button>
            </div> */}
            <div>
              회원 정보 조회
            </div>
            <div>
              <span>회원 이메일 입력</span>
              <input type="email" id="user-email" />
              <button onClick={this.onSearchButton}>검색</button>
            </div>
          </div>
          <div>
            <br />
          </div>
          {/* <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              modules={this.state.modules}
              floatingFilter={true}
              columnDefs={this.state.columnDefs}
              autoGroupColumnDef={this.state.autoGroupColumnDef}
              defaultColDef={this.state.defaultColDef}
              pagination={true}
              paginationPageSize={this.state.paginationPageSize}
              paginationNumberFormatter={this.state.paginationNumberFormatter}
              rowSelection={this.state.rowSelection}
              onSelectionChanged={this.onSelectionChanged.bind(this)}
              onGridReady={this.onGridReady}
              rowData={this.state.rowData}
              enableCellTextSelection={true}
            >
            </AgGridReact>
          </div> */}
        </div>
      </>
    );
  }
}

export default (MemberList);