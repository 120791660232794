import React from "react";

import { Route } from "react-router-dom";
import MemberList from "../components/member/MemberList"
import MemberDetail from "../components/member/detail/MemberDetail"
import MonthlyGradeDetail from "../components/member/grade/MonthlyGradeDetail"

export default function Member(props) {
  return (
    <>
      <Route exact path={props.match.path} component={MemberList} />
      <Route exact path={`${props.match.path}/:id`} component={MemberDetail} />
      <Route exact path={`${props.match.path}/month/:id`} component={MonthlyGradeDetail} />
    </>
  );
}
