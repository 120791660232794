import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { createProductData } from '../gql';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, FormLabel, FormControl, FormControlLabel, RadioGroup, Radio, Typography } from '@material-ui/core';

export default function InputModal({ handleCloseModal, setUpdateData, SUB_FIELDS }) {
  const classes = useStyles();
  const INPUT_SUB_FIELDS = {
    ...SUB_FIELDS, price: [
      {
        currency: "KRW",
        country: "KR",
        isWithVat: true,
        vat: 0,
        value: 0
      },
      {
        currency: "USD",
        value: 0
      }
    ]
  }

  const [createProductDataFunc] = useMutation(createProductData);

  const [newField, setNewField] = useState({...INPUT_SUB_FIELDS});

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedField = { ...newField };

    if (name === "isSubscribe") {
      updatedField[name] = value === "true";
    } else if (name === "isWithVat") {
      updatedField.price[0].isWithVat = value === "true";
    } else if (name === "vat") {
      updatedField.price[0].vat = value
    } else if (name.includes('Price')) {
      const { name, value } = event.target;
      if (name === 'krPrice') updatedField.price[0].value = Number(value);
      else updatedField.price[1].value = Number(value);
    } else {
      updatedField[name] = value;
    }

    setNewField(updatedField);
  };

  const handleSubmit = async () => {
    try {
      const input = {
        name: newField.name,
        grade: newField.grade,
        isSubscribe: newField.isSubscribe,
        price: newField.price
      }
      await createProductDataFunc({
        fetchPolicy: "network-only",
        variables: { input }
      });
    } catch (error) {
      console.error(error);
    }
    setUpdateData(true)
    setNewField({
      name: '',
      grade: '',
      isSubscribe: true,
      price: [
        {
          currency: "",
          country: "",
          isWithVat: true,
          value: 0
        },
        {
          currency: "",
          value: 0
        }
      ],
    });
    handleCloseModal();
  }

  return (
    <Dialog open={true} onClose={handleCloseModal}>
      <DialogTitle>Add Field</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText>
          Please enter the details of the new field.
        </DialogContentText>
        <form className={classes.modalContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Name"
                value={newField.name || ""}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="grade"
                label="Grade"
                value={newField.grade || ""}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Is Subscribe?</FormLabel>
                <RadioGroup
                  aria-label="isSubscribe"
                  name="isSubscribe"
                  value={newField.isSubscribe}
                  onChange={handleChange}
                >
                  <FormControlLabel value={true} control={<Radio id="subscribe-true-radio" />} label="True" />
                  <FormControlLabel value={false} control={<Radio id="subscribe-false-radio" />} label="False" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Price in KRW</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="krCurrency"
                label="Currency"
                value={"KRW"}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="krPrice"
                label="Value in KRW"
                type="number"
                inputProps={{ min: 0, max: 9999, step: "0.01"}}
                value={newField.price[0].value || 0}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Includes VAT?</FormLabel>
                <RadioGroup
                  aria-label="isWithVat"
                  name="isWithVat"
                  value={newField.price[0].isWithVat}
                  onChange={handleChange}
                >
                  <FormControlLabel value={true} control={<Radio id="withVat-true-radio" />} label="True" />
                  <FormControlLabel value={false} control={<Radio id="withVat-false-radio" />} label="False" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="vat"
                label="Vat in KRW"
                type="number"
                inputProps={{ min: 0, max: 9999 }}
                value={newField.price[0].vat || 0}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Price in USD</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="usdCurrency"
                label="Currency"
                value={"USD"}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                name="usdPrice"
                label="Value in USD"
                type="number"
                inputProps={{ min: 0, max: 9999, step: "0.01"}}
                value={newField.price[1].value || 0}
                onChange={handleChange}
                required
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    margin: `${theme.spacing(2)}px 0`,
  },
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  label: {
    marginBottom: theme.spacing(1),
  },
}));