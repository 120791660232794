import React, { useEffect, useState, useCallback } from 'react';

import numeral from 'numeral';
// import { useLazyQuery, useMutation } from '@apollo/client';

const purposeOptions = [
  { value: 'business', label: '비지니스' },
  { value: 'item', label: '제품 소개' },
  { value: 'brand', label: '브랜드 인지도 증대' },
  { value: 'event', label: '이벤트 참여 유도' },
  { value: 'viral', label: '바이럴 효과 유도' },
  { value: 'advertise', label: '간접 광고' }
];

const methodOptions = [
  { value: 'creator', label: '기획 및 제작까지 모두 유튜버에게 맡김' },
  { value: 'concept', label: '생각한 컨셉을 전달하고 콘텐츠 제작은 유튜버에게 맡김' },
  { value: 'planning', label: '광고주가 기획한 내용을 전달하고 콘텐츠 제작은 유튜버에게 맡김' },
  { value: 'others',  label: '기타' }
];

export default function CampaignModalPreview({ channelData, _setShowModal }) {
  
  return (
    <div className='ad_modal'>
      <div className="ad_modal_content">
        <span className="ad_close" onClick={() => _setShowModal(false)}>
          &times;
        </span>
        <div className="contentWhiteBox">
          <div className="tableContent">
            <p className="tableTitle">광고 제안 내용</p>
            <div className="tableContainer">
              <table className="table">
                <tbody>
                  <tr>
                    <td className="subTitle">
                      <span>광고 제안서 제목</span>
                    </td>
                    <td className="subConten">
                      <span>{channelData.title}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="subTitle">
                      <span>광고 목적</span>
                    </td>
                    <td className="subContent">
                      {
                        purposeOptions.map((x) => {
                          if(x.value === channelData.adType) 
                            return <span>{x.label}</span>
                          }
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="subTitle">
                      <span>광고 예산</span>
                    </td>
                    <td className="subContent">
                      <span>₩{numeral(channelData.minBudget).format('0,0')} ~ ₩{numeral(channelData.maxBudget).format('0,0')}</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="subTitle">
                      <span>영상 제작 방식</span>
                    </td>
                    <td className="subContent">
                      {
                        methodOptions.map((x) => {
                          if(x.value === channelData.videoType) 
                            return <span>{x.label}</span>
                          }
                        )
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="subTitle">
                      <span>광고 설명</span>
                    </td>
                    <td className="subContent">
                      <span>{channelData.desc}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="textInfo">
            <p>* 본 메일은 인플루언서 서칭 플랫폼 vling을 통해 발송된 광고 제안 메일입니다.</p>
            <p>* 본 메일은 발신전용 메일로 회신되지 않습니다. (문의사항은 고객센터를 이용해 주세요.)</p>
            <div className="address">
              <span>경기 성남시 분당구 분당내곡로 117 (주)버즈앤비</span>
              <span>ⓒ2019. BUZZ & BEYOND Inc.</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="modal">

    //   <Close_svg className="closeBtn" onClick={onRequestClose} />
    // </div>
  );
}
