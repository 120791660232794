import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ToastContainer, toast } from 'react-toastify';

//
import { uploadBannerImg } from './gql';

const setToast = (str) => {
  toast.success(str, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false
  });
}

export default function UploadFile({inputId, bannerId, fileType='PC',uploadImgCB }) {
  const [uploadFileMutation] = useMutation(uploadBannerImg);
  const onChange = async ({
    target: {
      validity,
      files: [file],
    },
  }) => {
    if (validity.valid) {
      console.log(file)
      const rs = await uploadFileMutation({
        variables: {
          _id: bannerId,
          file,
          fileType:fileType,
        }
      });
      console.log(rs)
      uploadImgCB();
    }
  }
  return (
    <>
      <input type="file" style={{ display: "none" }} id={inputId} required onChange={onChange} />
      <ToastContainer
        position="top-right"
        autoClose={100000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable={false}
        pauseOnHover={false}
      />
    </>
  )
}