import React, { useState } from 'react'
import ApiService from '../../services/ApiService';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const API = new ApiService();

const ADVERTISER_COLUMN = [
  { headerName: "회사명(상호명)", field: "companyName" },
  { headerName: "담당자 성함/직책", field: "name" },
  { headerName: "휴대폰 번호", field: "phoneNumber" },
  { headerName: "이메일", field: "email" },
  //{ headerName: "주소", field: "businessAddress" },
  { headerName: "사업자 번호", field: "businessNumber" },
  { headerName: "요청일자", field: "requestDate" },
  //{ headerName: "확인여부", field: "state" },
  { headerName: "확인자", field: "checkUserName"},
  { headerName: "확인일", field: "registrationDate"},
  { headerName: "진행상태", field: "stateTypeInfo" },
];

const ADVERTISER_OPTION = {
  editable: false,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
  sortable: true,
  resizable: true,
  filter: true
}


const AdvertiserList = (props) => {

  let gridColumnAPI = null;

  const [gridAPI, setGridAPI] = useState(null);
  const [pageNation, setPageNation] = useState(100);
  const [advertiserList, setAdvertiserList] = useState([]);

  const onGridReady = async (params) => {
    params.api.sizeColumnsToFit();
    setGridAPI(params.api);
    gridColumnAPI = params.columnApi;
    

    let advertiserList = await API.getAdvertiserList();

    let list = advertiserList.getAdvertiserList;
    list.sort((a, b) => new Date(b.requestDate).valueOf() - new Date(a.requestDate).valueOf());
    console.log("!!!", list);
    setAdvertiserList(list);
  }

  const couponSelectEvent = () => {
    let selectedRow = gridAPI.getSelectedRows();
    props.history.push({
      pathname : "/advertiserInfo",
      state : {advertiser : selectedRow[0]} 
    })
    // setRedirect("/advertiserInfo");
    // setColumnInfo(selectedRow[0]);
  }

  const onPageSizeChanged = (e) => {
    setPageNation(e.target.value);
  }

  return (
    <>
      {/* {redirect && columnInfo ? <Redirect to={{ pathname: redirect, state: { selectedData: columnInfo } }} /> : <></>} */}
      <div className="row" style={{ height: "600px" }}>
        <div className="col-lg-12">
          <div className="list-pagination-wrap">
            <div className="list-pagination-content">
              Page Size:
              <select onChange={onPageSizeChanged} style={{ marginLeft: "10px" }}>
                <option value="100" selected="">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
            </div>
          </div>
        </div>
        <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
          <AgGridReact
            modules={AllCommunityModules}
            floatingFilter={true}
            columnDefs={ADVERTISER_COLUMN}
            defaultColDef={ADVERTISER_OPTION}
            pagination={true}
            paginationPageSize={pageNation}
            onSelectionChanged={couponSelectEvent}
            rowSelection={"multiple"}
            onGridReady={onGridReady}
            rowData={advertiserList}
            enableCellTextSelection={true}
          >
          </AgGridReact>
        </div>
      </div>
    </>
  )
}

export default AdvertiserList;