import { useMutation, useQuery } from '@apollo/client';
import styles from './grade.module.scss';
import React, { useEffect, useState } from 'react';
import { Button, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { getGradeList, updateGradeList, deleteGradeList } from '../gql';

import InputModal from './InputModal';

const SUB_FIELDS = {
  _id: '',
  searchVideoTrend: 0,
  detailSearch: 0,
  favoriteFolders: 0,
  favoriteItems: 0,
  adInfo: 0,
  viewersInfo: 0,
  campaign: 0,
  openapi: 0
};

export default function BrandMemberShip() {
  const classes = useStyles();
  const [state, setState] = useState({
    basic: { name: '', grade: '', level: 0, quotas: { ...SUB_FIELDS } },
    standard: { name: '', grade: '', level: 0, quotas: { ...SUB_FIELDS } },
    startup: { name: '', grade: '', level: 0, quotas: { ...SUB_FIELDS } },
    professional: { name: '', grade: '', level: 0, quotas: { ...SUB_FIELDS } },
    enterprise: { name: '', grade: '', level: 0, quotas: { ...SUB_FIELDS } }
  });
  const [fields, setFields] = useState([]);
  const [tempData, setTempData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedField, setSelectedField] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const { data, refetch } = useQuery(getGradeList, {
    fetchPolicy: 'network-only',
    variables: {}
  });

  const [updateGradeListFunc] = useMutation(updateGradeList);
  const [deleteGradeListFunc] = useMutation(deleteGradeList);

  useEffect(() => {
    if (data) {
      const gradeList = data.getGradeList;

      const updatedState = { ...state };
      const updatedFields = [];

      for (const { _id, grade, name, level, quotas } of gradeList) {
        const newQuotas = Object.entries(quotas)
          .filter(([key]) => key !== '__typename')
          .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});

        updatedState[name] = { quotas: newQuotas, _id, grade, level, name };
        updatedFields.push({ _id, name, label: grade, level, subfields: Object.keys(newQuotas) })
      }

      setState(updatedState);
      setFields(updatedFields);
    }
  }, [data, updateData]);

  const handleInputChange = (event, fieldName, subfieldName) => {

    const inputValue = subfieldName === "grade" || subfieldName === "name" ? event.target.value : parseInt(event.target.value);

    const setData = (prevState, newData) => ({
      ...prevState,
      [fieldName]: {
        ...prevState[fieldName],
        ...newData
      }
    });

    switch (subfieldName) {
      case "grade":
      case "name":
        setState((prevState) => setData(prevState, {
          [subfieldName]: inputValue
        }));
        break;
      default:
        setState((prevState) => setData(prevState, {
          quotas: {
            ...prevState[fieldName].quotas,
            [subfieldName]: inputValue
          }
        }));
        break;
    }

  };

  const handleStartAdd = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDelete = async () => {
    if (window.confirm(`Delete ${selectedRows.length} items?`)) {
      try {
        await deleteGradeListFunc({
          fetchPolicy: "network-only",
          variables: { input: {_ids: selectedRows} },
        });
        
        setSelectedRows([]);
        refetch();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleUpdate = async (fieldName) => {
    setIsEditing(false);
    setSelectedField(null);

    const input = {
      _id: state[fieldName]._id,
      grade: state[fieldName].grade,
      name: state[fieldName].name,
      level: state[fieldName].level,
      quotas: { ...state[fieldName].quotas },
    };

    try {
      if (window.confirm(`Update ${fieldName}?`)) {
        await updateGradeListFunc({
          fetchPolicy: "network-only",
          variables: { input },
        });
      } else {
        setIsEditing(true);
        setSelectedField(fieldName);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleStartEdit = (fieldName) => {
    const temp = { ...state[fieldName] };
    setState((prevState) => ({ ...prevState, [fieldName]: temp }));

    setTempData(temp)
    setIsEditing(true);
    setSelectedField(fieldName);
  };

  const handleCancelEdit = (fieldName) => {
    setState((prevState) => ({ ...prevState, [fieldName]: tempData }));

    setIsEditing(false);
    setSelectedField(null);
  };

  const handleRowSelect = (event, productId) => {
    const { checked } = event.target;

    console.log(checked, productId);
    setSelectedRows((prevSelectedRows) =>
      checked ? [...prevSelectedRows, productId] : prevSelectedRows.filter((id) => id !== productId)
    );
  };

  const renderValue = (fieldName, subfield) => {
    const value = subfield === "name" || subfield === "grade" || subfield === "level" ? state[fieldName][subfield] : state[fieldName].quotas[subfield];
    if (!isEditing || selectedField !== fieldName) {
      return value !== null && value !== 0 ? value : "-";
    }

    return (
      subfield === "name" || subfield === "grade" ? (
        <input
          className={styles.input}
          type="text"
          value={value !== null && value !== 0 ? value : "-"}
          onChange={(event) => handleInputChange(event, fieldName, subfield)}
        />
      ) : (
        <input
          className={styles.input}
          type="number"
          min={0}
          max={9999}
          value={value !== null && value !== 0 ? value : "-"}
          onChange={(event) => handleInputChange(event, fieldName, subfield)}
        />
      )
    );
  };

  const renderButtons = (fieldName) => {
    const isActive = selectedField === fieldName && isEditing;
    const isDisabled = selectedField === fieldName ? null : true
    if (isEditing && isActive) {
      return (
        <>
          <button className={styles.isEditingBtn} style={{ cursor: "pointer" }} onClick={() => handleUpdate(fieldName)}>Save</button>
          <button className={styles.isEditingBtn} style={{ cursor: "pointer" }} onClick={() => handleCancelEdit(fieldName)}>Cancel</button>
        </>
      );
    } else {
      if (!isEditing) {
        return (
          <button className={styles.button} style={{ cursor: "pointer" }} onClick={() => handleStartEdit(fieldName)}>Edit</button>
        );
      } else {
        return (
          <button className={styles.button} disabled={isDisabled} onClick={() => handleStartEdit(fieldName)}>Edit</button>
        );
      }
    }
  };

  const renderTable = () => (
    <table className={styles.table}>
      <thead>
        <tr>
          <th></th>
          <th>grade</th>
          <th>name</th>
          <th>level</th>
          {fields[0]?.subfields.map((subfield, index) => (
            <th key={index}>{subfield}</th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {fields.map((field, index) => (
          <tr key={index}>
            <td>
              <Checkbox checked={selectedRows.includes(field._id)} onChange={(event) => handleRowSelect(event, field._id)} />
            </td>
            <td>{renderValue(field.name, "grade")}</td>
            <td>{renderValue(field.name, "name")}</td>
            <td>{renderValue(field.name, "level")}</td>
            {field.subfields.map((subfield, index) => (
              <td key={index}>{renderValue(field.name, subfield)}</td>
            ))}
            <td>
              <div className={styles.btnBox}>
                {renderButtons(field.name)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <>
      {isModalOpen && <InputModal handleCloseModal={handleCloseModal} setUpdateData={setUpdateData} SUB_FIELDS={SUB_FIELDS} />}
      <div className={styles.brand_container}>
        <h6 className={styles.sub_title}>광고주</h6>
        <div className={classes.btnBox}>
          <Button className={classes.button} onClick={handleStartAdd}>
            Add
          </Button>
          <Button className={classes.button} onClick={handleDelete} disabled={selectedRows.length === 0}>
            Delete
          </Button>
        </div>
      </div>
      {renderTable()}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  btnBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(1),
    gap: '10px'
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));