import React from "react";
import { Redirect } from "react-router-dom";

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';

import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

import { AgGridReact } from 'ag-grid-react';
import { AllcommunityModules } from "ag-grid-community";

import ApiService from "../../services/ApiService";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import moment from "moment";

class HotTrendList extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.state = {
      modules: AllcommunityModules,
      columnDefs: [
        {headerName: "구분", field: "division", width: 10 },
        {
          headerName: "등록일", field: "regDate", width: 20, filter: 'agDateColumnFilter', filterParams: {
            comparator: function (filterLocalDateAtMidnight, cellValue) {
              let cellDate = cellValue.replace(/-/g, "");
              cellDate = parseInt(cellDate);
              let standardDate = moment(filterLocalDateAtMidnight).format("YYYY-MM-DD").replace(/-/g, "");
              standardDate = parseInt(standardDate);

              if (cellDate == standardDate) {
                return 0;
              }

              if (cellDate < standardDate) {
                return -1;
              }

              if (cellDate > standardDate) {
                return 1;
              }
            }
          }
        },
        { headerName: "테마명", field: "themaName", width: 30 },
        { headerName: "키워드", field: "keyword", width: 50 },
      ],
      defaultColDef: {
        editable: false,
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        sortable: true,
        resizable: true,
        filter: true,
      },
      rowSelection: "multiple",
      paginationPageSize: 100,
      paginationNumberFormatter: function (params) {
        return params.value.toLocaleString();
      },
      data: [],
      rowData: [],
      selectedData: [],
      redirect: null,
      searchStartDate: moment().format("YYYY-MM-DD"),
      searchEndDate: moment().format("YYYY-MM-DD"),
      HighlightDate: []
    }
  }

  // ============================================================= Life cycle 

  componentDidMount() {
    //데이터 주입
    this.initialize();
  }

  // ============================================================= Component function

  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async (search) => {
    let resData = await this.api.getHottrendHighligthDate();
    resData = resData.trendData;

    let tempHighlightDate = [];

    resData.map((data) => {
      let highlightDate = {
        color: 'red',
        start: moment(data.date, 'YYYY-MM-DD').add(1, 'days').format("YYYY-MM-DD"),
        end: data.date
      }
      tempHighlightDate.push(highlightDate);
    });

    this.setState({
      HighlightDate: tempHighlightDate
    })
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    let resData = await this.api.getHottrendList();
    resData = resData.trendData;

    let tempData = [];

    resData = resData.sort((a,b) => new Date(b.date) - new Date(a.date));
    resData.map((data, index) => {
      data.trends.map((trend, trendindex) => {
        tempData.push({
          division: data.nation,
          regDate: data.date,
          themaName: trend.id,
          keyword: trend.keywords,
          id: `${index}_${trendindex}`
        });
      });
    });

    for (let i = 0; i < tempData.length; i++) {
      let keywordResult = [];
      var tempDataKeywordLength = 0;
      if(tempData[i].keyword && tempData[i].keyword.length>0) tempDataKeywordLength = tempData[i].keyword.length;

      for (let j = 0; j < tempDataKeywordLength; j++) {
        if (tempData[i].keyword[j].length == 1) {
          keywordResult.push(tempData[i].keyword[j].toString());
        } else {
          var secondresult = "";
          for (let k = 0; k < tempData[i].keyword[j].length; k++) {
            secondresult += (secondresult == "" ? "" : "+") + tempData[i].keyword[j][k].toString();
          }
          keywordResult.push(secondresult);
        }
      }
      tempData[i].keyword = keywordResult;
    }

    this.setState({
      rowData: tempData,
    });

    this.gridApi.sizeColumnsToFit();
  }

  onPageSizeChanged(newPageSize) {
    var value = document.getElementById("page-size").value;
    this.setState({
      paginationPageSize: value
    })
    this.gridApi.paginationSetPageSize(Number(value));
  }

  onSelectionChanged = () => {
    var selectedRow = this.gridApi.getSelectedRows();
    this.setState({
      redirect: "/hottrend/" + selectedRow[0].id,
      selectedData: selectedRow[0],
    });
  }

  onCreateThema = () => {
    this.setState({
      redirect: "/hottrend/create",
      selectedData: null
    })
  }

  onChangeThemaStartDate = (value) => {
    let startDate = moment(value).format("YYYY-MM-DD");
    this.setState({
      searchStartDate: startDate
    });

    let params = {
      greaterThanDate: moment(startDate, 'YYYY-MM-DD').add(-1, 'days').format("YYYY-MM-DD"),
      lessThanDate: moment(this.state.searchEndDate, 'YYYY-MM-DD').add(1, 'days').format("YYYY-MM-DD")
    }

    this.dateCombined(params);
  }

  onChangeThemaEndDate = (value) => {
    let endDate = moment(value).format("YYYY-MM-DD");
    this.setState({
      searchEndDate: endDate
    });

    let params = {
      greaterThanDate: moment(this.state.searchStartDate, 'YYYY-MM-DD').add(-1, 'days').format("YYYY-MM-DD"),
      lessThanDate: moment(endDate, 'YYYY-MM-DD').add(1, 'days').format("YYYY-MM-DD")
    }

    this.dateCombined(params);
  }

  onChangeDatePicker(days) {
    let startDateValue = moment().format("YYYY-MM-DD");
    let untilDateValue = moment().add(-days, 'days').format("YYYY-MM-DD");

    this.setState({
      searchStartDate: untilDateValue,
      searchEndDate: startDateValue
    })

    let params = {
      greaterThanDate: moment(untilDateValue, 'YYYY-MM-DD').add(-1, 'days').format("YYYY-MM-DD"),
      lessThanDate: moment(startDateValue, 'YYYY-MM-DD').add(1, 'days').format("YYYY-MM-DD")
    }

    this.dateCombined(params);
  }

  dateCombined(params) {
    var dateFilterComponent = this.gridApi.getFilterInstance('regDate');

    dateFilterComponent.setModel({
      condition1: {
        type: 'greaterThan',
        dateFrom: params.greaterThanDate,
        dateTo: null
      },
      condition2: {
        type: 'lessThan',
        dateFrom: params.lessThanDate,
        dateTo: null
      },
      operator: 'AND'
    });
    this.gridApi.onFilterChanged();
  }

  onClickSearch = () => {
    let searchValue = document.getElementById("datasearchInput").value;
    console.log(searchValue);
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={{ pathname: this.state.redirect, state: { selectedData: this.state.selectedData, HighlightDate: this.state.HighlightDate}}} />
    }

    return (
      <>
        <div className="row">
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-4">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="themaStartDate"
                    key="themaStartDate"
                    label="시작일"
                    value={this.state.searchStartDate}
                    format="yyyy-MM-dd"
                    daysName={['일', '월', '화', '수', '목', '금', '토']}
                    monthsName={['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']}
                    onChange={this.onChangeThemaStartDate}
                    //disableFuture={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }} />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-lg-4">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="themaEndDate"
                    key="themaEndDate"
                    label="종료일"
                    value={this.state.searchEndDate}
                    format="yyyy-MM-dd"
                    daysName={['일', '월', '화', '수', '목', '금', '토']}
                    monthsName={['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']}
                    onChange={this.onChangeThemaEndDate}
                    //disablePast={true}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }} />
                </MuiPickersUtilsProvider>
              </div>
              <div className="col-lg-4">
                <center>
                  <Button id="Btn1day" onClick={() => this.onChangeDatePicker(0)} variant="contained" color="primary" style={{ margin: "auto 5px", padding: "5px 10px" }}>오늘</Button>
                  <Button id="Btn7days" onClick={() => this.onChangeDatePicker(7)} variant="contained" color="primary" style={{ margin: "auto 5px", padding: "5px 15px" }}>7일</Button>
                  <Button id="Btn30days" onClick={() => this.onChangeDatePicker(30)} variant="contained" color="primary" style={{ margin: "auto 5px", padding: "5px 10px" }}>30일</Button>
                </center>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="col-lg-4">
              {/* <Input id="datasearchInput" placeholder="검색" variant="outlined" /> */}
            </div>
            <div className="col-lg-4">
              {/* <Button id="BtnSearch" variant="contained" color="primary" style={{ margin: "auto 5px", padding: "5px 10px" }} onClick={this.onClickSearch}>검색</Button> */}
            </div>
            <div className="col-lg-4">
              <Button id="BtnNewThema" onClick={this.onCreateThema} variant="contained" color="primary" style={{ margin: "auto 5px", padding: "5px 10px" }}>신규 테마 등록</Button>
            </div>
          </div>
        </div>
        <div className="row" style={{ height: "600px" }}>
          <div className="col-lg-12">
            <div className="list-pagination-wrap">
              <div className="list-pagination-content">
                Page Size:
                <select onChange={this.onPageSizeChanged.bind(this)} id="page-size">
                  <option value="100" selected="">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="5000">5000</option>
                </select>
              </div>
            </div>
            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                modules={this.state.modules}
                floatingFilter={true}
                columnDefs={this.state.columnDefs}
                autoGroupColumnDef={this.state.autoGroupColumnDef}
                defaultColDef={this.state.defaultColDef}
                debug={true}
                pagination={true}
                paginationPageSize={this.state.paginationPageSize}
                paginationNumberFormatter={this.state.paginationNumberFormatter}
                rowSelection={this.state.rowSelection}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}>
              </AgGridReact>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default (HotTrendList);