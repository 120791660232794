import React from "react";

import { Route } from "react-router-dom";
import AddMails from '../components/addMail/AddMails';

export default function SendMail(props) {
  return (
    <>
      <Route exact path={props.match.path} component={AddMails} />
    </>
  );
}
