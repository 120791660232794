import React, { useEffect, useState } from 'react'
import ApiService from '../../services/ApiService';
import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";
import Button from '@material-ui/core/Button';
import { Link, Redirect } from 'react-router-dom';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

const API = new ApiService();
const COUPON_COLUMN = [
  { headerName: "등급", field: "gradeName" },
  { headerName: "만기일", field: "expiryDate" },
  { headerName: "쿠폰 코드", field: "code" },
  { headerName: "사용 기간", field: "term" },
  { headerName: "정보", field: "description" },
  { headerName: "생성일", field: "createDate" },
  { headerName: "생성자", field: "creator" },
  // { headerName: "이메일알람", field: "emailon", valueFormatter: this.onNotiFormatter },
  // { headerName: "푸시알람", field: "pushon", valueFormatter: this.onNotiFormatter },
];

const COLUMN_OPTION = {
  editable: false,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
  sortable: true,
  resizable: true,
  filter: true
}

export default function CouponList(props) {

  let gridColumnAPI = null;

  const [gridAPI, setGridAPI] = useState(null);
  const [pageNation, setPageNation] = useState(100);
  const [couponList, setCouponList] = useState([]);
  const [columnInfo, setColumnInfo] = useState(null);
  const [redirect, setRedirect] = useState("");

  useEffect(() => {

  }, [couponList,redirect])

  const onGridReady = async (params) => {
    params.api.sizeColumnsToFit();
    setGridAPI(params.api);
    gridColumnAPI = params.columnApi;

    let result = await API.getCouponList();
    let couponList = result.getCouponList;

    couponList.forEach(coupon => {
      if(!coupon.createDate) coupon.createDate = "2000-01-01"

      if (coupon.grade === "standard") {
        coupon.gradeName = "스탠다드";
      } else if (coupon.grade === "premium") {
        coupon.gradeName = "스타트업";
      } else if (coupon.grade === "professional") {
        coupon.gradeName = "프로페셔널";
      } else {
        coupon.gradeName = "??";
      }
    });

    couponList = couponList.sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

    setCouponList(couponList);
  }

  const couponSelectEvent = () => {
    let selectedRow = gridAPI.getSelectedRows();
    setRedirect("/couponInfo");
    setColumnInfo(selectedRow[0]);
  }

  const onPageSizeChanged = (e) => {
    setPageNation(e.target.value);
  }
  // if (this.state.redirect) {
  //   return <Redirect push={true} to={{ pathname: this.state.redirect, state: { selectedData: this.state.selectedData, HighlightDate: this.state.HighlightDate } }} />
  // }
  return (
    <>
      {redirect && columnInfo ? <Redirect push={true} to={{ pathname: redirect , state : {selectedData :columnInfo }}} /> : <></>}
      <div className="row" style={{ height: "600px" }}>
        <div className="col-lg-12">
          <div className="list-pagination-wrap">
            <div className="list-pagination-content">
              Page Size:
                <select onChange={onPageSizeChanged} style={{ marginLeft: "10px" }}>
                <option value="100" selected="">100</option>
                <option value="200">200</option>
                <option value="300">300</option>
              </select>
              <div style={{ marginBottom: "3%" }}>
                <Link to={"/addCoupon"}>
                  <Button id="BtnNewThema" variant="contained" color="primary" style={{ margin: "auto", padding: "5px 10px", float: "right" }}>
                    쿠폰 등록
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
            <AgGridReact
              modules={AllCommunityModules}
              floatingFilter={true}
              columnDefs={COUPON_COLUMN}
              defaultColDef={COLUMN_OPTION}
              pagination={true}
              paginationPageSize={pageNation}
              onSelectionChanged={couponSelectEvent}
              rowSelection={"multiple"}
              onGridReady={onGridReady}
              rowData={couponList}
              enableCellTextSelection={true}
            >

            </AgGridReact>
          </div>
        </div>
      </div>
    </>
  );
}