import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from "apollo-upload-client";
import Auth from '@aws-amplify/auth';
import { deleteOpenapiCookie } from '../utils/cookie';

const base_url = process.env.REACT_APP_BACKEND_HOST;
console.log("@apollo_endpoint", base_url)

export default function ApolloProviderWrapper({ children }) {
  const uploadLink = createUploadLink({
    uri: base_url + '/graphql',
  })

  const authLink = setContext(async (_, { headers }) => { // header token ::  authorization 를 위해서는 https://www.apollographql.com/docs/react/networking/authentication/ 참고
    let session;
    let token;
    try {
      deleteOpenapiCookie();
      session = await Auth.currentSession();
    } catch (error) {
      console.log('@ApolloProviderWrapper | ', error)
    }
    if (session) {
      const token_front = session.getIdToken().getJwtToken()
      // console.log('@token_front', token_front)
      const token_back = session.getAccessToken().getJwtToken()
      // console.log('@token_back', token_back)
      if (token_front && token_back) token = `Buzz ${token_front}#${token_back}`;
    }
    return {
      headers: {
        ...headers,
        authorization: token ? token : '',
      }
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}
