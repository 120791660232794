import React from "react";

import { Route } from "react-router-dom";
import RequestAdPriceList from "../components/requestAdPrice/RequestAdPriceList"
import RequestAdPriceDetail from "../components/requestAdPrice/RequestAdPriceDetail"

export default function RequestAdPrice(props) {
  return (
    <>
        <Route exact path={props.match.path} component={RequestAdPriceList} />
        <Route path={`${props.match.path}/:id`} component={RequestAdPriceDetail} />
    </>
  );
}
