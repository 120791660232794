import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";
import Auth from '@aws-amplify/auth';
import { deleteOpenapiCookie } from "../../utils/cookie";

const GRAPHQL_BASE_URL = process.env.REACT_APP_BACKEND_HOST+"/graphql";

const getSession = async () => {
  try {
    deleteOpenapiCookie();
    let session = await Auth.currentSession();
    let header = {};
    if (session) header.Authorization = `Buzz ${session.getIdToken().getJwtToken()}#${session.getAccessToken().getJwtToken()}`;

    const ClinetFileUpload = new ApolloClient({
      cache: new InMemoryCache(),
      link: createUploadLink({ uri: GRAPHQL_BASE_URL, headers: { Authorization: header.Authorization }})
    });

    return ClinetFileUpload;
  } catch(ex) {
    console.log(ex);
  }
  return null;
}

export default getSession;