import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

const columnDefs = [
  { headerName: "시작 날짜", field: "startAt", sizeColumnsToFit: true },
  { headerName: "만료 날짜", field: "expireAt", sizeColumnsToFit: true },
  { headerName: "등급", field: "grade", sizeColumnsToFit: true },
  { headerName: "비고", field: "demo", sizeColumnsToFit: true },
  { headerName: "메모", field: "memo", sizeColumnsToFit: true }
]

const defaultColDef = {
  editable: false,
  enableRowGroup: true,
  enablePivot: true,
  enableValue: true,
  sortable: true,
  resizable: true,
  filter: true
}

export default function GradeRecord({ membership }) {
  const [rowData, setRowData] = useState([])

  useEffect(() => {
    onGridReady();
  }, [membership]);

  const onGridReady = () => {
    let gridData = [];
    if (membership && Array.isArray(membership)) {
      for (let i = membership.length - 1; i >= 0; i--) {
        gridData.push({
          startAt: moment(membership[i].start_at).format("YYYY-MM-DD HH:mm"),
          expireAt: moment(membership[i].expire_at).format("YYYY-MM-DD HH:mm"),
          grade: membership[i].grade,
          demo: membership[i].isExperience && '체험판',
          memo: membership[i].memo
        })
      }
    }
    setRowData(gridData);
  }

  return (
    <div className="member-detail-history-wrap">
      <div className="member-detail-history-title">회원 등급 변경 이력</div>
      <div className="ag-theme-balham" style={{ height: "175px", width: "100%" }}>
        <AgGridReact
          modules={AllCommunityModules}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowSelection="multiple"
          onGridReady={onGridReady}
          rowData={rowData}
          enableCellTextSelection={true}
          floatingFilter={true} />
      </div>
    </div>
  )
}