import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USERS_WITH_TRACKING_ENABLED_QUERY, ADD_AMPLITUDE_TRACKING_ENABLED_MUTATION, REMOVE_AMPLITUDE_TRACKING_ENABLED_MUTATION, SAVE_MEMO_MUTATION } from './gql';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, TextField, Button, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

export default function ManageTracking() {
  const classes = useStyles();
  const [userId, setUserId] = useState('');
  const [userMemos, setUserMemos] = useState({});
  const [page, setPage] = useState(1);
  const pageSize = 10;
  const [totalPages, setTotalPages] = useState(0);
  const [currentPageData, setCurrentPageData] = useState([]);

  // 사용자 조회 쿼리
  const { data, loading, error, refetch } = useQuery(GET_USERS_WITH_TRACKING_ENABLED_QUERY, {
    variables: { page, pageSize },
    onCompleted: (data) => {
      if (data && data.getAmplitudeTrackingEnabled) {
        setCurrentPageData(data.getAmplitudeTrackingEnabled.userDetails);
        setTotalPages(Math.ceil(data.getAmplitudeTrackingEnabled.totalCount / pageSize));
      }
    },
  });

  useEffect(() => {
    if (data && data.getAmplitudeTrackingEnabled.totalCount) {
      console.log(data.getAmplitudeTrackingEnabled);
      if (data?.getAmplitudeTrackingEnabled?.userDetails.filter((user) => user.memo).length > 0) {
        const userMemos = data.getAmplitudeTrackingEnabled.userDetails.filter((user) => user.memo).reduce((acc, user) => {
          acc[user.userId] = user.memo;
          return acc;
        }, {});
        setUserMemos(userMemos);
      }
      setTotalPages(Math.ceil(data.getAmplitudeTrackingEnabled.totalCount / pageSize));
    }
  }, [data, pageSize]);

  useEffect(() => {
    refetch({ page, pageSize });
  }, [page, pageSize, refetch]);

  // 사용자 추가 뮤테이션
  const [addAmplitudeTrackingEnabled] = useMutation(ADD_AMPLITUDE_TRACKING_ENABLED_MUTATION, {
    onCompleted: (data) => {
      alert(data.addAmplitudeTrackingEnabled.message);
      setUserId('');
      refetch();
    },
  });

  // 사용자 삭제 뮤테이션
  const [removeAmplitudeTrackingEnabled] = useMutation(REMOVE_AMPLITUDE_TRACKING_ENABLED_MUTATION, {
    onCompleted: (data) => {
      alert(data.removeAmplitudeTrackingEnabled.message);
      refetch();
    },
  });

  // 사용자 메모 저장 뮤테이션
  const [saveMemoMutation] = useMutation(SAVE_MEMO_MUTATION);

  const handleAddAmplitudeTrackingEnabled = () => {
    addAmplitudeTrackingEnabled({ variables: { userId } });
  };

  const handleDeleteUser = (userId) => {
    if (window.confirm(`${userId} 사용자의 Amplitude 트래킹을 비활성화하시겠습니까?`)) {
      removeAmplitudeTrackingEnabled({ variables: { userId } });
    }
  };

  // 메모 저장 함수
  const handleSaveMemo = (userId) => {
    const memo = userMemos[userId];
    saveMemoMutation({ variables: { userId, memo } }).then(() => {
      alert("메모가 저장되었습니다.");
      refetch();
    });
  };

  // 메모 입력 처리 함수
  const handleMemoChange = (userId, memo) => {
    console.log(userMemos);
    setUserMemos(prevMemos => ({
      ...prevMemos,
      [userId]: memo,
    }));
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <>
      <h2 style={{ textAlign: 'center', marginBottom: '30px' }}>Amplitude 트래킹 사용자 관리</h2>
      <Container className={classes.container}>
        <div className={classes.addUserSection}>
          <TextField
            label="User ID"
            variant="outlined"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            className={classes.addUserInput}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddAmplitudeTrackingEnabled}
          >
            추가
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.wideCell}>User ID</TableCell>
                <TableCell className={classes.wideCell}>Email</TableCell>
                <TableCell className={classes.wideCell}>Memo</TableCell>
                <TableCell className={classes.narrowCell} align="center">삭제</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentPageData.map((row) => (
                <TableRow key={row.userId}>
                  <TableCell className={classes.wideCell} component="th" scope="row">
                    {row.userId}
                  </TableCell>
                  <TableCell className={classes.wideCell}>{row.email}</TableCell>
                  <TableCell className={classes.memoCell}>
                    <TextField
                      fullWidth
                      multiline
                      variant="outlined"
                      value={userMemos[row.userId] || ''}
                      onChange={(e) => handleMemoChange(row.userId, e.target.value)}
                      placeholder="메모 입력"
                      className={classes.memoInput}
                    />
                    <Button variant="contained" color="primary" onClick={() => handleSaveMemo(row.userId)} className={classes.saveButton}>저장</Button>
                  </TableCell>
                  <TableCell className={classes.narrowCell} align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDeleteUser(row.userId)}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.pagination}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            color="primary"
          />
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    maxWidth: 1200,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'white',
    // border: '1px solid black'
  },
  addUserSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  addUserInput: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  wideCell: {
    width: '40%',
  },
  narrowCell: {
    width: '20%',
  },
  memoCell: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  table: {
    minWidth: 800,
  },
  pagination: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  memoInput: {
    flexGrow: 1,
    width: '300px',
    marginRight: theme.spacing(2),
  },
  saveButton: {
    marginTop: theme.spacing(1),
  },
  memoFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  }
}));