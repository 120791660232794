import React from 'react';
import { Redirect } from "react-router-dom";

import Datasheet from "react-datasheet";

import 'react-datasheet/lib/react-datasheet.css';

import { AgGridReact } from 'ag-grid-react';
import { AllCommunityModules } from "ag-grid-community";

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import ApiService from '../../services/ApiService';

// import * as moment from 'moment';
// import update from 'react-addons-update'

class RequestAdPriceEdit extends React.Component {

  constructor(props) {
    super(props);
    this.api = new ApiService();
    this.loading = false;
    this.state = {
      modules: AllCommunityModules,
      columnDefs: [
        { headerName: "채널ID", field: "channelId", filter: true, width: 120 },
        { headerName: "채널명", field: "title", filter: true, width: 120 },
        { headerName: "기본PPL", field: "ppl", width: 80 },
        { headerName: "브랜디드", field: "branded", width: 80 },
        { headerName: "반영예정", field: "willUpdate", width: 80 },
        { headerName: "비고", field: "remark", width: 160 },
      ],
      defaultColDef: {
        editable: false,
        // enableRowGroup: true,
        // enablePivot: true,
        // enableValue: true,
        sortable: true,
        resizable: true,
      },
      rowSelection: "multiple",
      rowData: [],
      redirect: null,
      grid: [],
      selectedRows: [],
      isFirstPage: true,
      type: "hot",
      inputData: []
    };
  }


  // ============================================================= Life cycle 

  componentDidMount() {
    //데이터 주입
    this.initialize();

  }



  // ============================================================= Component function


  /**
   * 데이터 주입 싱크
   */
  initialize = async () => {
    this.dataInit();
  }

  /**
   * 데이터 주입
   */
  dataInit = async () => {
    let dataSheeteArrs = [];
    let inputData = [];

    dataSheeteArrs.push([{ readOnly: true, value: 'No.', width: "10%" }, { value: "채널ID", readOnly: true }, { value: "기본PPL", readOnly: true, width: "25%" }, { value: "브랜디드", readOnly: true, width: "25%" }]);
    for (let i = 0; i < 100; i++) {
      dataSheeteArrs.push([{ readOnly: true, value: i, width: "10%" }, { value: null, readOnly: false }, { value: null, readOnly: false }, { value: null, readOnly: false }]);
      inputData.push({ channelId: null, ppl: null, branded: null });
    }

    this.setState({
      inputData: inputData,
      grid: dataSheeteArrs
    })
  }

  getMainChannelData = async (params) => {
    let parameter = {};
    parameter.isChannelIds = params.isChannelIds;
    if (params.channelIds) {
      parameter.channelIds = params.channelIds;
    }
    let resData = await this.api.getMainChannels(parameter);
    return resData;
  }

  dataSet = async () => {
    let data = [];

    let resData = await this.getMainChannelData({ isChannelIds: false });
    data = resData ? resData.mainChannels ? resData.mainChannels.hot ? resData.mainChannels.hot : [] : [] : [];

    return data;
  }

  //   this.setState({ 
  //     grid : dataSheeteArrs,
  //       type : value
  //   }); 
  // }

  onGridReady = async (params) => {
    // this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;

    // this.gridApi.sizeColumnsToFit();

    // let resData =  await this.api.getAddNewChannels();

    // this.setState({ 
    //   rowData: resData.newChannels
    // });
  };

  onSelectionChanged = async () => {
    var selectedRows = this.gridApi.getSelectedRows();

    let channelIdArr = selectedRows.map((item, index) => {
      return item._id;
    })

    channelIdArr = channelIdArr.filter(function (item) {
      return item;
    });

    this.setState({
      selectedRows: channelIdArr
    });
  }

  confirmNewChannels = async () => {
    let inputData = this.state.inputData;
    inputData = inputData.filter(data => data && data.channelId);
    let channelIds = inputData.map(data => data.channelId);
    let parameter = { isChannelIds : true , channelIds : JSON.stringify(channelIds) }; 
    let result = await this.getMainChannelData(parameter);
    let channels = (result && result.mainChannels)? result.mainChannels : [];
       
    let passedChannels = []; // 중복확인 용
    let rowData = inputData.map(data => {
      data.ppl = parseInt(data.ppl)? parseInt(data.ppl) : 0;
      data.branded = parseInt(data.branded)? parseInt(data.branded) : 0;
      data.willUpdate = true;
      data.remark = "";

      if(data.ppl >= data.branded) {
        data.willUpdate = false;
        data.remark = "ppl보다 branded가 커야 함.";
      }
      let channel = channels.find(ch => ch.channelId === data.channelId);
      if(channel && channel.title) {
        data.title = channel.title;
      } else {
        data.title = "-";
        data.willUpdate = false;
        data.remark = "채널을 찾을 수 없음.";
      }
      
      if(data.willUpdate === true) {
        if(passedChannels.includes(data.channelId)) { 
          data.willUpdate = false;
          data.remark = "채널 중복.";
        } else {
          passedChannels.push(data.channelId);
        }        
      }

      return data;
    });
    
    // console.log(inputData);
    this.setState({ rowData: rowData });
  }

  addNewChannels = async () => {
    console.log(this.props)

    this.setState({ loading: true });
    let channels = this.state.rowData.filter(ch => ch.willUpdate);

    if(true) {
      let result = await this.api.updateAdPrice(channels);
      // console.log(result);

      alert(channels.length+"건이 저장 되었습니다.");
    } else {
      alert("저장할 값이 없습니다.");
    }

    this.setState({ loading: false });
  }

  getRowStyle = (param) => {
    if(param && param.data && param.data.willUpdate) return null;
    return { color: "#aa0000" };
  }

  // ============================================================= Component Render

  render() {
    if (this.state.redirect) {
      return <Redirect push={true} to={this.state.redirect} />
    }

    return (
      <>
        <div className="row">
          <div className="col-lg-2">
            {/* <label>신규 채널 URL</label> */}
            {/* <select value={this.state.selectValue} onChange={this.onChangeOption}>
              <option value="hot">요즘 뜨는 크리에이터 추가</option>
              <option value="found">당신이 딱 찾던 크리에이터 추가</option>
            </select> */}
          </div>
          <div className="col-lg-10">
          </div>
        </div>

        <div className="section-line"></div>

        <div className="row" style={{ height: "800px" }}>
          <div className="col-lg-6" style={{height: "800px"}}>
            <div className="scrollbar backoffic-scrollbar" style={{ height: "800px" }}>
              <Datasheet
                className="influencer-channels-datasheet"
                data={this.state.grid}
                valueRenderer={(cell) => cell.value}
                onContextMenu={(e, cell, i, j) => cell.readOnly ? e.preventDefault() : null}
                onCellsChanged={changes => {
                  let grid = this.state.grid.map(row => [...row]);
                  let inputData = this.state.inputData;
                  changes.forEach(({ cell, row, col, value }) => {
                    if(col === 1) inputData[row].channelId = value;
                    if(col === 2) inputData[row].ppl = value;
                    if(col === 3) inputData[row].branded = value;

                    grid[row][col] = {...grid[row][col], value}
                  });
                  console.log(inputData);
                  this.setState({
                    grid,
                    inputData,
                    rowData: []
                  })
                }}
              />
            </div>
            <button onClick={this.confirmNewChannels} style={{ marginLeft: "10px" }}>데이터 확인</button>
          </div>
          <div className="col-lg-6">
            <div className="ag-theme-balham" style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                modules={this.state.modules}
                // floatingFilter={true}
                columnDefs={this.state.columnDefs}
                autoGroupColumnDef={this.state.autoGroupColumnDef}
                defaultColDef={this.state.defaultColDef}
                debug={true}
                pagination={true}
                // onSelectionChanged={this.onSelectionChanged.bind(this)}
                rowSelection={this.state.rowSelection}
                onGridReady={this.onGridReady}
                rowData={this.state.rowData}
                getRowStyle={this.getRowStyle}
              >
              </AgGridReact>
            </div>
            <div style={{ marginBottom: 10 }}></div>
            <button onClick={this.addNewChannels} >데이터 반영</button>
          </div>
        </div>
      </>
    );
  }



}

export default (RequestAdPriceEdit);