import React from "react";
import { Auth } from 'aws-amplify';
import { deleteOpenapiCookie } from "../../../utils/cookie";

class Header extends React.Component {
  state = {
    user: null
  }

  async componentDidMount() {
    try{
      let user = await Auth.currentAuthenticatedUser();
      this.setState({user: user});
      deleteOpenapiCookie();
      let session = await Auth.currentSession();
      let groups = session.getIdToken().payload["cognito:groups"];
      if(groups.indexOf('Authorized')===-1){
        alert("인증되지 않은 사용자입니다.");
        Auth.signOut();
      }
      console.log(session.getIdToken());
    }catch(e){
      console.log(e);
    }
  }

  render(){
    let { user } = this.state;
    let username = user ? user.attributes.name : "Guest";
    //let username = user ? user.attributes.email.split('@')[0] : "Guest";
    if(user){
      console.log(`{"Authorization": "Buzz ${user.signInUserSession.idToken.jwtToken}#${user.signInUserSession.accessToken.jwtToken}"}`);
    }
    return (
      <>
        <header id="header" className="header">
          <div className="header-menu">
            <div className="col-sm-7">
            </div>
            <div className="col-sm-5">
              <div className="user-area dropdown float-right">
                <div>
                  <span>{username}</span> <button onClick={() => Auth.signOut()}>Sign Out</button>
                </div>                     
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}
export default Header;
